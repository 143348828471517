import * as React from 'react';
import { useState } from 'react';
import { Alert, Button, Card, Input } from 'components';
import { BankOutlined, CreditCardOutlined } from '@ant-design/icons';
import './create-bank-account.scss';
import { detect_bank, formatCardNumber } from 'utils/scripts/bankDetecter';
import { Modal } from 'components/modal/modal';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { Bank } from 'core/domains';
import { faNumToEnNum, IsNumeric } from 'utils/scripts/decimal';
import { message } from 'components/toast/toast';

type fieldError = {
  cardNumber?: string[];
  iban?: string[];
};

type Props = {
  setCreditCardList: any;
  getMineBankAccounts: any;
};

export const CreateBankAccount: React.FC<Props> = (props) => {
  const [cardNumber, setCardNumber] = useState<string>();
  const [iBan, setIBan] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [bank, setBank] = useState<Bank>();
  const [loading, setLoading] = useState<boolean>(false);

  const [iBanNeed, setIsIBanNeeded] = useState<boolean>(false);

  const [cardLoading, setCardLoading] = useState<boolean>(false);

  const [fieldError, setFieldError] = useState<fieldError>();
  const handleCreditCardChange = (value: any = '') => {
    const cardNumber =
      formatCardNumber(faNumToEnNum(value)) ?? faNumToEnNum(value);
    const card = detect_bank(cardNumber);
    card &&
      setBank({
        code: card?.bank_name,
        name: card?.bank_title,
        bank_logo: card?.bank_logo,
      });
    setCardNumber(cardNumber);
  };

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (cardNumber?.length !== 19) {
      setFieldError({
        ...fieldError,
        cardNumber: ['تعداد رقم شماره کارت وارد شده کمتر از حد مجاز است.'],
      });
    }
    setIsOpen(true);
  }

  const getUserValidationSettings = () => {
    setCardLoading(true);
    getViaAuth<any>(API.validations.settings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setIsIBanNeeded(response.value.validationType.id === 0 ? true : false);
      }
    });
  };

  function handleIBanChange(value: string) {
    setFieldError({});

    // all number Fa to En
    if (value !== '') value = faNumToEnNum(value);
    // validation
    if (value !== '' && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    // set on state
    setIBan(value);
  }

  function handleModalSubmit() {
    setFieldError({ ...fieldError, cardNumber: [''] });

    setLoading(true);
    postViaAuth(API.bankAccount.addCard, {
      cardNumber: cardNumber?.replaceAll('-', ''),
      iban: iBan,
    }).then((response: any) => {
      setLoading(false);

      if (responseValidator(response.status)) {
        message.success('کارت با موفقیت اضافه شد');
        props.getMineBankAccounts();
      }
    });
    setIsOpen(false);
  }

  React.useEffect(() => {
    getUserValidationSettings();
  }, []);
  return (
    <>
      <Modal
        description="در صورت عدم تطابق اطلاعات هویتی صاحب کارت با مشخصات شما یا عدم تطابق شماره کارت و شماره شبا با یکدیگر، کارت مورد نظر ثبت نخواهد شد.
      "
        title="آیا از اطلاعات وارد شده اطمینان دارید؟"
        isOpen={isOpen}
        closeAble
        setIsOpen={setIsOpen}
      >
        <div className="confirm-box">
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              onClick={() => handleModalSubmit()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
      </Modal>
      <div className="xpay-create-bank-account">
        <Card blur={cardLoading} className="right-side">
          <form onSubmit={(e) => handleSubmit(e)}>
            <h4>افزودن حساب جدید</h4>
            <div className="main">
              <Input
                label="شماره کارت*"
                type="text"
                name="card"
                onChange={(el) => handleCreditCardChange(el.target.value)}
                adorments={{
                  startAdornment: {
                    adornmentIcon: bank ? (
                      <img
                        src={bank.bank_logo.toLowerCase() || ''}
                        alt={bank?.code || ''}
                      />
                    ) : (
                      <CreditCardOutlined />
                    ),
                  },
                }}
                value={cardNumber}
                dir={!cardNumber || cardNumber?.length == 0 ? 'rtl' : 'ltr'}
                maxLength={19}
                errors={fieldError?.cardNumber}
                placeholder="شماره کارت خود را وارد کنید"
              />
              {iBanNeed && (
                <Input
                  label="شماره شبا*"
                  type="text"
                  name="iban"
                  dir={iBan === '' ? 'rtl' : 'ltr'}
                  onChange={(el) => handleIBanChange(el.target.value)}
                  adorments={{
                    startAdornment: {
                      adornmentIcon: <BankOutlined />,
                    },
                    endAdornment: {
                      adornmentIcon: 'IR',
                    },
                  }}
                  value={iBan}
                  maxLength={24}
                  errors={fieldError?.iban}
                  placeholder="شماره شبای کارت خود را وارد کنید"
                />
              )}
            </div>
            <Button
              type="info"
              text="ثبت حساب"
              htmlType="submit"
              loading={loading}
              className={`${cardNumber?.length !== 19 ? 'disabled' : ''}`}
              disabled={loading || cardNumber?.length !== 19 ? true : false}
            />
          </form>
        </Card>
        <div className="left-side">
          <Alert
            title="نکته"
            type="warning"
            message="شماره کارت باید به نام خودتان باشد."
            outlinedIcon
          />
          <p>
            بنا به مسائل امنیتی واریز و برداشت تنها به کارت های ثبت شده امکان
            پذیر است. بعدا میتوانید با کلیک روی دکمه ی ویرایش اطلاعات، کارت های
            مجاز را ویرایش کنید.
            <br /> <br />
          </p>
        </div>
      </div>
    </>
  );
};

import * as React from 'react';
import { Button, Input } from 'components';

import './complete.scss';
import { faNumToEnNum, IsNumeric } from 'utils/scripts/decimal';
import { post, postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { message } from 'components/toast/toast';
import { Level4FieldErrorType, Level4FieldValueType } from 'core/domains';
import { CheckCircleFilled } from '@ant-design/icons';

type Props = {};

const { useState } = React;

export const CompleteStage: React.FC<Props> = (props) => {
  const [fieldError, setFieldError] = useState<Level4FieldErrorType>({});
  const [fieldValue, setFieldValue] = useState<Level4FieldValueType>({});
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="complete-stage">
      <CheckCircleFilled />
      <p>شما در آخرین سطح کاربری قرار دارید</p>
    </div>
  );
};

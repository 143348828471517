import * as React from 'react';
import './xpay.scss';
import './../styles/antd.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from 'routes/dashboard/dashboard';
import { SignIn } from 'routes/pages/signin/signin';
import { SignUp } from 'routes/pages/signup/signup';
import { BankAccount } from 'routes/dashboard/bank-account/bank-account';
import Main from 'routes/dashboard/main/main';
import { Markets } from 'routes/dashboard/markets/markets';
import { Authentication } from 'routes/dashboard/authentication/authentication';
import CreateOrder from 'routes/dashboard/orders/create/create-order';
import Widthrawl from 'routes/dashboard/wallet/widthrawl/widthrawl';
import { Wallet } from 'routes/dashboard/wallet/wallet';
import { Profile } from 'routes/dashboard/profile/profile';
import MyOrders from 'routes/dashboard/orders/my-orders/my-orders';
import { Referral } from './dashboard/referral/referral';
import { IrtTransactions } from './dashboard/orders/my-orders/irt-transactions/irt-transactions';
import { isLogin } from '../utils/scripts/api';
import Deposit from './dashboard/wallet/deposit/deposit';
import { CryptoTransactions } from './dashboard/orders/my-orders/crypto-transactions/crypto-transactions';
import { ReferralPurchase } from './dashboard/referral/referral-purchase/referral-purchase';
import { ReferralActionsHistory } from './dashboard/referral/referral-actions-history/referral-actions-history';
import { ThankYouIrt } from './dashboard/wallet/deposit/currency/ThankYouPage/thank-you-irt';
import { BuyPerfectMoneyThankYouPage } from './dashboard/wallet/perfect-money/buy/thankyou/buy-pm-thankyou';
import { PmTransactions } from './dashboard/orders/my-orders/pm-transactions/pm-transactions';
import { MyTrades } from './dashboard/orders/my-orders/trade/my-trades';
import { NotificationsPage } from './dashboard/notifications/notifications';
import LoginStage from 'routes/pages/signin/stages/login/login';
import TwoFactor from 'routes/pages/signin/stages/2fa/index';
import CodeStage from 'routes/pages/signup/stages/code/code';
import { NewPasswordStage } from './pages/signin/stages/new-password/new-password';
import { PasswordReset } from './pages/signin/stages/password-reset/password-reset';
import { PasswordResetCodeStage } from './pages/signin/stages/password-reset-code/password-reset-code';
import { SignupStage } from './pages/signup/stages/signup/signup';
import { BuyUvoucherThankYouPage } from './dashboard/thankyou/u-voucher/thankyou/buy-uusd-thankyou';

export const Xpay: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/dashboard">
          <Route
            path=""
            element={<Dashboard title="داشبورد" component={<Main />} />}
          />
          <Route
            path="bank-accounts"
            element={
              <Dashboard title="حساب های بانکی" component={<BankAccount />} />
            }
          />
          <Route
            path="markets"
            element={<Dashboard title="قیمت لحظه ای" component={<Markets />} />}
          />
          <Route path="orders">
            <Route
              path=""
              element={<Dashboard title="سفارش ها" component={<MyOrders />} />}
            />
            <Route
              path="create-order"
              element={
                <Dashboard title="خرید و فروش" component={<CreateOrder />} />
              }
            />
            <Route
              path="trade"
              element={
                <Dashboard title="سوابق سفارشات" component={<MyTrades />} />
              }
            />
            <Route
              path="irt"
              element={
                <Dashboard
                  title="سوابق ریالی"
                  component={<IrtTransactions />}
                />
              }
            />
            <Route
              path="currency"
              element={
                <Dashboard
                  title="سوابق ریالی"
                  component={<CryptoTransactions />}
                />
              }
            />
            <Route
              path="pm"
              element={
                <Dashboard
                  title="سوابق یو ووچر"
                  component={<PmTransactions />}
                />
              }
            />
            <Route
              path="friends-purchase"
              element={
                <Dashboard
                  title="سوابق رفرال"
                  component={<ReferralPurchase />}
                />
              }
            />
            <Route
              path="received-prizes"
              element={
                <Dashboard
                  title="جوایز"
                  component={<ReferralActionsHistory />}
                />
              }
            />
            <Route
              path="sessions"
              element={
                <Dashboard
                  title="سوابق ارزی"
                  component={<CryptoTransactions />}
                />
              }
            />
          </Route>

          <Route path="pm">
            <Route
              path="verify/:trackingCode"
              element={
                <Dashboard
                  title="نتیجه خرید ووچر"
                  component={
                    <BuyPerfectMoneyThankYouPage depositFromWallet={false} />
                  }
                />
              }
            />
          </Route>
          <Route path="uvoucher">
            <Route
              path="verify/:trackingCode"
              element={
                <Dashboard
                  title="نتیجه خرید یو ووچر"
                  component={
                    <BuyUvoucherThankYouPage depositFromWallet={false} />
                  }
                />
              }
            />
          </Route>
          {/* <Route path="support">
                        <Route path="" element={<Dashboard component={<Support />} />} />
                        <Route
                            path="ticket-detail/:id"
                            element={<Dashboard component={<TicketDetail />} />}
                        />
                    </Route> */}

          <Route path="wallet">
            <Route
              path=""
              element={<Dashboard title="کیف پول" component={<Wallet />} />}
            />
            <Route
              path="deposit/:symbol"
              element={<Dashboard title="واریز" component={<Deposit />} />}
            />
            <Route
              path="deposit/IRT/verify/:trackingCode"
              element={<Dashboard title="واریز" component={<ThankYouIrt />} />}
            />
            <Route
              path="widthrawl/:symbol"
              element={<Dashboard title="برداشت" component={<Widthrawl />} />}
            />
          </Route>

          <Route
            path="referral"
            element={<Dashboard title="دعوت دوستان" component={<Referral />} />}
          />

          <Route
            path="authentication"
            element={
              <Dashboard title="احراز هویت" component={<Authentication />} />
            }
          />

          <Route
            path="notifications"
            element={
              <Dashboard title="نوتیفیکیشن" component={<NotificationsPage />} />
            }
          />

          <Route
            path="profile"
            element={<Dashboard title="تنظیمات" component={<Profile />} />}
          />
        </Route>
        <Route path="/login">
          <Route path="" element={<SignIn component={<LoginStage />} />} />
          <Route
            path="new-password"
            element={<SignIn component={<NewPasswordStage />} />}
          />
          <Route
            path="password-reset"
            element={<SignIn component={<PasswordReset />} />}
          />
          <Route
            path="password-reset-code"
            element={<SignIn component={<PasswordResetCodeStage />} />}
          />
          <Route
            path="two-factor"
            element={<SignIn component={<TwoFactor />} />}
          />
        </Route>
        <Route path="/register">
          <Route path="" element={<SignUp component={<SignupStage />} />} />
          <Route path="code" element={<SignUp component={<CodeStage />} />} />
        </Route>
        <Route
          path="/"
          element={<Dashboard title="داشبورد" component={<Main />} />}
        />
      </Routes>
    </BrowserRouter>
  );

  function RequireAuth({ children }: any) {
    const isAuthenticated = isLogin();
    return isAuthenticated ? children : <Navigate to="/login" />;
  }
};

import * as React from 'react';
import './notifications.scss';
import { Divider } from 'antd';
import { Button, Card } from 'components';
import { Enum, PaginationList } from 'core/domains';
import {
  Notification,
  NotificationFilters,
} from 'core/domains/notifications/notification';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  getMineNotifications,
  getNotificationReasons,
  readNotification,
} from 'core/repositories/notification/notification';

interface Props {}

const defaultNotificationReason = {
  id: 0,
  title: 'همه',
};

const empty_filters = {
  reason: defaultNotificationReason,
};

export const NotificationsPage: React.FC<Props> = (props) => {
  const [notifications, setNotifications] =
    React.useState<PaginationList<Notification>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reasons, setReasons] = React.useState<Enum[]>();
  const [filters, setFilters] =
    React.useState<NotificationFilters>(empty_filters);
  const [query, setQuery] = React.useState<string>('');

  function onReasonTagClick(selected: Enum) {
    setFilters({ ...filters, reason: selected });
  }

  const filterHandle = (newFilters: NotificationFilters) => {
    var q = '';

    if (newFilters) {
      if (newFilters.reason?.id !== 0)
        q += `${q.includes('?') ? '&' : '?'}reason=${newFilters.reason?.id}`;
    }
    setQuery(q);
    getMineNotifications(
      setLoading,
      setNotifications,
      {
        page: 1,
        pageSize: 20,
      },
      q,
    );
  };

  React.useEffect(() => {
    getNotificationReasons(setLoading, setReasons);
  }, []);

  React.useEffect(() => {
    filterHandle(filters);
  }, [filters]);

  return (
    <div className="xpay-notifications-page">
      <Card blur={loading}>
        <div className="head">
          <h4>اعلانات</h4>

          <div className="tags">
            {reasons &&
              reasons.map((item) => (
                <Button
                  type={filters.reason.id === item.id ? 'info' : 'outlined'}
                  text={item.title}
                  onClick={() => onReasonTagClick(item)}
                />
              ))}
          </div>
        </div>
        <div className="main">
          {notifications?.items?.map((notification, index) => {
            return (
              <>
                {index !== 0 && <Divider />}
                <div
                  className="message"
                  onClick={() =>
                    !notification.isRead &&
                    readNotification(
                      setNotifications,
                      setLoading,
                      notification.id,
                    )
                  }
                >
                  <div className="row">
                    <div className="right">
                      {!notification.isRead && <span className="circle"></span>}
                      <span className="title">{notification.title}</span>
                    </div>
                    <div className="left">
                      <span>{notification.dateTime}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="right">
                      <p>{notification.message}</p>
                    </div>
                    <div className="left">
                      <a href="">{'بیشتر بدانید'}</a>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <Divider />
        <div className="footer">
          <RightOutlined
            onClick={() =>
              notifications?.pagination.hasPreviousPage &&
              getMineNotifications(setLoading, setNotifications, {
                page: notifications?.pagination.pageIndex - 1,
                pagesize: 20,
              })
            }
          />

          {notifications?.pagination && (
            <div className="pages">
              {(() => {
                let arr = [];
                for (
                  let i = 1;
                  i <= notifications?.pagination?.totalPages;
                  i++
                ) {
                  arr.push(
                    <Button
                      type={`${
                        i === notifications?.pagination.pageIndex
                          ? 'info'
                          : 'outlined'
                      }`}
                      text={i.toString()}
                      onClick={() =>
                        i !== notifications?.pagination.pageIndex &&
                        getMineNotifications(
                          setLoading,
                          setNotifications,

                          {
                            page: i,
                            pageSize: 20,
                          },
                        )
                      }
                    />,
                  );
                }
                return arr;
              })()}
            </div>
          )}

          <LeftOutlined
            onClick={() =>
              notifications?.pagination.totalPages !=
                notifications?.pagination.pageIndex &&
              notifications?.pagination.pageIndex &&
              getMineNotifications(setLoading, setNotifications, {
                page: notifications?.pagination?.pageIndex + 1,
                pagesize: 20,
              })
            }
          />
        </div>
      </Card>
    </div>
  );
};

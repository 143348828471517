import * as React from 'react';
import './change-phone.scss';
import { QrCode } from 'core/domains/profile/profile';
import { TwoFactor } from 'core/domains/profile/two-factor-auth/two-factor-auth';
import { ChangePhoneConfirmCode } from './stages/code';
import { EnterChangingPhoneNumber } from './stages/phone';

const { useState, useRef } = React;

type Props = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  phone?: string;
  modalStage: 'PHONE' | 'CODE';
  setModalStage: React.Dispatch<React.SetStateAction<'PHONE' | 'CODE'>>;
  setProfileData: any;
  profileData: any;
};

export const ChangePhone: React.FC<Props> = (props) => {
  function renderStage() {
    switch (props.modalStage) {
      case 'PHONE':
        return (
          <EnterChangingPhoneNumber
            phone={props.phone}
            setIsOpen={props.setIsOpen}
            setProfileData={props.setProfileData}
            profileData={props.profileData}
            setStage={props.setModalStage}
          />
        );
      case 'CODE':
        return (
          <ChangePhoneConfirmCode
            phone={props.phone ?? ''}
            setIsOpen={props.setIsOpen}
            setStage={props.setModalStage}
          />
        );
      default:
        return <>invalid stage</>;
    }
  }

  return renderStage();
};

import { Enum } from "core/domains"

export const _types: Enum[] = [
    {
        id: 0,
        title: 'همه',
    },
    {
        id: 1,
        title: 'واریز',
    },
    {
        id: 2,
        title: 'برداشت',
    },
]

export const _states: Enum[] =
    [
        {
            id: 1,
            title: 'در انتظار پرداخت',
        },
        {
            id: 2,
            title: 'انجام شده',
        },
        {
            id: 3,
            title: 'رد شده',
        },
        {
            id: 4,
            title: 'در انتظار',
        },
        {
            id: 5,
            title: 'در انتظار کد تایید',
        },
        {
            id: 6,
            title: 'در انتظار تایید ادمین',
        },
        {
            id: 7,
            title: 'در انتظار تایید ارائه دهنده',
        },
        {
            id: 8,
            title: 'لغو شده',
        },
        {
            id: 9,
            title: 'تایید دستی',
        },
    ]
import { API } from 'apis';
import { message } from 'components/toast/toast';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';

export const getMineNotifications = (
  setLoader: any,
  setResponse: any,
  page?: any,
  query?: string,
) => {
  setLoader(true);

  getViaAuth(
    API.notification.getMine +
      `/${page?.page ? page.page : 1}/${page?.pageSize ? page.pageSize : 20}` +
      (query || ''),
  ).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
    }
  });
};

export const getMineNotificationsDispatch = (
  setLoader: any,
  setResponse: any,
  dispatch?: any,
  page?: any,
  query?: string,
) => {
  setLoader(true);

  getViaAuth(
    API.notification.getMine +
      `/${page?.page ? page.page : 1}/${page?.pageSize ? page.pageSize : 20}` +
      (query || ''),
  ).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      dispatch(setResponse(response.value.items));
    }
  });
};

export const getNotificationReasons = (setLoader: any, setResponse: any) => {
  setLoader(true);

  getViaAuth(API.notification.reasonsEnum).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse([{ id: 0, title: 'همه' }, ...response.value]);
    }
  });
};

export const readNotification = (
  setdata: any,
  setLoader: any,
  id: string,
  dispatch?: any,
) => {
  setLoader(true);
  postViaAuth(API.notification.read, {
    notificationId: id,
  }).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      dispatch
        ? getMineNotifications(setLoader, setdata, dispatch)
        : getMineNotifications(setLoader, setdata);
    }
  });
};

import * as React from 'react';
import { Button, TransactionCard } from 'components';

import './recent-transactions.scss';
import { Link } from 'react-router-dom';
import { getViaAuth, responseValidator } from 'utils/scripts/api';
import { Transaction } from 'core/domains';
import { API } from 'apis';
import { Empty, message, Spin } from 'antd';
import moment from 'jalali-moment';

export const RecentTransactions: React.FC = () => {
  const [cardIsLoading, setCardIsLoading] = React.useState<boolean>(false);
  const [transactions, setTransactions] = React.useState<Transaction[]>([]);

  const getRecentTransactions = () => {
    setCardIsLoading(true);
    getViaAuth<Transaction[]>(API.transactions.recent).then((response: any) => {
      setCardIsLoading(false);
      if (responseValidator(response.status)) {
        setTransactions(response.value);
      }
    });
  };

  React.useEffect(() => {
    getRecentTransactions();
  }, []);
  return (
    <div className="xpay-recent-transactions">
      <div className="header">
        <img src="/assets/icons/recent-transactions/recent-transactions.svg" />
        <h3>تراکنش های اخیر</h3>
        <Link to="/dashboard/history">
          <Button
            type="info"
            text="مشاهده بیشتر"
            icon={
              <img src="/assets/icons/recent-transactions/triple-dots.svg" />
            }
            className="recent-transactions-button"
          />
        </Link>
      </div>
      <Spin className="transactions-list" spinning={cardIsLoading}>
        {transactions.length > 0 ? (
          transactions.map((item) => {
            return (
              <TransactionCard
                date={moment(item.createdOn)
                  .locale('fa')
                  .local()
                  .format('YYYY/MM/DD')}
                amount={item.amount}
                code={item.trackingCode}
                action={item.type}
              />
            );
          })
        ) : (
          <Empty description="تراکنشی یافت نشد" />
        )}
      </Spin>
    </div>
  );
};

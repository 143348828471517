import { HelperCard } from 'components/helper-card/helper-card';
import { HelperRow } from 'components/helper-row/helper-row';
import { LevelCardList } from './level-card/level-card-list/level-card-list';
import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './authentication.scss';
import { AuthForm } from './form/form';
import { Profile, UserLevel } from 'core/domains';
import { message, Spin } from 'antd';
import { Alert } from 'components';
import { HelperBox } from 'components/helper-box/helper-box';
import { getMineLevelAsync } from 'core/repositories';

export const Authentication: React.FC = () => {
  const [level, setLevel] = React.useState<UserLevel>();
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [stage, setStage] = React.useState<
    'BASIC' | 'ADDRESS' | 'UPLOAD_FILES' | 'LANDLINE' | 'COMPLETE'
  >(
    // level?.uniqueName.id == 1
    //   ? 'BASIC'
    //   : level?.uniqueName.id == 2
    //   ? 'LANDLINE'
    //   : level?.uniqueName.id == 3
    //   ? 'COMPLETE'
    //   : 'COMPLETE',
    'UPLOAD_FILES',
  );

  React.useEffect(() => {
    getMineLevelAsync(setCardLoading, setLevel);
  }, [stage]);

  return (
    <div className="xpay-dashboard-authentication">
      <div className="right-side">
        <Spin spinning={cardLoading}>
          <div className="slider">
            <Swiper>
              <SwiperSlide>
                <div className="auth-slide">
                  <div className="content">
                    <h3>احراز هویت</h3>
                    <p>
                      با اهراز هویت سطح کاربری خود را ارتقا دهید<br></br>و از
                      مزایای بیشتر نظیر افزایش سقف واریز و برداشت بهره مند شوید
                      و همچنین محدودیتی در خرید و فروش نداشته باشد.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          {level?.uniqueName.id !== 5 && (
            <Alert
              className="mobile-show"
              title="سطح فعلی"
              type="info"
              message={`شما در حال حاضر شما در ${level?.name ?? ''} هستید`}
            />
          )}

          {level && (
            <AuthForm
              stage={stage}
              setStage={setStage}
              level={level}
              spinning={cardLoading}
            />
          )}
          <HelperRow />
          <HelperBox title={'راهنمای احراز هویت'}>
            <ul>
              <li>
                <p>
                  <span className="number">1</span>
                  از طریق کادر سمت راست میتوانید فرایند احراز هویت خود را مرحله
                  به مرحله طی کنید، همچنین از طریق کادر سمت چپ شما به مشاهده
                  مزایا و مدارک لازم برای احراز هویت هر سطح دسترسی دارید.
                </p>
              </li>
            </ul>
          </HelperBox>
        </Spin>
      </div>

      <div className="left-side">
        <HelperCard title="راهنمای احراز هویت">
          <ul>
            <li>
              <p>
                <span className="number">1</span>
                از طریق کادر سمت راست میتوانید فرایند احراز هویت خود را مرحله به
                مرحله طی کنید، همچنین از طریق کادر سمت چپ شما به مشاهده مزایا و
                مدارک لازم برای احراز هویت هر سطح دسترسی دارید.
              </p>
            </li>
          </ul>
        </HelperCard>
        {level && <LevelCardList level={level} />}
      </div>
    </div>
  );
};

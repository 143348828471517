import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card } from 'components';
import { BalanceCard } from './balance-card/balance-card';
import { Slider } from 'components/slider/slider';
import { PriceBoxSlider } from './price-box/slider/price-box-slider';
import { TransactionBox } from 'components/transaction-box/transaction-box';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './main.scss';
import { RecentTransactions } from './recent-transactions/recent-transactions';
import { Asset } from 'core/domains/markets/asset';
import { ReactComponent as Robot } from 'assets/icons/main/robot.svg';
import { ToIranCurrency } from 'utils/scripts/decimal';
import { getMineBalance } from 'core/repositories';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'core/redux/store';
import { Tooltip } from 'antd';
import { TGBOT_ID } from '../../../apis/constants';

const { useState, useRef } = React;

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const Main: React.FC<Props> = (props) => {
  // States
  const [cardIsLoading, setCardIsLoading] = useState<boolean>(false);
  const [balance, setBalance] = useState<Asset>();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const navigate = useNavigate();

  const fetchAllData = () => {
    !balance && getMineBalance(setCardIsLoading, setBalance);
  };
  React.useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <div className="xpay-dashboard-main">
      <div className="right-side">
        <div className="header">
          <Slider />
        </div>
        <div className="main">
          <div className="xpay-telegram-bot mobile-show">
            <div className="overlay"></div>
            <div className="text">
              <div>
                <p>ربات تلگرام</p>
                <img src="/assets/images/banners/raydown.svg" />
              </div>
              <p>با ربات تلگرامی به صورت سریع خرید و فروش کنید</p>
            </div>
            <button onClick={() => window.open(TGBOT_ID)}>
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.8 0C9.22435 0 9.63131 0.168571 9.93137 0.468629C10.2314 0.768687 10.4 1.17565 10.4 1.6C10.4 2.192 10.08 2.712 9.6 2.984V4H10.4C11.8852 4 13.3096 4.59 14.3598 5.6402C15.41 6.69041 16 8.11479 16 9.6H16.8C17.0122 9.6 17.2157 9.68429 17.3657 9.83431C17.5157 9.98434 17.6 10.1878 17.6 10.4V12.8C17.6 13.0122 17.5157 13.2157 17.3657 13.3657C17.2157 13.5157 17.0122 13.6 16.8 13.6H16V14.4C16 14.8243 15.8314 15.2313 15.5314 15.5314C15.2313 15.8314 14.8243 16 14.4 16H3.2C2.77565 16 2.36869 15.8314 2.06863 15.5314C1.76857 15.2313 1.6 14.8243 1.6 14.4V13.6H0.8C0.587827 13.6 0.384344 13.5157 0.234315 13.3657C0.0842854 13.2157 0 13.0122 0 12.8V10.4C0 10.1878 0.0842854 9.98434 0.234315 9.83431C0.384344 9.68429 0.587827 9.6 0.8 9.6H1.6C1.6 8.11479 2.19 6.69041 3.2402 5.6402C4.29041 4.59 5.71479 4 7.2 4H8V2.984C7.52 2.712 7.2 2.192 7.2 1.6C7.2 1.17565 7.36857 0.768687 7.66863 0.468629C7.96869 0.168571 8.37565 0 8.8 0ZM5.2 8.8C4.66957 8.8 4.16086 9.01071 3.78579 9.38579C3.41071 9.76086 3.2 10.2696 3.2 10.8C3.2 11.3304 3.41071 11.8391 3.78579 12.2142C4.16086 12.5893 4.66957 12.8 5.2 12.8C5.73043 12.8 6.23914 12.5893 6.61421 12.2142C6.98929 11.8391 7.2 11.3304 7.2 10.8C7.2 10.2696 6.98929 9.76086 6.61421 9.38579C6.23914 9.01071 5.73043 8.8 5.2 8.8ZM12.4 8.8C11.8696 8.8 11.3609 9.01071 10.9858 9.38579C10.6107 9.76086 10.4 10.2696 10.4 10.8C10.4 11.3304 10.6107 11.8391 10.9858 12.2142C11.3609 12.5893 11.8696 12.8 12.4 12.8C12.9304 12.8 13.4391 12.5893 13.8142 12.2142C14.1893 11.8391 14.4 11.3304 14.4 10.8C14.4 10.2696 14.1893 9.76086 13.8142 9.38579C13.4391 9.01071 12.9304 8.8 12.4 8.8Z"
                  fill="url(#paint0_linear_413_4753)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_413_4753"
                    x1="-4.95385"
                    y1="8.97259e-07"
                    x2="21.317"
                    y2="2.74611"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3F62FE" />
                    <stop offset="1" stop-color="#678AF5" />
                  </linearGradient>
                </defs>
              </svg>
              <span>عضویت در ربات</span>
            </button>
          </div>
          <Card className="links-row mobile-show">
            <Link to="wallet/deposit/irt" className="link-item">
              <img src="/assets/icons/main/empty-wallet-add.svg" />
              <p>واریز تومانی</p>
            </Link>

            <Link to="markets" className="link-item">
              <img src="/assets/icons/main/status-up.svg" />
              <p>قیمت لحظه‌ای</p>
            </Link>
            <Link to="authentication" className="link-item">
              <img src="/assets/icons/main/medal-star.svg" />
              <p>ارتقاء سطح</p>
            </Link>
          </Card>
          {
            <TransactionBox
              available={
                balance
                  ? balance?.assets?.filter((q) => q.symbol === 'IRT')[0]
                      ?.available ?? 0
                  : 0
              }
              allBalanceSelectAble={false}
              paymentTypes={[
                {
                  id: 1,
                  title: 'درگاه بانکی',
                },
                {
                  id: 2,
                  title: 'کیف پول',
                  balance: ToIranCurrency(8550400),
                },
              ]}
              isCompact
            />
          }
          <div className="banner-columns">
            <div className="xpay-upgrade-level">
              <div className="overlay"></div>
              <div className="level">
                <div>
                  <img src="/assets/images/banners/medal.svg" />
                  <div>
                    <p>{props.state.profileData.level.title}</p>
                    <Tooltip
                      openClassName="open-tooltip"
                      title={props.state.profileData.level.description}
                    >
                      <div className="pros">
                        <span>مشاهده مزایای سطح</span>
                        <InfoCircleOutlined />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <svg
                  width="48"
                  height="24"
                  viewBox="0 0 48 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-5.24537e-07 12L11.3882 11.44L11.9457 -5.22163e-07L12.5032 11.44L48 12L12.5032 12.56L11.9457 24L11.3882 12.56L-5.24537e-07 12Z"
                    fill="white"
                  />
                </svg>
              </div>
              <Button
                text="ارتقاء سطح"
                type="info"
                onClick={() => navigate('/dashboard/authentication')}
              />
            </div>
            <div className="xpay-telegram-bot desktop-show">
              <div className="overlay"></div>
              <div className="text">
                <div>
                  <p>ربات تلگرام</p>
                  <img src="/assets/images/banners/raydown.svg" />
                </div>
                <p>
                  در ربات تلگرامی به صورت آنی و سریع خرید و فروش ووچر پرفکت مانی
                  را انجام دهید.
                </p>
              </div>
              <button onClick={() => window.open(TGBOT_ID)}>
                <Robot />

                <span>عضویت در ربات</span>
              </button>
            </div>
          </div>
        </div>
        <div className="footer">
          <PriceBoxSlider />
        </div>
      </div>
      <div className="left-side">
        <BalanceCard loading={cardIsLoading} balance={balance} />
        <RecentTransactions />
      </div>
    </div>
  );
};

export default connector(Main);

import * as React from 'react';
import './order-box.scss';
import { Button, Input, Modal } from 'components';
import { faNumToEnNum } from 'utils/scripts/decimal';
import {
  Bank,
  Cart,
  DepositVoucher,
  DepositVoucherError,
  Fiat,
  Market,
  MarketAllPrices,
} from 'core/domains';
import { Divider, Spin } from 'antd';
import { isNumeric } from 'utils/scripts/exchange';
import { Link, useLocation } from 'react-router-dom';
import { AssetItem } from 'core/domains/markets/asset';
import { getViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { getMineCarts } from 'core/repositories';
import { OrderModalPayment } from './modal/payment/payment';
import { OrderModalCart } from './modal/cart/cart';
import { OrderModalThankyou } from './modal/thankyou/thankyou';
import { OrderModalConfirm } from './modal/confirm/confirm';
import { OrderBoxCalculator } from './calculator/calculator';
import { getMarketType, MarketType } from 'utils/scripts/market';
import { IsPersianChar } from '../../../../utils/scripts/stringExtentions';

type Props = {
  alertBox?: any;
  setDepositStage: React.Dispatch<any>;
  setDepositData: React.Dispatch<any>;
  allMarkets: Market[];
  allFiats: Fiat[];
  allBalanceSelectAble: boolean;
  side: string;
  setSide: React.Dispatch<React.SetStateAction<string>>;
  setSourceMarket: React.Dispatch<React.SetStateAction<Market | undefined>>;
  setDestinationMarket: React.Dispatch<
    React.SetStateAction<Market | undefined>
  >;
  sourceMarket?: Market;
  destinationMarket?: Market;
  hasInputrange?: boolean;
  cardLoading: boolean;
  isCompact?: boolean;
  allPrices: MarketAllPrices[];
  allBalances: AssetItem;
  onSideChangeFetch: () => void;
};

export const OrderBox: React.FC<Props> = ({
  alertBox,
  allMarkets,
  isCompact,
  allFiats,
  setDepositStage,
  allBalances,
  side,
  setSide,
  destinationMarket,
  setDestinationMarket,
  sourceMarket,
  setSourceMarket,
  setDepositData,
  allPrices,
  onSideChangeFetch,
}) => {
  //loadings
  const [cardIsLoading, setCardIsLoading] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [modalLoading, setModalLoading] = React.useState<boolean>(false);
  const [cardNumber, setCardNumber] = React.useState<string | undefined>('');
  const [bank, setBank] = React.useState<Bank>();
  const [iBan, setIBan] = React.useState<string | undefined>('');
  const [modalStage, setModalStage] = React.useState<
    'cart' | 'payment' | 'thankyou' | 'confirm'
  >('payment');

  const [carts, setCarts] = React.useState<Cart[]>();
  const [selectedCart, setSelectedCart] = React.useState<Cart>();
  const [isIBanNeeded, setIsIBanNeeded] = React.useState<boolean>(false);

  const [commissions, setCommissions] = React.useState<any[]>([]);

  //orders

  const [sourceValue, setSourceValue] = React.useState<number>(0);
  const [destinationValue, setDestinationValue] = React.useState<number>(0);

  const [sourceDropdownIsOpen, setSourceDropdownIsOpen] =
    React.useState<boolean>(false);
  const [destinationDropdownIsOpen, setDestinationDropdownIsOpen] =
    React.useState<boolean>(false);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  (window as any).dataLayer = (window as any).dataLayer || [];

  function checkDisable(): boolean {
    if (loading || modalLoading) return true;
    if (sourceMarket) {
      if (sourceMarket?.symbol.toLowerCase() == 'pm') {
        return !voucher?.voucher_code || !voucher?.activation_code;
      } else if (sourceMarket?.symbol.toLowerCase() == 'uusd') {
        return !voucher?.voucher_code;
      } else return sourceValue == 0 || destinationValue == 0;
    }
    return true;
  }

  function queryChecker() {
    var side = query.get('side')?.toLowerCase() ?? 'buy';
    setSide(side);
    let symbol = query.get('symbol')?.toUpperCase();

    if (side === 'sell') {
      setDestinationMarket(allMarkets.filter((s) => s.symbol === 'IRT')[0]);
      symbol
        ? symbol === 'PM' || symbol === 'USDT'
          ? setSourceMarket(allFiats.filter((s) => s.symbol === symbol)[0])
          : setSourceMarket(allMarkets.filter((s) => s.symbol === symbol)[0])
        : setSourceMarket(allFiats.filter((s) => s.symbol === 'PM')[0]);
    } else {
      symbol
        ? symbol === 'PM' || symbol === 'USDT'
          ? setDestinationMarket(allFiats.filter((s) => s.symbol === symbol)[0])
          : setDestinationMarket(
              allMarkets.filter((s) => s.symbol === symbol)[0],
            )
        : setDestinationMarket(allFiats.filter((s) => s.symbol === 'PM')[0]);
    }
  }

  const [voucher, setVoucher] = React.useState<DepositVoucher>();
  const [fieldError, setFieldError] = React.useState<DepositVoucherError>({});

  const getUserValidationSettings = () => {
    setCardIsLoading(true);
    getViaAuth<any>(API.validations.settings).then((response: any) => {
      setCardIsLoading(false);
      if (responseValidator(response.status)) {
        setIsIBanNeeded(response.value.validationType === 0 ? true : false);
      }
    });
  };

  const createPmAndUusdOrder = () => {
    setModalStage('payment');
    setModalIsOpen(true);
  };
  const getCommision = async () => {
    setCardIsLoading(true);
    await getViaAuth<any>(API.orders.commisions).then((response: any) => {
      setCardIsLoading(false);
      if (responseValidator(response.status)) {
        setCommissions(response.value);
      }
    });
  };
  const handleCrypto = async () => {
    await getCommision();
    setModalStage('confirm');
    setModalIsOpen(true);
  };
  const onNumberHandler = (value: string, field: string) => {
    if (value !== '') value = faNumToEnNum(value);

    if (value !== '' && !isNumeric(value[value.length - 1])) return;

    setVoucher({
      ...voucher,
      [field]: value,
    });
  };
  const onEnglishFieldChange = (value: string, field: string) => {
    if (value !== '') value = faNumToEnNum(value);

    if (value !== '' && IsPersianChar(value[value.length - 1])) return;

    setVoucher({
      ...voucher,
      [field]: value,
    });
  };
  const OrderBoxSwitch: React.FC = () => {
    return (
      <div className={`switch`}>
        <button
          className={`buy ${side === 'buy' ? 'active' : ''}`}
          onClick={() => handleAction('buy')}
        >
          خرید
        </button>
        <button
          className={`sell ${side === 'sell' ? 'active' : ''}`}
          onClick={() => handleAction('sell')}
        >
          فروش
        </button>
      </div>
    );
  };

  function handleSubmit() {
    sourceMarket?.symbol === 'PM' ||
    destinationMarket?.symbol === 'PM' ||
    sourceMarket?.symbol === 'UUSD' ||
    destinationMarket?.symbol === 'UUSD'
      ? createPmAndUusdOrder()
      : handleCrypto();
  }

  function handleAction(buttonName: 'sell' | 'buy') {
    if (side !== buttonName) {
      setSourceValue(0);
      setDestinationValue(0);
      setSide(buttonName);
      setSourceMarket(destinationMarket);
      setDestinationMarket(sourceMarket);
      setSourceDropdownIsOpen(false);
      setDestinationDropdownIsOpen(false);
      destinationMarket &&
        buttonName === 'sell' &&
        getMarketType(destinationMarket?.symbol) !== MarketType.PM &&
        onSideChangeFetch();
    }
  }

  React.useEffect(() => {
    carts && setSelectedCart(carts[0]);
    getUserValidationSettings();
  }, [carts]);

  React.useEffect(() => {
    setSourceMarket(allMarkets.filter((s) => s.symbol === 'IRT')[0]);
    setDestinationMarket(allFiats.filter((s) => s.symbol === 'PM')[0]);

    queryChecker();
    getCommision();

    getMineCarts(setCardIsLoading, setCarts);
  }, []);

  const clearInputs = () => {
    setVoucher({ voucher_code: '', activation_code: '' });
  };
  return (
    <>
      <Modal
        closeAble
        title={`${
          modalStage === 'payment'
            ? side === 'buy'
              ? 'انتخاب روش پرداخت'
              : 'انتخاب روش برداشت'
            : modalStage === 'cart'
            ? 'افزودن حساب بانکی'
            : `جزئیات سفارش`
        }`}
        className={`${
          destinationMarket?.symbol === 'PM' ||
          destinationMarket?.symbol === 'UUSD'
            ? 'buy-pm-modals'
            : 'order-confirm-modal'
        }`}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      >
        {modalStage === 'payment' ? (
          <OrderModalPayment
            allBalances={allBalances}
            bank={bank}
            carts={carts}
            destinationValue={destinationValue}
            fieldError={fieldError}
            selectedCart={selectedCart}
            setBank={setBank}
            setCardIsLoading={setCardIsLoading}
            setCardNumber={setCardNumber}
            setCarts={setCarts}
            setDepositData={setDepositData}
            setDepositStage={setDepositStage}
            setFieldError={setFieldError}
            setModalIsOpen={setModalIsOpen}
            setModalLoading={setModalLoading}
            setModalStage={setModalStage}
            setDestinationValue={setDestinationValue}
            setSourceValue={setSourceValue}
            setSelectedCart={setSelectedCart}
            side={side}
            sourceValue={sourceValue}
            voucher={voucher}
            cardNumber={cardNumber}
            destinationMarket={destinationMarket}
            iBan={iBan}
            setIBan={setIBan}
            sourceMarket={sourceMarket}
          />
        ) : modalStage === 'cart' ? (
          <OrderModalCart
            bank={bank}
            isIBanNeeded={isIBanNeeded}
            setBank={setBank}
            setCardNumber={setCardNumber}
            setIBan={setIBan}
            setLoading={setLoading}
            setModalStage={setModalStage}
            cardNumber={cardNumber}
            iBan={iBan}
          />
        ) : modalStage === 'thankyou' ? (
          <OrderModalThankyou
            destinationValue={destinationValue}
            setModalIsOpen={setModalIsOpen}
            side={side}
            clearInput={clearInputs}
            sourceValue={sourceValue}
            destinationMarket={destinationMarket}
            sourceMarket={sourceMarket}
          />
        ) : (
          sourceMarket &&
          destinationMarket && (
            <OrderModalConfirm
              commissions={commissions}
              destinationMarket={destinationMarket}
              destinationValue={destinationValue}
              setDestinationValue={setDestinationValue}
              setModalIsOpen={setModalIsOpen}
              setSourceValue={setSourceValue}
              side={side}
              sourceMarket={sourceMarket}
              sourceValue={sourceValue}
            />
          )
        )}
      </Modal>

      <Spin spinning={cardIsLoading}>
        <div className={`xpay-order-box ${side} ${isCompact && 'compact'}`}>
          <div className="content">
            <OrderBoxSwitch />
            {isCompact && (
              <>
                <Divider />
                <div className="currencies-row">
                  <Link to={`orders/create-order`} className="currency">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="40"
                        height="40"
                        rx="5"
                        fill="url(#paint0_linear_2697_10348)"
                      />
                      <path
                        d="M28.7498 16.0252C27.8123 15.1596 26.5154 13.8377 25.4592 12.9002L25.3967 12.8565C25.2927 12.773 25.1754 12.7075 25.0498 12.6627C22.503 12.1877 10.65 9.97214 10.4187 10.0003C10.3539 10.0093 10.292 10.0328 10.2375 10.069L10.1781 10.1159C10.105 10.1901 10.0495 10.2798 10.0156 10.3784L10 10.419V10.6409V10.6753C11.3344 14.3908 16.603 26.5625 17.6405 29.4188C17.703 29.6125 17.8218 29.9812 18.0436 30H18.0936C18.2124 30 18.7186 29.3313 18.7186 29.3313C18.7186 29.3313 27.7685 18.3564 28.6841 17.1877C28.8026 17.0437 28.9073 16.8889 28.9966 16.7252C29.0194 16.5971 29.0087 16.4653 28.9654 16.3427C28.9221 16.22 28.8479 16.1106 28.7498 16.0252ZM21.0405 17.3033L24.9029 14.1002L27.1685 16.1877L21.0405 17.3033ZM19.5405 17.0939L12.8906 11.644L23.6498 13.6283L19.5405 17.0939ZM20.1405 18.522L26.9467 17.4252L19.1655 26.8L20.1405 18.522ZM11.9875 12.1877L18.9843 18.1252L17.9718 26.8063L11.9875 12.1877Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2697_10348"
                          x1="-25.8015"
                          y1="13.8182"
                          x2="54.337"
                          y2="35.7626"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#21BCF3" />
                          <stop offset="1" stop-color="#7BD5ED" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <div className="currency-name">ترون</div>
                  </Link>
                  <Link to={`orders/create-order`} className="currency">
                    <svg
                      width="41"
                      height="40"
                      viewBox="0 0 41 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.666504"
                        width="40"
                        height="40"
                        rx="5"
                        fill="url(#paint0_linear_2697_10349)"
                      />
                      <path
                        d="M31.2779 19.4519C31.2779 18.0892 27.57 16.9523 22.6425 16.69L22.6421 14.5187H28.7946V10H11.971V14.5187H18.1231V16.7006C13.2853 16.9809 9.6665 18.106 9.6665 19.4519C9.6665 20.7981 13.2853 21.9233 18.1231 22.2031V30H22.6421V22.2137C27.57 21.952 31.2779 20.8147 31.2779 19.4519ZM20.4722 20.7944C15.1832 20.7944 10.8955 19.9758 10.8955 18.9654C10.8955 18.1099 13.97 17.3917 18.1231 17.1918V17.7068H18.1236V19.817C18.8723 19.859 19.6592 19.881 20.4722 19.881C21.2207 19.881 21.9477 19.862 22.6426 19.8271V17.184C26.8857 17.3716 30.0491 18.0974 30.0491 18.9654C30.049 19.9758 25.7612 20.7944 20.4722 20.7944Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2697_10349"
                          x1="-10.5922"
                          y1="2.24315e-06"
                          x2="49.2264"
                          y2="5.68444"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#3F62FE" />
                          <stop offset="1" stop-color="#678AF5" />
                        </linearGradient>
                      </defs>
                    </svg>

                    <div className="currency-name">
                      {
                        allFiats?.filter(
                          (fiat) => fiat.symbol.toLowerCase() === 'usdt',
                        )[0]?.name
                      }
                    </div>
                  </Link>
                  <Link to={`orders/create-order`} className="currency">
                    <img src="/assets/icons/transaction-box/pm.svg" />
                    <div className="currency-name">
                      {
                        allFiats?.filter(
                          (fiat) => fiat.symbol.toLowerCase() === 'pm',
                        )[0]?.name
                      }
                    </div>
                  </Link>
                  <Link to={`orders/create-order`} className="currency">
                    <img src="/assets/icons/transaction-box/vpm.svg" />
                    <div className="currency-name">ووچر</div>
                  </Link>
                </div>
              </>
            )}
            {!isCompact && (
              <OrderBoxCalculator
                allBalances={allBalances}
                allFiats={allFiats}
                allMarkets={allMarkets}
                commissions={commissions}
                destinationDropdownIsOpen={destinationDropdownIsOpen}
                destinationMarket={destinationMarket}
                destinationValue={destinationValue}
                isCompact={isCompact}
                setDestinationDropdownIsOpen={setDestinationDropdownIsOpen}
                setDestinationMarket={setDestinationMarket}
                setDestinationValue={setDestinationValue}
                setSourceDropdownIsOpen={setSourceDropdownIsOpen}
                setSourceMarket={setSourceMarket}
                setSourceValue={setSourceValue}
                side={side}
                sourceDropdownIsOpen={sourceDropdownIsOpen}
                sourceMarket={sourceMarket}
                sourceValue={sourceValue}
                allPrices={allPrices}
              />
            )}

            {sourceMarket?.symbol === 'PM' && (
              <div className="deposit-options">
                <div className="deposit-value-row">
                  <Input
                    type="text"
                    value={voucher?.voucher_code}
                    label="کد ووچر (E-voucher)"
                    adorments={{
                      startAdornment: {
                        adornmentIcon: (
                          <div className=" symbolLogo">
                            <div className="selected-cart">
                              <img src={`/coins/pm.svg`} />
                            </div>
                          </div>
                        ),
                      },
                    }}
                    dir={voucher?.voucher_code ? 'ltr' : 'rtl'}
                    onChange={(e) => {
                      onNumberHandler(e.target.value, 'voucher_code');
                    }}
                    name="voucher_code"
                    placeholder="کد ووچر خود را وارد کنید"
                    maxLength={30}
                    errors={fieldError?.voucher_code}
                    onPressEnter={handleSubmit}
                  />
                </div>
                <div className="deposit-value-row">
                  <Input
                    type="text"
                    value={voucher?.activation_code}
                    label="فعال ساز ووچر (activation code)"
                    onChange={(e) => {
                      onNumberHandler(e.target.value, 'activation_code');
                    }}
                    adorments={{
                      startAdornment: {
                        adornmentIcon: (
                          <div className=" symbolLogo">
                            <div className="selected-cart">
                              <img src={`/coins/pm.svg`} />
                            </div>
                          </div>
                        ),
                      },
                    }}
                    dir={voucher?.activation_code ? 'ltr' : 'rtl'}
                    name="activation_code"
                    placeholder="فعال ساز ووچر را وارد کنید"
                    maxLength={20}
                    errors={fieldError?.activation_code}
                    onPressEnter={handleSubmit}
                  />
                </div>
              </div>
            )}
            {sourceMarket?.symbol === 'UUSD' && (
              <div className="deposit-options">
                <div className="deposit-value-row">
                  <Input
                    type="text"
                    value={voucher?.voucher_code}
                    label="کد ووچر (E-voucher)"
                    adorments={{
                      startAdornment: {
                        adornmentIcon: (
                          <div className=" symbolLogo">
                            <div className="selected-cart">
                              <img src={`/coins/UUSD.png`} />
                            </div>
                          </div>
                        ),
                      },
                    }}
                    dir={voucher?.voucher_code ? 'ltr' : 'rtl'}
                    onChange={(e) => {
                      onEnglishFieldChange(e.target.value, 'voucher_code');
                    }}
                    name="voucher_code"
                    placeholder="کد ووچر خود را وارد کنید"
                    maxLength={30}
                    errors={fieldError?.voucher_code}
                    onPressEnter={handleSubmit}
                  />
                </div>
              </div>
            )}
          </div>

          {sourceMarket?.symbol !== 'PM' &&
            destinationMarket?.symbol !== 'PM' &&
            sourceMarket?.symbol !== 'UUSD' &&
            destinationMarket?.symbol !== 'UUSD' &&
            alertBox &&
            alertBox}
          <div className="submit">
            <Button
              type="info"
              loading={loading}
              onClick={handleSubmit}
              text="پرداخت نهایی"
              disabled={checkDisable()}
            />
          </div>
        </div>
      </Spin>
    </>
  );
};

import { Fiat, Market } from 'core/domains';
import { faNumToEnNum } from 'utils/scripts/decimal';
import {
  deleteCommaSperator,
  digitSperator,
  isNumeric,
} from 'utils/scripts/exchange';
import {
  exchangeFromDestination,
  exchangeFromSource,
  Side,
} from '../calculator/script/calculator';

export const handleTransactionBoxChange = (
  allFiats: Fiat[],
  sourceMarket: Market | undefined,
  setSourceValue: any,
  destinationMarket: Market | undefined,
  setDestinationValue: any,
  side: any,
  value: any,
  clearInputs: any,
) => {
  value = deleteCommaSperator(faNumToEnNum(value.toString()));

  var char =
    value.length !== 0 ? value.split('')[value.split('').length - 1] : '';

  if (isNumeric(char)) {
    value = faNumToEnNum(value);
  } else {
    return;
  }

  if (side === 'source') setSourceValue(digitSperator(faNumToEnNum(value)));
  else if (side == 'destination')
    setDestinationValue(digitSperator(faNumToEnNum(value)));

  if (value !== '') value = faNumToEnNum(value);
  else clearInputs();

  if (side === 'source') {
    setSourceValue(digitSperator(value));

    if (value !== '' && sourceMarket && destinationMarket) {
      setDestinationValue(0);
      var orderSide = sourceMarket?.symbol === 'IRT' ? Side.Buy : Side.Sell;
      var orderValue = parseFloat(deleteCommaSperator(faNumToEnNum(value)));
      setDestinationValue(
        digitSperator(
          exchangeFromSource(
            orderSide,
            sourceMarket?.symbol === 'IRT' ? destinationMarket : sourceMarket,
            orderValue,
            allFiats,
          ),
        ),
      );
    }
  } else if (side === 'destination') {
    setDestinationValue(digitSperator(faNumToEnNum(value)));
    if (value !== '' && sourceMarket && destinationMarket) {
      setSourceValue(0);
      var orderSide = sourceMarket?.symbol === 'IRT' ? Side.Buy : Side.Sell;
      var orderValue = parseFloat(deleteCommaSperator(faNumToEnNum(value)));
      setSourceValue(
        digitSperator(
          exchangeFromDestination(
            orderSide,
            sourceMarket?.symbol === 'IRT' ? destinationMarket : sourceMarket,
            orderValue,
            allFiats,
          ),
        ),
      );
    }
  }
};

import * as React from 'react';
import { ReactComponent as CaretDown } from 'assets/icons/carrot-down-blue.svg';
import './deposit-crypto-box.scss';
import { Alert, Button, Input, Modal } from 'components';
import {
  CloseOutlined,
  CopyOutlined,
  FileSearchOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { faNumToEnNum } from 'utils/scripts/decimal';
import { Spin } from 'antd';
import { AddressWallet, Market, Network } from 'core/domains/index';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { message } from 'components/toast/toast';
import { getDepositInfo } from 'core/repositories';
import { getIconPath } from 'utils/scripts/getIconPath';
import { deleteCommaSperator, digitSperator } from 'utils/scripts/exchange';
import { copyClipboard } from 'utils/scripts/copyToClipboard';
import useOnBlur from 'utils/scripts/useOnBlur';
import { DepositCryptoNetworksModal } from './networks-modal/networks-modal';

const { useState } = React;

type Props = {
  markets?: Market[];
  setMarketName?: React.Dispatch<any>;
};

export const DepositCryptoBox: React.FC<Props> = (props) => {
  const [cardIsLoading, setCardIsLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  // states
  const [txId, setTxId] = useState<string>('');

  const [search, setSearch] = useState<string>();

  const [market, setMarket] = useState<Market>();
  const [results, setResults] = useState<Market[]>([]);
  const [networks, setNetworks] = useState<Network[]>([]);
  const [addressWallets, setAddressWallets] = useState<AddressWallet[]>([]);
  const [addressWallet, setAddressWallet] = useState<AddressWallet>();
  const [trackingCode, setTrackingCode] = useState<string | undefined>();

  const [depositValue, setDepositValue] = useState<string>('');

  const [marketsIsOpen, setMarketsIsOpen] = useState<boolean>(false);
  const [networksModalIsOpen, setNetworksModalIsOpen] =
    useState<boolean>(false);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalStage, setModalStage] = useState<'qrcode' | 'confirm'>('confirm');
  const [step, setStep] = useState<number>(1);

  const marketDropdownRef = React.useRef<any>();
  const networkDropdownRef = React.useRef<any>();
  const walletAddressRef = React.useRef<any>();

  useOnBlur(marketDropdownRef, () => marketsIsOpen && setMarketsIsOpen(false));

  const onMarketChangeHandler = (market: Market) => {
    setMarket(market);
    props.setMarketName && props.setMarketName(market.name);
    getDepositInfo(
      setCardIsLoading,
      market.symbol,
      setNetworks,
      setAddressWallets,
    );

    window.history.replaceState(null, '', `${market?.symbol}`);

    setMarketsIsOpen(false);
  };

  const onNetworkChangeHandler = (network: AddressWallet) => {
    setAddressWallet(
      addressWallets.filter((q) => q.network == network.network)[0],
    );
    setNetworksModalIsOpen(false);
  };

  React.useEffect(() => {
    props.markets && props.markets.length != 0 && setResults(props.markets);
    console.log(
      addressWallets.filter((q) => q.network.toUpperCase() === 'TRX')[0],
    );
    Object.keys(addressWallets).length > 0 &&
      setAddressWallet(
        addressWallets.filter((q) => q.network.toUpperCase() === 'TRX').length >
          0
          ? addressWallets.filter((q) => q.network.toUpperCase() === 'TRX')[0]
          : addressWallets[0],
      );
  }, []);

  React.useEffect(() => {
    props.markets &&
      setMarket(
        props.markets.filter(
          (s) =>
            s.symbol.toLowerCase() ===
            window.location.href.split('/')[6].toLocaleLowerCase(),
        )[0],
      );
  }, [props.markets]);

  React.useEffect(() => {
    market &&
      networks.length === 0 &&
      getDepositInfo(
        setCardIsLoading,
        market.symbol,
        setNetworks,
        setAddressWallets,
      );
  }, [market]);

  React.useEffect(() => {
    props.setMarketName && props.setMarketName(market?.name);
  }, [market]);

  React.useEffect(() => {
    if (marketsIsOpen)
      props.markets &&
        setResults(
          props.markets.filter(
            (s) => s.symbol.includes('') || s.name.includes(''),
          ),
        );
  }, [marketsIsOpen]);
  React.useEffect(() => {
    addressWallets &&
      Object.keys(addressWallets).length > 0 &&
      setAddressWallet(
        addressWallets.filter((q) => q.network.toUpperCase() === 'TRX').length >
          0
          ? addressWallets.filter((q) => q.network.toUpperCase() === 'TRX')[0]
          : addressWallets[0],
      );
  }, [addressWallets]);

  const confirmTransaction = () => {
    setLoading(true);
    postViaAuth(API.deposit.crypto.step2, {
      txId: txId,
      trackingCode: trackingCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success(
          'نراکنش شما با موفقیت ثبت شد نتیجه تا ۱۰ دقیقه دیگر به شما اعلام خواهد شد.',
        );
        setModalIsOpen(false);
        setStep(1);
        setTxId('');
        setDepositValue('');
      } else {
        setModalIsOpen(false);
      }
    });
  };

  const nextToStep2Handle = () => {
    setLoading(true);
    postViaAuth(API.deposit.crypto.step1, {
      network: addressWallet?.network,
      symbol: market?.symbol,
      amount: depositValue,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setTrackingCode(response.value.trackingCode);

        setStep(2);
      }
    });
  };

  const onSearchChangeHandler = (value: string) => {
    if (value !== '') {
      props.markets &&
        setResults(
          props.markets.filter(
            (s) =>
              s.symbol.toLowerCase().includes(value.trim()) ||
              s.name.includes(value),
          ),
        );
    } else {
      props.markets && setResults(props.markets);
    }
    setSearch(value);
  };

  const onSubmit = () => {
    if (step === 2) {
      setModalStage('confirm');
      setModalIsOpen(true);
    } else {
      nextToStep2Handle();
    }
  };
  return (
    <>
      <DepositCryptoNetworksModal
        isOpen={networksModalIsOpen}
        setIsOpen={setNetworksModalIsOpen}
        addressWallets={addressWallets}
        onNetworkChange={onNetworkChangeHandler}
      />
      <Modal
        className="confirm-deposit-crypto-modal"
        isOpen={modalIsOpen}
        closeAble
        description={
          modalStage === 'confirm'
            ? 'آیا اطلاعات زیر را جهت واریز تایید می کنید؟'
            : 'بارکد زیر را جهت دریافت آدرس والت اسکن کنید.'
        }
        title={
          modalStage === 'confirm' ? 'تایید اطلاعات واریز' : 'آدرس والت واریزی'
        }
        setIsOpen={setModalIsOpen}
      >
        {modalStage === 'confirm' ? (
          <div className="confirm-box">
            <div className="content">
              <ul>
                <li>
                  <span>ارز واریزی</span>
                  <span>{market?.symbol}</span>
                </li>
                <li>
                  <span>مقدار واریزی</span>
                  <span>{digitSperator(depositValue)}</span>
                </li>
                <li>
                  <span>شبکه انتقال</span>
                  <span>{addressWallet?.name}</span>
                </li>
                <li>
                  <span>آدرس واریزی</span>
                  <span>{addressWallet?.walletAddress}</span>
                </li>
                <li>
                  <span>شناسه واریز</span>
                  <span>{txId}</span>
                </li>
              </ul>
            </div>
            <div className="buttons">
              <Button
                type="info"
                text="بله"
                loading={loading}
                disabled={loading}
                htmlType="button"
                onClick={() => confirmTransaction()}
              />
              <Button
                type="outlined"
                text="خیر"
                onClick={() => setModalIsOpen(false)}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="address-wallet-info">
              <img
                src={`data:image/png;base64,${addressWallet?.qrCodeUrl}`}
                alt=""
              />
            </div>
          </>
        )}
      </Modal>
      <Spin spinning={cardIsLoading}>
        <div className={`xpay-deposit-crypto-box `}>
          <div className="content">
            <div className="deposit-options">
              <div className="deposit-value-row">
                <div className="market-selector" ref={marketDropdownRef}>
                  <span className="input-info-label">
                    {`سقف واریز : نامحدود`}
                  </span>
                  <Input
                    type="text"
                    value={digitSperator(depositValue.toString())}
                    onChange={(e) => {
                      setDepositValue(
                        deleteCommaSperator(faNumToEnNum(e.target.value)),
                      );
                    }}
                    name="depositValue"
                    dir={depositValue === '' ? 'rtl' : 'ltr'}
                    label={`مقدار ${market?.name}`}
                    placeholder="مقدار واریز را وارد کنید"
                    maxLength={12}
                    disabled={step === 2}
                    onPressEnter={() =>
                      !loading &&
                      depositValue !== '0' &&
                      txId !== '' &&
                      onSubmit()
                    }
                    adorments={{
                      startAdornment: {
                        adornmentIcon: (
                          <div
                            className="mark"
                            onClick={() => setMarketsIsOpen(!marketsIsOpen)}
                          >
                            <div className="selected-item">
                              <img src={getIconPath(market?.symbol || 'btc')} />
                              <span>{market?.name}</span>
                              <span>{market?.symbol}</span>
                            </div>
                            <div className="icon">
                              <CaretDown />
                            </div>
                          </div>
                        ),
                      },
                    }}
                  />
                  {marketsIsOpen && (
                    <div className={`markets-dropdown`}>
                      <div className="searchbox">
                        <Input
                          type="text"
                          dir={'rtl'}
                          autoFocus
                          placeholder="نام یا نماد ارز مورد نظر خود را جست و جو کنید"
                          adorments={{
                            startAdornment: {
                              adornmentIcon: <FileSearchOutlined />,
                            },
                            endAdornment: {
                              adornmentIcon: (
                                <CloseOutlined
                                  onClick={() => setMarketsIsOpen(false)}
                                />
                              ),
                            },
                          }}
                          name="searchvalue"
                          value={search}
                          onChange={(e) =>
                            onSearchChangeHandler(e.target.value)
                          }
                        />
                      </div>
                      <ul>
                        {results.map((market) => {
                          return (
                            <li
                              onClick={() => onMarketChangeHandler(market)}
                              key={`market-${market.symbol}`}
                            >
                              <img src={getIconPath(market?.symbol || 'btc')} />
                              <span>{market.name}</span>
                              <span>({market.symbol})</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div className="network-options-row" ref={networkDropdownRef}>
                <div className="network-selector">
                  <Input
                    value={addressWallet?.name}
                    type="text"
                    onChange={(e) => {}}
                    name="network"
                    onClick={() => step !== 2 && setNetworksModalIsOpen(true)}
                    readonly
                    label="شبکه انتقال"
                    adorments={{
                      endAdornment: {
                        adornmentIcon: (
                          <div className="icon">
                            <CaretDown
                              onClick={() =>
                                step !== 2 && setNetworksModalIsOpen(true)
                              }
                            />
                          </div>
                        ),
                      },
                    }}
                  />
                </div>
              </div>

              {addressWallet && addressWallet.minDeposit && (
                <Alert
                  className="minimum-withdrawal"
                  type={'warning'}
                  message={` حداقل میزران واریز ارز ${addressWallet.minDeposit} واحد میباشد.واریز مبلع کمتر از حد مجاز منجر به از دست رفتن دارایی میشود.`}
                />
              )}

              {step === 2 && (
                <>
                  {addressWallet && (
                    <>
                      <Input
                        ref={walletAddressRef}
                        value={addressWallet.walletAddress}
                        type="text"
                        onChange={(e) => {}}
                        disabled
                        dir="ltr"
                        className="address-wallet-input"
                        name="addressWallet"
                        label="آدرس کیف پول"
                        onPressEnter={() =>
                          !loading &&
                          depositValue !== '0' &&
                          txId !== '' &&
                          onSubmit()
                        }
                        adorments={{
                          endAdornment: {
                            adornmentIcon: (
                              <>
                                <CopyOutlined
                                  onClick={() =>
                                    copyClipboard(
                                      addressWallet?.walletAddress,
                                      'آدرس کیف پول',
                                    )
                                  }
                                />
                                <QrcodeOutlined
                                  onClick={() => {
                                    setModalStage('qrcode');
                                    setModalIsOpen(true);
                                  }}
                                />
                              </>
                            ),
                          },
                        }}
                      />
                      {addressWallet.walletTag && (
                        <Input
                          value={addressWallet.walletTag}
                          type="text"
                          onChange={(e) => {}}
                          disabled
                          dir="ltr"
                          className="memo-address-input"
                          name="memoAddress"
                          label="آدرس ممو"
                          onPressEnter={() =>
                            !loading &&
                            depositValue !== '0' &&
                            txId !== '' &&
                            onSubmit()
                          }
                          adorments={{
                            endAdornment: {
                              adornmentIcon: (
                                <>
                                  <CopyOutlined
                                    onClick={() =>
                                      copyClipboard(
                                        addressWallet?.walletTag,
                                        'آدرس تگ',
                                      )
                                    }
                                  />
                                  <QrcodeOutlined />
                                </>
                              ),
                            },
                          }}
                        />
                      )}
                    </>
                  )}
                  {addressWallet?.network !== 'trx' ? (
                    <Input
                      value={txId}
                      type="text"
                      onChange={(e) => {
                        setTxId(e.target.value);
                      }}
                      onPressEnter={() =>
                        !loading &&
                        depositValue !== '0' &&
                        txId !== '' &&
                        onSubmit()
                      }
                      dir={txId === '' ? 'rtl' : 'ltr'}
                      className="txid-input"
                      name="txid"
                      label="لینک تراکنش (TXID)"
                    />
                  ) : (
                    <Alert
                      type="success"
                      message="پرداخت شما بلافاصله بعد از ثبت به آدرس اختصاصی فوق سبب شارژ کیف پول شما به صورت اتوماتیک خواهد شد."
                      title="نکته"
                    />
                  )}
                </>
              )}
            </div>

            {(addressWallet?.network !== 'trx' || step === 1) && (
              <div className="submit">
                <Button
                  loading={loading}
                  disabled={
                    loading || (step === 1 ? depositValue === '' : txId === '')
                  }
                  type="info"
                  onClick={onSubmit}
                  text={step === 2 ? 'ثبت اطلاعات پرداخت' : 'بعدی'}
                />
              </div>
            )}
          </div>
        </div>
      </Spin>
    </>
  );
};

import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import './sidebar.scss';
import SidebarItem from 'components/sidebar/sidebar-item/sidebar-item';
import quitIcon from 'assets/icons/quit.svg';
import avatar from 'assets/images/avatar.png';

import { RootState } from 'core/redux/store';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { setMenuItem } from 'core/redux/actions';
import { MenuItem, SidebarItems } from 'utils/constants/common';
import { isLogin } from 'utils/scripts/api';
import { Button } from 'components/button/button';
import { Modal } from 'components/modal/modal';
import { Drawer } from 'antd';
import { isMobile } from 'react-device-detect';
import { Logo } from '../../apis/constants';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface PropTypes extends PropsFromRedux {
  items: MenuItem[];
}

const { useState, useEffect } = React;

const Sidebar: React.FC<PropTypes> = ({ state, items }) => {
  const [stage, setStage] = useState<'PARENT' | 'CHILDREN'>('PARENT');
  const [menuChildItems, setMenuChildItems] =
    useState<{ id: number; title: string; link: string }[]>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.trim();
  const navigate = useNavigate();

  const [userName, setUserName] = useState<string | null>('');

  function handleModalSubmit() {
    setModalIsOpen(false);
    navigate('/login');
  }

  const closeDrawer = () => {
    setModalIsOpen(false);
  };
  React.useEffect(() => {
    if (isLogin()) setUserName(localStorage.getItem('phoneNumber'));
    else window.open('/login', '_self');
  }, []);

  return (
    <div
      className={`xpay-sidebar ${state.sidebar === 'close' ? 'disActive' : ''}`}
    >
      <Drawer
        onClose={closeDrawer}
        closable
        className="xpay-logout-drawer"
        open={isMobile && modalIsOpen === true ? true : false}
        placement="bottom"
        title="آیا می‌خواهید خارج شوید؟"
      >
        <div className="buttons">
          <Button
            type="info"
            text="بله مطمئنم"
            htmlType="button"
            onClick={() => handleModalSubmit()}
          />
          <Button
            type="outlined"
            text="بازگشت"
            onClick={() => setModalIsOpen(false)}
          />
        </div>
      </Drawer>

      <Modal
        className="logout-modal"
        isOpen={!isMobile && modalIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <div className="confirm-box">
          <div className="head">
            <h4>آیا می‌خواهید خارج شوید؟</h4>
          </div>
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              onClick={() => handleModalSubmit()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setModalIsOpen(false)}
            />
          </div>
        </div>
      </Modal>
      <div className="navbar-user">
        <div className="mobile-leftside">
          <div className="navbar-notification">
            <svg
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2095 20.8C14.2095 21.1183 14.0839 21.4235 13.8602 21.6485C13.6366 21.8736 13.3332 22 13.017 22H6.65658C6.34029 22 6.03695 21.8736 5.8133 21.6485C5.58965 21.4235 5.46401 21.1183 5.46401 20.8C5.46401 20.4817 5.58965 20.1765 5.8133 19.9515C6.03695 19.7264 6.34029 19.6 6.65658 19.6H13.017C13.3332 19.6 13.6366 19.7264 13.8602 19.9515C14.0839 20.1765 14.2095 20.4817 14.2095 20.8ZM19.4091 16.9992C19.2368 17.3048 18.9866 17.5586 18.6844 17.7345C18.3821 17.9105 18.0387 18.0021 17.6895 18H1.98398C1.63479 18.0021 1.29136 17.9104 0.989079 17.7345C0.686801 17.5586 0.436613 17.3047 0.264298 16.9991C0.0902931 16.6936 -0.000848712 16.3474 5.95595e-06 15.9952C0.000860623 15.643 0.0936817 15.2973 0.269168 14.9926C0.834645 14.0136 1.50894 12.1446 1.50894 8.8V8.4C1.50631 6.17492 2.38218 4.03991 3.9439 2.46462C5.50562 0.889329 7.62526 0.00278308 9.83657 0L9.90017 0.000200081C14.4576 0.0343001 18.1654 3.843 18.1654 8.4904V8.8C18.1654 12.1448 18.8392 14.014 19.4045 14.993C19.5799 15.2977 19.6727 15.6434 19.6735 15.9955C19.6743 16.3476 19.5831 16.6937 19.4091 16.9992ZM17.0287 15.6C16.0062 13.445 15.7802 10.7553 15.7802 8.8V8.4904C15.7802 5.1566 13.1345 2.4244 9.88248 2.4002C9.86734 2.40008 9.8522 2.40002 9.83706 2.4C9.05532 2.40131 8.28148 2.55755 7.55974 2.85979C6.83799 3.16202 6.18248 3.60435 5.63062 4.1615C5.07877 4.71865 4.64138 5.37972 4.34342 6.10696C4.04546 6.83421 3.89278 7.61338 3.89409 8.4V8.8C3.89409 10.7553 3.66789 13.4451 2.64487 15.6H17.0287Z"
                fill="#4E4E4E"
              />
            </svg>
          </div>
        </div>

        <div className="navbar-profile">
          <div className="avatar">
            <img src={avatar}></img>
          </div>
          <div className="dropdown">
            <h4>
              {state.profileData.level.uniqueName.id
                ? state.profileData.level.uniqueName.id > 1
                  ? state.profileData.fullName
                    ? state.profileData.fullName
                    : state.profileData.phoneNumber
                  : state.profileData.phoneNumber
                : state.profileData.phoneNumber}{' '}
            </h4>
            <span>
              <span>
                {SidebarItems.filter((q) => q.link == path)[0]?.title ??
                  'داشبورد'}
              </span>
            </span>
          </div>
        </div>
      </div>

      <Link to="/" className="sidebar-head">
        <img src={Logo} alt="Logo" />
      </Link>
      <div className="sidebar-main desktop-show">
        {items.map((item: MenuItem, index) => {
          return (
            <SidebarItem
              key={index}
              id={item.id}
              title={item.title}
              icon={item.icon}
              link={item.link}
              filled={item.filled}
            />
          );
        })}
      </div>
      <div className="sidebar-main mobile-show">
        {stage === 'PARENT' ? (
          items.map((item: MenuItem, index) => {
            return (
              <SidebarItem
                key={index}
                id={item.id}
                title={item.title}
                icon={item.icon}
                link={item.link}
                childrens={item?.childrens}
                setStage={setStage}
                setMenuChildItems={setMenuChildItems}
                filled={item.filled}
              />
            );
          })
        ) : (
          <>
            <SidebarItem
              id={999}
              title="بازگشت به منوی اصلی"
              onClickHandle={() => {
                dispatch(setMenuItem(999));
                setStage('PARENT');
              }}
              className="back-button"
            />
            {menuChildItems?.map((i, index) => (
              <SidebarItem
                key={index}
                id={i.id}
                title={i.title}
                link={i.link}
                setStage={setStage}
                setMenuChildItems={setMenuChildItems}
              />
            ))}
          </>
        )}
      </div>
      <div className="sidebar-footer">
        <div className="sidebar-item">
          <p className="title">حالت شب</p>
          <label className="switch disabled">
            <input disabled={true} type="checkbox" />
            <span className="slider" />
          </label>
        </div>
        <div className="sidebar-item" onClick={() => setModalIsOpen(true)}>
          <img src={quitIcon} alt="quit" />
          <p className="title">خروج</p>
        </div>
      </div>
    </div>
  );
};

export default connector(Sidebar);

import * as React from 'react';
import './level-card.scss';
import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { Rate } from 'antd';
type Props = {
  name: string;
  star: number;
  active: number;
  badge?: string;
  checked?: React.ReactNode;
  setOpen: React.Dispatch<React.SetStateAction<number>>;
};

export const LevelInfoCard: React.FC<Props> = ({
  star,
  active,
  badge,
  name,
  children,
  checked,
  setOpen,
}) => {
  return (
    <div
      className={`xpay-level-info-card ${active === star ? 'open' : ''} ${
        checked ? 'checked' : ''
      }`}
    >
      {badge && <div className="level-badge"> {badge}</div>}
      {checked && <div className="level-checked"> {checked}</div>}
      <div
        onClick={() => {
          active === star ? setOpen(-1) : setOpen(star);
        }}
        className="head"
      >
        <span className="name">{name}</span>
        <Rate disabled count={4} defaultValue={star} />
        <div className="check-advantages">
          {active === star ? (
            <>
              <span>بستن مزایا</span>
              <UpCircleFilled />
            </>
          ) : (
            <>
              <span>مشاهده مزایا</span>
              <DownCircleFilled />
            </>
          )}
        </div>
      </div>
      <div className={`level-card-body ${active == star ? '' : 'd-none'}`}>
        {children}
      </div>
    </div>
  );
};

import { WarningOutlined } from '@ant-design/icons';
import * as React from 'react';
import './notice.scss';


type Props = {
    className?: string,
    text: string
};

export const Notice: React.FC<Props> = (props) => {

    return (
        <div className={`xpay-notice ${props.className}`}>
            <div className="right-side">
                <WarningOutlined />
                <span>نکته:</span>
            </div>
            <span className="left-side">
                {props.text}
            </span>
        </div>
    );
};

import * as React from 'react';
import { Fragment, useRef, useState } from 'react';
import './dropdown.scss';
import { CaretDownOutlined } from '@ant-design/icons';
import useOnBlur from 'utils/scripts/useOnBlur';
import { Enum, XpayComponent } from 'core/domains';
import { Divider } from 'antd';

interface DropdownItem {
  id: number;
  title: string;
  icon?: React.ReactElement;
}

interface Props extends XpayComponent {
  disabled?: boolean;
  onChangeHandle?: (value: number) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  items: DropdownItem[];
  defaultSelected?: DropdownItem;
  label?: string;
  dropDownButtonIcon?: React.ReactNode;
  value?: Enum;
}

export const Dropdown: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [selectedItemm, setSelectedItem] = useState<DropdownItem>(
    props.value ?? props.defaultSelected ?? props.items[0],
  );

  const dropdowRef = useRef<any>();

  useOnBlur(dropdowRef, () => {
    if (isOpen) setIsOpen(false);
  });

  return (
    <div
      onClick={() => !props.disabled && setIsOpen(!isOpen)}
      className={`xpay-dropdown ${
        props.className && props.className !== '' ? props.className : ''
      }`}
    >
      <span className="label">{props.label}</span>
      <div className="dropdown-selector">
        <a role="button">
          {props?.value?.icon ?? selectedItemm?.icon}
          <span> {props?.value?.title ?? selectedItemm?.title}</span>
        </a>
        {props.dropDownButtonIcon ? (
          props.dropDownButtonIcon
        ) : (
          <CaretDownOutlined />
        )}
      </div>
      <div
        ref={dropdowRef}
        className={`dropdown-items ${isOpen ? '' : 'd-none'}`}
      >
        <ul>
          {props.items.length > 0 &&
            props.items
              .filter((i) => i.id !== selectedItemm.id)
              .map((item, idx, arr) => {
                return (
                  <Fragment key={idx}>
                    <li
                      className={`dropdown-item ${
                        item.id === selectedItemm?.id ? 'selected' : ''
                      }`}
                      onClick={() => {
                        setSelectedItem(item);
                        props.onChangeHandle && props.onChangeHandle(item.id);
                        setIsOpen(false);
                      }}
                    >
                      {item.icon} <span>{item.title}</span>
                    </li>
                    {idx !== arr.length - 1 && <Divider />}
                  </Fragment>
                );
              })}
        </ul>
      </div>
    </div>
  );
};

import {
  CaretDownOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Checkbox, Row, Statistic } from 'antd';
import { Button, Card, Dropdown, Input } from 'components';
import * as React from 'react';
import './wallet.scss';
import SkeletonAsset from './skeletons/skeleton-asset';
import { Asset, AssetItem } from 'core/domains/markets/asset';
import { getMineAssets, syncWallet } from 'core/repositories/wallet/wallet';
import { digitSperator } from '../../../utils/scripts/exchange';
import { WalletFilterValues } from 'core/domains/wallet/wallet';
import { AssetCart } from './components/asset-cart';
import moment from 'moment';

export const Wallet: React.FC = () => {
  const { Countdown } = Statistic;
  const [filterValues, setFilterValues] = React.useState<WalletFilterValues>({
    amountSort: 2,
  });
  const [search, setSearch] = React.useState<string>('');
  const [results, setResults] = React.useState<AssetItem[]>();
  const [assets, setAssets] = React.useState<Asset>();
  const [loading, setLoading] = React.useState<boolean>();
  const [balanceLoading, setBalanceLoading] = React.useState<boolean>();
  const [checked, setChecked] = React.useState<boolean>(false);

  React.useEffect(() => {
    getMineAssets(setLoading, setAssets, setResults, filterValues, search);
  }, [filterValues, search]);

  function filterSingleCoin(symbol: string) {
    return assets?.assets.filter(
      (asset) => asset.symbol.toLowerCase() === symbol.toLowerCase(),
    )[0];
  }

  const syncWalletHandle = async () => {
    await syncWallet(setBalanceLoading);
    await getMineAssets(
      setBalanceLoading,
      setAssets,
      setResults,
      filterValues,
      search,
    );
  };

  const isBalanceZero = (symbol: string) => {
    return filterSingleCoin(symbol)?.balance === 0;
  };

  const onCheckboxClickHandle = () => {
    setChecked(!checked);
  };

  return (
    <div className="xpay-wallet-page">
      <Card blur={balanceLoading} title=" معادل تمام دارایی ها">
        <div className="main-row">
          <div className="right-side">
            <span className="irt-balance">
              {assets && digitSperator(assets?.irtBalance.toFixed(0))} تومان{' '}
            </span>
            <span className="usdt-balance">
              (USDT {assets && assets?.usdtBalance.toFixed(2)})
            </span>
          </div>
          <div className="left-side">
            {assets?.withdrawalEnable != null && !assets?.withdrawalEnable && (
              <div className="timer">
                <span>زمان باقی مانده تا پایان محدودیت برداشت:</span>
                <Countdown
                  value={moment
                    .utc(assets?.withdrawalDisableTill)
                    .locale('fa')
                    .local()
                    .format()}
                />
              </div>
            )}

            <Button
              type="primary"
              onClick={() => syncWalletHandle()}
              text={'بروزرسانی'}
              icon={<ReloadOutlined />}
            />
          </div>
        </div>
      </Card>
      <Row className="assets-row">
        <div className="head">
          <div className="mobile-show">
            <Row>
              <h4>سبد دارایی ها</h4>
            </Row>
            <Row>
              <div className="checkbox" onClick={(e) => setChecked(!checked)}>
                <Checkbox checked={checked} />
                <span>نمایش ارز های دارای موجودی</span>
              </div>
            </Row>
          </div>
          <Input
            type="text"
            value={search}
            placeholder="ارز مورد نظر خود را جست و جو کنید"
            onChange={(e) => setSearch(e.target.value)}
            name="searchValue"
            adorments={{
              startAdornment: {
                adornmentIcon: <SearchOutlined />,
              },
              endAdornment: {
                adornmentIcon: <CaretDownOutlined color="#3f62fe" />,
              },
            }}
          />

          <div className="sort">
            <div
              className="checkbox desktop-show"
              onClick={(e) => onCheckboxClickHandle()}
            >
              <Checkbox checked={checked} />
              <span>نمایش ارز های دارای موجودی</span>
            </div>
            <Dropdown
              onChangeHandle={(value) =>
                setFilterValues({ ...filterValues, amountSort: value })
              }
              items={[
                {
                  id: 1,
                  title: 'زیاد به کم',
                  icon: (
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.6792 7.43821C12.9617 7.43821 14.0005 8.45947 14.0005 9.71947C14.0005 10.9787 12.9617 12 11.6792 12C10.3975 12 9.35801 10.9787 9.35801 9.71947C9.35801 8.45947 10.3975 7.43821 11.6792 7.43821ZM5.95671 8.74662C6.51845 8.74662 6.97445 9.19462 6.97445 9.74651C6.97445 10.2977 6.51845 10.7464 5.95671 10.7464H1.51823C0.956486 10.7464 0.500488 10.2977 0.500488 9.74651C0.500488 9.19462 0.956486 8.74662 1.51823 8.74662H5.95671ZM2.82172 0C4.10422 0 5.14296 1.02126 5.14296 2.28053C5.14296 3.54053 4.10422 4.56179 2.82172 4.56179C1.53998 4.56179 0.500488 3.54053 0.500488 2.28053C0.500488 1.02126 1.53998 0 2.82172 0ZM12.9835 1.28137C13.5445 1.28137 14.0005 1.72937 14.0005 2.28053C14.0005 2.83242 13.5445 3.28042 12.9835 3.28042H8.54502C7.98327 3.28042 7.52727 2.83242 7.52727 2.28053C7.52727 1.72937 7.98327 1.28137 8.54502 1.28137H12.9835Z"
                        fill="url(#paint0_linear_417_6211)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_417_6211"
                          x1="-3.29934"
                          y1="6.72944e-07"
                          x2="16.8416"
                          y2="2.15319"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#3F62FE" />
                          <stop offset="1" stopColor="#678AF5" />
                        </linearGradient>
                      </defs>
                    </svg>
                  ),
                },
                {
                  id: 1,
                  title: 'کم به زیاد',
                  icon: (
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.6792 7.43821C12.9617 7.43821 14.0005 8.45947 14.0005 9.71947C14.0005 10.9787 12.9617 12 11.6792 12C10.3975 12 9.35801 10.9787 9.35801 9.71947C9.35801 8.45947 10.3975 7.43821 11.6792 7.43821ZM5.95671 8.74662C6.51845 8.74662 6.97445 9.19462 6.97445 9.74651C6.97445 10.2977 6.51845 10.7464 5.95671 10.7464H1.51823C0.956486 10.7464 0.500488 10.2977 0.500488 9.74651C0.500488 9.19462 0.956486 8.74662 1.51823 8.74662H5.95671ZM2.82172 0C4.10422 0 5.14296 1.02126 5.14296 2.28053C5.14296 3.54053 4.10422 4.56179 2.82172 4.56179C1.53998 4.56179 0.500488 3.54053 0.500488 2.28053C0.500488 1.02126 1.53998 0 2.82172 0ZM12.9835 1.28137C13.5445 1.28137 14.0005 1.72937 14.0005 2.28053C14.0005 2.83242 13.5445 3.28042 12.9835 3.28042H8.54502C7.98327 3.28042 7.52727 2.83242 7.52727 2.28053C7.52727 1.72937 7.98327 1.28137 8.54502 1.28137H12.9835Z"
                        fill="url(#paint0_linear_417_6211)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_417_6211"
                          x1="-3.29934"
                          y1="6.72944e-07"
                          x2="16.8416"
                          y2="2.15319"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#3F62FE" />
                          <stop offset="1" stop-color="#678AF5" />
                        </linearGradient>
                      </defs>
                    </svg>
                  ),
                },
              ]}
            />
          </div>
        </div>
        <div className="mine-assets-list">
          <ul className="desktop-show">
            {results && assets && assets?.assets ? (
              <>
                {search === '' && (
                  <>
                    {filterSingleCoin('pm') && (
                      <AssetCart
                        device="desktop"
                        asset={filterSingleCoin('pm')}
                        isPinned={true}
                        isShowHaveBalance={true}
                      />
                    )}
                    {!filterSingleCoin('uusd') && (
                      <AssetCart
                        device="desktop"
                        asset={filterSingleCoin('uusd')}
                        isPinned={true}
                        isShowHaveBalance={true}
                      />
                    )}
                    {filterSingleCoin('irt') && (
                      <AssetCart
                        device="desktop"
                        asset={filterSingleCoin('irt')}
                        isPinned={true}
                        isShowHaveBalance={true}
                      />
                    )}
                    {filterSingleCoin('usdt') && (
                      <AssetCart
                        device="desktop"
                        asset={filterSingleCoin('usdt')}
                        isPinned={true}
                        isShowHaveBalance={true}
                      />
                    )}
                  </>
                )}
                {assets?.assets
                  .filter(
                    (s) =>
                      s.symbol !== 'IRT' &&
                      s.symbol !== 'PM' &&
                      s.symbol !== 'USDT' &&
                      s.symbol !== 'UUSD' &&
                      s.symbol !== 'CRYPTO',
                  )
                  .map((coin, index) => {
                    return (
                      <AssetCart
                        key={index}
                        asset={coin}
                        isPinned={false}
                        device={'desktop'}
                        isShowHaveBalance={!checked}
                      />
                    );
                  })}
              </>
            ) : (
              <>
                {loading && (
                  <>
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                  </>
                )}
              </>
            )}
          </ul>
          <ul className="mobile-show">
            {results && assets ? (
              <>
                {search === '' && (
                  <>
                    <AssetCart
                      device="mobile"
                      asset={filterSingleCoin('pm')}
                      isPinned={true}
                      isShowHaveBalance={true}
                    />
                    <AssetCart
                      device="mobile"
                      asset={filterSingleCoin('irt')}
                      isPinned={true}
                      isShowHaveBalance={true}
                    />
                    <AssetCart
                      device="mobile"
                      asset={filterSingleCoin('usdt')}
                      isPinned={true}
                      isShowHaveBalance={true}
                    />
                  </>
                )}
                {assets?.assets
                  .filter(
                    (s) =>
                      s.symbol !== 'IRT' &&
                      s.symbol !== 'PM' &&
                      s.symbol !== 'USDT' &&
                      s.symbol !== 'CRYPTO',
                  )
                  .map((coin, index) => {
                    return (
                      <AssetCart
                        key={index}
                        device="mobile"
                        asset={coin}
                        isPinned={false}
                        isShowHaveBalance={!checked}
                      />
                    );
                  })}
              </>
            ) : (
              <>
                {loading && (
                  <>
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                    <SkeletonAsset device="desktop" />
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </Row>
    </div>
  );
};

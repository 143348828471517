import * as React from 'react';
import { Alert, Button, Dropdown, Input, Modal } from 'components';
import { Col, Progress, Row, Tooltip, Upload } from 'antd';
import type { DraggerProps } from 'antd/es/upload';
import type { RcFile, UploadProps } from 'antd/es/upload/interface';
import './upload-files.scss';
import { EyeOutlined } from '@ant-design/icons';
import { message } from 'components/toast/toast';
import {
  Enum,
  Level2AddressFieldErrorType,
  LevelTwoUploadFilesValueType,
  UserLevel,
} from 'core/domains';
import { IsPersianChar } from 'utils/scripts/stringExtentions';
import {
  APPNAME,
  SAMPLEAUTHPHOTOLevel2,
  SAMPLEAUTHPHOTOLevel3,
} from '../../../../../apis/constants';
import { upgradeToLevel3 } from 'core/repositories';
import {
  postViaAuth,
  responseValidator,
} from '../../../../../utils/scripts/api';
import { API } from '../../../../../apis';

type FieldErrorType = {};

type State = {
  title: string;
  id: number;
};

type City = {
  id: number;
  title: string;
  stateId?: number;
};

type Props = {
  level: UserLevel;
  setStage: React.Dispatch<
    React.SetStateAction<
      'BASIC' | 'ADDRESS' | 'UPLOAD_FILES' | 'LANDLINE' | 'COMPLETE'
    >
  >;
};

const { useState } = React;

export const SelfieStage: React.FC<Props> = (props) => {
  const [fieldError, setFieldError] = useState<Level2AddressFieldErrorType>({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<LevelTwoUploadFilesValueType>({
    DocumentFile: [],
    SelfiePic: [],
  });
  const [uploading, setUploading] = useState(false);
  const [isOpenPic, setIsOpenPic] = useState<boolean>(false);
  const [isOpenCart, setIsOpenCart] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const [uploadCancelSource, setUploadCancelSource] =
    useState<AbortController | null>(null);
  const [states, setStates] = useState<Enum[]>();
  const [citiesList, setCitiesList] = useState<City[]>();

  const documentUploadProps: DraggerProps = {
    name: 'document-uploader',
    className: 'document-uploader',
    onRemove: (file) => {
      setFormData({
        ...formData,
        DocumentFile: formData.DocumentFile.filter((m) => m.uid !== file.uid),
      });
    },
    beforeUpload: (file) => {
      const isCorrectFormat =
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg';
      if (!isCorrectFormat)
        message.error(`فرمت تصویر آپلود شده صحیح نمی باشد.`);
      else
        setFormData({
          ...formData,
          DocumentFile: [
            ...formData.DocumentFile,
            {
              file: file,
              type: 'DocumentFile',
              uid: file.uid,
              name: file.name,
            },
          ],
        });

      return false;
    },
    multiple: false,
    fileList: formData.DocumentFile,
  };

  const selfieUploadProps: UploadProps = {
    name: 'selfie-uploader',
    className: 'selfie-uploader',
    onRemove: (file) => {
      setFormData({
        ...formData,
        SelfiePic: formData.SelfiePic.filter((m) => m.uid !== file.uid),
      });
    },
    beforeUpload: (file: RcFile) => {
      const isCorrectFormat =
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg';
      if (!isCorrectFormat)
        message.error(`فرمت تصویر آپلود شده صحیح نمی باشد.`);
      else
        setFormData({
          ...formData,
          SelfiePic: [
            ...formData.SelfiePic,
            { file: file, type: 'SelfiePic', uid: file.uid, name: file.name },
          ],
        });

      return false;
    },
    multiple: false,
    fileList: formData.SelfiePic,
  };

  const submitHandle = () => {
    const form = new FormData();

    const documentFile = formData.DocumentFile[0]?.file;
    if (documentFile) {
      form.append('DocumentFile', documentFile);
    }

    const selfiePic = formData.SelfiePic[0]?.file;
    if (selfiePic) {
      form.append('SelfiePic', selfiePic);
    }

    if (documentFile && selfiePic) {
      setLoading(true);
      postViaAuth(API.authentication.uploadFilesUpgradeTo2, form, true).then(
        (response: any) => {
          setLoading(false);
          if (responseValidator(response.status)) {
            message.success('مدارک شما ارسال شد');
            window.location.reload();
          }
        },
      );
    } else {
      setFieldError({
        ...fieldError,
        address: ['لطفاً تصویر سلفی و مدارک را آپلود کنید.'],
      });
      setLoading(false);
    }
  };

  const { Dragger } = Upload;

  return (
    <form className="form-selfie">
      <Modal
        className="xpay-help-modal"
        isOpen={isOpenCart}
        setIsOpen={setIsOpenCart}
      >
        <img src={SAMPLEAUTHPHOTOLevel2} alt="" />

        <Button
          onClick={() => setIsOpenCart(false)}
          text="متوجه شدم"
          type="primary"
        />
      </Modal>
      <Modal
        className="xpay-help-modal"
        isOpen={isOpenPic}
        setIsOpen={setIsOpenPic}
      >
        <img src={SAMPLEAUTHPHOTOLevel3} alt="" />

        <Button
          onClick={() => setIsOpenPic(false)}
          text="متوجه شدم"
          type="primary"
        />
      </Modal>

      <div className="input-row">
        <div className="browse-field">
          <label htmlFor="browse-field">
            <span>
              تصویر سلفی با مدارک
              <span>(مطابق با نمونه عکس)</span>
            </span>
            <span onClick={() => setIsOpenPic(true)} className="sample">
              مشاهده نمونه عکس
              <Tooltip title="متن راهنمای آپلود فایل" color={'#3f62fe'}>
                <EyeOutlined />
              </Tooltip>
            </span>
          </label>
          <div id="selfie-photo" className="browse">
            <Dragger maxCount={1} {...selfieUploadProps}>
              <button onClick={(el) => el.preventDefault()}>آپلود تصویر</button>
            </Dragger>
          </div>
        </div>
        <div className="browse-field">
          <label htmlFor="browse-field">
            <span>
              تصویر مدارک
              <span>(سمت جلو)</span>
            </span>
            <span onClick={() => setIsOpenCart(true)} className="sample">
              مشاهده نمونه عکس
              <Tooltip title="متن راهنمای آپلود فایل" color={'#3f62fe'}>
                <EyeOutlined />
              </Tooltip>
            </span>
          </label>
          <div id="doc-photo" className="browse">
            <Dragger {...documentUploadProps} maxCount={1}>
              <button onClick={(el) => el.preventDefault()}>آپلود تصویر</button>
            </Dragger>
          </div>
        </div>
      </div>

      {uploading && (
        <div className="progress-bar-container">
          <Progress percent={percent} status="active" />
          <Button
            type="trade"
            text="لغو آپلود"
            htmlType="button"
            onClick={() => {
              if (uploadCancelSource) {
                uploadCancelSource.abort();
                setUploadCancelSource(null);
                setFormData({
                  DocumentFile: [],
                  SelfiePic: [],
                });
                setPercent(0);
                setLoading(false);
                setUploading(false);
              }
            }}
          />
        </div>
      )}

      {props.level.upgradeRequestState === 0 ? (
        <Alert
          title="صبور باشید"
          type="warning"
          message={
            `مدرک ارسالی شما در دست بررسی توسط کارشناسان ` +
            APPNAME +
            ' می باشد.'
          }
        />
      ) : props.level.upgradeRequestState === 2 ? (
        <Alert
          title="مدارک شما رد شد ، دلیل رد :"
          type="danger"
          message={props.level.rejectUpgradeMessage}
        />
      ) : (
        <></>
      )}

      <Button
        type="info"
        text="ثبت اطلاعات"
        htmlType="button"
        loading={loading || uploading}
        disabled={
          loading ||
          uploading ||
          !formData.DocumentFile.length ||
          !formData.SelfiePic.length
        }
        onClick={() => submitHandle()}
      />
    </form>
  );
};

import { message } from "components/toast/toast";

export function copyClipboard(value: string, name: string) {
    navigator.clipboard.writeText(value);
    message.info(`${name} کپی شد`);
}

export function copyToClipboard(element: HTMLElement | null, name: string) {
    navigator.clipboard.writeText(element ? element?.innerText : "");
    message.info(`${name} کپی شد`);
}


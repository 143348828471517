import * as React from 'react';
import './sidebar-item.scss';
import ArrowLeftIcon from 'assets/icons/ArrowLeft.svg';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'core/redux/store';
import { setMenuItem, setSidebar } from 'core/redux/actions';
import { useAppDispatch } from 'core/redux/hooks';
import { Link } from 'react-router-dom';

const mapState = (state: RootState) => ({
  state: state,
});
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface SidebarProps extends PropsFromRedux {
  id?: number;
  title: string;
  icon?: React.ReactNode;
  link?: string;
  className?: string;
  filled?: boolean;
  setStage?: React.Dispatch<React.SetStateAction<'PARENT' | 'CHILDREN'>>;
  childrens?: {
    id: number;
    title: string;
    link: string;
  }[];
  setMenuChildItems?: React.Dispatch<
    React.SetStateAction<
      { id: number; title: string; link: string }[] | undefined
    >
  >;
  onClickHandle?: () => void;
}

const SidebarItem: React.FC<SidebarProps> = ({
  className,
  id,
  state,
  title,
  icon,
  link,
  childrens,
  filled,
  setStage,
  setMenuChildItems,
  onClickHandle,
}) => {
  const dispatch = useAppDispatch();
  return (
    <Link
      to={link ?? ''}
      onClick={() => {
        dispatch(setMenuItem(id ?? -100));
        if (childrens && childrens?.length > 0) {
          setStage && setStage('CHILDREN');
          setMenuChildItems && setMenuChildItems(childrens ? childrens : []);
        } else if (onClickHandle) {
          onClickHandle();
        } else {
          dispatch(setSidebar('close'));
        }
      }}
      className={`sidebar-item ${state.menu === id ? 'active' : ''} ${className ?? ''
        } ${filled ? 'filled' : ''}`}
    >
      <div className="content">
        {icon}
        <p className="title">{title}</p>
      </div>
      <svg
        className={`${state.menu === id && 'active'}`}
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.356857 4.869C0.411469 4.811 0.617674 4.563 0.809755 4.359C1.93588 3.076 4.87359 0.976 6.41119 0.335C6.6447 0.232 7.23507 0.014 7.55049 0C7.85274 0 8.14086 0.072 8.4158 0.218C8.75854 0.422 9.03347 0.743 9.18413 1.122C9.28111 1.385 9.43176 2.172 9.43176 2.186C9.58241 3.047 9.66527 4.446 9.66527 5.992C9.66527 7.465 9.58241 8.807 9.45907 9.681C9.44494 9.695 9.29429 10.673 9.12952 11.008C8.82727 11.62 8.2369 12 7.60511 12H7.55049C7.13903 11.985 6.27372 11.605 6.27372 11.591C4.81898 10.949 1.94906 8.952 0.795631 7.625C0.795631 7.625 0.470788 7.284 0.329551 7.071C0.109223 6.765 0 6.386 0 6.007C0 5.584 0.123346 5.19 0.356857 4.869Z"
          fill="white"
        />
      </svg>
    </Link>
  );
};

export default connector(SidebarItem);

import * as React from 'react';
import { Button, Card } from 'components';
import ReactCodeInput from 'react-verification-code-input';
import {
  TwoFactorAuthSmsVerify,
  TwoFactorAuthSmsVerifyError,
} from 'core/domains/profile/two-factor-auth/two-factor-auth';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  disableOtp,
  setupGoogleAuth,
  verifyNewPhoneNumber,
} from 'core/repositories/profile/profile';
import './index.scss';
import {
  ChangePhoneError,
  ChangePhoneVerify,
} from '../../../../../../core/domains/profile/change-phone/two-factor-auth';

type Props = {
  phone: string;
  setIsOpen: any;
  setStage: any;
};

export const ChangePhoneConfirmCode: React.FC<Props> = ({
  setStage,
  setIsOpen,
  phone,
}) => {
  const [fieldValue, setFieldValue] = React.useState<ChangePhoneVerify>();
  const [fieldError, setFieldError] = React.useState<ChangePhoneError>();
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Card className="xpay-change-phone">
      <>
        <div className="head">
          <div>
            <h4>تایید شماره موبایل جدید</h4>
          </div>
          <p>
            یک کد ۶ رقمی به شماره قدیمی و شماره جدید{' '}
            {localStorage.getItem('newPhoneNumber') &&
              `(${localStorage.getItem('newPhoneNumber')})`}{' '}
            شما ارسال شد. لطفا کد را اینجا وارد کنید.
          </p>
        </div>

        <div className="main code app-code">
          <p>کد تایید ارسال شده به شماره قدیمی:</p>
          <ReactCodeInput
            className={`input `}
            fieldWidth={48}
            values={
              fieldValue?.previousPhoneCode
                ? fieldValue?.previousPhoneCode.split('')
                : []
            }
            autoFocus={true}
            onChange={(value) => {
              setFieldValue((prev) => ({
                ...prev,
                previousPhoneCode: value,
              }));
            }}
          />
          <div className="stats">
            <div className="errors">
              {fieldError?.previousPhoneCode && (
                <>
                  <QuestionCircleOutlined />
                  <span>{fieldError.previousPhoneCode}</span>
                </>
              )}
            </div>
          </div>
        </div>

        <Button
          type="info"
          text="تایید"
          onClick={() =>
            verifyNewPhoneNumber(
              setLoading,
              setFieldError,
              fieldError,
              fieldValue,
              setIsOpen,
              setStage,
            )
          }
          loading={loading}
          disabled={fieldValue?.previousPhoneCode?.length !== 6 || loading}
        />
      </>
    </Card>
  );
};

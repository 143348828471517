import {QuestionCircleOutlined} from "@ant-design/icons";
import * as React from "react";

import "./helper-card.scss";

type Props = {
    title: string;
    className?: string;
};

export const HelperCard: React.FC<Props> = (props) => {
    return (
        <div className={`xpay-helper-card ${props?.className}`}>
            <div className='title'>
                <span>{props.title}</span>
                <QuestionCircleOutlined/>
            </div>
            {props.children}
        </div>
    );
};

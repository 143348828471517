import * as React from 'react';
import './price-box-slider.scss';
import 'swiper/css';
import { PriceBox } from '../price-box';
import { GlobalOutlined } from '@ant-design/icons';
import { Fiat } from 'core/domains';
import { getAllFiats } from 'core/repositories';
import { Spin } from 'antd';

type Props = {};

export const PriceBoxSlider: React.FC<Props> = (props) => {
  const [fiats, setFiats] = React.useState<Fiat[]>([]);
  const [cardIsLoading, setCardIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    fiats.length === 0 && getAllFiats(setCardIsLoading, setFiats);
  }, []);

  return (
    <Spin spinning={cardIsLoading}>
      <div className="xpay-price-box-slider">
        <div className="head">
          <div className="right-side">
            <GlobalOutlined />
            <span>قیمت لحظه ای</span>
          </div>
        </div>
        <div className="fiat-boxes">
          {fiats.length > 0 &&
            fiats
              .filter((s) => s.symbol !== 'CRYPTO' && s.symbol !== 'UUSD')
              .map((fiat) => {
                return (
                  <PriceBox
                    buyPrice={fiat.buyPrice}
                    sellPrice={fiat.sellPrice}
                    name={fiat.name}
                    symbol={fiat.symbol}
                  />
                );
              })}
        </div>
      </div>
    </Spin>
  );
};

import * as React from 'react';
import { Button } from 'components';
import './index.scss';
import { post, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RootState } from 'core/redux/store';
import { MobileNavigate } from 'components/mobile-navigate/mobile-navigate';
import { Link } from 'react-router-dom';
import { message } from 'components/toast/toast';
import ReactCodeInput from 'react-verification-code-input';
import moment from 'moment';
import { setUserData } from 'core/redux/actions';
import { SITE_URL } from '../../../../../apis/constants';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const { useState } = React;
(window as any).dataLayer = (window as any).dataLayer || [];

const TwoFactorStage: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [code, setCode] = useState<string>('');

  const type = localStorage.getItem('loginTwoFactorType');
  const phoneNumber = localStorage.getItem('phoneNumber');

  React.useEffect(() => {}, []);

  function handleLoginSubmit(code: string): void {
    if (code.length !== 6) return message.error('کد وارد شده صحیح نمی باشد.');
    setIsLoading(true);
    post(API.verify_2fa, {
      phoneNumber: phoneNumber,
      code: code,
    })
      .then(function (response: any) {
        setIsLoading(false);
        if (responseValidator(response.status)) {
          (window as any).dataLayer.push({
            event: 'login',
          });
          dispatch(
            setUserData({
              ...props.state.userData,
              isTwoFactorEnabled: response.data.value.isTwoFactorEnabled,
              twoFactorMethod: response.data.value.twoFactorMethod,
              accessToken: response.data.value.access_token,
              refreshToken: response.data.value.refresh_token,
              tokenType: response.data.value.token_type,
              expiresIn: response.data.value.expires_in,
              roles: response.data.value.roles,
              isAuthorized: response.data.value.isAuthorized,
            }),
          );

          localStorage.setItem('token', response.data.value.access_token);
          response.data.value.fullName &&
            localStorage.setItem('auth', response.data.value.isAuthorized);
          localStorage.setItem('phoneNumber', phoneNumber || '');
          localStorage.setItem('name', response.data.value.fullName);
          localStorage.setItem('date', moment.utc().toString());
          localStorage.setItem('ref', response.data.value.refresh_token);
          localStorage.setItem('expires_in', response.data.value.expires_in);

          window.open('/dashboard', '_self');
        }
      })
      .catch(function (error: any) {
        setIsLoading(false);
      });
  }

  return (
    <div className="twofactor-stage">
      <MobileNavigate
        title="تایید دو مرحله ای"
        onBackClick={() => window.open(SITE_URL, '_self')}
        backBtnDisabled={true}
      />
      <h3>تایید دو مرحله ای</h3>
      <p>کد ۶ رقمی ورود به حساب خود را وارد کنید</p>
      <form>
        <ReactCodeInput
          className={`input `}
          fieldWidth={48}
          values={code ? code.split('') : []}
          autoFocus={true}
          onChange={(value) => {
            setCode(value);
          }}
          onComplete={(e) => handleLoginSubmit(e)}
        />
        <Button
          type="info"
          htmlType="button"
          text="تایید کد"
          disabled={code.length !== 6}
          onClick={() => handleLoginSubmit(code)}
          loading={isLoading ? true : false}
        />
        {parseInt(type || '') === 1 && (
          <div className="signup-link">
            <div>
              <p>به شماره خود دسترسی ندارید؟</p>{' '}
              <Link to="/register">به پشتیبانی اطلاع دهید</Link>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default connector(TwoFactorStage);

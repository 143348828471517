import { API } from 'apis';
import { message } from 'components/toast/toast';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';

export const getMineCarts = (setLoader: any, setResponse: any) => {
  setLoader(true);
  getViaAuth(API.bankAccount.mine).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) setResponse(response.value);
  });
};
export const createCart = (
  cardNumber: string,
  loading: React.Dispatch<React.SetStateAction<boolean>>,
  iban?: string,
) => {
  loading(true);
  postViaAuth(API.bankAccount.addCard, {
    cardNumber: cardNumber.replaceAll('-', ''),
    iban: iban,
  }).then((response: any) => {
    loading(false);
    if (responseValidator(response.status)) {
      message.success('کارت با موفقیت اضافه شد');
    }
  });
};

export const createCartAndReload = (
  cardNumber: string,
  reload: any,
  setdata: any,
  setLoader: any,
  iban?: string,
) => {
  postViaAuth(API.bankAccount.addCard, {
    cardNumber: cardNumber.replaceAll('-', ''),
    iban: iban,
  }).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      reload(setLoader, setdata);
      message.success('کارت با موفقیت اضافه شد');
    }
  });
};

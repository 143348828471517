import { APPNAME } from '../../../../apis/constants';

export const WithdrawlCryptoHelperText = () => {
  return (
    <>
      <li>
        <p>
          <span className="number">1</span>
          کارمزد پرداختی از سوی شما برای تراکنش های رمز ارز ها کارمزد شبکه ارز
          مدنظر میباشد و {APPNAME} از این کارمزد نفعی ندارد
        </p>
      </li>
      <li>
        <p>
          <span className="number">2</span>
          از آنجایی که تراکنش های رمز ارز ها غیر قابل بازگشت است، چنانچه آدرس
          عمومی شخص دریافت کننده اعتبار را به اشتباه اعلام کنید، تراکنش ارسال
          شده قابل بازگشت نخواهد بود.
        </p>
      </li>
      <li>
        <p>
          <span className="number">3</span>
          در هنگام برداشت به نوع شبکه دقت نمائید ، در صورت اشتباه وارد کردن آدرس
          و عدم همخوانی با شبکه ی انتخابی ، ممکن است مقدار برداشت شده در شبکه
          معلق گردد و مسئولیت این مورد با کاربر است و {APPNAME} مسئولیتی در این
          باره نخواهد داشت
        </p>
      </li>
      <li>
        <p>
          <span className="number">4</span>
          با توجه به غیر قابل برگشت بودن تراکنش رمز ارز ها امکان لغو تراکنش یا
          بازگشت دادن وجه پس از انجام امکان پذیر نیست.
        </p>
      </li>
      <li>
        <p>
          <span className="number">5</span>
          پس از ثبت برداشت به آدرس ولت مورد نظر که وارد کرده اید مقدار ثبت شده
          برای برداشت با توجه به شلوغی و وضعیت شبکه تا چند دقیقه به ولت مورد نظر
          انتقال می یابد ، دقت نمائید در برخی مواقع ممکن است انتقال به دلیل
          شلوغی شبکه طولانی باشد
        </p>
      </li>
    </>
  );
};

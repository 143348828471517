import * as React from 'react';
import './calculator.scss';
import 'components/transaction-box/transaction-box.scss';
import { faNumToEnNum, ToIranCurrency } from 'utils/scripts/decimal';
import { OrderBoxCoinsDropDown } from '../dropdown/order-box-dropdown';
import { Fiat, Market, MarketAllPrices } from 'core/domains';
import { AssetItem } from 'core/domains/markets/asset';
import useOnBlur from 'utils/scripts/useOnBlur';
import { getIconPath } from 'utils/scripts/getIconPath';
import { CaretDownFilled, LoadingOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { handleTransactionBoxChange } from '../scripts';
import { deleteCommaSperator, digitSperator } from 'utils/scripts/exchange';
import { Notice } from 'components/note/notice';
import { getMineBalanceByPair } from '../../../../../core/repositories';

const { useState } = React;

type Props = {
  side: string;
  sourceMarket?: Market;
  destinationMarket?: Market;
  sourceValue: number;
  destinationValue: number;
  commissions: any;
  allBalances: AssetItem;
  allMarkets: Market[];
  allFiats: Fiat[];
  isCompact?: boolean;
  sourceDropdownIsOpen: boolean;
  destinationDropdownIsOpen: boolean;
  setSourceValue: React.Dispatch<any>;
  setDestinationValue: React.Dispatch<any>;
  setDestinationMarket: React.Dispatch<any>;
  setSourceMarket: React.Dispatch<any>;
  setSourceDropdownIsOpen: React.Dispatch<any>;
  setDestinationDropdownIsOpen: React.Dispatch<any>;
  allPrices: MarketAllPrices[];
};

export const OrderBoxCalculator: React.FC<Props> = ({
  side,
  sourceMarket,
  destinationMarket,
  sourceValue,
  destinationValue,
  commissions,
  allMarkets,
  allFiats,
  allBalances,
  isCompact,
  destinationDropdownIsOpen,
  sourceDropdownIsOpen,
  setSourceValue,
  setDestinationValue,
  setDestinationMarket,
  setSourceMarket,
  setDestinationDropdownIsOpen,
  setSourceDropdownIsOpen,
  allPrices,
}) => {
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [balances, setBalances] = useState<AssetItem>(allBalances);
  const [fetchedBalance, setFetchedBalance] = useState<AssetItem>();
  const [fetchedBalanceLoading, setFetchedBalanceLoading] =
    useState<boolean>(false);

  const [balanceRange, setBalanceRange] = useState<number>(0);

  const sourceDropdownRef = React.useRef<any>();
  const destinationDropdownRef = React.useRef<any>();

  useOnBlur(sourceDropdownRef, () => {
    if (sourceDropdownIsOpen) setSourceDropdownIsOpen(false);
  });

  useOnBlur(destinationDropdownRef, () => {
    if (destinationDropdownIsOpen) setDestinationDropdownIsOpen(false);
  });

  const onDestinationMarketSelectHandle = (market: Market) => {
    if (side === 'buy') {
      setDestinationMarket(market);
      handleTransactionBoxChange(
        allFiats,
        sourceMarket,
        setSourceValue,
        market,
        setDestinationValue,
        'source',
        sourceValue.toString(),
        clearInputs,
      );
    } else {
      setDestinationMarket(market);
      clearInputs();
    }
  };

  const onSourceMarketSelectHandle = (market: Market) => {
    setSourceMarket(market);
    clearInputs();
  };

  const clearInputs = () => {
    setSourceValue(0);
    setDestinationValue(0);
  };
  React.useEffect(() => {
    if (destinationMarket?.symbol) {
      if (
        destinationMarket.symbol !== 'PM' &&
        destinationMarket.symbol !== 'UUSD' &&
        destinationMarket.symbol !== 'USDT'
      )
        setDestinationMarket(
          allMarkets.filter((q) => q.symbol == destinationMarket.symbol)[0],
        );
    }
  }, [allMarkets]);

  React.useEffect(() => {
    if (side === 'sell' && sourceMarket)
      getMineBalanceByPair(
        sourceMarket?.symbol.toUpperCase(),
        setFetchedBalanceLoading,
        setFetchedBalance,
      );
  }, [sourceMarket]);

  function onInputRangeChange(value: string) {
    value = deleteCommaSperator(faNumToEnNum(value));
    setBalanceRange(parseFloat(faNumToEnNum(value)));
    handleTransactionBoxChange(
      allFiats,
      sourceMarket,
      setSourceValue,
      destinationMarket,
      setDestinationValue,
      'source',
      (fetchedBalance?.balance ?? 0) * (parseFloat(value) / 100),
      clearInputs,
    );
  }

  return (
    <div className="calculator">
      <div className="input-info-labels">
        <span className="input-info-label">
          {side === 'buy' ? 'پرداخت میکنم' : 'می‌فروشم'}
        </span>
        <span className="input-info-label">
          {sourceMarket && (
            <>
              {allPrices && sourceMarket.symbol === 'IRT' ? (
                <>
                  موجودی قابل تبدیل تومانی :
                  <span>{ToIranCurrency(allBalances.balance)} تومان </span>
                </>
              ) : (
                <>
                  قیمت خرید هر واحد {sourceMarket.name} از شما:
                  <span>
                    {ToIranCurrency(
                      sourceMarket.symbol === 'PM' ||
                        sourceMarket.symbol === 'UUSD' ||
                        sourceMarket.symbol === 'USDT'
                        ? sourceMarket.buyPrice
                        : allPrices.filter(
                            (q) => q.symbol === sourceMarket.symbol,
                          )[0]?.buyPrice,
                    )}{' '}
                    تومان
                  </span>
                </>
              )}
            </>
          )}
        </span>
      </div>
      <div className="field">
        {side === 'sell' && allPrices && (
          <OrderBoxCoinsDropDown
            markets={allMarkets}
            fiats={allFiats}
            onClose={setSourceDropdownIsOpen}
            isOpen={sourceDropdownIsOpen}
            allPrices={allPrices}
            selected={sourceMarket}
            side={side}
            onClick={onSourceMarketSelectHandle}
          />
        )}
        {sourceMarket ? (
          <div
            className={`symbol ${
              sourceMarket?.symbol === 'IRT' && 'symbol-irt'
            }`}
            onClick={() =>
              sourceMarket?.symbol === 'IRT'
                ? setSourceDropdownIsOpen((prev: boolean) => prev)
                : setSourceDropdownIsOpen(true)
            }
          >
            <div className="coin">
              <img
                src={getIconPath(sourceMarket?.symbol?.toLowerCase())}
                alt=""
              />
              <span>{sourceMarket?.name}</span>
              <span>{sourceMarket?.symbol}</span>
            </div>
            {side === 'buy' ? <></> : <CaretDownFilled />}
          </div>
        ) : (
          <>
            <div className="selected-coin-skeleton xpay-skeleton"></div>
          </>
        )}
        <Divider
          className={`${sourceMarket?.symbol === 'IRT' && 'active'}`}
          type="vertical"
        />{' '}
        <input
          type="text"
          placeholder={!isCompact ? '0' : 'پرداخت میکنید'}
          value={sourceValue === 0 ? '' : sourceValue}
          name="source"
          onChange={(el) => {
            handleTransactionBoxChange(
              allFiats,
              sourceMarket,
              setSourceValue,
              destinationMarket,
              setDestinationValue,
              'source',
              el.target.value.toString(),
              clearInputs,
            );
          }}
          disabled={
            sourceMarket?.symbol.toUpperCase() === 'PM' ||
            sourceMarket?.symbol.toUpperCase() === 'UUSD'
          }
          maxLength={30}
          dir="ltr"
        />
      </div>

      {destinationMarket?.symbol !== 'PM' &&
        sourceMarket?.symbol !== 'PM' &&
        destinationMarket?.symbol !== 'UUSD' &&
        sourceMarket?.symbol !== 'UUSD' && (
          <div
            className={`balance-range ${
              allBalances?.balance == 0 ? 'disabled' : ''
            }`}
          >
            <span>100%</span>
            <span>75%</span>
            <span>50%</span>
            <span>25%</span>
            <span>حداقل</span>
            <input
              type="range"
              id="points"
              defaultValue="0"
              name="points"
              step="25"
              min="0"
              max="100"
              dir="ltr"
              value={balanceRange}
              onChange={(e) => onInputRangeChange(e.target.value)}
              disabled={allBalances.balance == 0}
            ></input>
          </div>
        )}
      {!isCompact && sourceMarket?.symbol === 'IRT' && (
        <Notice text=" مبلغ را به تومان نیز می توانید مشخص نمایید." />
      )}
      {(commissions.length > 0 && sourceMarket?.symbol === 'PM') ||
        (commissions.length > 0 && sourceMarket?.symbol === 'UUSD' && (
          <div className="input-info-labels commission">
            <span>کارمزد سفارش :</span>
            <span>
              {side === 'buy'
                ? digitSperator(
                    commissions.filter((s: any) => s.orderSide === 1)[0]
                      .commission,
                  )
                : digitSperator(
                    commissions.filter((s: any) => s.orderSide === 2)[0]
                      .commission,
                  )}{' '}
              تومان
            </span>
          </div>
        ))}
      {!isCompact &&
        sourceMarket?.symbol !== 'PM' &&
        sourceMarket?.symbol !== 'UUSD' && <Divider />}
      {!isCompact &&
        sourceMarket?.symbol !== 'PM' &&
        sourceMarket?.symbol !== 'UUSD' && (
          <div className="input-info-labels">
            <span className="input-info-label">دریافت میکنم</span>
            <span className="input-info-label">
              {destinationMarket && sourceMarket && (
                <>
                  <span>
                    {side === 'buy' ? (
                      <>
                        قیمت خرید هر واحد {destinationMarket.name}:{' '}
                        {sourceMarket.symbol === 'IRT'
                          ? digitSperator(destinationMarket.sellPrice)
                          : destinationMarket.sellPrice}
                        {` `} {sourceMarket.name}
                      </>
                    ) : (
                      <>
                        موجودی قابل تبدیل :{' '}
                        {fetchedBalanceLoading ? (
                          <LoadingOutlined />
                        ) : (
                          digitSperator(fetchedBalance?.balance)
                        )}
                        {` `} {sourceMarket.name}
                      </>
                    )}
                  </span>
                </>
              )}
            </span>
          </div>
        )}

      {sourceMarket?.symbol !== 'PM' && sourceMarket?.symbol !== 'UUSD' && (
        <div className="field">
          {side === 'buy' && allPrices && (
            <OrderBoxCoinsDropDown
              onClose={setDestinationDropdownIsOpen}
              markets={allMarkets}
              side={side}
              fiats={allFiats}
              isOpen={destinationDropdownIsOpen}
              selected={destinationMarket}
              allPrices={allPrices}
              onClick={onDestinationMarketSelectHandle}
            />
          )}
          {destinationMarket ? (
            <div
              className={`symbol ${
                destinationMarket?.symbol === 'IRT' && 'symbol-irt'
              }`}
              onClick={() =>
                destinationMarket?.symbol === 'IRT'
                  ? setDestinationDropdownIsOpen((prev: boolean) => prev)
                  : setDestinationDropdownIsOpen(true)
              }
            >
              <div className="coin">
                <img
                  src={getIconPath(destinationMarket?.symbol?.toLowerCase())}
                  alt=""
                />
                <span>{destinationMarket?.name}</span>
                <span>{destinationMarket?.symbol}</span>
              </div>
              {side === 'sell' ? <></> : <CaretDownFilled />}
            </div>
          ) : (
            <>
              <div className="selected-coin-skeleton xpay-skeleton"></div>
            </>
          )}

          <Divider
            className={`${destinationMarket?.symbol === 'IRT' && 'active'}`}
            type="vertical"
          />
          <input
            type="text"
            value={destinationValue === 0 ? '' : destinationValue}
            placeholder={!isCompact ? '0' : 'دریافت میکنید'}
            name="destination"
            onChange={(el) => {
              handleTransactionBoxChange(
                allFiats,
                sourceMarket,
                setSourceValue,
                destinationMarket,
                setDestinationValue,
                'destination',
                el.target.value.toString(),
                clearInputs,
              );
            }}
            maxLength={30}
            dir="ltr"
          />
        </div>
      )}
      {commissions.length > 0 &&
        (destinationMarket?.symbol === 'PM' ||
          destinationMarket?.symbol === 'UUSD') && (
          <div className="input-info-labels commission">
            <span>کارمزد سفارش :</span>
            <span>
              {side === 'buy'
                ? digitSperator(
                    commissions.filter((s: any) => s.orderSide === 1)[0]
                      .commission,
                  )
                : digitSperator(
                    commissions.filter((s: any) => s.orderSide === 2)[0]
                      .commission,
                  )}{' '}
              تومان
            </span>
          </div>
        )}
    </div>
  );
};

import { Fiat, Market } from 'core/domains';
import { MarketType } from 'utils/scripts/market';
import { roundDown } from '../../../../../../utils/scripts/decimal';

export enum Side {
  Buy = 1,
  Sell,
}

const getMarketType = (market: Market): MarketType => {
  switch (market.symbol) {
    case 'IRT':
      return MarketType.IRT;
    case 'USDT':
      return MarketType.USDT;
    case 'PM':
      return MarketType.PM;
    default:
      return MarketType.Crypto;
  }
};
export const exchangeFromSource = (
  side: Side,
  market: Market,
  amount: number,
  fiats: Fiat[],
) => {
  if (side === Side.Buy) {
    switch (getMarketType(market)) {
      case MarketType.USDT:
        var usdt = fiats.filter((s) => s.symbol === 'USDT')[0];
        return roundDown(amount / usdt.sellPrice, 2);
      case MarketType.Crypto:
        return (amount / market.sellPrice).toFixed(6);
      case MarketType.PM:
        var pm = fiats.filter((s) => s.symbol === 'PM')[0];
        return roundDown(amount / pm.sellPrice, 2);
      default:
        return 0;
    }
  } else {
    switch (getMarketType(market)) {
      case MarketType.USDT:
        var usdt = fiats.filter((s) => s.symbol === 'USDT')[0];
        return (amount * usdt.buyPrice).toFixed(0);
      case MarketType.Crypto:
        return (amount * market?.buyPrice).toFixed(0);
      case MarketType.PM:
        var pm = fiats.filter((s) => s.symbol === 'PM')[0];
        return (amount * pm.buyPrice).toFixed(0);
      default:
        return 0;
    }
  }
};

export const exchangeFromDestination = (
  side: Side,
  market: Market,
  amount: number,
  fiats: Fiat[],
) => {
  var usdt = fiats.filter((s) => s.symbol === 'USDT')[0];

  if (side === Side.Buy) {
    switch (getMarketType(market)) {
      case MarketType.USDT:
        return (amount * usdt.sellPrice).toFixed(0);
      case MarketType.Crypto:
        return (amount * market.sellPrice).toFixed(0);
      case MarketType.PM:
        var pm = fiats.filter((s) => s.symbol === 'PM')[0];
        return (amount * pm.sellPrice).toFixed(0);
      default:
        return 0;
    }
  } else {
    switch (getMarketType(market)) {
      case MarketType.USDT:
        return (amount / usdt.buyPrice).toFixed(2);
      case MarketType.Crypto:
        return (amount / market.buyPrice).toFixed(6);
      case MarketType.PM:
        var pm = fiats.filter((s) => s.symbol === 'PM')[0];
        return (amount * pm.buyPrice).toFixed(2);
      default:
        return 0;
    }
  }
};

export const exchangeFiatFromSource = (
  side: Side,
  amount: number,
  fiat: Fiat,
) => {
  if (side === Side.Sell) return (amount * fiat.sellPrice).toFixed(2);
  else return (amount * fiat.buyPrice).toFixed(0);
};

export const exchangeFiatFromDestination = (
  side: Side,
  amount: number,
  fiat: Fiat,
) => {
  if (side === Side.Buy) return (amount / fiat.sellPrice).toFixed(2);
  else return (amount / fiat.buyPrice).toFixed(2);
};

import * as React from 'react';

import './networks-modal.scss';
import { Alert, Modal } from 'components';
import { Network, NetwrokList } from 'core/domains';
import { ReactComponent as WarningOutlined } from 'assets/icons/information-circle.svg';
import { Tag } from 'antd';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addressWallet: string;
  networks: NetwrokList;
  onNetworkChange: (network: Network) => void;
}

export const WithdrawlCryptoNetworksModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  addressWallet,
  networks,
  onNetworkChange,
}) => {
  const isAddressMatchesRegex = (reg: string) =>
    addressWallet && addressWallet.match(reg);
  return (
    <Modal
      title="انتخاب شبکه"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="withdrawl-crypto-networks-modal"
      closeAble
    >
      <div className="alert">
        <Alert
          message="مطمئن شوید که شبکه انتخابی با شبکه وارد شده شما مطابقت دارد
آدرس، یا ممکن است تراکنش پرداختی شما گم شود.
شبکه هایی که مطابقت ندارند فیلتر شده اند. یک شبکه را از لیست زیر انتخاب کنید."
          type="warning"
          title="هشدار!"
          customIcon={<WarningOutlined />}
        />
      </div>
      <div className={`networks-list`}>
        <ul>
          {Object.keys(networks.networks).length > 0 &&
            networks.networks.map((network) => {
              return (
                <li
                  className={`${
                    (network.isWithdrawEnabled &&
                      !isAddressMatchesRegex(network.addressRegex)) ||
                    !network.isWithdrawEnabled
                      ? 'disabled'
                      : ''
                  }`}
                  onClick={() =>
                    network.isWithdrawEnabled && onNetworkChange(network)
                  }
                  key={`network-${network.network}`}
                >
                  <div className="network-title">
                    <p className="en">
                      {network.name}
                      {!network.isWithdrawEnabled && (
                        <Tag className="unavailable" color="red">
                          در دسترس نیست
                        </Tag>
                      )}
                      {network.isWithdrawEnabled &&
                        addressWallet !== '' &&
                        (isAddressMatchesRegex(network.addressRegex) ? (
                          <Tag
                            title="آدرس کیف پول معتبر است"
                            className="match"
                            color="green"
                          >
                            مطابقت دارد
                          </Tag>
                        ) : (
                          <Tag className="not-match" color="gray">
                            مطابقت ندارد
                          </Tag>
                        ))}
                    </p>
                    <span className="en">{network.network.toUpperCase()}</span>
                  </div>
                  <div className="network-stats">
                    <span className="fee">
                      هزینه تراکنش: {network.withdrawFee} واحد
                    </span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </Modal>
  );
};

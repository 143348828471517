import * as React from 'react';
import './slider.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Button } from 'components/button/button';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { CHANNEL_ID } from '../../apis/constants';

const slides = [
  {
    id: 1,
    title: 'برای با خبر شدن از اخبار صرافی کانال تلگرام ما را دنبال کنید.',
    description: '',
    image: '/assets/images/slides/telegram-logo.svg',
    children: (
      <Button url={CHANNEL_ID} type="secondary" text="عضویت در کانال" />
    ),
  },
  // {
  //   id: 2,
  //   title: 'سطح کاربری شما: دو',
  //   description:
  //     'در سطح دو امکان برداشت رمزارز وجود ندارد اما محدودیت زیادی برای برداشت و واریز تومانی نسبت به بقیه سطح ها اعمال نشده است.',
  //   image: '/assets/images/slides/income-glass.svg',
  // },
];

const { useRef } = React;

export const Slider: React.FC = () => {
  const prevEl = useRef<HTMLDivElement>(null);
  const nextEl = useRef<HTMLDivElement>(null);

  return (
    <div className="xpay-slider">
      <Swiper
        modules={[Navigation, Pagination]}
        pagination
        navigation={{
          nextEl: nextEl.current,
          prevEl: prevEl.current,
        }}
        spaceBetween={50}
        slidesPerView={1}
      >
        {slides.map((slide, index) => {
          return (
            <SwiperSlide>
              <div className="slide-overlay"></div>
              <div className="xpay-slide">
                <div className="right-side">
                  <h2>{slide.title}</h2>
                  <p>{slide.description}</p>
                  {slide.children}
                </div>
                <img
                  className={`shape ${index === 1 ? 'machine' : ''}`}
                  src={slide.image}
                  alt=""
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div ref={nextEl} className="next-navigation">
        <ArrowRightOutlined />
      </div>
      <div ref={prevEl} className="pre-navigation">
        <ArrowLeftOutlined />
      </div>
    </div>
  );
};


import * as React from "react";
import "./skeleton-asset.scss";

type Props = {
    device: 'mobile' | 'desktop'
}
const SkeletonAsset: React.FC<Props> = (props) => {

    return (
        <div className={`xpay-skeleton-asset ${props.device}`}>
            {
                props.device === 'desktop' ? <>
                    <div className="xpay-skeleton box">

                    </div>
                    <div className="xpay-skeleton rectengle">

                    </div>
                    <div className="xpay-skeleton rectengle">

                    </div>
                    <div className="xpay-skeleton rectengle">

                    </div>
                    <div className=" buttons">
                        <div className="xpay-skeleton widthrawl">

                        </div>
                        <div className="xpay-skeleton deposit">

                        </div>
                    </div>
                </> :
                    <>
                        <ul>
                            <li>
                                <span className="xpay-skeleton rectengle"></span>
                                <span className="xpay-skeleton rectengle"></span>
                            </li>
                            <li>
                                <span className="xpay-skeleton rectengle"></span>
                                <span className="xpay-skeleton rectengle"></span>
                            </li>
                            <li>
                                <span className="xpay-skeleton rectengle"></span>
                                <span className="xpay-skeleton rectengle"></span>
                            </li>
                            <li>
                                <span className="xpay-skeleton rectengle"></span>
                                <span className="xpay-skeleton rectengle"></span>
                            </li>
                        </ul>
                    </>
            }
        </div>
    );
};

export default SkeletonAsset;

import * as React from 'react';
import './my-orders.scss';
import { IrtTransactions } from './irt-transactions/irt-transactions';
import { PmTransactions } from './pm-transactions/pm-transactions';
import { CryptoTransactions } from './crypto-transactions/crypto-transactions';
import { MyTrades } from './trade/my-trades';

const { useState } = React;

export enum OrderHistoryTypes {
  PMTransactions = 4,
  CryptoOrders = 1,
  Currency = 2,
  CryptoTransactions,
}

interface Props {
  orderType?: OrderHistoryTypes;
}

const MyOrders: React.FC<Props> = (props) => {
  const [orderType, setOrderType] = useState<number>(
    props.orderType ?? OrderHistoryTypes.PMTransactions,
  );

  return (
    <div className="xpay-my-orders">
      <div className="type-selection">
        <div
          className={`${orderType === 4 ? 'active' : ''}`}
          onClick={() => setOrderType(4)}
        >
          <span>خرید و فروش ووچر</span>
        </div>
        <div
          className={`${orderType === 1 ? 'active' : ''}`}
          onClick={() => setOrderType(1)}
        >
          <span> خرید و فروش رمز ارز</span>
        </div>
        <div
          className={`${orderType === 2 ? 'active' : ''}`}
          onClick={() => setOrderType(2)}
        >
          <span>واریز و برداشت تومانی</span>
        </div>
        <div
          className={`${orderType === 3 ? 'active' : ''}`}
          onClick={() => setOrderType(3)}
        >
          <span>واریز و برداشت ارزی</span>
        </div>
      </div>
      <div className="orders-table">
        {orderType == OrderHistoryTypes.CryptoOrders ? (
          <MyTrades />
        ) : orderType == OrderHistoryTypes.Currency ? (
          <IrtTransactions />
        ) : orderType === OrderHistoryTypes.CryptoTransactions ? (
          <CryptoTransactions />
        ) : orderType === OrderHistoryTypes.PMTransactions ? (
          <PmTransactions />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MyOrders;

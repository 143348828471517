import { Button } from 'components';
import * as React from 'react';
import { Fragment } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { AssetItem } from 'core/domains/markets/asset';
import { getIconPath } from 'utils/scripts/getIconPath';
import { digitSperator } from 'utils/scripts/exchange';
import { ToIranCurrency } from '../../../../../utils/scripts/decimal';

type Props = {
  isPinned: boolean;
  asset?: AssetItem;
  device: 'mobile' | 'desktop';
  isShowHaveBalance: boolean;
};

export const AssetCart: React.FC<Props> = ({
  asset,
  isPinned,
  device,
  isShowHaveBalance,
}) => {
  const isShow = () => {
    return isShowHaveBalance ? true : asset?.balance !== 0;
  };
  return isShow() ? (
    <div
      className={`xpay-wallet-asset-cart ${device} ${
        isPinned ? 'pinned-coin' : ''
      }`}
    >
      {device === 'mobile' ? (
        <div className="mobile-show">
          <span className="asset-info">
            <img src={getIconPath(asset?.symbol || '')} alt="" />
            <div className="coin-name">
              <span>{asset?.name}</span>
              <span className="en">{asset?.symbol.toUpperCase()}</span>
            </div>
          </span>

          <div className="stats">
            {
              <li className="amount-balance">
                <span>موجودی شما :</span>
                <span>
                  {asset?.symbol === 'IRT'
                    ? ToIranCurrency(asset.balance)
                    : asset?.balance}{' '}
                  {asset?.symbol === 'IRT' && ' تومان'}
                </span>
              </li>
            }

            {asset?.symbol === 'PM' ||
            asset?.symbol === 'USDT' ||
            asset?.symbol.toUpperCase() === 'UUSD' ||
            asset?.symbol === 'IRT' ? (
              <></>
            ) : (
              <li className="price">
                <span> قیمت جهانی :</span>
                <span>{asset?.lastPrice}</span>
              </li>
            )}

            {asset?.symbol === 'PM' ||
            asset?.symbol === 'UUSD' ||
            asset?.symbol === 'USDT' ||
            asset?.symbol === 'IRT' ? (
              <></>
            ) : (
              <li className="irt-balance">
                <span> معادل تومانی موجودی :</span>
                <span>{digitSperator(asset?.value.toFixed(0))} تومان</span>
              </li>
            )}
          </div>

          <span className="actions">
            <Link
              className="deposit"
              to={
                asset?.symbol.toUpperCase() === 'PM' ||
                asset?.symbol.toUpperCase() === 'UUSD'
                  ? `/dashboard/orders/create-order?side=buy&symbol=${asset?.symbol.toLowerCase()}`
                  : `/dashboard/wallet/deposit/${asset?.symbol}`
              }
            >
              <Button
                className="deposit-button"
                type={'success'}
                text={
                  asset?.symbol.toUpperCase() === 'PM' ||
                  asset?.symbol.toUpperCase() === 'UUSD'
                    ? 'خرید'
                    : 'واریز'
                }
              />
            </Link>
            <Link
              className="widthrawl"
              to={`/dashboard/wallet/widthrawl/${asset?.symbol}`}
            >
              <Button
                className="widthrawl-button"
                type={'info'}
                text={
                  asset?.symbol.toUpperCase() === 'PM' ||
                  asset?.symbol.toUpperCase() === 'UUSD'
                    ? 'فروش'
                    : 'برداشت'
                }
              />
              {asset?.symbol === 'PM' && asset.balance !== 0 && (
                <Button
                  className="widthrawl-button"
                  type={'info'}
                  text="برداشت"
                />
              )}
            </Link>
            {asset?.symbol == 'IRT' ||
            asset?.symbol == 'PM' ||
            asset?.symbol == 'UUSD' ? (
              <></>
            ) : (
              <Link
                className="trade"
                to={`/dashboard/orders/create-order?side=buy&symbol=${asset?.symbol.toLowerCase()}`}
              >
                <Button
                  className="trade-button"
                  type={'trade'}
                  text={'مبادله'}
                />
              </Link>
            )}
          </span>
        </div>
      ) : (
        <div className="desktop-show">
          <span className="asset-info">
            <img src={getIconPath(asset?.symbol || '')} alt="" />
            <div className="coin-name">
              <span>{asset?.name}</span>
              <span className="en">{asset?.symbol.toUpperCase()}</span>
            </div>
          </span>

          <div className="stats">
            {
              <li className="amount-balance">
                <span>موجودی شما :</span>
                <span>
                  {asset?.symbol === 'IRT'
                    ? ToIranCurrency(asset.balance)
                    : asset?.balance}{' '}
                  {asset?.symbol === 'IRT' && ' تومان'}
                </span>
              </li>
            }

            {asset?.symbol === 'PM' ||
            asset?.symbol === 'USDT' ||
            asset?.symbol === 'UUSD' ||
            asset?.symbol === 'IRT' ? (
              <></>
            ) : (
              <li className="price">
                <span> قیمت جهانی :</span>
                <span>{asset?.lastPrice}</span>
              </li>
            )}

            {asset?.symbol === 'IRT' ? (
              <></>
            ) : (
              <li className="irt-balance">
                <span> معادل تومانی موجودی :</span>
                <span>{digitSperator(asset?.value.toFixed(0))} تومان</span>
              </li>
            )}
          </div>

          <span className="actions">
            <Link
              className="deposit"
              to={
                asset?.symbol === 'PM' || asset?.symbol === 'UUSD'
                  ? `/dashboard/orders/create-order?side=buy&symbol=${asset?.symbol.toLowerCase()}`
                  : `/dashboard/wallet/deposit/${asset?.symbol}`
              }
            >
              <Button
                className="deposit-button"
                type={'success'}
                text={
                  asset?.symbol === 'PM' || asset?.symbol === 'UUSD'
                    ? 'خرید'
                    : 'واریز'
                }
              />
            </Link>
            <Link
              className="widthrawl"
              to={
                asset?.symbol === 'PM' || asset?.symbol === 'UUSD'
                  ? `/dashboard/orders/create-order?side=sell&symbol=${asset?.symbol.toLowerCase()}`
                  : `/dashboard/wallet/widthrawl/${asset?.symbol}`
              }
            >
              <Button
                className="widthrawl-button"
                type={'info'}
                text={
                  asset?.symbol === 'PM' || asset?.symbol === 'UUSD'
                    ? 'فروش'
                    : 'برداشت'
                }
              />
            </Link>
            <Link
              className="widthrawl"
              to={`/dashboard/wallet/widthrawl/${asset?.symbol}`}
            >
              {asset?.symbol === 'PM' && asset.balance !== 0 && (
                <Button
                  className="widthrawl-button"
                  type={'info'}
                  text="برداشت"
                />
              )}
            </Link>
            {asset?.symbol == 'IRT' ||
            asset?.symbol == 'PM' ||
            asset?.symbol == 'UUSD' ? (
              <></>
            ) : (
              <Link
                className="trade"
                to={`/dashboard/orders/create-order?side=buy&symbol=${asset?.symbol.toLowerCase()}`}
              >
                <Button
                  className="trade-button"
                  type={'trade'}
                  text={'مبادله'}
                />
              </Link>
            )}
          </span>
        </div>
      )}
    </div>
  ) : (
    <Fragment></Fragment>
  );
};

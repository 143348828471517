import * as React from 'react';
import { Button, Input } from 'components';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { passwordRegex } from 'utils/constants/constants';
import 'routes/pages/signin/stages/new-password/new-password.scss';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { message } from 'components/toast/toast';
import { MobileNavigate } from 'components/mobile-navigate/mobile-navigate';
import { useNavigate } from 'react-router-dom';

const { useState } = React;

type Props = {};

export const NewPasswordStage: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [greenHelperText, setGreenHelperText] = useState<string[]>(['']);
  const [passwordIsHidden, setPasswordIsHidden] = useState<boolean>(true);
  const [repeatPasswordIsHidden, setRepeatPasswordIsHidden] =
    useState<boolean>(true);
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [repeatPasswordValue, setRepeatPasswordValue] = useState<string>('');
  const [passwordHasError, setPasswordHasError] = useState<boolean>(false);
  const [passwordFieldError, setPasswordFieldError] = useState<string[]>([
    'حداقل ۶ کاراکتر داشته باشد.',
    'حداقل یک عدد داشته باشد.',
  ]);
  const [repeatPasswordHasError, setRepeatPasswordHasError] =
    useState<boolean>(false);
  const [repeatPasswordFieldError, setRepeatPasswordFieldError] = useState<
    string[]
  >(['']);

  const navigate = useNavigate();

  async function handleSubmit() {
    setPasswordHasError(false);
    setRepeatPasswordHasError(false);
    if (
      passwordValue.search(passwordRegex) === 0 &&
      passwordValue.length !== 0 &&
      repeatPasswordValue === passwordValue
    ) {
      setIsLoading(true);
      await postViaAuth(API.setPassword, {
        password: passwordValue,
        repeatPassword: repeatPasswordValue,
      }).then(function (response: any) {
        if (responseValidator(response.status)) {
          message.success('پسورد شما با موفقیت بازیابی شد.');
          navigate('/login/');
        } else setIsLoading(false);
      });
    } else {
      if (passwordValue !== repeatPasswordValue) {
        setRepeatPasswordHasError(true);
        setRepeatPasswordFieldError(['تکرار پسورد با پسورد همخوانی ندارد.']);
      }
      if (
        passwordValue.search(passwordRegex) !== 0 ||
        passwordValue.length === 0
      ) {
        setPasswordHasError(true);
        setPasswordFieldError(['پسورد وارد شده قابل قبول نیست.']);
      }
    }
  }

  function handlePasswordChange(value: string): void {
    setPasswordFieldError(['']);
    setPasswordHasError(false);
    setRepeatPasswordFieldError(['']);
    setRepeatPasswordHasError(false);
    setGreenHelperText(['']);
    setPasswordValue('');

    if (value.length < 6) {
      setPasswordHasError(true);
      setPasswordFieldError((prev) => [...prev, 'حداقل ۶ کاراکتر داشته باشد.']);
    } else {
      setGreenHelperText((prev) => [...prev, 'حداقل ۶ کاراکتر داشته باشد.']);
      setPasswordFieldError((prev) => prev);
    }

    if (
      value.includes('1') ||
      value.includes('2') ||
      value.includes('3') ||
      value.includes('4') ||
      value.includes('5') ||
      value.includes('6') ||
      value.includes('7') ||
      value.includes('8') ||
      value.includes('9') ||
      value.includes('0')
    ) {
      setGreenHelperText((prev) => [...prev, 'حداقل یک عدد داشته باشد.']);
      setPasswordFieldError((prev) => prev);
    } else {
      setPasswordHasError(true);
      setPasswordFieldError((prev) => [...prev, 'حداقل یک عدد داشته باشد.']);
    }

    setPasswordValue(value);
  }

  function handleRepeatPasswordChange(value: string): void {
    setRepeatPasswordFieldError(['']);
    setRepeatPasswordHasError(false);
    setGreenHelperText(['']);
    setRepeatPasswordValue('');

    setRepeatPasswordValue(value);
  }

  return (
    <div className="new-password-stage">
      <MobileNavigate
        title="رمز عبور جدید"
        onBackClick={() => navigate('/login/password-reset-code')}
      />
      <h3>رمز عبور جدید</h3>
      <form className="new-password-form">
        <Input
          name="password"
          type={passwordIsHidden ? 'password' : 'text'}
          className="new-password-input"
          value={passwordValue}
          onPressEnter={() => handleSubmit()}
          onChange={(el) => handlePasswordChange(el.target.value)}
          placeholder="رمز عبور"
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <img
                  className="start-adornment"
                  src="assets/icons/unlock.svg"
                />
              ),
            },
            endAdornment: {
              adornmentIcon: passwordIsHidden ? (
                <EyeOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => setPasswordIsHidden(false)}
                />
              ) : (
                <EyeInvisibleOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => setPasswordIsHidden(true)}
                />
              ),
            },
          }}
          errors={passwordFieldError}
          greenHelperText={greenHelperText}
          dir={passwordValue.length !== 0 ? 'ltr' : 'rtl'}
        />
        <Input
          name="repeat-password"
          type={repeatPasswordIsHidden ? 'password' : 'text'}
          className="new-password-input"
          value={repeatPasswordValue}
          onChange={(el) => handleRepeatPasswordChange(el.target.value)}
          placeholder="تکرار رمز عبور"
          onPressEnter={() => handleSubmit()}
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <img
                  className="start-adornment"
                  src="/assets/icons/unlock.svg"
                />
              ),
            },
            endAdornment: {
              adornmentIcon: repeatPasswordIsHidden ? (
                <EyeOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => setRepeatPasswordIsHidden(false)}
                />
              ) : (
                <EyeInvisibleOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => setRepeatPasswordIsHidden(true)}
                />
              ),
            },
          }}
          errors={repeatPasswordFieldError}
          dir={repeatPasswordValue.length !== 0 ? 'ltr' : 'rtl'}
        />
        <Button
          type="info"
          onClick={() => handleSubmit()}
          text="تایید"
          className={`new-password-button ${
            passwordHasError ||
            repeatPasswordHasError ||
            passwordValue.length === 0 ||
            isLoading
              ? 'button-disabled'
              : ''
          }`}
          htmlType="submit"
          disabled={
            passwordHasError ||
            repeatPasswordHasError ||
            passwordValue.length === 0 ||
            repeatPasswordValue.length === 0 ||
            isLoading
          }
          loading={isLoading ? true : false}
        />
      </form>
    </div>
  );
};

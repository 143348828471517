import * as React from 'react';
// @ts-ignore
import './helper-row.scss';
import {Modal} from '../modal/modal';
import {Button} from '../button/button';

const { useState } = React;

type Props = {
  className?: string;
};

export const HelperRow: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={`xpay-helper-row desktop-show ${props?.className}`}>
      <Modal className="xpay-help-modal" isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="player">
          <img src="/assets/images/sample.jpg" alt="" />
        </div>
        <h3 className="modal-title">راهنمای گرفتن سلفی</h3>
        <p className="modal-description">
          و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار
          گیرد.
        </p>
        <Button
          onClick={() => setIsOpen(false)}
          text="متوجه شدم"
          type="primary"
        />
      </Modal>

      <div className="right-side">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="48" height="48" rx="10" fill="#4f46e0" />
          <path
            d="M24 38C16.258 38 10 31.7266 10 24C10 16.2706 16.258 10 24 10C31.728 10 38 16.2706 38 24C38 31.7266 31.728 38 24 38ZM24 19.8966C24.672 19.8966 25.218 19.3506 25.218 18.6786C25.218 18.0066 24.672 17.4466 23.986 17.4466C23.314 17.4466 22.768 18.0066 22.768 18.6786C22.768 19.3506 23.314 19.8966 24 19.8966ZM24 30.538C24.672 30.538 25.232 29.9766 25.232 29.306L25.232 23.118C25.232 22.4446 24.672 21.9 24 21.9C23.328 21.9 22.782 22.4446 22.782 23.118L22.782 29.306C22.782 29.9766 23.328 30.538 24 30.538Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_0_1"
              x1="-13.5105"
              y1="2.69178e-06"
              x2="58.2718"
              y2="6.82133"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#3F62FE" />
              <stop offset="1" stop-color="#678AF5" />
            </linearGradient>
          </defs>
        </svg>

        <div className="text">
          <h4>نیاز به کمک دارید؟</h4>
          <span>اگر نیاز به کمک دارید فیلم آموزش این بخش را مشاهده کنید</span>
        </div>
      </div>

      <Button
        disabled
        onClick={() => setIsOpen((prev) => !prev)}
        type="info"
        text="مشاهده ویدیو"
        className="watch-video"
        icon={
          <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.74867 3.98117L3.97822 0.483104C3.60562 0.210263 3.16474 0.0459268 2.70446 0.00831062C2.24418 -0.0293056 1.78247 0.0612678 1.37051 0.269991C0.958558 0.478714 0.612444 0.797435 0.370537 1.19082C0.128631 1.58421 0.000380691 2.0369 3.01786e-06 2.49872V9.49835C-0.000715448 9.96062 0.126864 10.414 0.36854 10.8081C0.610216 11.2021 0.956508 11.5214 1.36887 11.7303C1.78123 11.9393 2.24348 12.0297 2.70416 11.9915C3.16485 11.9533 3.6059 11.788 3.97822 11.514L8.74867 8.0159C9.06521 7.78361 9.3226 7.48004 9.50001 7.12978C9.67743 6.77953 9.76988 6.39242 9.76988 5.99979C9.76988 5.60716 9.67743 5.22005 9.50001 4.86979C9.3226 4.51953 9.06521 4.21597 8.74867 3.98367V3.98117Z"
              fill="white"
            />
          </svg>
        }
      />
    </div>
  );
};

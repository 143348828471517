import * as React from 'react';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Calendar, Input } from 'components';
import { Badge } from 'components/badge/badge';
import { Table } from 'components/table/table';
import moment from 'jalali-moment';
import './irt-transactions.scss';
import { Divider, Dropdown, MenuProps, Spin } from 'antd';
import {
  PaginationList,
  Transaction,
  TransactionFilterValues,
} from 'core/domains';
import { digitSperator } from '../../../../../utils/scripts/exchange';
import { getIconPath } from '../../../../../utils/scripts/getIconPath';
import { TransactionDetailModal } from '../detail-modal/transactions/modal';
import { TableColumn } from 'react-data-table-component';
import { ToItemTypes } from 'utils/scripts/table';
import { _states, _types } from '../filters/constants';
import { getAllMineTransactions } from 'core/repositories/transcations/transaction';
import { Pagination } from 'components/pagination/pagination';
import { getPageNum } from 'utils/scripts/paggingHelper';
import { TransactionOrderDetailModal } from '../../../../../components/transaction-order-modal/transaction-order-modal';

const { useState } = React;

const empty_filters = {
  state: {
    id: 0,
    title: 'وضعیت تراکنش',
  },
  type: {
    id: 0,
    title: 'نوع تراکنش',
  },
};

export const IrtTransactions: React.FC = () => {
  const [data, setData] = useState<PaginationList<Transaction>>();

  const [cardLoading, setCardloading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [filters, setFilters] =
    useState<TransactionFilterValues>(empty_filters);
  const [query, setQuery] = useState<string>('');

  const [selectedItem, setSelectedItem] = useState<Transaction | null>();

  const clickOnType: MenuProps['onClick'] = ({ key }) => {
    setFilters({
      ...filters,
      type: _types.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const clickOnState: MenuProps['onClick'] = ({ key }) => {
    setFilters({
      ...filters,
      state: _states.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const filterHandle = () => {
    var q = '';
    q += `${q.includes('?') ? '&' : '?'}marketType=2`;

    if (filters) {
      if (filters.type?.id !== 0)
        q += `${q.includes('?') ? '&' : '?'}type=${filters.type?.id}`;
      if (filters.state?.id !== 0)
        q += `${q.includes('?') ? '&' : '?'}state=${filters.state?.id}`;
      if (filters.startDate)
        q += `${q.includes('?') ? '&' : '?'}startDate=${filters.startDate}`;
      if (filters.endDate)
        q += `${q.includes('?') ? '&' : '?'}endDate=${filters.endDate}`;
      if (filters.sort?.id)
        q += `${q.includes('?') ? '&' : '?'}sort=${filters.sort?.id}`;
      if (filters.keywords)
        q += `${q.includes('?') ? '&' : '?'}keywords=${filters.keywords}`;
    }
    setQuery(q);
    getAllMineTransactions(
      setCardloading,
      setData,
      {
        pageNumber: getPageNum(),
        pageSize: 10,
      },
      q,
    );
  };

  React.useEffect(() => {
    filterHandle();
  }, [filters]);

  React.useEffect(() => {
    setIsOpen(false);
  }, []);

  const columns: TableColumn<Transaction>[] = [
    {
      name: '#',
      id: 'index',
      selector: (row, rowIndex) => (rowIndex ? rowIndex + 1 : 0),
      width: '50px',
    },
    {
      name: 'کد پیگیری',
      id: 'trackingCode',
      selector: (row) => row?.trackingCode,
      cell: (row) => <span className="en">{row.trackingCode}</span>,
    },
    {
      name: 'نام ارز',
      id: 'currencyName',
      selector: (row) => row.market.symbol,
      cell: (row) => (
        <>
          <img src={getIconPath(row.market.symbol)} />
          <span>{row.market.name}</span>
        </>
      ),
    },
    {
      name: 'نوع تراکنش',
      id: 'transactionType',
      selector: (row) => row.type.title,
      cell: (row) => (
        <>
          <Badge
            type={row.type.id === 1 ? 'success' : 'error'}
            text={row.type.title}
          />
        </>
      ),
    },
    {
      name: 'مقدار',
      id: 'price',
      selector: (row) => digitSperator(row.amount) + ' تومان',
    },
    {
      name: 'وضعیت',
      id: 'state',
      selector: (row) => row.state.id,
      cell: (row: any) => (
        <Badge
          text={row.state.id == 2 ? 'موفق' : row.state.title}
          type={
            row.state.id == 2
              ? 'success'
              : row.state.id == 3 || row.state.id == 8
              ? 'error'
              : 'waiting'
          }
          icon={`/assets/icons/table/${
            row.state.id == 2
              ? 'success'
              : row.state.id == 3 || row.state.id == 8
              ? 'danger'
              : 'waiting'
          }.svg`}
        />
      ),
      width: '200px',
    },
    {
      name: 'زمان',
      id: 'date',
      selector: (row) => row.createdAt,
      sortable: true,
      cell: (row: any) => (
        <div className="column-date">
          <span className="day">
            {moment(row.createdAt).locale('fa').local().format('YYYY/MM/DD')}
          </span>
          <span className="time">
            {moment(row.createdAt).local().format('HH:mm')}
          </span>
        </div>
      ),
    },

    {
      name: 'جزئیات',
      id: 'detail',
      cell: (row) => <InfoCircleOutlined onClick={() => handleModal(row)} />,
    },
  ];

  function handleModal(item: Transaction) {
    setSelectedItem(item);
  }

  React.useEffect(() => {
    selectedItem && setIsOpen(!isOpen);
  }, [selectedItem]);
  React.useEffect(() => {
    !isOpen && setSelectedItem(null);
  }, [isOpen]);

  return (
    <div className="orders-irt-transactions">
      {selectedItem && (
        <TransactionOrderDetailModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          transactionItem={selectedItem}
        />
      )}

      <Spin spinning={cardLoading}>
        <div className="desktop-show">
          <div className="filter-row">
            <div className="fields">
              <Input
                type="text"
                value={filters?.keywords}
                placeholder="جستجو کنید ..."
                className="search"
                name="search"
                onChange={(e) =>
                  setFilters({ ...filters, keywords: e.target.value })
                }
              />

              <Dropdown
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(_types), onClick: clickOnType }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.type?.title}
                />
              </Dropdown>

              <Dropdown
                className="states-dropdown"
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(_states), onClick: clickOnState }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.state?.title}
                />
              </Dropdown>

              <Calendar
                label="از تاریخ"
                placeholder="از تاریخ"
                name="startDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, startDate: value }))
                }
              />
              <Calendar
                label="تا تاریخ"
                placeholder="تا تاریخ"
                name="endDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, endDate: value }))
                }
              />
            </div>
            <Divider type="vertical" />
            <div className="buttons">
              <span onClick={() => setFilters(empty_filters)}>حذف فیلتر</span>
            </div>
          </div>
          {data && (
            <Table
              pagination={data.pagination}
              blur={cardLoading}
              hasFiltering={true}
              data={data.items}
              column={columns}
              setData={setData}
              setTableLoading={setCardloading}
              getData={getAllMineTransactions}
              query={query}
            />
          )}
        </div>

        <ul className="mobile-show">
          <div className="filter-row">
            <h4>واریز و برداشت های تومانی</h4>

            <div className="fields">
              <Input
                type="text"
                value={filters?.keywords}
                placeholder="جستجو کنید ..."
                className="search"
                name="search"
                onChange={(e) =>
                  setFilters({ ...filters, keywords: e.target.value })
                }
              />

              <Dropdown
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(_types), onClick: clickOnType }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.type?.title}
                />
              </Dropdown>

              <Dropdown
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(_states), onClick: clickOnState }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.state?.title}
                />
              </Dropdown>

              <Calendar
                label="از تاریخ"
                placeholder="از تاریخ"
                name="startDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, startDate: value }))
                }
              />
              <Calendar
                label="تا تاریخ"
                placeholder="تا تاریخ"
                name="endDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, endDate: value }))
                }
              />
            </div>
            <div className="buttons">
              <Button
                type="info"
                text="حذف فیلتر"
                onClick={() => {
                  {
                    setFilters(empty_filters);
                  }
                }}
              />
            </div>
          </div>
          {data?.items?.map((item) => (
            <li>
              <div>
                <div className="right">
                  <img src={getIconPath(item.market.symbol)} alt="" />
                  <span>{item.market.name}</span>
                  <span>{item.market.symbol.toUpperCase()}</span>
                </div>
                <div>
                  <p> نوع تراکنش : {item?.type.title}</p>
                </div>
              </div>
              <div>
                <div className="right">
                  <p>مبلغ: </p>
                  <span>{digitSperator(item.amount)}</span>
                  <p>تومان</p>
                </div>
              </div>
              <div>
                <div className="right">
                  <p>
                    {moment(item.createdAt)
                      .locale('fa')
                      .local()
                      .format('YYYY/MM/DD')}{' '}
                    | {moment(item.createdAt).local().format('HH:mm')}
                  </p>
                </div>
                <div className="left">
                  <Badge
                    text={item.state.title}
                    type={
                      item.state.id == 2 || item.state.id == 3
                        ? 'success'
                        : item.state.id == 1
                        ? 'error'
                        : 'waiting'
                    }
                  />
                </div>
              </div>
              <Button
                type="outlined"
                text="مشاهده جزئیات"
                onClick={() => handleModal(item)}
              />
            </li>
          ))}
        </ul>
        <div className="mobile-show">
          {data && data.items.length > 0 && (
            <Pagination
              page={data.pagination.pageIndex || 0}
              data={data.items || []}
              setdata={setData}
              setloader={setCardloading}
              getdata={getAllMineTransactions}
              size={data.pagination.pageSize || 10}
              totalRows={data.pagination.totalCount}
              query={query}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

import { APPNAME } from '../../../../apis/constants';

export const DepositCryptoHelperText = () => {
  return (
    <>
      <li>
        <p>
          <span className="number">1</span>
          توجه کنید ! از زمان ثبت واریز ارزی تنها 15 دقیقه برای ثبت هش یا همان
          TXID دارید
        </p>
      </li>
      <li>
        <p>
          <span className="number">2</span>
          برای واریز رمز ارز به ما، ابتدا میزان رمز ارز مورد نظر را جهت واریز در
          فیلد مقدار وارد نمایید، سپس دقیقا معادل مقداری که در فیلد مقدار بیت
          کوین وارد کرده اید را به آدرس کیف پول صرافی که در همین صفحه ارائه شده
          است انتقال دهید و سپس لینک یا شماره تراکنشی که به شما ارائه می شود را
          در بخش لینک تراکنش وارد نموده و دکمه « ثبت درخواست » را کلیک نمایید.
        </p>
      </li>
      <li>
        <p>
          <span className="number">3</span>
          اخیرا ویروس و تروجان تغییر دهنده آدرس در زمانی کپی کردن آن گسترش یافته
          است.حتما در پس از کپی ادرس خود و اعمال آن در کادر کیف ارز مد نظر دقت
          فرمائید و آن را با ادرس خود مقایسه نمائید.
        </p>
      </li>
      <li>
        <p>
          <span className="number">4</span>
          کارمزد پرداختی از سوی شما برای تراکنش های رمز ارز ها کارمزد شبکه ارز
          مدنظر میباشد و {APPNAME} از این کارمزد نفعی ندارد
        </p>
      </li>
      <li>
        <p>
          <span className="number">5</span>
          دقت نمائید اگر ولت سایت برای واریز 2 بخشه یا همان دارای ممو و تگ است
          حتما وارد نمائید ، در صورت وارد نکردن مقدار واریز شده در شبکه معلق
          خواهد شد و {APPNAME} مسئولیتی در این باره نخواهد داشت
        </p>
      </li>
    </>
  );
};

import * as React from 'react';

import './networks-modal.scss';
import { Alert, Modal } from 'components';
import { AddressWallet } from 'core/domains';
import { ReactComponent as WarningOutlined } from 'assets/icons/information-circle.svg';
import { Badge } from 'components/badge/badge';
import { Tag } from 'antd';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addressWallets: AddressWallet[];
  onNetworkChange: (network: AddressWallet) => void;
}

export const DepositCryptoNetworksModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  addressWallets,
  onNetworkChange,
}) => {
  return (
    <Modal
      title="انتخاب شبکه"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="deposit-crypto-networks-modal"
      closeAble
    >
      <div className="alert">
        <Alert
          message="مطمئن شوید که شبکه انتخابی با شبکه وارد شده شما مطابقت دارد
آدرس، یا ممکن است تراکنش پرداختی شما گم شود.
شبکه هایی که مطابقت ندارند فیلتر شده اند. یک شبکه را از لیست زیر انتخاب کنید."
          type="warning"
          title="هشدار!"
          customIcon={<WarningOutlined />}
        />
      </div>
      <div className={`networks-list`}>
        <ul>
          {Object.keys(addressWallets).length > 0 &&
            addressWallets.map((network) => {
              return (
                <li
                  onClick={() => network.depositEnabled == true &&  onNetworkChange(network)}
                  key={`network-${network.network}`}
                >
                  <div className="network-title">
                    <p className="en">
                      {network.name}
                      {network.depositEnabled == false && (
                        <Tag color="red">در دسترس نیست</Tag>
                      )}
                    </p>
                    <span className="en">{network.network.toUpperCase()}</span>
                  </div>
                  <div className="network-stats">
                    <span className="fee">
                      هزینه تراکنش: {network.widthrawFee} واحد
                    </span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </Modal>
  );
};

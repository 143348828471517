import * as React from 'react';
import { Order, Transaction } from '../../../core/domains';
import { Drawer, Spin } from 'antd';
import { Card } from '../../card/card';
import './transaction-order-modal-content-mobile.scss';
import { OrderDetailModalData } from '../order-data/order-modal-data';
import { TransactionDetailModalData } from '../transaction-data/transaction-modal-data';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transactionItem?: Transaction;
  orderItem?: Order;
  modalLoading?: boolean;
}

export const TransactionOrderDetailModalMobileContent: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  transactionItem,
  orderItem,
  modalLoading,
}) => {
  const closeDrawer = () => {
    setIsOpen(false);
  };

  return orderItem !== null || transactionItem !== null ? (
    <>
      <Drawer
        onClose={closeDrawer}
        closable
        className="transaction-order-detail-drawer"
        open={isOpen}
        placement="bottom"
        title={`جزئیات ${orderItem ? 'سفارش' : 'تراکنش'}`}
      >
        <Card className="detail-card">
          {orderItem ? (
            <div className="content">
              <OrderDetailModalData orderItem={orderItem} />
            </div>
          ) : (
            transactionItem && (
              <div className="content">
                <TransactionDetailModalData transactionItem={transactionItem} />
              </div>
            )
          )}
        </Card>
      </Drawer>
    </>
  ) : (
    <></>
  );
};

import * as React from 'react';

import './withdrawal-type-modal.scss';
import { Alert, Modal } from 'components';
import { Network, NetwrokList } from 'core/domains';
import { ReactComponent as WarningOutlined } from 'assets/icons/information-circle.svg';
import { Tag } from 'antd';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onTypeChange: (withdrawalType: 'voucher' | 'account') => void;
}

export const PmWithdrawalTypeModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  onTypeChange,
}) => {
  return (
    <Modal
      title="نوع برداشت"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="withdrawal-types-modal"
      closeAble
    >
      <div className={`withdrawal-types-list`}>
        <ul>
          <li onClick={() => onTypeChange('voucher')}>
            <div className="withdrawal-type-title">
              <p className="">ووچر</p>
              <span className="en">E-VOUCHER</span>
            </div>
          </li>
          <li onClick={() => onTypeChange('account')}>
            <div className="withdrawal-type-title">
              <p className="">حساب پرفکت مانی</p>
              <span className="en">Perfect Money Account</span>
            </div>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

import * as React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RootState} from 'core/redux/store';
import {Link} from 'react-router-dom';
import {useAppDispatch} from 'core/redux/hooks';

import 'components/bottom-menu/bottom-menu-item/bottom-menu-item.scss';
import {setMenuItem, setSidebar} from 'core/redux/actions';
import {Modal} from 'components/modal/modal';
import {List} from 'antd';
import {MenuItem} from 'utils/constants/common';
import {isMobile} from 'react-device-detect';
import CaretLeftOutlined from '@ant-design/icons/lib/icons/CaretLeftOutlined';

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    id: number;
    link: string;
    icon: React.ReactElement;
    title: string;
    sidebar?: boolean;
    childrens?: any;
}

const BottomMenuItem: React.FC<Props> = (props) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const dispatch = useAppDispatch();
    return (
        <>
            {isMobile && (
                <Modal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    title="سوابق"
                    className="orders-menu-modal"
                >
                    {props.link === '/dashboard/orders' && props?.childrens && (
                        <List
                            className="orders-menu-list"
                            itemLayout="horizontal"
                            dataSource={props.childrens}
                            renderItem={(item: MenuItem) => (
                                <List.Item
                                    onClick={() =>
                                        dispatch(setMenuItem(props.id)) &&
                                        dispatch(setSidebar('close')) &&
                                        setIsOpen(false)
                                    }
                                    actions={[
                                        <>

                                            <Link
                                                to={item.link}
                                                onClick={() =>
                                                    dispatch(setMenuItem(props.id)) &&
                                                    dispatch(setSidebar('close')) &&
                                                    setIsOpen(false)
                                                }
                                                key={`order-modal-list-${item.id}`}
                                            >
                                                
                                                <CaretLeftOutlined/>
                                            </Link></>,
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={<Link to={item.link}>{item.title}</Link>}
                                    />
                                </List.Item>
                            )}
                        />
                    )}
                </Modal>
            )}
            <Link
                to={props.link === '/dashboard/orders' ? '' : props.link}
                onClick={() =>
                    props.sidebar
                        ? props.state.sidebar === 'close'
                            ? dispatch(setSidebar('open'))
                            : dispatch(setSidebar('close'))
                        : props.link === '/dashboard/orders'
                            ? setIsOpen(true)
                            : dispatch(setMenuItem(props.id)) && dispatch(setSidebar('close'))
                }
                className={`bottom-menu-item  ${
                    props.state.menu === props.id ? 'active' : ''
                } ${
                    props.state.sidebar === 'open' && props.id === -1
                        ? 'menu-button-active'
                        : ''
                }`}
            >
                <div className={`bottom-menu-icon `}>{props.icon}</div>
                <span>{props.title.replace('خرید و فروش', 'معامله')}</span>
            </Link>
        </>
    );
};

export default connector(BottomMenuItem);

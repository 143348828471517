import { QuestionCircleOutlined } from '@ant-design/icons';
import { message } from 'components/toast/toast';
import { API } from 'apis';
import { Button } from 'components';
import { MobileNavigate } from 'components/mobile-navigate/mobile-navigate';
import * as React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import ReactCodeInput from 'react-verification-code-input';

import 'routes/pages/signin/stages/password-reset-code/password-reset-code.scss';
import {
  getValidationErrors,
  hasValidationError,
  post,
  responseValidator,
} from 'utils/scripts/api';
import { Link, useNavigate } from 'react-router-dom';

const { useState } = React;

type Props = {};

export const PasswordResetCodeStage: React.FC<Props> = (props) => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countDownTime, setCountDownTime] = useState<number>(120);
  const [hasError, setHasError] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState<string>('');
  const [codeValue, setCodeValue] = useState<string>('');

  const phoneNumber = localStorage.getItem('phoneNumber');
  const navigate = useNavigate();

  function handleVerify(codeValue: string) {
    setIsLoading(true);
    setHasError(false);
    setFieldError('');
    if (codeValue.length === 6) {
      post(API.verifyForgotPassword, {
        code: codeValue,
        phoneNumber: phoneNumber,
      }).then(function (response: any) {
        setIsLoading(false);
        if (responseValidator(response.status)) {
          localStorage.setItem('token', response.data.value.access_token);
          navigate('/login/new-password');
        } else setFieldError(response.data.message);
      });
    } else {
      setHasError(true);
      setIsLoading(false);
      setFieldError('کد تایید باید دارای ۶ رقم باشد.');
    }
  }

  function handleResend() {
    setCodeValue('');

    post(API.forgotPassowrd, {
      phoneNumber,
    }).then(function (response: any) {
      if (responseValidator(response.status)) {
        message.success('کد تایید ارسال شد.');
        setCountDownTime(120);
      }
    });
  }

  return (
    <div className="password-reset-code-stage">
      <MobileNavigate
        title="تایید شماره همراه"
        onBackClick={() => navigate('/login/password-reset')}
      />
      <h3>تایید شماره همراه</h3>
      <div className="password-reset-code-header">
        <p>
          یک کد ۶ رقمی به شماره {phoneNumber} ارسال شد. لطفا کد را اینجا وارد
          کنید.
        </p>
      </div>
      <div className="password-reset-code-main">
        <ReactCodeInput
          className={`password-reset-code-input ${
            fieldError ? 'has-error' : ''
          }`}
          fieldWidth={50}
          values={codeValue.split('')}
          autoFocus={true}
          onChange={(value) => {
            setCodeValue(value);
          }}
          onComplete={(e) => handleVerify(e)}
        />
        <div className="password-reset-code-stats">
          <div className="password-reset-code-errors">
            {fieldError ? (
              <>
                <QuestionCircleOutlined />
                <span>{fieldError}</span>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="password-reset-code-timer">
            {countDownTime < 1 ? (
              <span className="resend" onClick={() => handleResend()}>
                ارسال مجدد
              </span>
            ) : (
              <>
                <CountdownCircleTimer
                  size={30}
                  isPlaying
                  duration={countDownTime}
                  colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                  colorsTime={[7, 5, 2, 0]}
                  onComplete={() => setCountDownTime(0)}
                >
                  {({ remainingTime }) => <span>{remainingTime}</span>}
                </CountdownCircleTimer>{' '}
                ثانیه تا ارسال مجدد کد
              </>
            )}
          </div>
          {!hasError && <span></span>}
        </div>
        <Button
          type="info"
          text="تایید"
          className={`password-reset-code-button ${
            codeValue.length !== 6 || isLoading ? 'button-disabled' : ''
          }`}
          htmlType="submit"
          disabled={codeValue.length !== 6 || isLoading ? true : false}
          onClick={() => handleVerify(codeValue)}
          loading={isLoading ? true : false}
        />
        <div className="back-links">
          <div>
            <p
              onClick={() => navigate('/login/password-reset')}
              className="password-reset-code-change-number"
            >
              ورود با یک شماره موبایل دیگر
            </p>
          </div>
          <div>
            <Link to="/register">ثبت نام کنید</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WithdrawlIrtHelperText = () => {
  return (
    <>
      <li>
        <p>
          <span className="number">1</span>
          دقت نمایید پس از ثبت درخواست، امکان لغو درخواست وجود نداشته و وجه
          درخواستی بلافاصله از کیف پول شما کسر می گردد.
        </p>
      </li>
      <li>
        <p>
          <span className="number">2</span>
          پس از ثبت، درخواست شما مبلغ در یکی از سیکل های پایا تسویه و به حساب
          شما واریز میگردد
        </p>
      </li>
      <li>
        <p>
          <span className="number">3</span>
          برای پیگیری درخواست میتوانید از پشتیبانی آنلاین کمک بگیرید.{' '}
        </p>
      </li>
      <li>
        <p>
          <span className="number">4</span>
          کارمزد انتقال که در هنگام واریز به حساب شما کسر میگردد مربوط به بانک
          است و ما در آن ذی نفع نیستیم.
        </p>
      </li>
      <li>
        <p>
          <span className="number">5</span>
          سیکل های پایا به ترتیب ۰۳:۴۵ صبح - ۱۰:۴۵ صبح - ۱۳:۴۵ ظهر و ۱۸:۴۵ عصر
          میباشد
        </p>
      </li>
    </>
  );
};

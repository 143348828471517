export const CashInHelperText = () => {
  return (
    <>
      <li>
        <p>
          <span className="number">1</span>
          برای واریز شناسه دار، شناسه ایجاد شده را در قسمت شناسه پرداخت در
          موبایل بانک، اینترنت بانک یا فیش پرداخت بانکی وارد کنید و مطمئن باشید
          که شناسه مربوط به شماره شبای مبدا را وارد کرده‌اید.
        </p>
      </li>
      <li>
        <p>
          <span className="number">2</span>
          ۲- در هنگام واریز دقت کنید تا شناسه واریز به صورت صحیح و کامل وارد
          گردد ٬ در غیر اینصورت وجه تا ۷۲ ساعت برگشت خواهد خورد
        </p>
      </li>
      <li>
        <p>
          <span className="number">3</span>
          امکان واریز شناسه دار از حساب های بانکی مشترک امکان پذیر نیست و در
          صورت واریز وجه تا ۷۲ ساعت برگشت خواهد خورد
        </p>
      </li>
      <li>
        <p>
          <span className="number">4</span>
          شما امکان دریافت شناسه واریز به ازای هر شماره شبا که متعلق به خودتان
          هست را دارید
        </p>
      </li>
      <li>
        <p>
          <span className="number">5</span>
          قبل از واریز لطفا از فعال بودن سیستم واریز شناسه دار و شناسه واریز
          مخصوص به خودتان در پلتفرم مطمئن شوید{' '}
        </p>
      </li>
    </>
  );
};

import * as React from 'react';
import { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import './credit-card-slider.scss';
import { Alert } from '../../../../../components';
import { Cart } from '../../../../../core/domains';
import { CreditCard } from '../credit-card';

type props = {
  carts: Cart[];
};

export const CreditCardSlider: React.FC<props> = ({ carts }) => {
  return (
    <>
      <div className="xpay-credit-card-slider">
        <Swiper
          modules={[Navigation]}
          centeredSlides={false}
          breakpoints={{
            // when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 2,
            },
            1290: {
              slidesPerView: 3,
            },
            1600: {
              slidesPerView: 4,
            },
          }}
          navigation={true}
          autoplay
        >
          {carts &&
            carts.map((card) => (
              <SwiperSlide>
                <CreditCard cart={card} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  );
};

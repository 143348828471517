import * as React from 'react';
import { BasicStage } from './basic/basic';
import { SelfieStage } from './selfie/upload-files';
import './form.scss';
import { LandlineStage } from './landline/landline';
import { UserLevel } from 'core/domains';
import { Spin } from 'antd';
import { CompleteStage } from './compelete/complete';
import { AddressStage } from './address/address';
import {
  checkForAddressAndPostalCode,
  checkForLandLine,
} from '../../../../core/repositories';

const { useState } = React;

type Props = {
  spinning: boolean;
  level: UserLevel;
  stage: string;
  setStage: React.Dispatch<
    React.SetStateAction<
      'BASIC' | 'ADDRESS' | 'UPLOAD_FILES' | 'LANDLINE' | 'COMPLETE'
    >
  >;
};
export const AuthForm: React.FC<Props> = ({
  spinning,
  level,
  setStage,
  stage,
}) => {
  React.useEffect(() => {
    switch (level.uniqueName.id) {
      case 1:
        setStage('BASIC');
        break;
      case 2: {
        setStage('ADDRESS');
        checkSteps();
        break;
      }
      case 3:
        {
          setStage('COMPLETE');
          break;
        }
        break;
    }
  }, []);

  async function checkSteps() {
    // await checkForLandLine(setStage);
    await checkForAddressAndPostalCode(setStage);
  }

  function renderStage() {
    switch (stage) {
      case 'BASIC':
        return <BasicStage setStage={setStage} />;
      case 'ADDRESS':
        return <AddressStage level={level} setStage={setStage} />;
      case 'UPLOAD_FILES':
        return <SelfieStage level={level} setStage={setStage} />;
      // case 'LANDLINE':
      //   return <LandlineStage level={level} setStage={setStage} />;
      case 'COMPLETE':
        return <CompleteStage />;

      default:
        return <>invalid stage</>;
    }
  }

  return (
    <Spin spinning={spinning}>
      <div className="auth-form">{renderStage()}</div>
    </Spin>
  );
};

import * as React from 'react';
import { Alert, Button, Dropdown, Input, Modal } from 'components';
import { Col, Progress, Row, Tooltip, Upload } from 'antd';
import './address.scss';
import {
  Enum,
  Level2AddressFieldErrorType,
  LevelTwoAddressValueType,
  UserLevel,
} from 'core/domains';
import { IsPersianChar } from 'utils/scripts/stringExtentions';
import { APPNAME } from '../../../../../apis/constants';
import { upgradeLevel2Address, upgradeToLevel3 } from 'core/repositories';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import provinces from '../states.json';
import cities from '../cities.json';
import { useEffect } from 'react';
import { faNumToEnNum, IsNumeric } from '../../../../../utils/scripts/decimal';
import { deleteCommaSperator } from '../../../../../utils/scripts/exchange';
import { message } from '../../../../../components/toast/toast';

type FieldErrorType = {};

type State = {
  title: string;
  id: number;
};

type City = {
  id: number;
  title: string;
  stateId?: number;
};

type Props = {
  level: UserLevel;
  setStage: React.Dispatch<
    React.SetStateAction<
      'BASIC' | 'ADDRESS' | 'UPLOAD_FILES' | 'LANDLINE' | 'COMPLETE'
    >
  >;
};

const { useState } = React;

export const AddressStage: React.FC<Props> = (props) => {
  const [fieldError, setFieldError] = useState<Level2AddressFieldErrorType>({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<LevelTwoAddressValueType>();

  const [isOpenPic, setIsOpenPic] = useState<boolean>(false);
  const [isOpenCart, setIsOpenCart] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const [uploadCancelSource, setUploadCancelSource] =
    useState<AbortController | null>(null);
  const [states, setStates] = useState<Enum[]>();
  const [citiesList, setCitiesList] = useState<City[]>();

  const onNumberHandler = (value: string) => {
    if (value !== '') value = faNumToEnNum(value);
    if (value !== '' && !IsNumeric(value[value.length - 1])) return;
    setFormData({ ...formData, postalCode: value });
  };

  const handleTextChange = (value: string) => {
    if (
      value !== '' &&
      !IsPersianChar(value.split('')[value.split('').length - 1])
    )
      return;

    setFormData({ ...formData, address: value });
  };

  const submitHandle = () => {
    if (formData?.state?.id === -1 || !formData?.state)
      return message.error('لطفا استان خود را انتخاب نمایید');
    if (formData?.city?.id === -1 || !formData?.city)
      return message.error('لطفا شهر خود را انتخاب نمایید');

    if (formData && formData?.address) {
      setLoading(true);
      postViaAuth(API.authentication.addAddressUpgradeTo2, {
        address:
          formData.city?.title +
          '،' +
          formData.state?.title +
          '،' +
          formData.address,
        postalCode: formData.postalCode,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          message.success('آدرس شما ثبت شد');
          window.location.reload();
        }
      });
    } else {
      setFieldError({ ...fieldError, address: ['وارد کردن آدرس الزامی است.'] });
    }
  };

  const { Dragger } = Upload;

  useEffect(() => {
    let provinceArr: State[] = [];
    let cityArr: City[] = [];
    provinces.forEach((e) => provinceArr.push(e));
    cities.forEach((e) => cityArr.push(e));
    console.log(cities, provinces);
    setStates([{ id: -1, title: 'انتخاب استان' }, ...provinceArr]);
    setCitiesList([{ id: -1, title: 'انتخاب شهر' }]);
  }, []);

  return (
    <form className="form-address">
      <Row>
        {states && (
          <Dropdown
            onChangeHandle={(e) => {
              setFormData({
                ...formData,
                state: states.find((state) => state.id == e),
                city: { id: -1, title: 'انتخاب شهر' },
              });

              e == -1
                ? setCitiesList([{ id: -1, title: 'انتخاب شهر', stateId: -1 }])
                : setCitiesList([
                    { id: -1, title: 'انتخاب شهر', stateId: -1 },
                    ...cities?.filter((city) => city.stateId === e),
                  ]);
            }}
            label={'استان'}
            items={states}
            value={formData?.state}
          />
        )}
        {citiesList && (
          <Dropdown
            label={'شهر'}
            items={citiesList}
            value={formData?.city}
            onChangeHandle={(e) => {
              setFormData({
                ...formData,
                city: citiesList.find((city) => city.id === e),
              });
            }}
            disabled={formData?.state?.id === -1}
          />
        )}
      </Row>

      <Row>
        <Input
          label="آدرس محل سکونت"
          name="address"
          type="text"
          value={formData?.address}
          onChange={(event) => handleTextChange(event.target.value)}
          maxLength={100}
          errors={fieldError.address}
          disableAutoComplete
        />
      </Row>
      <Row>
        <Input
          label="کد پستی"
          name="postalCode"
          type="text"
          value={formData?.postalCode}
          onChange={(event) => onNumberHandler(event.target.value)}
          maxLength={10}
          errors={fieldError.postalCode}
          onPressEnter={submitHandle}
          disableAutoComplete
        />
      </Row>

      {props.level.upgradeRequestState === 0 ? (
        <Alert
          title="صبور باشید"
          type="warning"
          message={
            `مدرک ارسالی شما در دست بررسی توسط کارشناسان ` +
            APPNAME +
            ' می باشد.'
          }
        />
      ) : props.level.upgradeRequestState === 2 ? (
        <Alert
          title="مدارک شما رد شد ، دلیل رد :"
          type="danger"
          message={props.level.rejectUpgradeMessage}
        />
      ) : (
        <></>
      )}

      <Button
        type="info"
        text="ثبت اطلاعات"
        htmlType="button"
        loading={loading}
        disabled={
          !formData?.address || formData?.postalCode?.length != 10 || loading
        }
        onClick={() => submitHandle()}
      />
    </form>
  );
};

import * as React from 'react';
import { Button, Card, Input } from 'components';
import './index.scss';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { message } from 'components/toast/toast';
import { emailRegex } from 'utils/constants/constants';

const { useState, useRef, useEffect } = React;

type Props = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  phone?: string;
  setProfileData: any;
  profileData: any;
};

export const ChangeEmailAddress: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>('');

  const onConfirmHandler = () => {
    setLoading(true);
    if (!emailAddress.match(emailRegex)) {
      setLoading(false);
      return message.error('آدرس ایمیل وارد شده صحیح نمی باشد.');
    }

    postViaAuth(API.profile.setEmailAddress, {
      email: emailAddress,
    }).then(function (response: any) {
      setLoading(false);
      props.setIsOpen(false);
      if (responseValidator(response.status)) {
        message.success('ادرس ایمیل با موفقیت ویرایش شد.');
        props.setProfileData({
          ...props.profileData,
          emailAddress: emailAddress,
        });
      }
    });
  };

  return (
    <Card className="xpay-profile-change-email">
      <div className="main code">
        <Input
          type="text"
          name="emailAddress"
          value={emailAddress}
          placeholder="آدرس ایمیل خود را واردکنید"
          dir={emailAddress === '' ? 'rtl' : 'ltr'}
          onChange={(e) => setEmailAddress(e.target.value)}
          onPressEnter={onConfirmHandler}
        />
      </div>
      <Button
        type="info"
        text="تایید"
        onClick={() => onConfirmHandler()}
        disabled={loading ? true : false}
        loading={loading}
      />
    </Card>
  );
};

import { API } from 'apis';
import { message } from 'components/toast/toast';
import { LevelTwoAddressValueType, Profile } from 'core/domains';
import {
  getViaAuth,
  postViaAuth,
  responseValidator,
  uploadMultipleViaAuth,
  uploadViaAuth,
} from 'utils/scripts/api';

export const getMineLevelAsync = async (setloader: any, setdata: any) => {
  setloader(true);
  await getViaAuth<Profile>(API.level.mine).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const level2CallLandline = (
  setLoader: any,
  data: any,
  setStage: any,
  setIsOpen: any,
) => {
  setLoader(true);
  postViaAuth(API.authentication.callLandlineUpgradeTo2, {
    landline: `${data.cityCode}${data.landline}`,
  }).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      if (response.value.isSentCode && response.value.isSentCode == true)
        setIsOpen(true);
      else window.location.reload();
      message.success('درخواست شما ارسال شد.');
    }
  });
};
export const checkForLandLine = async (setStep: any) => {
  await getViaAuth(API.authentication.hasLandline).then((response: any) => {
    if (responseValidator(response.status)) {
      if (response.value.userHasLandLine === true) setStep('ADDRESS');
    }
  });
};

export const checkForAddressAndPostalCode = async (setStep: any) => {
  await getViaAuth(API.authentication.hasAddressAndPostalCode).then(
    (response: any) => {
      if (responseValidator(response.status)) {
        if (response.value.userHasAddressOrPostalCode === true)
          setStep('UPLOAD_FILES');
      }
    },
  );
};

export const level2VerifyLandline = (
  setLoader: any,
  data: any,
  setIsOpen: any,
  setCodeError: any,
) => {
  setLoader(true);

  postViaAuth(API.authentication.verifyLandlineUpgradeTo2, {
    landline: `${data.cityCode}${data.landline}`,
    otpCode: data.code,
  }).then(function (response: any) {
    setLoader(false);
    if (responseValidator(response.status)) {
      setIsOpen(false);
      message.success('شماره تماس تایید شد');
      window.location.reload();
    } else {
      if (response.status === 400) setCodeError(response.message);
    }
  });
};

export const upgradeLevel2Address = (
  setLoader: any,
  data: LevelTwoAddressValueType,
) => {
  setLoader(true);

  postViaAuth(API.authentication.addAddressUpgradeTo2, {
    address: data.address + '،' + data.city?.title + '،' + data.state?.title,
    postalCode: data.postalCode,
  }).then(function (response: any) {
    setLoader(false);
    if (responseValidator(response.status)) {
      message.success('آدرس شما ثبت شد');
      window.location.reload();
    }
  });
};

export const upgradeToLevel2 = async (
  file: File,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPercent: React.Dispatch<React.SetStateAction<number>>,
  setUploadCancelSource: React.Dispatch<
    React.SetStateAction<AbortController | null>
  >,
  setUploading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const formData = new FormData();
  formData.append('DocumentFile', file);

  try {
    var res = await uploadViaAuth(
      API.authentication.upgradeToLevel2,
      formData,
      setPercent,
      setUploadCancelSource,
    );
    setLoading(false);
    setUploading(false);
    if (res.isSuccess) {
      message.success('تصویر آپلود شد');
      window.location.reload();
    }
  } catch (error) {
    setLoading(false);
    setUploading(false);

    if (error instanceof DOMException && error.name === 'AbortError') {
      message.error('آپلود تصویر لغو شد');
    } else {
      message.error('آپلود تصویر لغو شد');
    }
  }
};

export const upgradeToLevel3 = async (
  formData: FormData,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPercent: React.Dispatch<React.SetStateAction<number>>,
  setUploadCancelSource: React.Dispatch<
    React.SetStateAction<AbortController | null>
  >,
  setUploading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  try {
    setLoading(true);
    setUploading(true);
    var res = await uploadMultipleViaAuth(
      API.authentication.upgradeToLevel3,
      formData,
      setPercent,
      setUploadCancelSource,
    );
    setLoading(false);
    setUploading(false);
    if (res.isSuccess) {
      message.success('تصاویر آپلود شد');
      window.location.reload();
    }
  } catch (error) {
    setLoading(false);
    setUploading(false);
    if (error instanceof DOMException && error.name === 'AbortError') {
      message.error('آپلود تصاویر لغو شد');
    } else {
      message.error('آپلود تصاویر لغو شد');
    }
  }
};

import * as React from 'react';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import { Button, Calendar, Input } from 'components';
import { Badge } from 'components/badge/badge';
import { Table } from 'components/table/table';
import moment from 'jalali-moment';
import './crypto-transactions.scss';
import {
  PaginationList,
  Transaction,
  TransactionFilterValues,
  TransactionList,
} from '../../../../../core/domains';
import { TableColumn } from 'react-data-table-component';
import { getIconPath } from 'utils/scripts/getIconPath';
import { ToItemTypes } from 'utils/scripts/table';
import { _states, _types } from '../filters/constants';
import { TransactionDetailModal } from '../detail-modal/transactions/modal';
import { getAllMineTransactions } from 'core/repositories/transcations/transaction';
import { Pagination } from 'components/pagination/pagination';
import {
  getViaAuth,
  responseValidator,
} from '../../../../../utils/scripts/api';
import { API } from '../../../../../apis';
import { getPageNum } from 'utils/scripts/paggingHelper';
import { TransactionOrderDetailModal } from '../../../../../components/transaction-order-modal/transaction-order-modal';

const { useState } = React;

const empty_filters = {
  state: {
    id: 0,
    title: 'وضعیت تراکنش',
  },
};

export const CryptoTransactions: React.FC = () => {
  const [data, setData] = useState<PaginationList<Transaction>>();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [cardLoading, setCardloading] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<TransactionFilterValues>({
    ...empty_filters,
    marketType: 1,
  });

  const [selectedItem, setSelectedItem] = useState<Transaction | null>();
  const [query, setQuery] = useState<string>('');

  const clickOnType: MenuProps['onClick'] = ({ key }) => {
    setFilters({
      ...filters,
      type: _types.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const clickOnState: MenuProps['onClick'] = ({ key }) => {
    setFilters({
      ...filters,
      state: _states.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const filterHandle = () => {
    var q = '';
    q += `${q.includes('?') ? '&' : '?'}marketType=1`;

    if (filters) {
      if (filters.state?.id !== 0)
        q += `${q.includes('?') ? '&' : '?'}state=${filters.state?.id}`;
      if (filters.startDate)
        q += `${q.includes('?') ? '&' : '?'}startDate=${filters.startDate}`;
      if (filters.endDate)
        q += `${q.includes('?') ? '&' : '?'}endDate=${filters.endDate}`;
      if (filters.sort?.id)
        q += `${q.includes('?') ? '&' : '?'}sort=${filters.sort?.id}`;
      if (filters.keywords)
        q += `${q.includes('?') ? '&' : '?'}keywords=${filters.keywords}`;
    }
    setQuery(q);
    getAllMineTransactions(
      setCardloading,
      setData,
      {
        pageNumber: getPageNum(),
        pageSize: 10,
      },
      q,
    );
  };

  const columns: TableColumn<Transaction>[] = [
    {
      name: '#',
      id: 'index',
      selector: (row, rowIndex) => (rowIndex ? rowIndex + 1 : 0),
      width: '50px',
    },
    {
      name: 'کد پیگیری',
      id: 'trackingCode',
      selector: (row) => row?.trackingCode,
      cell: (row) => <span className="en">{row.trackingCode}</span>,
    },
    {
      name: 'ارز',
      id: 'market',
      selector: (row) => row?.market.name,
      cell: (row) => (
        <>
          <img src={getIconPath(row.market.symbol)} />
          <span>{row.market.name}</span>
        </>
      ),
    },
    {
      name: 'نوع تراکنش',
      id: 'transactionType',
      selector: (row) => row.type.title,
      cell: (row) => (
        <>
          <Badge
            type={row.type.id === 1 ? 'success' : 'error'}
            text={row.type.title}
          />
        </>
      ),
    },
    {
      name: 'نام شبکه',
      id: 'network',
      selector: (row) => row?.network?.name,
      cell: (row) => <span className="en">{row?.network?.name}</span>,
    },
    {
      name: 'مقدار کوین',
      id: 'amount',
      selector: (row) => row?.amount,
      cell: (row) => <span className="en">{row?.amount}</span>,
    },
    {
      name: 'وضعیت',
      id: 'state',
      selector: (row) => row.state.id,
      cell: (row: any) => (
        <Badge
          text={row.state.id == 2 ? 'موفق' : row.state.title}
          type={
            row.state.id == 2
              ? 'success'
              : row.state.id == 3 || row.state.id == 8
              ? 'error'
              : 'waiting'
          }
          icon={`/assets/icons/table/${
            row.state.id == 2
              ? 'success'
              : row.state.id == 3 || row.state.id == 8
              ? 'danger'
              : 'waiting'
          }.svg`}
        />
      ),
      width: '200px',
    },
    {
      name: 'زمان',
      id: 'createdAt',
      selector: (row) => row?.createdAt,
      cell: (row: any) => (
        <div className="column-date">
          <span className="day">
            {moment(row.createdAt).locale('fa').local().format('YYYY/MM/DD')}
          </span>
          <span className="time">
            {moment(row.createdAt).local().format('HH:mm')}
          </span>
        </div>
      ),
    },

    {
      name: 'جزئیات',
      id: 'detail',
      cell: (row) => <InfoCircleOutlined onClick={() => handleModal(row)} />,
    },
  ];
  const getTxid = async () => {
    if (
      !selectedItem ||
      selectedItem.market.symbol == 'PM' ||
      selectedItem.market.symbol == 'IRT'
    )
      return;
    if (
      selectedItem.state.id === 2 &&
      (selectedItem.txId == null || selectedItem.txId == '')
    ) {
      setModalLoading(true);
      await getViaAuth<TransactionList>(
        API.transactions.crypto.detail + `/${selectedItem?.trackingCode}`,
      ).then((response: any) => {
        if (responseValidator(response.status)) {
          if (response.value.txid === '')
            setSelectedItem({
              ...selectedItem,
              txId: null,
            });
          else
            setSelectedItem({
              ...selectedItem,
              txId: response.value.txid,
            });
          setModalLoading(false);
        } else {
          setModalLoading(false);
        }
      });
    }
  };

  async function handleModal(item: Transaction) {
    setIsOpen(true);
    setSelectedItem(item);
  }

  React.useEffect(() => {
    getTxid();
  }, [selectedItem?.trackingCode]);
  React.useEffect(() => {
    filterHandle();
  }, [filters]);

  return (
    <div className="orders-currency-transactions">
      {selectedItem && (
        <TransactionOrderDetailModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          transactionItem={selectedItem}
        />
      )}

      <Spin spinning={cardLoading}>
        <div className="desktop-show">
          <div className="filter-row">
            <div className="fields">
              <Input
                type="text"
                value={filters?.keywords}
                placeholder="جستجو کنید ..."
                className="search"
                name="search"
                onChange={(e) =>
                  setFilters({ ...filters, keywords: e.target.value })
                }
              />

              <Dropdown
                className="states-dropdown"
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(_states), onClick: clickOnState }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.state?.title}
                />
              </Dropdown>
              <Calendar
                label="از تاریخ"
                placeholder="از تاریخ"
                name="startDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, startDate: value }))
                }
              />
              <Calendar
                label="تا تاریخ"
                placeholder="تا تاریخ"
                name="endDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, endDate: value }))
                }
              />
            </div>
            <div className="buttons">
              <span
                onClick={() => setFilters({ ...empty_filters, marketType: 1 })}
              >
                حذف فیلتر
              </span>
            </div>
          </div>

          {data && (
            <Table
              pagination={data?.pagination}
              blur={cardLoading}
              hasFiltering={true}
              data={data.items}
              column={columns}
              setData={setData}
              setTableLoading={setCardloading}
              getData={getAllMineTransactions}
              query={query}
            />
          )}
        </div>

        <ul className="mobile-show">
          <div className="filter-row">
            <h4>واریز و برداشت های ارزی</h4>
            <div className="fields">
              <Input
                type="text"
                value={filters?.keywords || ''}
                placeholder="جستجو کنید ..."
                name="search"
                onChange={(e) =>
                  setFilters({ ...filters, keywords: e.target.value })
                }
              />

              <Calendar
                label="از تاریخ"
                placeholder="از تاریخ"
                name="startDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, startDate: value }))
                }
              />
              <Calendar
                label="تا تاریخ"
                placeholder="تا تاریخ"
                name="endDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, endDate: value }))
                }
              />
              <Dropdown
                className="states-dropdown"
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(_states), onClick: clickOnState }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.state?.title}
                />
              </Dropdown>
            </div>

            <div className="buttons">
              <Button
                type="info"
                text="حذف فیلتر"
                onClick={() => {
                  {
                    setFilters({});
                  }
                }}
              />
            </div>
          </div>
          {data?.items?.map((item) => (
            <li>
              <div>
                <div className="right">
                  <img src={getIconPath(item.market.symbol)} alt="" />
                  <p>{item.market.name}</p>
                  <span>{item.market.symbol.toUpperCase()}</span>
                </div>
                <div>
                  <p> نوع تراکنش : {item?.type.title}</p>
                </div>
              </div>
              <div>
                <div className="right">
                  <p>نام شبکه:</p>
                  <span>{item?.network?.name}</span>
                </div>
                <div className="left">
                  <p>مقدار :</p>
                  <span>{item.amount}</span>
                  <p>واحد</p>
                </div>
              </div>
              <div>
                <div className="right">
                  <p>
                    {moment(item.createdAt)
                      .locale('fa')
                      .local()
                      .format('YYYY/MM/DD')}{' '}
                    | {moment(item.createdAt).local().format('HH:mm')}
                  </p>
                </div>
                <div className="left">
                  <Badge
                    text={item.state.title}
                    type={
                      item.state.id == 2
                        ? 'success'
                        : item.state.id == 3 || item.state.id == 8
                        ? 'error'
                        : 'waiting'
                    }
                  />
                </div>
              </div>
              <Button
                type="outlined"
                text="مشاهده جزئیات"
                onClick={() => handleModal(item)}
              />
            </li>
          ))}
        </ul>

        <div className="mobile-show">
          {data && data.items.length > 0 && (
            <Pagination
              query={query}
              page={data.pagination.pageIndex || 0}
              data={data.items || []}
              setdata={setData}
              setloader={setCardloading}
              getdata={getAllMineTransactions}
              size={data.pagination.pageSize || 10}
              totalRows={data.pagination.totalCount}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

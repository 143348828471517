import * as React from 'react';
import './payment.scss';
import 'components/transaction-box/transaction-box.scss';
import {
  BankOutlined,
  CreditCardOutlined,
  PlusOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Alert, Button, Input } from 'components';
import {
  Bank,
  Cart,
  DepositVoucher,
  DepositVoucherError,
  Enum,
  Market,
} from 'core/domains';
import { faNumToEnNum, IsNumeric, ToIranCurrency } from 'utils/scripts/decimal';
import { deleteCommaSperator } from 'utils/scripts/exchange';
import { AssetItem } from 'core/domains/markets/asset';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { message } from 'components/toast/toast';
import { detect_bank, formatCardNumber } from 'utils/scripts/bankDetecter';
import { createCartAndReload, getMineCarts } from 'core/repositories';

const { useState } = React;

const buyPaymentTypes: Enum[] = [
  {
    id: 1,
    title: 'واریز از کیف پول',
    icon: <WalletOutlined />,
  },
  {
    id: 2,
    title: 'واریز از درگاه',
    icon: <CreditCardOutlined />,
  },
];

const sellPaymentTypes: Enum[] = [
  {
    id: 1,
    title: 'واریز به کیف پول',
    icon: <WalletOutlined />,
  },
  {
    id: 2,
    title: 'واریز به حساب',
    icon: <CreditCardOutlined />,
  },
];

type Props = {
  side: string;
  sourceMarket?: Market;
  destinationMarket?: Market;
  sourceValue: number;
  destinationValue: number;
  allBalances: AssetItem;
  iBan?: string;
  bank?: Bank;
  carts?: Cart[];
  voucher?: DepositVoucher;
  selectedCart?: Cart;
  fieldError: DepositVoucherError;
  cardNumber?: string;
  setModalIsOpen: React.Dispatch<any>;
  setFieldError: React.Dispatch<any>;
  setModalLoading: React.Dispatch<any>;
  setModalStage: React.Dispatch<any>;
  setDepositData: React.Dispatch<any>;
  setSelectedCart: React.Dispatch<any>;
  setDepositStage: React.Dispatch<any>;
  setIBan?: React.Dispatch<any>;
  setCardNumber: React.Dispatch<any>;
  setBank: React.Dispatch<any>;
  setCardIsLoading: React.Dispatch<any>;
  setCarts: React.Dispatch<any>;
  setSourceValue: React.Dispatch<any>;
  setDestinationValue: React.Dispatch<any>;
};

export const OrderModalPayment: React.FC<Props> = ({
  side,
  sourceMarket,
  destinationMarket,
  sourceValue,
  destinationValue,
  allBalances,
  iBan,
  bank,
  carts,
  voucher,
  selectedCart,
  fieldError,
  cardNumber,
  setDepositData,
  setFieldError,
  setModalStage,
  setDepositStage,
  setSelectedCart,
  setCardNumber,
  setBank,
  setIBan,
  setCardIsLoading,
  setCarts,
  setSourceValue,
  setDestinationValue,
}) => {
  const [paymentType, setPaymentType] = useState<Enum>(buyPaymentTypes[1]);
  const [isIBanNeeded, setIsIBanNeeded] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  function handleIBanChange(value: string) {
    // all number Fa to En
    if (value !== '') value = faNumToEnNum(value);
    // validation
    if (value !== '' && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    // set on state
    setIBan && setIBan(value);
  }

  const onCreateBankAccountForBuy = () => {
    setModalLoading(true);

    cardNumber &&
      createCartAndReload(
        cardNumber,
        getMineCarts,
        setCarts,
        setModalLoading,
        iBan,
      );
  };

  const handleCreditCardChange = (value: string) => {
    const cardNumber = formatCardNumber(faNumToEnNum(value));
    if (cardNumber) {
      const card = detect_bank(cardNumber);
      card && setBank({ code: card.bank_logo, name: card.bank_title });
    }
    setCardNumber(cardNumber);
  };

  const onBuyPerfectMoneyHandler = () => {
    setModalLoading(true);
    if (paymentType.id === 2) {
      postViaAuth(API.transactions.perfectMoney.buy, {
        bankAccountId: selectedCart?.id,
        amount: parseFloat(deleteCommaSperator(destinationValue)),
        source: 1,
      }).then(function (response: any) {
        setModalLoading(false);
        if (responseValidator(response.status)) {
          window.open(response.value.paymentUrl, '_self');
        }
      });
    } else {
      postViaAuth(API.transactions.perfectMoney.buy, {
        amount: parseFloat(sourceValue.toString().replaceAll(',', '')),
        source: 0,
      }).then(function (response: any) {
        setModalLoading(false);
        if (responseValidator(response.status)) {
          setDepositData({
            voucher_code: response.value.voucherCode,
            activation_code: response.value.voucherActivationCode,
            amount: response.value.amount,
            trackingCode: response.value.trackingCode,
          });
          setDepositStage('thankyou');
        }
      });
    }
  };
  const onBuyUusdHandler = () => {
    setModalLoading(true);
    if (paymentType.id === 2) {
      postViaAuth(API.transactions.uvoucher.buy, {
        bankAccountId: selectedCart?.id,
        amount: parseFloat(deleteCommaSperator(destinationValue)),
        source: 1,
      }).then(function (response: any) {
        setModalLoading(false);
        if (responseValidator(response.status)) {
          window.open(response.value.paymentUrl, '_self');
        }
      });
    } else {
      postViaAuth(API.transactions.uvoucher.buy, {
        amount: parseFloat(sourceValue.toString().replaceAll(',', '')),
        source: 0,
      }).then(function (response: any) {
        setModalLoading(false);
        if (responseValidator(response.status)) {
          setDepositData({
            voucher_code: response.value.voucherCode,
            amount: response.value.amount,
            trackingCode: response.value.trackingCode,
          });
          setDepositStage('thankyou');
        }
      });
    }
  };

  function onSellPerfectMoneyHandler() {
    setFieldError({});
    if (voucher?.activation_code && voucher?.voucher_code) {
      setModalLoading(true);
      postViaAuth(API.deposit.perfectMoney, {
        voucherActivationCode: voucher.activation_code,
        bankAccountId: selectedCart?.id,
        voucherCode: voucher.voucher_code,
        destination: paymentType.id === 2 ? 1 : 0,
      }).then((response: any) => {
        setModalLoading(false);
        if (responseValidator(response.status)) {
          message.success(response.message);
          setDepositData(response.value);
          setSourceValue(response.value.voucherAmount);
          setDestinationValue(response.value.amount);
          setModalStage('thankyou');
        }
      });
    } else {
      if (!voucher?.activation_code) {
        setFieldError({
          ...fieldError,
          activation_code: ['وارد کردن کد فعالسازی الزامیست'],
        });
      }
      if (!voucher?.voucher_code) {
        setFieldError({
          ...fieldError,
          voucher_code: ['وارد کردن کد ووچر الزامیست'],
        });
      }
    }
  }
  function onSellUusdHandler() {
    setFieldError({});
    if (voucher?.voucher_code) {
      setModalLoading(true);
      postViaAuth(API.deposit.uvoucher, {
        bankAccountId: selectedCart?.id,
        voucherCode: voucher.voucher_code,
        destination: paymentType.id === 2 ? 1 : 0,
      }).then((response: any) => {
        setModalLoading(false);
        if (responseValidator(response.status)) {
          message.success(response.message);
          setDepositData(response.value);
          setSourceValue(response.value.voucherAmount);
          setDestinationValue(response.value.amount);
          setModalStage('thankyou');
        }
      });
    } else {
      if (!voucher?.voucher_code) {
        setFieldError({
          ...fieldError,
          voucher_code: ['وارد کردن کد ووچر الزامیست'],
        });
      }
    }
  }

  React.useEffect(() => {
    side === 'sell' &&
      (sourceMarket?.symbol === 'PM' || sourceMarket?.symbol === 'UUSD') &&
      setPaymentType(sellPaymentTypes[0]);
  }, [side]);

  return (
    <div className="payment-types-modal">
      <div className="payment-type-list">
        {destinationMarket?.symbol === 'PM' ||
        destinationMarket?.symbol === 'UUSD'
          ? buyPaymentTypes.map((item) => {
              return (
                <div
                  onClick={() => setPaymentType(item)}
                  className={`payment-type-item ${
                    paymentType.id === item.id && 'selected'
                  }`}
                >
                  {item.icon}
                  <span>{item.title}</span>
                </div>
              );
            })
          : sellPaymentTypes.map((item) => {
              return (
                <div
                  onClick={() => setPaymentType(item)}
                  className={`payment-type-item ${
                    paymentType.id === item.id && 'selected'
                  }`}
                >
                  {item.icon}
                  <span>{item.title}</span>
                </div>
              );
            })}
      </div>
      {paymentType.id === 1 &&
        side === 'buy' &&
        allBalances.balance < sourceValue && (
          <Alert
            title="عدم موجودی"
            noIcon
            message="موجودی کیف پول شما جهت خرید این مقدار پرفکت مانی کافی نمی باشد."
            type="warning"
          />
        )}
      {paymentType.id === 1 && side === 'buy' && (
        <Alert
          title="موجودی فعلی شما"
          noIcon
          message={`موحودی تومانی شما معادل ${ToIranCurrency(
            allBalances.balance,
          )} تومان می باشد.`}
          type="info"
        />
      )}
      {carts && carts.length > 0 && paymentType.id === 2 ? (
        <div className="cart-selector">
          <div className="head">
            <label>کارت بانکی خود را انتخاب کنید</label>
          </div>
          <div className="cart-list">
            {carts?.map((cart) => {
              return (
                <div
                  onClick={() => setSelectedCart(cart)}
                  className={`cart-item ${
                    selectedCart?.id === cart.id && 'selected'
                  }`}
                >
                  <img
                    src={`/banks/${cart.bank?.code.toLowerCase()}.svg`}
                    alt=""
                  />
                  <div className="cart-info">
                    <b>{cart.bank?.name}</b>
                    <span>{cart.cardNumber}</span>
                  </div>
                </div>
              );
            })}

            <div
              onClick={() => setModalStage('cart')}
              className="add-cart-section"
            >
              <PlusOutlined />
              <span>افزودن کارت بانکی جدید</span>
            </div>
          </div>
        </div>
      ) : paymentType.id === 2 ? (
        <div className="create-cart-modal">
          <Input
            placeholder="شماره کارت خود را وارد کنید"
            type="text"
            dir={cardNumber === '' ? 'rtl' : 'ltr'}
            name="card"
            onChange={(el) => handleCreditCardChange(el.target.value)}
            adorments={{
              startAdornment: {
                adornmentIcon: bank ? (
                  <img src={bank?.code} alt="" />
                ) : (
                  <CreditCardOutlined />
                ),
              },
            }}
            value={cardNumber}
            maxLength={19}
          />
          {isIBanNeeded && (
            <Input
              label="شماره شبا*"
              type="text"
              name="iban"
              onChange={(el) => handleIBanChange(el.target.value)}
              adorments={{
                startAdornment: {
                  adornmentIcon: <BankOutlined />,
                },
                endAdornment: {
                  adornmentIcon: 'IR',
                },
              }}
              value={iBan}
              maxLength={19}
            />
          )}
          <div className="buttons">
            <Button
              type="info"
              text="افزودن حساب بانکی"
              disabled={modalLoading}
              htmlType="button"
              loading={modalLoading}
              onClick={(e) => onCreateBankAccountForBuy()}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="footer">
        <Button
          onClick={() =>
            sourceMarket?.symbol === 'PM'
              ? onSellPerfectMoneyHandler()
              : sourceMarket?.symbol === 'UUSD'
              ? onSellUusdHandler()
              : destinationMarket?.symbol === 'PM'
              ? onBuyPerfectMoneyHandler()
              : onBuyUusdHandler()
          }
          loading={modalLoading}
          disabled={modalLoading || (paymentType.id === 2 && !selectedCart)}
          type="info"
          text="پرداخت"
        />
      </div>
    </div>
  );
};

import React from 'react';
// import './App.css';
import { Xpay } from './routes/xpay';
import 'styles/global.scss';
import { Provider } from 'react-redux';
import { store } from 'core/redux/store';
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import 'antd/dist/reset.css';
import { APPNAME, Fav, GTM, MANIFEST, ONLINE_CHAT } from './apis/constants';

const App: React.FC = () => {
  React.useEffect(() => {}, []);

  return (
    <Provider store={store}>
      <Helmet>
        <link href={Fav} rel="icon" type="image/x-icon" />
        <script src={`${ONLINE_CHAT}`} type="text/javascript" />
        <script src={`${GTM}`} type="text/javascript" />

        <link href={Fav} rel="icon" />
        <link href={Fav} rel="icon" type="image/png" />
        <link href={Fav} rel="apple-touch-icon" />
        <link href={MANIFEST} rel="manifest" />

        <title>{APPNAME} | پلتفرم معاملاتی ارز های دیجیتال</title>

        <meta content="no-cache" http-equiv="cache-control" />
        <meta content="0" http-equiv="expires" />
        <meta content="no-cache" http-equiv="pragma" />
        <meta
          name="description"
          content="خرید و فروش اتوماتیک و آسان پرفکت مانی ، تتر و رمز ارز ها احراز هویت هوشمند و سریع زیر ۱ دقیقه بدون معطلی نرخ های رقابتی و بدون کارمزد"
        />
      </Helmet>
      <Toaster position="top-center" reverseOrder={false} />
      <Xpay />
    </Provider>
  );
};

export default App;

import * as React from 'react';
import './thankyou.scss';
import 'components/transaction-box/transaction-box.scss';
import { Button, Card } from 'components';
import { Market } from 'core/domains';
import { getIconPath } from 'utils/scripts/getIconPath';
import { digitSperator } from 'utils/scripts/exchange';

type Props = {
  side: string;
  sourceMarket?: Market;
  destinationMarket?: Market;
  sourceValue: number;
  destinationValue: number;
  clearInput: any;
  setModalIsOpen: React.Dispatch<any>;
};

export const OrderModalThankyou: React.FC<Props> = ({
  side,
  sourceMarket,
  destinationMarket,
  sourceValue,
  destinationValue,
  setModalIsOpen,
  clearInput,
}) => {
  return (
    <Card className="detail-card">
      <div className="confirm-modal">
        <ul>
          <li>
            <span>نام ارز</span>
            <div className="coin-info">
              <>
                <img src={getIconPath(sourceMarket?.symbol || '')} />{' '}
                <span>{sourceMarket?.name}</span>
              </>
            </div>
          </li>
          <li>
            <span>مقدار ووچر</span>
            <span>
              {digitSperator(sourceValue)}
              واحد
            </span>
          </li>
          <li>
            <span>مقدار</span>
            <span>
              {digitSperator(destinationValue)}
              تومان
            </span>
          </li>
        </ul>

        <div className="row footer">
          <Button
            type={'outlined'}
            text={'تایید'}
            onClick={() => {
              clearInput();
              setModalIsOpen(false);
            }}
          />
        </div>
      </div>
    </Card>
  );
};

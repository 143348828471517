import * as React from 'react';
import { Button, Card, Input } from 'components';
import './index.scss';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { message } from 'components/toast/toast';
import { phoneRegex } from '../../../../../../utils/constants/constants';
import {
  faNumToEnNum,
  IsNumeric,
} from '../../../../../../utils/scripts/decimal';
import { deleteCommaSperator } from '../../../../../../utils/scripts/exchange';

const { useState, useRef, useEffect } = React;

type Props = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStage: React.Dispatch<React.SetStateAction<'PHONE' | 'CODE'>>;
  phone?: string;
  setProfileData: any;
  profileData: any;
};

export const EnterChangingPhoneNumber: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const onConfirmHandler = () => {
    setLoading(true);
    if (!phoneNumber.match(phoneRegex)) {
      setLoading(false);
      return message.error('شماره موبایل وارد شده صحیح نمی باشد.');
    }
    localStorage.setItem('newPhoneNumber', phoneNumber);
    postViaAuth(API.profile.editPhoneNumber, {
      newPhoneNumber: phoneNumber,
    }).then(function (response: any) {
      setLoading(false);
      if (responseValidator(response.status)) {
        props.setProfileData({
          ...props.profileData,
          phoneNumber: phoneNumber,
        });
        props.setStage('CODE');
      }
    });
  };

  const onNumberHandler = (value: string) => {
    if (value !== '') value = faNumToEnNum(value);
    if (value !== '' && !IsNumeric(value[value.length - 1])) return;
    setPhoneNumber(value);
  };

  return (
    <Card className="xpay-profile-change-phone">
      <div className="head">
        <p>لطفا شماره موبایلی که قصد تغییر به آن را دارید وارد کنید</p>
      </div>
      <div className="main code">
        <Input
          type="text"
          name="phoneNumber"
          value={phoneNumber}
          placeholder="شماره موبایل جدید خود را وارد کنید"
          dir={phoneNumber === '' ? 'rtl' : 'ltr'}
          onChange={(e) => onNumberHandler(e.target.value)}
          onPressEnter={onConfirmHandler}
        />
      </div>
      <Button
        type="info"
        text="تایید"
        onClick={() => onConfirmHandler()}
        disabled={loading ? true : false}
        loading={loading}
      />
    </Card>
  );
};

import * as React from 'react';
import './widthrawl-currency-box.scss';
import { Button, Card, Input, SwitchBar } from 'components';
import { Modal } from 'components/modal/modal';
import {
  CreditCardOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { faNumToEnNum, IsNumeric, ToIranCurrency } from 'utils/scripts/decimal';
import { Bank, Cart, Enum, Profile } from 'core/domains';
import { AssetItem } from '../../../../../core/domains/markets/asset';
import {
  getViaAuth,
  postViaAuth,
  responseValidator,
} from '../../../../../utils/scripts/api';
import { API } from '../../../../../apis';
import { message } from '../../../../../components/toast/toast';
import { WithdrawalConfirm } from '../confirmModal/withdrawal-confirm-modal';
import {
  deleteCommaSperator,
  digitSperator,
} from '../../../../../utils/scripts/exchange';
import { detect_bank, formatCardNumber } from 'utils/scripts/bankDetecter';
import { SelectBankAccountModal } from '../../../../../components/select-bankaccount-modal/select-bankAccount-modal';

const { useState } = React;

type Props = {};
export const WithdrawalCurrencyBox: React.FC<Props> = (props) => {
  // states
  const [stages, setStages] = useState<Enum[]>([
    {
      id: 1,
      title: 'موجودی قابل برداشت تومانی : ',
    },
  ]);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [bank, setBank] = useState<Bank>();
  const [cardNumber, setCardNumber] = useState<string>('');

  const [twoStepIsOpen, setTwoStepIsOpen] = useState<boolean>(false);

  const [stage, setStage] = useState<Enum>(stages[0]);
  const [widthrawlValue, setWidthrawlValue] = useState<number>(0);

  const [balance, setBalance] = useState<AssetItem>();

  const [cardIsLoading, setCardIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCart, setSelectedCart] = useState<Cart>();

  const [carts, setCarts] = useState<Cart[]>([]);

  const [isOpenSelectBankAccModal, setIsOpenSelectBankAccModal] =
    useState<boolean>(false);

  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [modalStage, setModalStage] = useState<'code' | 'thankyou'>('code');
  const [trackingCode, setTrackingCode] = useState<string>('');
  const [withdrawalCommission, setWithdrawalCommission] = useState<number>();

  const [isTwoStepActive, setIsTwoStepActive] = useState<number>();
  const onCartChangeHandler = (cart: any) => {
    setSelectedCart(cart);
    setIsOpenSelectBankAccModal(false);
  };
  const [minWidthrawlAmount, setMinWidthrawlAmount] = useState<number>(0);
  const [maxIrtWithdrawal, setMaxIrtWithdrawal] = useState<number>(0);

  const getAmountPolicies = async () => {
    setCardIsLoading(true);
    await getViaAuth<Cart[]>(API.policies.amount).then((response: any) => {
      setCardIsLoading(false);
      if (responseValidator(response.status)) {
        setMinWidthrawlAmount(response.value.minWidthrawlAmount);
      }
    });
  };

  const sendRequestWithdrawal = () => {
    setLoading(true);
    setModalStage('code');
    postViaAuth(API.widthrawl.currency, {
      amount: parseFloat(deleteCommaSperator(widthrawlValue)),
      bankAccountId: selectedCart?.id,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setTwoStepIsOpen(true);
        setTrackingCode(response.value.tracking);
        setWithdrawalCommission(response.value.withdrawalCommission);
        setIsTwoStepActive(response.value.twoFactorMethod.id);
        message.success('درخواست شما با موفقیت ثبت شد');
      }
    });

    /*  if (policies && policies.minWidthrawlAmount) {
                                                                                                                                                                                                                                                      if (
                                                                                                                                                                                                                                                          Number.parseInt(policies.minWidthrawlAmount) <
                                                                                                                                                                                                                                                          Number.parseInt(deleteCommaSperator(widthrawlValue))
                                                                                                                                                                                                                                                      ) {

                                                                                                                                                                                                                                                      } else
                                                                                                                                                                                                                                                          message.error(
                                                                                                                                                                                                                                                              `حداقل مقدار برداشت ${digitSperator(
                                                                                                                                                                                                                                                                  policies?.minWidthrawlAmount,
                                                                                                                                                                                                                                                              )} تومان است.`,
                                                                                                                                                                                                                                                          );
                                                                                                                                                                                                                                                  }*/
  };

  const verifyCurrencyWidthrawl = (
    code: string,
    trackingCode: string,
    setError: any | undefined,
  ) => {
    if (code.length === 6) {
      setLoading(true);
      postViaAuth(API.widthrawl.verifyCurrency, {
        trackingCode: trackingCode,
        otp: code,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          setModalStage('thankyou');
          setIsWaiting(response.data.state.id === 6 ? true : false);
        } else {
          if (response.status === 400)
            setError &&
              setError({
                phoneCode: response.message,
              });
        }
      });
    }
  };

  const getBankAccounts = () => {
    setCardIsLoading(true);
    getViaAuth<Cart[]>(API.bankAccount.mine).then((response: any) => {
      setCardIsLoading(false);
      if (responseValidator(response.status)) {
        setCarts(response.value);
        setSelectedCart(response.value[0]);
      }
    });
  };

  const handleCreditCardChange = (value: string) => {
    const cardNumber = formatCardNumber(faNumToEnNum(value));
    if (cardNumber) {
      const card = detect_bank(cardNumber);
      card &&
        setBank({
          code: card.bank_logo,
          name: card.bank_title,
          bank_logo: card.bank_logo,
        });
      setCardNumber(cardNumber);
    }
  };

  const onCreateBankAccount = () => {
    setModalLoading(true);
    postViaAuth(API.bankAccount.addCard, {
      cardNumber: cardNumber.replaceAll('-', ''),
    }).then((response: any) => {
      setModalLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success('کارت با موفقیت اضافه شد');
      }
    });
    setIsOpenSelectBankAccModal(false);
  };

  const getMineLevelAsync = async () => {
    setCardIsLoading(true);
    await getViaAuth<Profile>(API.level.mine).then((response: any) => {
      setCardIsLoading(false);
      if (responseValidator(response.status)) {
        setMaxIrtWithdrawal(response.value.maxIrtWithdrawal);
      }
    });
  };

  const fetches = async () => {
    await getMineLevelAsync();
    await getAmountPolicies();

    getBankAccounts();
    getMineBalance();
  };
  React.useEffect(() => {
    fetches();
    setStage(stages[0]);
  }, []);

  React.useEffect(() => {
    setSelectedCart(carts[0]);
  }, [carts]);

  const getMineBalance = () => {
    setCardIsLoading(true);
    getViaAuth<AssetItem>(API.wallet.pairBalance + 'IRT').then(
      (response: any) => {
        setCardIsLoading(false);

        if (responseValidator(response.status)) {
          setBalance(response.value);
          setStages([
            {
              id: 1,
              title:
                'موجودی قابل برداشت تومانی : ' +
                digitSperator(response.value.balance.toFixed(0)),
            },
          ]);
        }
      },
    );
  };

  const onNumberHandler = (value: string) => {
    if (value !== '') value = faNumToEnNum(value);
    if (value !== '' && !IsNumeric(value[value.length - 1])) return;
    setWidthrawlValue(
      value !== '' ? parseFloat(deleteCommaSperator(value)) : 0,
    );
  };

  function backModal() {
    getMineBalance();
    setWidthrawlValue(0);
    setTwoStepIsOpen(false);
  }

  return (
    <>
      <Modal
        className="currency-widthrawl-confirm-modal"
        isOpen={twoStepIsOpen}
        setIsOpen={setTwoStepIsOpen}
      >
        <WithdrawalConfirm
          isTwoStepActive={isTwoStepActive ?? 0}
          onBack={() => backModal()}
          setIsOpen={setTwoStepIsOpen}
          marketName={'تومان'}
          setLoading={setLoading}
          isWaiting={isWaiting}
          trackingCode={trackingCode}
          stage={modalStage}
          amount={widthrawlValue}
          cardNumber={selectedCart?.cardNumber ?? '0'}
          loading={loading}
          onConfirm={verifyCurrencyWidthrawl}
          onResend={sendRequestWithdrawal}
          symbol={'IRT'}
          withdrawalCommission={withdrawalCommission}
        />
      </Modal>
      <Modal
        title="افزودن حساب بانکی"
        closeAble
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <div className="create-cart-modal">
          <Input
            placeholder="شماره کارت خود را وارد کنید"
            type="text"
            dir={cardNumber === '' ? 'rtl' : 'ltr'}
            name="card"
            onChange={(el) => handleCreditCardChange(el.target.value)}
            adorments={{
              startAdornment: {
                adornmentIcon: bank ? (
                  <img src={bank?.code} alt="" />
                ) : (
                  <CreditCardOutlined />
                ),
              },
            }}
            value={cardNumber}
            maxLength={19}
            onPressEnter={onCreateBankAccount}
          />
          <div className="buttons">
            <Button
              type="info"
              text="افزودن حساب بانکی"
              htmlType="button"
              loading={modalLoading}
              onClick={() => onCreateBankAccount()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setModalIsOpen(false)}
            />
          </div>
        </div>
      </Modal>
      <Card blur={cardIsLoading} className={`xpay-widthrawl-currency-box `}>
        <div className="content">
          <SwitchBar
            disabled
            items={stages}
            active={stage}
            onChange={setStage}
          />

          <div className="widthrawl-options">
            <div className="widthrawl-value-row">
              {minWidthrawlAmount && (
                <span className="input-info-label">
                  {`حداقل میزان برداشت : ${ToIranCurrency(
                    minWidthrawlAmount,
                  )} تومان`}
                </span>
              )}
              <Input
                type="text"
                value={
                  widthrawlValue === 0
                    ? ''
                    : ToIranCurrency(widthrawlValue.toString())
                }
                onPressEnter={() =>
                  !loading && widthrawlValue !== 0 && sendRequestWithdrawal()
                }
                onChange={(e) => {
                  onNumberHandler(e.target.value);
                }}
                dir={widthrawlValue === 0 ? 'rtl' : 'ltr'}
                name="widthrawlValue"
                placeholder="مبلغ را به تومان وارد کنید"
                maxLength={12}
                adorments={{
                  startAdornment: {
                    adornmentIcon: (
                      <div className="mark">
                        <div className="selected-item">
                          <img src={`/coins/irt.svg`} />
                          <span>تومان</span>
                          <span>IRT</span>
                        </div>
                      </div>
                    ),
                  },
                  endAdornment: {
                    adornmentIcon: (
                      <div className="options">
                        <span
                          onClick={() =>
                            setWidthrawlValue(balance ? balance.balance : 0)
                          }
                          className="select-available-balance"
                        >
                          انتخاب تمام موجودی تومانی
                        </span>
                      </div>
                    ),
                  },
                }}
              />
            </div>
            <div className="payment-options-row">
              {carts.length === 0 ? (
                <>
                  <div
                    className="add-new-cart"
                    onClick={() => setModalIsOpen(true)}
                  >
                    <PlusOutlined />
                    <span>جهت افزودن حساب بانکی کلیک کنید</span>
                  </div>
                </>
              ) : (
                <div className="payment-cart-selector">
                  {maxIrtWithdrawal && (
                    <span className="input-info-label">
                      {`سقف برداشت : ${ToIranCurrency(maxIrtWithdrawal)}`}
                    </span>
                  )}
                  <Input
                    value={selectedCart?.cardNumber}
                    type="text"
                    label="انتخاب کارت بانکی"
                    onClick={() => setIsOpenSelectBankAccModal(true)}
                    onChange={(e) => {
                      setWidthrawlValue(ToIranCurrency(e.target.value));
                    }}
                    onPressEnter={() =>
                      !loading &&
                      widthrawlValue !== 0 &&
                      sendRequestWithdrawal()
                    }
                    name="cart"
                    readonly
                    adorments={{
                      startAdornment: {
                        adornmentIcon: (
                          <div
                            onClick={() =>
                              setIsOpenSelectBankAccModal(
                                !isOpenSelectBankAccModal,
                              )
                            }
                            className="mark"
                          >
                            <div className="selected-cart">
                              <img
                                src={`/banks/${selectedCart?.bank.code.toLowerCase()}.svg`}
                              />
                              <span>
                                {selectedCart?.bank.name.replace('بانک', '')}
                              </span>
                            </div>
                          </div>
                        ),
                      },
                      endAdornment: {
                        adornmentIcon: (
                          <DownOutlined
                            onClick={() =>
                              setIsOpenSelectBankAccModal(
                                !isOpenSelectBankAccModal,
                              )
                            }
                          />
                        ),
                      },
                    }}
                    placeholder="(کلیک کنید) کارت بانکی خود را از لیست کارت های ثبت شده انتخاب کنید."
                  />
                </div>
              )}
              {carts && (
                <SelectBankAccountModal
                  title={'کارت بانکی خود را جهت برداشت انتخاب کنید'}
                  carts={carts}
                  isOpen={isOpenSelectBankAccModal}
                  setIsOpen={setIsOpenSelectBankAccModal}
                  onChange={onCartChangeHandler}
                />
              )}
            </div>
          </div>
          <div className="submit">
            <Button
              loading={loading}
              disabled={loading || widthrawlValue === 0}
              onClick={() => sendRequestWithdrawal()}
              type="info"
              text="تایید برداشت"
            />
          </div>
        </div>
      </Card>
    </>
  );
};

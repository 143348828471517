import { Col, Row } from 'antd';
import { HelperCard } from 'components/helper-card/helper-card';
import { HelperRow } from 'components/helper-row/helper-row';
import { Market } from 'core/domains';
import {
  getAllCryptoMarkets,
  getAllDepositCryptoMarketNameIds,
} from 'core/repositories';
import * as React from 'react';
import { SellPerfectmoneyBox } from '../perfect-money/sell/sell-perfectmoney-box';
import { WidthrawlCryptoBox } from './crypto/widthrawl-crypto-box';
import { WithdrawalCurrencyBox } from './curency/withdrawal-currency-box';
import './widthrawl.scss';
import { WithdrawlIrtHelperText } from '../helper-text/withdrawl-irt';
import { WithdrawlCryptoHelperText } from '../helper-text/withdrawl-crypto';
import { HelperBox } from 'components/helper-box/helper-box';
import { useLocation } from 'react-router-dom';
import { WidthrawlPmBox } from './perfectmoney/widthrawl-pm-box';

const Widthrawl: React.FC = (props) => {
  const [markets, setMarkets] = React.useState<Market[]>([]);
  const [cardIsLoading, setCardIsLoading] = React.useState<boolean>(false);
  const [marketName, setMarketName] = React.useState<string>();

  const location = useLocation();

  React.useEffect(() => {
    location.pathname.includes('widthrawl') && document.body.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    markets.length === 0 && getAllCryptoMarkets(setCardIsLoading, setMarkets);
  }, []);

  React.useEffect(() => {
    markets.length === 0 &&
      getAllDepositCryptoMarketNameIds(setCardIsLoading, setMarkets);
  }, []);

  React.useEffect(() => {
    location.pathname.endsWith('IRT')
      ? setMarketName('تومان')
      : markets.length !== 0 &&
        setMarketName(
          markets?.find(
            (e: Market) =>
              e.symbol.toUpperCase() ===
              location.pathname
                .split('/')
                [location.pathname.split('/').length - 1].toUpperCase(),
          )?.name,
        );
  }, [markets]);

  const getWidthrawlBox = (symbol: string) => {
    switch (symbol) {
      case 'IRT':
        return <WithdrawalCurrencyBox />;
      case 'PM':
        return <WidthrawlPmBox />;

      default:
        return (
          markets && (
            <WidthrawlCryptoBox
              markets={markets}
              setMarketName={setMarketName}
            />
          )
        );
    }
  };

  return (
    <>
      <div className="xpay-widthrawl-page">
        <Row>
          <Col className="right-side" md={16} sm={24} xs={24} lg={16}>
            {getWidthrawlBox(window.location.href.split('/')[6].toUpperCase())}
          </Col>

          <Col className="left-side" md={8} sm={24} xs={24} lg={8}>
            <HelperCard title={`راهنمای برداشت ${marketName || ''}`}>
              <ul>
                {marketName === 'تومان' ? (
                  <WithdrawlIrtHelperText />
                ) : (
                  <WithdrawlCryptoHelperText />
                )}
              </ul>
            </HelperCard>
            <HelperBox title={`راهنمای برداشت ${marketName || ''}`}>
              {marketName === 'تومان' ? (
                <WithdrawlIrtHelperText />
              ) : (
                <WithdrawlCryptoHelperText />
              )}
            </HelperBox>
          </Col>
        </Row>
      </div>
      <Row className="help-bar-row">
        <HelperRow />
      </Row>
    </>
  );
};

export default Widthrawl;

import {Divider} from 'antd';
import * as React from 'react'; // @ts-ignore
import './helper-box.scss';
import {DownCircleOutlined, EyeInvisibleOutlined, EyeOutlined, UpCircleOutlined,} from '@ant-design/icons';
import {Button} from '../button/button';
import {HelperCard} from 'components/helper-card/helper-card';
import {HelperRow} from '../helper-row/helper-row';
import {Card} from 'components/card/card';

const { useState } = React;

type Props = {
  title: string;
};

export const HelperBox: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Card className="xpay-helper-box mobile-show">
      <div className="head">
        <div className="right">
          {isExpanded ? (
            <UpCircleOutlined onClick={() => setIsExpanded(!isExpanded)} />
          ) : (
            <DownCircleOutlined onClick={() => setIsExpanded(!isExpanded)} />
          )}
          <h4>راهنما</h4>
        </div>

        <Button
          text={`${isExpanded ? 'بستن' : 'مشاهده'} راهنما`}
          type="outlined"
          icon={isExpanded ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </div>

      {isExpanded && (
        <HelperCard title={props.title}>{props?.children}</HelperCard>
      )}

      <Divider />
      <HelperRow className="mobile-show" />
    </Card>
  );
};

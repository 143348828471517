import * as React from 'react';
import './card.scss';
import { Spin } from 'antd';
import { XpayComponent } from 'core/domains';

interface Props extends XpayComponent {
  title?: string;
  children: React.ReactNode;
  tags?: React.ReactNode;
  blur?: boolean;
  shadow?: boolean;
  style?: any;
  ref?: any;
}

export const Card: React.FC<Props> = (props: Props) => {
  return (
    <div
      ref={props.ref}
      style={props.style}
      className={`xpay-panel-card 
            ${props.shadow ? 'shadowed' : ''} 
            ${props.blur ? 'loading' : ''} 
            ${props.className ? props.className : ''}`}
    >
      {props.title ? (
        <div className="head">
          <h3>{props.title}</h3>
          {props.tags && props.tags}
        </div>
      ) : (
        <></>
      )}

      <div className="card-body">
        {props.blur ? (
          <Spin
            tip="در حال بارگذاری..."
            spinning={props.blur ? props.blur : false}
          >
            {props.children}
          </Spin>
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};

import { Enum, Pagination } from './../commons/common';

export type Market = {
  name: string;
  symbol: string;
  sellPrice: number;
  buyPrice: number;
};

export type Wallet = {
  address_wallet: string;
  memo_address: string;
};

export type Voucher = {
  trackingCode?: string;
  amount?: number;
  voucher_code?: string;
  activation_code?: string;
  batch_number?: number;
  card_number?: number;
  voucherAmount?: number;
};

export type Idebtifier = {
  timeStamp: string;
  creationTime: Date;
};
export type FilterValues = {
  transactionType?: number;
  fromDate?: string;
  toDate?: string;
  transactionTimeSort?: number;
  transactionState?: number;
  marketType?: number;
  type?: number;
  sort?: number;
};

export type TransactionFilterValues = {
  startDate?: string;
  endDate?: string;
  state?: Enum;
  keywords?: string;
  sort?: Enum;
  type?: Enum;
  marketType?: number;
};

export type TransactionFilter = {
  startDate?: string;
  endDate?: string;
  state?: Enum;
  keywords?: string;
  sort?: Enum;
  type?: Enum;
  marketType?: Enum;
};

interface BankAccount {
  bankName: string;
  cardNumber: string;
  iBan: string;
  id: string;
}

export interface Transaction {
  id: Idebtifier;
  market: Market;
  amount: number;
  bankAccount: BankAccount;
  trackingCode: string;
  digitalReceipt: string;
  type: Enum;
  platform: Enum;
  state: Enum;
  createdAt: string;
  createdBy: string;
  network: Network;
  voucher: VoucherViewModel;
  commission?: number;
  txId?: string | null;
  createdOn?: string;
  wallet?: {
    walletAddress: string;
    memo: string;
  };
}

interface Network {
  name: string;
  fee: number;
  network: string;
}

export interface TransactionList {
  pagination: Pagination;
  items: Transaction[];
}

export interface VoucherViewModel {
  voucherCode: string;
  voucerhActivationCode: string;
  batchNumber: string;
}

export enum TransactionType {
  Deposit = 1,
  Withdrawal,
  AffiliateDeposit,
  RefferralWithdrawal,
}

export enum TransactionState {
  Waiting = 1,
  Succeed,
  Rejected,
  Pending,
  WaitingForOtp,
  WaitingForAdmin,
  WaitingForProvider,
  Cancel,
  ManualDoing,
  Doing,
}

export const TransactionStateLabel = new Map<number, string>([
  [TransactionState.Waiting, 'در انتظار پرداخت'],
  [TransactionState.Succeed, 'انجام شده'],
  [TransactionState.Rejected, 'رد شده'],
  [TransactionState.Pending, 'در انتظار تایید'],
  [TransactionState.WaitingForOtp, 'در انتظار تایید کد'],
  [TransactionState.WaitingForAdmin, 'در انتظار تایید ادمین'],
  [TransactionState.WaitingForProvider, 'در انتظار تسویه شبکه'],
  [TransactionState.Cancel, 'لغو شده'],
  [TransactionState.ManualDoing, 'درحال انجام دستی'],
  [TransactionState.Doing, 'درحال انجام'],
]);

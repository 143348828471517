import * as React from 'react';

import 'components/transaction-box/transaction-box.scss';
import { Button } from 'components';
import { CaretDownFilled, WarningOutlined } from '@ant-design/icons';
import { faNumToEnNum, ToIranCurrency } from 'utils/scripts/decimal';
import { Fiat, Market } from 'core/domains';
import { Divider, Radio, Spin } from 'antd';
import { CoinDropDown } from './dropdown/coins-dropdown';
import useOnBlur from 'utils/scripts/useOnBlur';
import { digitSperator, isNumeric } from 'utils/scripts/exchange';
import axios from 'axios';
import { getAllFiats, getAllMarkets } from 'core/repositories';
import { Link } from 'react-router-dom';
import { getIconPath } from '../../utils/scripts/getIconPath';
import { SERVER_URL } from '../../apis/constants';

const { useState } = React;

type Props = {
  noHeader?: boolean;
  price?: number;
  available: number;
  alertBox?: any;
  paymentTypes?: {
    id: number;
    title: string;
    balance?: number;
  }[];
  allBalanceSelectAble: boolean;
  hasInputrange?: boolean;
  onButtonClick?: () => void;
  isCompact?: boolean;
};

export const TransactionBox: React.FC<Props> = ({
  noHeader,
  price,
  available,
  alertBox,
  paymentTypes,
  allBalanceSelectAble,
  hasInputrange,
  onButtonClick,
  isCompact,
}) => {
  // states
  const [sourceValue, setSourceValue] = useState<string | number>('');
  const [destinationValue, setDestinationValue] = useState<string | number>('');
  const [sourceMarket, setSourceMarket] = useState<Market>();
  const [destinationMarket, setDestinationMarket] = useState<Market>();
  const [side, setSide] = useState<string>('buy');
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  const [balanceRange, setBalanceRange] = useState<number>(0);

  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const sourceDropdownRef = React.useRef<any>();
  const destinationDropdownRef = React.useRef<any>();

  const [paymentType, setPaymentType] = useState<number>(1);
  const [allBlanceIsVisible, setAllBlanceIsVisible] =
    useState<boolean>(allBalanceSelectAble);

  const [markets, setMarkets] = useState<Market[]>([]);
  const [fiats, setFiats] = useState<Fiat[]>([]);

  const fetchAllData = () => {
    setCardLoading(true);
    {
      if (!isCompact) {
        markets.length === 0 && getAllMarkets(setCardLoading, setMarkets);
      }
      fiats.length === 0 && getAllFiats(setCardLoading, setFiats);
    }
    setCardLoading(false);
  };

  React.useEffect(() => {
    fetchAllData();
  }, []);

  React.useEffect(() => {
    setSourceMarket(markets.filter((m) => m.symbol === 'IRT')[0]);
    setDestinationMarket(markets.filter((m) => m.symbol === 'PM')[0]);
  }, [markets]);

  React.useEffect(() => {
    setAllBlanceIsVisible(true);
  }, [side]);

  const intervalRef = React.useRef<any>();

  const clearInputs = () => {
    setSourceValue('');
    setDestinationValue('');
  };

  const handleTransactionBoxChange = (side: any, value: any) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    if (side === 'source') setSourceValue(faNumToEnNum(value));
    else if (side == 'destination') setDestinationValue(faNumToEnNum(value));

    intervalRef.current = setTimeout(() => {
      if (value !== '') value = faNumToEnNum(value);
      else clearInputs();

      var char =
        value.length !== 0
          ? value.split('')[value.split('').length - 1]
          : setDestinationValue('');

      setSourceValue('');
      if (isNumeric(char)) {
        if (side === 'source') {
          setSourceValue(digitSperator(faNumToEnNum(value)));

          if (value !== '') {
            setDestinationValue('');
            axios
              .get(
                SERVER_URL +
                  `Market/Exchange/1/${value.replaceAll(',', '')}/${
                    sourceMarket?.symbol
                  }/${destinationMarket?.symbol}/`,
              )
              .then((res) => {
                setDestinationValue(
                  digitSperator(res.data.value.destinationAmount),
                );
              });
          }
        } else if (side === 'destination') {
          setDestinationValue(digitSperator(faNumToEnNum(value)));
          if (value !== '') {
            setSourceValue('');
            axios
              .get(
                SERVER_URL +
                  `Market/Exchange/2/${value.replaceAll(',', '')}/${
                    sourceMarket?.symbol
                  }/${destinationMarket?.symbol}/`,
              )
              .then((res) => {
                setSourceValue(digitSperator(res.data.value.sourceAmount));
              });
          }
        }
      }
      intervalRef.current = null;
    }, 500);
  };

  const onAllBalanceClickHandler = (type: string) => {
    const value = available?.toString();
    switch (type) {
      case 'source':
        setSourceValue(value);
        break;
      case 'destination':
        setDestinationValue(ToIranCurrency(value));
        break;
      default:
        break;
    }
    setAllBlanceIsVisible(false);
    handleTransactionBoxChange(type, value);
    setZeroIsHidden(true);
  };

  function handleAction(buttonName: string) {
    setSourceValue('');
    setDestinationValue('');
    setSide(buttonName);
    setSourceMarket(destinationMarket);
    setDestinationMarket(sourceMarket);
    setDropdownIsOpen(false);
  }

  const [zeroIsHidden, setZeroIsHidden] = useState<boolean>(false);

  useOnBlur(sourceDropdownRef, () => {
    if (dropdownIsOpen) setDropdownIsOpen(!dropdownIsOpen);
  });
  useOnBlur(destinationDropdownRef, () => {
    if (dropdownIsOpen) setDropdownIsOpen(!dropdownIsOpen);
  });

  function onInputRangeChange(value: string) {
    setBalanceRange(parseFloat(value));

    setSourceValue(available * (parseFloat(value) / 100));
  }

  return (
    <Spin spinning={cardLoading}>
      <div className={`xpay-transaction-box ${side} ${isCompact && 'compact'}`}>
        {!noHeader && (
          <div className="header">
            <div className="title">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9985 9.09091H0.159552C0.0720524 9.09091 0.000461449 9.1625 0.000461449 9.25V10.4432C0.000461449 10.5307 0.0720524 10.6023 0.159552 10.6023H12.1948L9.32518 14.2415C9.24364 14.3449 9.31722 14.5 9.45046 14.5H10.8922C10.9897 14.5 11.0811 14.4563 11.1428 14.3787L14.4996 10.121C14.8277 9.70341 14.5314 9.09091 13.9985 9.09091ZM14.4777 4.39773H2.44251L5.31211 0.758523C5.39364 0.655114 5.32006 0.5 5.18682 0.5H3.74506C3.64762 0.5 3.55614 0.54375 3.4945 0.621307L0.137677 4.87898C-0.190448 5.29659 0.105859 5.90909 0.636825 5.90909H14.4777C14.5652 5.90909 14.6368 5.8375 14.6368 5.75V4.55682C14.6368 4.46932 14.5652 4.39773 14.4777 4.39773Z"
                  fill="url(#paint0_linear_413_4464)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_413_4464"
                    x1="-4.11954"
                    y1="0.500001"
                    x2="17.751"
                    y2="2.67284"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3F62FE" />
                    <stop offset="1" stop-color="#678AF5" />
                  </linearGradient>
                </defs>
              </svg>
              <span>تبادل سریع</span>
            </div>
            <div className="balance">
              <span>موجودی تومانی :</span>
              <span>{ToIranCurrency(available.toFixed(0))} تومان</span>
            </div>
          </div>
        )}
        <div className="content">
          <div className="switch">
            <button
              className={`buy ${side === 'buy' ? 'active' : ''}`}
              onClick={() => handleAction('buy')}
            >
              خرید
            </button>
            <button
              className={`sell ${side === 'sell' ? 'active' : ''}`}
              onClick={() => handleAction('sell')}
            >
              فروش
            </button>
          </div>

          {isCompact && <Divider />}

          {isCompact && (
            <div className="currencies-row">
              <Link
                to={`orders/create-order?side=${side}&symbol=pm`}
                className="currency"
              >
                <img src="/assets/icons/transaction-box/pm.svg" />
                <div className="currency-name">
                  {
                    fiats?.filter(
                      (fiat) => fiat.symbol.toLowerCase() === 'pm',
                    )[0]?.name
                  }
                </div>
              </Link>
              <Link
                to={`orders/create-order?side=${side}&symbol=usdt`}
                className="currency"
              >
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.666504"
                    width="40"
                    height="40"
                    rx="5"
                    fill="url(#paint0_linear_2697_10349)"
                  />
                  <path
                    d="M31.2779 19.4519C31.2779 18.0892 27.57 16.9523 22.6425 16.69L22.6421 14.5187H28.7946V10H11.971V14.5187H18.1231V16.7006C13.2853 16.9809 9.6665 18.106 9.6665 19.4519C9.6665 20.7981 13.2853 21.9233 18.1231 22.2031V30H22.6421V22.2137C27.57 21.952 31.2779 20.8147 31.2779 19.4519ZM20.4722 20.7944C15.1832 20.7944 10.8955 19.9758 10.8955 18.9654C10.8955 18.1099 13.97 17.3917 18.1231 17.1918V17.7068H18.1236V19.817C18.8723 19.859 19.6592 19.881 20.4722 19.881C21.2207 19.881 21.9477 19.862 22.6426 19.8271V17.184C26.8857 17.3716 30.0491 18.0974 30.0491 18.9654C30.049 19.9758 25.7612 20.7944 20.4722 20.7944Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2697_10349"
                      x1="-10.5922"
                      y1="2.24315e-06"
                      x2="49.2264"
                      y2="5.68444"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#3F62FE" />
                      <stop offset="1" stop-color="#678AF5" />
                    </linearGradient>
                  </defs>
                </svg>

                <div className="currency-name">
                  {
                    fiats?.filter(
                      (fiat) => fiat.symbol.toLowerCase() === 'usdt',
                    )[0]?.name
                  }
                </div>
              </Link>
              <Link
                to={`orders/create-order?side=${side}&symbol=trx`}
                className="currency"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="40"
                    height="40"
                    rx="5"
                    fill="url(#paint0_linear_2697_10348)"
                  />
                  <path
                    d="M28.7498 16.0252C27.8123 15.1596 26.5154 13.8377 25.4592 12.9002L25.3967 12.8565C25.2927 12.773 25.1754 12.7075 25.0498 12.6627C22.503 12.1877 10.65 9.97214 10.4187 10.0003C10.3539 10.0093 10.292 10.0328 10.2375 10.069L10.1781 10.1159C10.105 10.1901 10.0495 10.2798 10.0156 10.3784L10 10.419V10.6409V10.6753C11.3344 14.3908 16.603 26.5625 17.6405 29.4188C17.703 29.6125 17.8218 29.9812 18.0436 30H18.0936C18.2124 30 18.7186 29.3313 18.7186 29.3313C18.7186 29.3313 27.7685 18.3564 28.6841 17.1877C28.8026 17.0437 28.9073 16.8889 28.9966 16.7252C29.0194 16.5971 29.0087 16.4653 28.9654 16.3427C28.9221 16.22 28.8479 16.1106 28.7498 16.0252ZM21.0405 17.3033L24.9029 14.1002L27.1685 16.1877L21.0405 17.3033ZM19.5405 17.0939L12.8906 11.644L23.6498 13.6283L19.5405 17.0939ZM20.1405 18.522L26.9467 17.4252L19.1655 26.8L20.1405 18.522ZM11.9875 12.1877L18.9843 18.1252L17.9718 26.8063L11.9875 12.1877Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2697_10348"
                      x1="-25.8015"
                      y1="13.8182"
                      x2="54.337"
                      y2="35.7626"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#21BCF3" />
                      <stop offset="1" stop-color="#7BD5ED" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="currency-name">ترون</div>
              </Link>
            </div>
          )}
          {!isCompact && (
            <div className="calculator">
              {!isCompact && (
                <div className="input-info-labels">
                  <span className="input-info-label">
                    {side === 'buy' ? 'پرداخت میکنم' : 'می‌فروشم'}
                  </span>
                  <span className="input-info-label">
                    {sourceMarket?.symbol === 'IRT' ? (
                      <>
                        موجودی قابل تبدیل تومانی :
                        <span>{ToIranCurrency(available)} تومان </span>
                      </>
                    ) : (
                      <>
                        قیمت هر واحد {sourceMarket?.name} :
                        <span>{ToIranCurrency(price)} تومان</span>
                      </>
                    )}
                  </span>
                </div>
              )}
              <div className="field">
                {sourceMarket?.symbol !== 'IRT' && (
                  <CoinDropDown
                    markets={markets}
                    onClose={setDropdownIsOpen}
                    ref={sourceDropdownRef}
                    isOpen={dropdownIsOpen}
                    selected={sourceMarket}
                    onClick={setSourceMarket}
                  />
                )}
                <div
                  className={`symbol ${
                    sourceMarket?.symbol === 'IRT' && 'symbol-irt'
                  }`}
                  onClick={() =>
                    sourceMarket?.symbol === 'IRT'
                      ? setDropdownIsOpen((prev: boolean) => prev)
                      : setDropdownIsOpen(true)
                  }
                >
                  <div className="coin">
                    <img
                      src={getIconPath(sourceMarket?.symbol ?? 'irt')}
                      alt=""
                    />
                    <span>{sourceMarket?.name ?? 'تومان'}</span>
                    <span>{sourceMarket?.symbol ?? 'IRT'}</span>
                  </div>
                  {side === 'buy' ? <></> : <CaretDownFilled />}
                </div>
                <Divider
                  className={`${sourceMarket?.symbol === 'IRT' && 'active'}`}
                  type="vertical"
                />{' '}
                <input
                  className={
                    side === 'sell' &&
                    allBlanceIsVisible &&
                    allBalanceSelectAble
                      ? 'max-content'
                      : ''
                  }
                  type="text"
                  placeholder={!isCompact ? '0' : 'پرداخت میکنید'}
                  value={sourceValue}
                  name="source"
                  onChange={(el) => {
                    handleTransactionBoxChange(
                      'source',
                      el.target.value.toString(),
                    );
                    if (el.target.value.length !== 0) setZeroIsHidden(true);
                    else setZeroIsHidden(false);
                  }}
                  maxLength={30}
                  dir="ltr"
                />
                {side === 'sell' &&
                allBalanceSelectAble &&
                allBlanceIsVisible ? (
                  <div className="options">
                    <span
                      onClick={() => onAllBalanceClickHandler('source')}
                      className="select-available-balance"
                    >
                      تمام موجودی {sourceMarket?.name}
                    </span>
                    <span>{isCompact && !zeroIsHidden && 0}</span>
                  </div>
                ) : (
                  <span>{isCompact && !zeroIsHidden && 0}</span>
                )}
                <span className="input-unit">
                  {!isCompact && sourceMarket?.symbol === 'IRT'
                    ? 'تومان'
                    : 'واحد'}
                </span>
              </div>
              {noHeader && isCompact && (
                <span className="input-info-label">
                  {sourceMarket?.symbol === 'IRT' ? (
                    <>
                      موجودی قابل تبدیل تومانی :
                      <span>{ToIranCurrency(available)} تومان </span>
                    </>
                  ) : (
                    <>
                      قیمت هر واحد
                      <span>
                        {sourceMarket?.name} : {ToIranCurrency(price)} تومان
                      </span>
                    </>
                  )}
                </span>
              )}
              {hasInputrange && (
                <div className="balance-range">
                  <span>حداقل</span>
                  <span>25%</span>
                  <span>50%</span>
                  <span>75%</span>
                  <span>100%</span>
                  <input
                    type="range"
                    id="points"
                    name="points"
                    step="25"
                    min="0"
                    max="100"
                    value={balanceRange}
                    onChange={(e) => onInputRangeChange(e.target.value)}
                  ></input>
                </div>
              )}
              {!isCompact && <Divider />}
              {!isCompact && (
                <div className="input-info-labels">
                  <span className="input-info-label">دریافت میکنم</span>
                  <span className="input-info-label">
                    {destinationMarket?.symbol === 'IRT' ? (
                      <>
                        موجودی قابل تبدیل تومانی :
                        <span>{ToIranCurrency(available)} تومان </span>
                      </>
                    ) : (
                      <>
                        قیمت هر واحد {destinationMarket?.name} :
                        <span>{ToIranCurrency(price)} تومان</span>
                      </>
                    )}
                  </span>
                </div>
              )}
              <div className="field">
                {destinationMarket?.symbol !== 'IRT' && (
                  <CoinDropDown
                    onClose={setDropdownIsOpen}
                    markets={markets}
                    ref={destinationDropdownRef}
                    isOpen={dropdownIsOpen}
                    selected={destinationMarket}
                    onClick={setDestinationMarket}
                  />
                )}
                <div
                  className={`symbol ${
                    destinationMarket?.symbol === 'IRT' && 'symbol-irt'
                  }`}
                  onClick={() =>
                    destinationMarket?.symbol === 'IRT'
                      ? setDropdownIsOpen((prev: boolean) => prev)
                      : setDropdownIsOpen(true)
                  }
                >
                  <div className="coin">
                    <img
                      src={`/coins/${
                        destinationMarket?.symbol?.toLowerCase() ?? 'btc'
                      }.svg`}
                      alt=""
                    />
                    <span>{destinationMarket?.name ?? 'بیت کوین'}</span>
                    <span>{destinationMarket?.symbol ?? 'BTC'}</span>
                  </div>
                  {side === 'sell' ? <></> : <CaretDownFilled />}
                </div>
                <Divider
                  className={`${
                    destinationMarket?.symbol === 'IRT' && 'active'
                  }`}
                  type="vertical"
                />
                <input
                  className={
                    side === 'buy' && allBlanceIsVisible && allBalanceSelectAble
                      ? 'max-content'
                      : ''
                  }
                  type="text"
                  value={destinationValue}
                  placeholder={!isCompact ? '0' : 'دریافت میکنید'}
                  name="destination"
                  onChange={(el) => {
                    handleTransactionBoxChange(
                      'destination',
                      el.target.value.toString(),
                    );
                    if (el.target.value.length !== 0) setZeroIsHidden(true);
                    else setZeroIsHidden(false);
                  }}
                  maxLength={30}
                  dir="ltr"
                />
                {side === 'buy' &&
                allBalanceSelectAble &&
                allBlanceIsVisible ? (
                  <div className="options">
                    <span
                      onClick={() => onAllBalanceClickHandler('destination')}
                      className="select-available-balance"
                    >
                      انتخاب تمام موجودی تومانی
                    </span>
                    <span>{isCompact && !zeroIsHidden && 0}</span>
                  </div>
                ) : (
                  <span>{isCompact && !zeroIsHidden && 0}</span>
                )}
                <span className="input-unit">
                  {!isCompact && destinationMarket?.symbol === 'IRT'
                    ? 'تومان'
                    : 'واحد'}
                </span>
              </div>

              {noHeader && isCompact && (
                <span className="input-info-label">
                  {destinationMarket?.symbol === 'IRT' ? (
                    <>
                      موجودی قابل تبدیل تومانی :
                      <span>{ToIranCurrency(available)} تومان </span>
                    </>
                  ) : (
                    <>
                      قیمت هر واحد
                      <span>
                        {destinationMarket?.name} : {ToIranCurrency(price)}{' '}
                        تومان
                      </span>
                    </>
                  )}
                </span>
              )}
            </div>
          )}
          {!isCompact && (
            <div className="note">
              <div className="right-side">
                <WarningOutlined />
                <span>نکته: مبلغ را به تومان نیز می توانید مشخص نمایید.</span>
              </div>
            </div>
          )}
          {alertBox && alertBox}
          {!isCompact &&
            paymentTypes &&
            paymentTypes.length > 0 &&
            side === 'buy' && (
              <>
                <div
                  className={`payment-type-options ${
                    !isCompact && 'not-compact'
                  }`}
                >
                  <label className="payment-type-title">
                    {!isCompact ? 'نحوه پرداخت' : 'روش پرداخت'}
                  </label>
                  <Radio.Group
                    onChange={(e) => setPaymentType(e.target.value)}
                    value={paymentType}
                  >
                    {paymentTypes.map((type) => {
                      return (
                        <Radio value={type.id}>
                          <span>{type.title}</span>{' '}
                          <span>
                            {type.id === 2 && ` (موجودی: ${type.balance})`}
                          </span>
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </div>
              </>
            )}
          {!isCompact && (
            <div className="submit">
              <Button
                type="info"
                text="پرداخت نهایی"
                onClick={() => onButtonClick && onButtonClick()}
              />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

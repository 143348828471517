import * as React from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Input } from 'components';

import 'routes/pages/signin/stages/login/login.scss';
import { phoneRegex } from 'utils/constants/constants';
import { post, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RootState } from 'core/redux/store';
import moment from 'moment';
import { MobileNavigate } from 'components/mobile-navigate/mobile-navigate';
import { Link, useNavigate } from 'react-router-dom';
import { faNumToEnNum, IsNumeric } from 'utils/scripts/decimal';
import { APPNAME, SITE_URL, TERM_LINK } from '../../../../../apis/constants';
import { setUserData } from '../../../../../core/redux/actions';
import { Enum } from 'core/domains';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

type FieldErrorState = {
  phone: string[];
  password: string[];
};

type HasErrorState = {
  phone: boolean;
  password: boolean;
};

interface Props extends PropsFromRedux {}

type fieldErrorType = {
  password: string[];
  phone: string[];
};

type hasErrorType = {
  password: boolean;
  phone: boolean;
};

const { useState, useEffect } = React;

const LoginStage: React.FC<Props> = (props) => {
  const [passwordIsHidden, setPasswordIsHidden] = useState<boolean>(true);
  const [rememberMeIsChecked, setRememberMeIsChecked] =
    useState<boolean>(false);
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [twoFactorType, setTwoFactorType] = useState<Enum>();
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [fieldError, setFieldError] = useState<fieldErrorType>({
    password: [''],
    phone: [''],
  });
  const [hasError, setHasError] = useState<hasErrorType>({
    phone: false,
    password: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const dispatch = useDispatch();

  (window as any).dataLayer = (window as any).dataLayer || [];

  function handlePhoneChange(value: string): void {
    setHasError((prev) => ({
      ...prev,
      phone: false,
    }));

    // all number Fa to En
    if (value !== '') value = faNumToEnNum(value);
    // validation nationalCode
    if (value !== '' && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    // set on state
    setPhoneValue(value);
  }

  const navigate = useNavigate();

  function handleLoginSubmit(): void {
    setIsLoading(true);
    setHasError({
      phone: false,
      password: false,
    });
    setFieldError({
      phone: [''],
      password: [''],
    });
    if (phoneValue.search(phoneRegex) === 0 && passwordValue.length !== 0) {
      post(API.login, {
        phoneNumber: phoneValue,
        password: passwordValue,
      })
        .then(function (response: any) {
          setIsLoading(false);
          if (responseValidator(response.status)) {
            if (response.data.value.isTwoFactorEnabled) {
              localStorage.setItem(
                'loginTwoFactorType',
                response.data.value.twoFactorMethod,
              );
              localStorage.setItem('phoneNumber', phoneValue);
              setTwoFactorType(response.data.value.twoFactorMethod);
              navigate('/login/two-factor');
            } else {
              (window as any).dataLayer.push({
                event: 'login',
              });
              dispatch(
                setUserData({
                  ...props.state.userData,
                  isTwoFactorEnabled: response.data.value.isTwoFactorEnabled,
                  twoFactorMethod: response.data.value.twoFactorMethod,
                  accessToken: response.data.value.access_token,
                  refreshToken: response.data.value.refresh_token,
                  tokenType: response.data.value.token_type,
                  expiresIn: response.data.value.expires_in,
                  roles: response.data.value.roles,
                  isAuthorized: response.data.value.isAuthorized,
                }),
              );

              localStorage.setItem('token', response.data.value.access_token);
              response.data.value.fullName &&
                localStorage.setItem('name', response.data.value.fullName);

              localStorage.setItem('auth', response.data.value.isAuthorized);
              localStorage.setItem('phoneNumber', phoneValue);
              localStorage.setItem('date', moment.utc().toString());
              localStorage.setItem('ref', response.data.value.refresh_token);
              localStorage.setItem(
                'expires_in',
                response.data.value.expires_in,
              );
              window.open('/dashboard', '_self');
            }
          }
        })
        .catch(function (error: any) {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      if (passwordValue.length === 0) {
        setHasError((prev) => ({
          ...prev,
          password: true,
        }));
        setFieldError((prev) => ({
          ...prev,
          password: ['لطفا پسورد را وارد کنید.'],
        }));
      }
      if (phoneValue.search(phoneRegex) !== 0) {
        setHasError((prev) => ({
          ...prev,
          phone: true,
        }));
        setFieldError((prev) => ({
          ...prev,
          phone: ['شماره موبایل وارد شده نادرست است.'],
        }));
      }
    }
  }

  function handlePasswordChange(value: string): void {
    if (value.length > 0) {
      setFieldError((prev) => ({
        ...prev,
        password: [''],
      }));
      setHasError((prev) => ({
        ...prev,
        password: false,
      }));
    }
    setPasswordValue(value);
  }

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('auth');
    localStorage.removeItem('ref');
    localStorage.removeItem('date');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('name');
  }, []);

  return (
    <div className="signin-stage">
      <MobileNavigate
        title="ورود به حساب کاربری"
        onBackClick={() => window.open(SITE_URL, '_self')}
        backBtnDisabled={true}
      />
      <h3>ورود به حساب کاربری</h3>
      <form>
        <Input
          name="phone"
          type="text"
          className="signin-input"
          onPressEnter={() => !isLoading && handleLoginSubmit()}
          value={phoneValue}
          onChange={(el) => handlePhoneChange(el.target.value)}
          placeholder="موبایل"
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <img className="start-adornment" src="/assets/icons/Call.svg" />
              ),
            },
          }}
          hasError={hasError.phone}
          errors={fieldError.phone}
          maxLength={11}
          dir={phoneValue.length !== 0 ? 'ltr' : 'rtl'}
        />
        <Input
          name="password"
          type={passwordIsHidden ? 'password' : 'text'}
          className="signin-input"
          value={passwordValue}
          onPressEnter={() => !isLoading && handleLoginSubmit()}
          onChange={(el) => handlePasswordChange(el.target.value)}
          placeholder="رمز عبور"
          errors={fieldError.password}
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <img
                  className="start-adornment"
                  src="/assets/icons/unlock.svg"
                />
              ),
            },
            endAdornment: {
              adornmentIcon: passwordIsHidden ? (
                <EyeOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => setPasswordIsHidden(false)}
                />
              ) : (
                <EyeInvisibleOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => setPasswordIsHidden(true)}
                />
              ),
            },
          }}
          dir={passwordValue.length !== 0 ? 'ltr' : 'rtl'}
        />
        <div className="rules">
          با ورود به {APPNAME}{' '}
          <a target="_blank" href={TERM_LINK}>
            قوانین و شرایط استفاده{' '}
          </a>{' '}
          را میپذیرم.
        </div>
        <Button
          type="info"
          htmlType="button"
          text="ورود"
          disabled={
            hasError.phone ||
            hasError.password ||
            passwordValue.length === 0 ||
            isLoading
              ? true
              : false
          }
          className={`signin-button ${
            hasError.phone ||
            hasError.password ||
            passwordValue.length === 0 ||
            isLoading
              ? 'button-disabled'
              : ''
          }`}
          onClick={(e) => handleLoginSubmit()}
          loading={isLoading ? true : false}
        />
        <div className="signup-link">
          <div>
            <p>حساب کاربری ندارید؟</p> <Link to="/register">ثبت نام کنید</Link>
          </div>
          <div>
            <a
              style={{ cursor: 'pointer' }}
              onClick={(e) => navigate('/login/password-reset')}
            >
              {' '}
              بازیابی رمز عبور
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default connector(LoginStage);

import * as React from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Button, Card, Input, Modal } from 'components';
import { copyClipboard } from 'utils/scripts/copyToClipboard';
import { ReactComponent as CoinBagIcon } from 'assets/icons/referral/coin-bag.svg';
import { ReactComponent as AddFriendIcon } from 'assets/icons/referral/add-friend.svg';
import { ReactComponent as MedalIcon } from 'assets/icons/referral/medal.svg';
import { ReactComponent as TwoPersonsIcon } from 'assets/icons/referral/two-persons.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/referral/group.svg';
import { ReactComponent as DashedDots } from 'assets/images/steps-dashed.svg';
import { ReactComponent as Arrow } from 'assets/icons/carrot-left.svg';

import './referral.scss';
import { ReferralActionsHistory } from './referral-actions-history/referral-actions-history';
import { ReferralInvitedUsers } from './referral-invited-users/referral-invited-users';
import { postViaAuth, responseValidator } from '../../../utils/scripts/api';
import { getMineReferral } from 'core/repositories/referral/referral';
import { ReferralInfo } from 'core/domains/referral/referral';
import { Segmented } from 'antd';
import { message } from 'components/toast/toast';
import { API } from 'apis';
import { digitSperator, isNumeric } from 'utils/scripts/exchange';
import { faNumToEnNum, ToIranCurrency } from 'utils/scripts/decimal';
import { Navigation, Pagination } from 'swiper/modules';
import { APPNAME, ReferralPost, ReferralStory } from 'apis/constants';

const stepItems = [
  {
    step: 1,
    title: 'اشتراک گذاری لینک دعوت',
    description:
      'لینک دعوت اختصاصی خود را با دوستان و یا در شبکه‌های اجتماعی، به اشتراک بگذارید.',
  },
  {
    step: 2,
    title: `ثبت‌نام در ${APPNAME}`,
    description:
      'دوستان با کد دعوت اختصاصی شما ثبت نام، احراز هویت خود را تکمیل و شروع به معامله می‌کنند.',
  },
];

const { useState, useRef } = React;
export const Referral: React.FC = () => {
  const [isInvitationModalOpen, setIsInvitationModalOpen] =
    React.useState<boolean>(false);
  const [isAdvertisingModalOpen, setIsAdvertisingModalOpen] =
    React.useState<boolean>(false);
  const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] =
    React.useState<boolean>(false);
  const [adImageType, setAdImageType] = useState<string>('post');
  const [adImageLink, setAdImageLink] = useState<string>(ReferralPost);
  const [loading, setLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<string[]>([]);
  const [referralInfo, setReferralInfo] = useState<ReferralInfo>();
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>('');

  const navigationNextRef = useRef<HTMLDivElement>(null);
  const navigationPrevRef = useRef<HTMLDivElement>(null);

  const swiper = useRef<SwiperProps>(null);

  const handlePrev = () => {
    if (!swiper.current) return;
    swiper.current.swiper.slidePrev();
  };

  const handleNext = () => {
    if (!swiper.current) return;
    swiper.current.swiper.slideNext();
  };

  const fetchAllData = () => {
    getMineReferral(setLoading, setReferralInfo);
  };

  React.useEffect(() => {
    fetchAllData();
  }, []);

  const defaultLinkText = useRef<HTMLElement>(null);
  const invitationLinkText = useRef<HTMLParagraphElement>(null);

  const onWithdrawalAmountChange = (value: string) => {
    if (value !== '') value = faNumToEnNum(value);

    if (value !== '' && !isNumeric(value[value.length - 1])) return;

    setWithdrawalAmount(value);
  };

  const ListHead: React.FC<{ title: string; name: string }> = (pr) => (
    <div className="head">
      <div className="right">
        <h4>{pr.title}</h4>
      </div>

      <Button
        text={`${isExpanded ? 'بستن' : 'نمایش'} لیست`}
        type="outlined"
        icon={isExpanded ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        onClick={() =>
          isExpanded.filter((value) => value === pr.name)[0]
            ? setIsExpanded(isExpanded.slice(isExpanded.indexOf(pr.name)))
            : setIsExpanded([...isExpanded, pr.name])
        }
      />
    </div>
  );

  const onConfirmHandler = () => {
    setLoading(true);
    if (withdrawalAmount)
      postViaAuth(API.referral.withdrawal, {
        amount: withdrawalAmount,
      }).then(function (response: any) {
        setLoading(false);
        setIsWithdrawalModalOpen(false);
        if (responseValidator(response.status)) {
          message.success('درخواست برداشت با موفقیت ثبت شد.');
        }
      });
  };

  return (
    <>
      <div className="xpay-dashboard-referral">
        <Modal
          className="referral-invitation-modal"
          isOpen={isInvitationModalOpen}
          setIsOpen={setIsInvitationModalOpen}
          closeAble
          title="اشتراک گذاری لینک دعوت"
        >
          <div className="content-description">
            <p>
              با استفاده از کد دعوت {referralInfo?.code} کمتر از یک دقیقه در
              {APPNAME} ثبت نام کنید و به سرعت به خرید و فروش ارز های دیجیتال
              بپردازید.
              <br></br>
              کارمزد اولین تراکنش شما رایگان خواهد بود.<br></br>
              بترین نرخ خرید و فروش<br></br>
              احراز هویت سریع<br></br>
              تسویه حساب فوری تومانی و ارزی<br></br>
              {APPNAME}، پلتفرم معاملاتی ارز های دیجیتال<br></br>
              {location.origin}/register?ref={referralInfo?.code}
              <br></br>
            </p>
          </div>
          <div className="buttons">
            <Button
              type="info"
              text="اشتراک گذاری"
              htmlType="button"
              onClick={() => setIsInvitationModalOpen(false)}
            />
            <Button
              type="outlined"
              text="ارسال در تلگرام"
              htmlType="button"
              onClick={() =>
                window.open(
                  `tg://msg_url?url=با استفاده از کد دعوت ${referralInfo?.code} کمتر از یک دقیقه در ${APPNAME} ثبت نام کنید و به سرعت به خرید و فروش ارز های دیجیتال بپردازید.  کارمزد اولین تراکنش شما رایگان خواهد بود.  بهترین نرخ خرید و فروش  احراز هویت سریع  تسویه حساب فوری تومانی و ارزی ${APPNAME}   ، پلتفرم معاملاتی ارز های دیجیتال ${location.origin}/register?ref=${referralInfo?.code} 
`,
                )
              }
            />
          </div>
        </Modal>
        <Modal
          className="referral-ads-modal"
          isOpen={isAdvertisingModalOpen}
          setIsOpen={setIsAdvertisingModalOpen}
          closeAble
          title="اشتراک گذاری لینک دعوت"
        >
          <div className="ad-image">
            <Segmented
              onResize={() => 0}
              onResizeCapture={() => 0}
              value={adImageType}
              onChange={(e) => {
                if (e == 'story') {
                  setAdImageType('story');
                  setAdImageLink(ReferralStory);
                } else {
                  setAdImageType('post');
                  setAdImageLink(ReferralPost);
                }
              }}
              selected
              options={[
                {
                  value: 'post',
                  label: 'پست',
                },
                {
                  value: 'story',
                  label: 'استوری',
                },
              ]}
            />

            <img className="ads-pic" src={adImageLink} />
          </div>
          {referralInfo && (
            <div className="invite-link">
              <a>لینک دعوت</a>
              <p>
                {location.origin}/register?ref=${referralInfo?.code}
              </p>
              <CopyOutlined
                onClick={() =>
                  copyClipboard(
                    `${location.origin}/register?ref=${referralInfo?.code}`,

                    'لینک دعوت',
                  )
                }
              />
            </div>
          )}
          <div className="buttons">
            <a href={adImageLink} download={'ad-share-image'}>
              <Button
                type="info"
                text="ذخیره تصویر"
                htmlType="button"
                onClick={() => setIsInvitationModalOpen(false)}
              />
            </a>
            <Button
              type="outlined"
              text="کپی کردن لینک دعوت"
              htmlType="button"
              onClick={() =>
                copyClipboard(
                  `${location.origin}/register?ref=${referralInfo?.code}`,
                  'لینک دعوت',
                )
              }
            />
          </div>
        </Modal>
        <Modal
          isOpen={isWithdrawalModalOpen}
          setIsOpen={setIsWithdrawalModalOpen}
          className="referral-withdrawal-modal"
          closeAble
          title={'واریز پاداش به کیف پول'}
        >
          <Card className="referral-withdrawal">
            <span className="input-info-label">
              {
                <>
                  {
                    <>
                      موجودی :
                      <span>{ToIranCurrency(referralInfo?.balance)} تومان</span>
                    </>
                  }
                </>
              }
            </span>
            <Input
              type="text"
              name="amount"
              value={digitSperator(withdrawalAmount)}
              placeholder="مقدار واریزی را وارد کنید"
              dir={withdrawalAmount === '' ? 'rtl' : 'ltr'}
              onChange={(e) => onWithdrawalAmountChange(e.target.value)}
              onPressEnter={onConfirmHandler}
            />
            <Button
              type="info"
              text="واریز"
              onClick={() => onConfirmHandler()}
              disabled={
                loading || !(withdrawalAmount && withdrawalAmount != '0')
              }
              loading={loading}
            />
          </Card>
        </Modal>
        <Card className="guide-card" blur={loading}>
          <div className="steps-row">
            <div className="desktop-show">
              <div className="guide">
                <p>چطور پاداش دریافت کنم؟</p>
                <div className="steps">
                  <p className="step">1</p>
                  <DashedDots />
                  <p className="step">2</p>
                  <DashedDots />
                  <p className="step">3</p>
                </div>
                <div className="text">
                  <div className="col">
                    <span>اشتراک گذاری لینک دعوت</span>
                    <p>
                      لینک دعوت اختصاصی خود را با دوستان و یا در شبکه‌های
                      اجتماعی، به اشتراک بگذارید.
                    </p>
                  </div>
                  <div className="col">
                    <span>ثبت نام در {APPNAME} </span>
                    <p>
                      دوستان با کد دعوت اختصاصی شما ثبت نام، احراز هویت خود را
                      تکمیل و شروع به معامله می‌کنند.
                    </p>
                  </div>
                  <div className="col">
                    <span>دریافت پاداش!</span>
                    <p>
                      به ازای هر معامله زیر مجموعه های خود ، تا{' '}
                      {referralInfo?.referralLevelRewardPercent}٪ از تراکنش آنها
                      را به عنوان پاداش دریافت می‌کنید.
                    </p>
                  </div>
                </div>
              </div>

              <div className="banner">
                <div className="content">
                  {referralInfo && (
                    <div className="invite-link">
                      <a>کد دعوت</a>
                      <p>{referralInfo?.code}</p>
                      <CopyOutlined
                        onClick={() =>
                          copyClipboard(referralInfo.code, 'کد دعوت')
                        }
                      />
                    </div>
                  )}
                  <div className="invite-link">
                    <a>لینک دعوت</a>
                    <p>
                      {location.origin}/register?ref={referralInfo?.code}
                    </p>
                    <CopyOutlined
                      onClick={() =>
                        copyClipboard(
                          `${location.origin}/register?ref=${referralInfo?.code}`,
                          'لینک دعوت',
                        )
                      }
                    />
                  </div>
                  <div className="b">
                    <Button
                      type="outlined"
                      text="اشتراک گذاری لینک دعوت"
                      onClick={() => setIsInvitationModalOpen(true)}
                    />
                    <Button
                      type="info"
                      text="تصویر تبلیغاتی"
                      onClick={() => setIsAdvertisingModalOpen(true)}
                    />
                  </div>
                </div>
              </div>
              <div className="prize-status">
                <div className="col">
                  <p>سطح دریافت پاداش</p>
                  <span>{referralInfo?.referralLevelTitle} </span>
                </div>
                <div className="col">
                  <p>درصد سهم تراکنش</p>
                  <span>{referralInfo?.referralLevelRewardPercent}٪</span>
                </div>
              </div>
            </div>
            <div className="mobile-show">
              <Swiper
                modules={[Pagination, Navigation]}
                pagination
                navigation={{
                  prevEl: navigationPrevRef?.current,
                  nextEl: navigationNextRef?.current,
                }}
                slidesPerView={1}
                dir="rtl"
                ref={swiper}
              >
                <>
                  {stepItems.map((slide, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="guide">
                          <p>چطور پاداش دریافت کنم؟</p>
                          <div className="steps">
                            <p className="step">{index + 1}</p>
                          </div>
                          <div className="text">
                            <div className="col">
                              <span>{slide.title}</span>
                              <p>{slide.description}</p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  <SwiperSlide>
                    <div className="guide">
                      <p>چطور پاداش دریافت کنم؟</p>
                      <div className="steps">
                        <p className="step">3</p>
                      </div>
                      <div className="text">
                        <div className="col">
                          <span>دریافت پاداش!</span>
                          <p>
                            به ازای هر معامله زیر مجموعه های خود ، تا{' '}
                            {referralInfo?.referralLevelRewardPercent}٪ از
                            تراکنش آنها را به عنوان پاداش دریافت می‌کنید.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              </Swiper>
              <div
                ref={navigationPrevRef}
                className="pre-navigation"
                onClick={handlePrev}
              >
                <Arrow />
              </div>
              <div
                ref={navigationNextRef}
                className="next-navigation"
                onClick={handleNext}
              >
                <Arrow />
              </div>

              <div className="banner">
                <div className="content">
                  {referralInfo && (
                    <div className="invite-link">
                      <a
                        href={`https://${invitationLinkText?.current?.innerText}`}
                      >
                        کد دعوت
                      </a>
                      <p>{referralInfo.code}</p>
                      <CopyOutlined
                        onClick={() =>
                          copyClipboard(referralInfo.code, 'کد دعوت')
                        }
                      />
                    </div>
                  )}
                  {referralInfo && (
                    <div className="invite-link">
                      <a
                        href={`https://${invitationLinkText?.current?.innerText}`}
                      >
                        لینک دعوت
                      </a>
                      <p>
                        {location.origin}/register?ref={referralInfo?.code}
                      </p>
                      <CopyOutlined
                        onClick={() =>
                          copyClipboard(
                            `${location.origin}/register?ref=${referralInfo?.code}`,

                            'لینک دعوت',
                          )
                        }
                      />
                    </div>
                  )}
                  <div className="buttons">
                    <Button
                      type="outlined"
                      text="اشتراک گذاری لینک دعوت"
                      onClick={() => setIsInvitationModalOpen(true)}
                    />
                    <Button
                      type="info"
                      text="تصویر تبلیغاتی"
                      onClick={() => setIsAdvertisingModalOpen(true)}
                    />
                  </div>
                </div>
              </div>
              <div className="prize-status">
                <div className="col">
                  <p>سطح دریافت پاداش</p>
                  <span>{referralInfo?.referralLevelTitle}</span>
                </div>
                <div className="col">
                  <p>درصد سهم تراکنش</p>
                  <span>{referralInfo?.referralLevelRewardPercent}٪</span>
                </div>
              </div>
            </div>

            <div className="balance">
              <div className="row">
                <p>مجموع پاداش دریافتی</p>
                <div>
                  <div className="icon">
                    <CoinBagIcon />
                  </div>
                  <span>
                    {digitSperator(referralInfo?.totalBalance.toFixed(0))} تومان
                  </span>
                  <span></span>
                </div>
              </div>
              <div className="row">
                <p>معامله دوستان</p>
                <div>
                  <div className="icon">
                    <TwoPersonsIcon />
                  </div>
                  <span>{referralInfo?.actionCount} تراکنش</span>
                  <span></span>
                </div>
              </div>
              <div className="row">
                <p>دوستان دعوت شده</p>
                <div>
                  <div className="icon">
                    <GroupIcon />
                  </div>
                  <span>{referralInfo?.userCount} نفر</span>
                  <span></span>
                </div>
              </div>
              <div className="row">
                <p>دوستان تایید هویت شده</p>
                <div>
                  <div className="icon">
                    <AddFriendIcon />
                  </div>
                  <span>{referralInfo?.authenticatedUsersCount} نفر</span>
                  <span></span>
                </div>
              </div>
              <div className="row">
                <p>موجودی فعلی</p>
                <div>
                  <div className="icon">
                    <MedalIcon />
                  </div>
                  <span>
                    {digitSperator(referralInfo?.balance.toFixed(0))} تومان
                  </span>
                  <span></span>
                </div>
              </div>

              <Button
                type="info"
                text="واریز پاداش به کیف پول"
                onClick={() => setIsWithdrawalModalOpen(true)}
              />
            </div>
          </div>
        </Card>

        <ReferralActionsHistory />
        <ReferralInvitedUsers />
      </div>
    </>
  );
};

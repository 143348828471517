import { Enum, XpayComponent } from 'core/domains';
import * as React from 'react';
import './switch.scss';

interface Props extends XpayComponent {
  items: any;
  active: Enum;
  onChange?: any;
  disabled?: boolean;
}
export const SwitchBar: React.FC<Props> = ({
  items,
  active,
  className,
  onChange,
  disabled,
}) => {
  return (
    <div className={`xpay-switch-bar ${className}`}>
      {items.map((item: any) => {
        return (
          <button
            className={`switch-item ${
              active === item && !disabled ? 'active' : ''
            } ${item.disabled ? 'button-disable' : ''}`}
            onClick={() => !item.disabled && !disabled && onChange(item)}
          >
            {item.title}
          </button>
        );
      })}
    </div>
  );
};

import { APPNAME } from '../../../../../apis/constants';

export const BuyPmHelperText = () => {
  return (
    <>
      <li>
        <p>
          <span className="number">1</span>
          بلافاصله پس از پرداخت، می توانید اطلاعات ووچر را از بخش سفارشات، برای
          سفارش مورد نظر مشاهده نمایید.
        </p>
      </li>
      <li>
        <p>
          <span className="number">2</span>
          کدهای ووچر به صورت سیستمی صادر می شوند، بنابراین احتمال خطا در آنها
          وجود ندارد اما اگر به هر دلیلی هنگام وارد کردن کد با خطا روبرو شدید،
          از صحت اطلاعات وارد شده اطمینان حاصل نمایید تا مشکل برطرف شود.
        </p>
      </li>
      <li>
        <p>
          <span className="number">3</span>
          در هنگام خرید فقط مجاز به پرداخت با کارت‌های تایید شده در سایت
          می‌باشید، در غیر این‌صورت وجه پرداختی به صورت اتوماتیک به کارت واریزی
          عودت داده خواهد شد.
        </p>
      </li>
      <li>
        <p>
          <span className="number">4</span>
          خرید رمز ارز فقط از طریق کیف پول انجام میشود اما ووچر پرفکت مانی امکان
          خرید مستقیم از درگاه را نیز دارد.
        </p>
      </li>
      <li>
        <p>
          <span className="number">5</span>
          پیش از ثبت درخواست و پرداخت وجه، از میزان ارز وارد شده اطمینان حاصل
          نمایید.
        </p>
      </li>
      <li>
        <p>
          <span className="number">6</span>
          {APPNAME} تنها در مورد پرداخت مبلغ سفارش مسئولیت دارد و وظیفه پیگیری
          های بعدی سفارش، برعهده شماست.
        </p>
      </li>
      <li>
        <p>
          <span className="number">7</span>
          هر ووچر دارای 2 کد ووچر و فعالسازی ووچر است که بعد از خرید تحویل
          میگردد.
        </p>
      </li>
      <li>
        <p>
          <span className="number">8</span>
          در خرید با پرفکت مانی دقت نمائید آدرس وارد شده درست و معتبر باشد ، در
          صورت وارد کردن آدرس اشتباه مسئولیت پیگیری های بعدی به عهده کاربر است و
          {APPNAME} مسئولیتی نخواهد داشت
        </p>
      </li>
    </>
  );
};

import * as React from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Card } from 'components';
import './referral-actions-history.scss';
import { Table } from 'components/table/table';
import { TableColumn } from 'react-data-table-component';
import { ReferralAction } from 'core/domains/referral/referral';
import { PaginationList } from 'core/domains';
import { getMineReferralActions } from 'core/repositories/referral/referral';
import { getPageNum } from 'utils/scripts/paggingHelper';
import { digitSperator } from '../../../../utils/scripts/exchange';
import moment from 'jalali-moment';

const { useState, useRef } = React;

export const ReferralActionsHistory: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [referralActions, setReferralActions] =
    useState<PaginationList<ReferralAction>>();

  const columns: TableColumn<any>[] = [
    {
      name: '#',
      id: 'index',
      selector: (row: any, rowIndex: any) => rowIndex + 1,
      width: '50px',
    },
    {
      name: 'تاریخ',
      id: 'createdOn',
      selector: (row: any) =>
        moment
          .utc(row?.createdOn)
          .locale('fa')
          .format('  HH:mm  -  jYYYY/MM/DD  '),
    },
    {
      name: 'مبلغ',
      id: 'amount',
      selector: (row: any) => digitSperator(row.amount.toFixed(0)),
    },
    {
      name: 'موجودی(بعد از تراکنش):',
      id: 'balanceAfterTransaction',
      selector: (row: any) =>
        digitSperator(row.balanceAfterTransaction.toFixed(0)),
    },
  ];

  React.useEffect(() => {
    getMineReferralActions(
      setLoading,
      setReferralActions,
      {
        pageNumber: getPageNum(),
        pageSize: 10,
      },
      '',
    );
  }, []);

  return (
    <>
      <Card className={`referral-table-card referral-history`} blur={loading}>
        <div className="head">
          <div className="right">
            <h4>سوابق دریافت پاداش</h4>
          </div>

          <Button
            text={`${isExpanded ? 'بستن' : 'نمایش'} لیست`}
            type="outlined"
            icon={isExpanded ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
        {isExpanded && referralActions && (
          <div className={`desktop-show  `}>
            <Table
              className="history-table"
              setData={setReferralActions}
              pagination={referralActions.pagination}
              hasFiltering={true}
              blur={loading}
              data={referralActions.items}
              column={columns}
              getData={getMineReferralActions}
              setTableLoading={loading}
            />
          </div>
        )}

        {isExpanded && (
          <ul className={`mobile-show `}>
            {referralActions?.items?.map((item) => (
              <li>
                <div className="row">
                  <div className="right">
                    <p>تاریخ:</p>
                    <span>
                      {moment
                        .utc(item?.createdOn)
                        .locale('fa')
                        .format('  HH:mm  -  jYYYY/MM/DD  ')}
                    </span>
                  </div>
                  <div className="left">
                    <p>مبلغ: </p>
                    <span>{digitSperator(item.amount)}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="right">
                    <p>موجودی:</p>
                    <span>(بعد از تراکنش)</span>
                  </div>
                  <div className="left">
                    <span>
                      {digitSperator(item.balanceAfterTransaction)} تومان
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Card>
    </>
  );
};

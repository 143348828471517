import { isMobile } from 'react-device-detect';
import { Spin } from 'antd';
import { Card } from '../../card/card';
import { getIconPath } from '../../../utils/scripts/getIconPath';
import { digitSperator } from '../../../utils/scripts/exchange';
import { CopyOutlined } from '@ant-design/icons';
import { copyClipboard } from '../../../utils/scripts/copyToClipboard';
import { getMarketType, MarketType } from '../../../utils/scripts/market';
import { detect_bank } from '../../../utils/scripts/bankDetecter';
import moment from 'jalali-moment';
import { Modal } from '../../modal/modal';
import * as React from 'react';
import { Order, Transaction } from '../../../core/domains';
import { TransactionDetailModalData } from '../transaction-data/transaction-modal-data';
import { OrderDetailModalData } from '../order-data/order-modal-data';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transactionItem?: Transaction;
  orderItem?: Order;
  modalLoading?: boolean;
}

export const TransactionOrderDetailModalDesktopContent: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  transactionItem,
  orderItem,
  modalLoading,
}) => {
  return (
    <Modal
      className="transaction-order-detail-modal"
      isOpen={!isMobile && isOpen}
      title={`جزئیات ${orderItem ? 'سفارش' : transactionItem && 'تراکنش'}`}
      closeAble
      setIsOpen={setIsOpen}
    >
      <Spin spinning={modalLoading ?? false}>
        <Card className="detail-card">
          {orderItem ? (
            <div className="content">
              <OrderDetailModalData orderItem={orderItem} />
            </div>
          ) : (
            transactionItem && (
              <div className="content">
                <TransactionDetailModalData transactionItem={transactionItem} />
              </div>
            )
          )}
        </Card>
      </Spin>
    </Modal>
  );
};

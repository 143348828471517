import { QuestionCircleOutlined } from '@ant-design/icons';
import { API } from 'apis';
import { Button } from 'components';
import { MobileNavigate } from 'components/mobile-navigate/mobile-navigate';
import * as React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import ReactCodeInput from 'react-verification-code-input';
import { message } from 'components/toast/toast';
import 'routes/pages/signup/stages/code/code.scss';
import { post, responseValidator } from 'utils/scripts/api';
import { RootState } from 'core/redux/store';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { setUserData } from 'core/redux/actions';
import moment from 'jalali-moment';
import { useNavigate } from 'react-router-dom';

const { useState } = React;

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const CodeStage: React.FC<Props> = (props) => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countDownTime, setCountDownTime] = useState<number>(120);
  const [hasError, setHasError] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState<string>('');
  const [codeValue, setCodeValue] = useState<string>('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const phoneNumber = localStorage.getItem('phoneNumber');
  const referralCode = localStorage.getItem('referralCode');
  const password = localStorage.getItem('passwordValue');
  const repeatPassword = localStorage.getItem('repeatPasswordValue');
  (window as any).dataLayer = (window as any).dataLayer || [];

  function handleVerify(codeValue: string) {
    setIsLoading(true);
    if (codeValue.length === 6) {
      setHasError(false);
      setFieldError('');
      post(API.signupVerify, {
        OtpCode: codeValue,
        phoneNumber: phoneNumber,
      }).then(function (response: any) {
        setIsLoading(false);
        if (responseValidator(response.status)) {
          setHasError(false);
          message.success('شماره تماس شما تایید شد');
          localStorage.removeItem('passwordValue');
          localStorage.removeItem('repeatPasswordValue');
          dispatch(
            setUserData({
              ...props.state.userData,
              isTwoFactorEnabled: response.data.value.isTwoFactorEnabled,
              twoFactorMethod: response.data.value.twoFactorMethod,
              accessToken: response.data.value.access_token,
              refreshToken: response.data.value.refresh_token,
              tokenType: response.data.value.token_type,
              expiresIn: response.data.value.expires_in,
              roles: response.data.value.roles,
              isAuthorized: response.data.value.isAuthorized,
            }),
          );
          (window as any).dataLayer.push({
            event: 'sign_up',
          });

          localStorage.setItem('token', response.data.value.access_token);
          response.data.value.fullName &&
            localStorage.setItem('name', response.data.value.fullName);
          localStorage.setItem('expires_in', response.data.value.expires_in);
          localStorage.setItem('auth', response.data.value.isAuthorized);
          phoneNumber && localStorage.setItem('phoneNumber', phoneNumber);
          localStorage.setItem('date', moment.utc().toString());
          localStorage.setItem('ref', response.data.value.refresh_token);
          navigate('/dashboard');
        } else {
          setHasError(true);
          setFieldError('کد وارد شده اشتباه است.');
        }
      });
    } else {
      setFieldError('کد باید دارای ۶ رقم باشد.');
    }
  }

  function resendHandle() {
    post(API.signup, {
      phoneNumber,
      password,
      repeatPassword,
      referralCode,
    }).then(function (response: any) {
      if (responseValidator(response.status)) {
        message.success('کد تایید ارسال شد.');
        setCountDownTime(120);
      }
    });
  }

  return (
    <div className="signup-code-stage">
      <MobileNavigate
        title="تایید شماره همراه"
        onBackClick={() => navigate('/register/')}
      />

      <h3>تایید شماره همراه</h3>
      <p>
        یک کد ۶ رقمی به شماره {phoneNumber} ارسال شد. لطفا کد را اینجا وارد
        کنید.
      </p>

      <div className="signup-code-main">
        <ReactCodeInput
          className={`signup-code-input ${hasError ? 'has-error' : ''}`}
          fieldWidth={50}
          values={codeValue.split('')}
          autoFocus={true}
          onChange={(value) => {
            setCodeValue(value);
          }}
          onComplete={(e) => handleVerify(e)}
        />

        <div className="signup-code-stats">
          <div className="signup-code-errors">
            {hasError ? (
              <>
                <QuestionCircleOutlined />
                <span>{fieldError}</span>
              </>
            ) : (
              <span></span>
            )}
          </div>
          <div className="signup-code-timer">
            {countDownTime < 1 ? (
              <span className="resend" onClick={() => resendHandle()}>
                ارسال مجدد
              </span>
            ) : (
              <>
                <CountdownCircleTimer
                  size={30}
                  isPlaying
                  duration={countDownTime}
                  colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                  colorsTime={[7, 5, 2, 0]}
                  onComplete={() => setCountDownTime(0)}
                >
                  {({ remainingTime }) => <span>{remainingTime}</span>}
                </CountdownCircleTimer>{' '}
                ثانیه تا ارسال مجدد کد
              </>
            )}
          </div>
          {!hasError && <span></span>}
        </div>
        <Button
          type="info"
          text="تایید"
          className={`signup-code-button ${
            codeValue.length !== 6 ? 'button-disabled' || isLoading : ''
          }`}
          htmlType="button"
          disabled={codeValue.length !== 6 || isLoading ? true : false}
          onClick={() => handleVerify(codeValue)}
          loading={isLoading ? true : false}
        />
        <p
          onClick={() => navigate('/register/')}
          className="signup-code-change-number"
        >
          ورود با یک شماره موبایل دیگر
        </p>
      </div>
    </div>
  );
};
export default connector(CodeStage);

import * as React from 'react';
import { Dispatch } from 'react';
import { Button, Input, Modal } from 'components';

import './basic.scss';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { IsPersianChar } from 'utils/scripts/stringExtentions';
import { faNumToEnNum, IsNumeric } from '../../../../../utils/scripts/decimal';
import { message } from '../../../../../components/toast/toast';
import {
  Level1FieldErrorType,
  Level1FieldValueType,
  VerifyLevel1Result,
} from 'core/domains';
import { DatePicker } from '../../../../../components/date-picker/date-picker';
import { Divider } from 'antd';

type Props = {
  setStage: Dispatch<
    React.SetStateAction<
      'BASIC' | 'ADDRESS' | 'UPLOAD_FILES' | 'LANDLINE' | 'COMPLETE'
    >
  >;
};

const { useState, useRef } = React;

export const BasicStage: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [basicInfo, setBasicInfo] = useState<Level1FieldValueType>({});
  const [fieldError, setFieldError] = useState<Level1FieldErrorType>({});
  const [verifyInfoModalIsOpen, setVerifyInfoModalIsOpen] = useState(false);
  const [verifyResult, setVerifyResult] = useState<VerifyLevel1Result>();
  const [confirmButtonIsLoading, setConfirmButtonIsLoading] = useState(false);

  function handleChangeForm(value: string, field: string) {
    setFieldError({});

    // all number Fa to En
    if (value !== '' && field === 'nationalCode') value = faNumToEnNum(value);
    // validation nationalCode
    if (
      value !== '' &&
      field === 'nationalCode' &&
      !IsNumeric(faNumToEnNum(value.split('')[value.split('').length - 1]))
    )
      return;
    //validation others
    else if (field !== 'nationalCode')
      if (value !== '' && IsPersianChar(value)) return;

    // set on state
    setBasicInfo({
      ...basicInfo,
      [field]: value,
    });
  }

  function handleSubmit() {
    setFieldError({});
    if (basicInfo.nationalCode && basicInfo.birthDate && basicInfo.email) {
      if (basicInfo.nationalCode.length === 10) {
        setLoading(true);
        postViaAuth(API.authentication.requestUpgradeToLevel1, {
          birthDate: basicInfo.birthDate,
          nationalCode: basicInfo.nationalCode,
          email: basicInfo.email,
        }).then((response: any) => {
          setLoading(false);

          if (responseValidator(response.status)) {
            setVerifyInfoModalIsOpen(true);
            setVerifyResult(response.value);
          } else {
            if (response.status === 403 || response.status === 401)
              window.open('/login', '_self');
          }
        });
      } else {
        setFieldError({
          ...fieldError,
          nationalCode: ['شماره ملی نادرست است.'],
        });
      }
    } else {
      if (!basicInfo.email) {
        setFieldError({
          ...fieldError,
          email: ['وارد کردن ایمیل الزامیست'],
        });
      }

      if (!basicInfo.nationalCode) {
        setFieldError({
          ...fieldError,
          nationalCode: ['وارد کردن کد ملی الزامیست'],
        });
      }
    }
    if (!basicInfo.birthDate) {
      setFieldError({
        ...fieldError,
        birthDate: ['وارد کردن تاریخ تولد الزامیست'],
      });
    }
  }

  function handleVerifySubmit() {
    setFieldError({});
    setLoading(true);
    postViaAuth(API.authentication.verifyUpgradeToLevel1, {
      isConfirmed: true,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setVerifyInfoModalIsOpen(false);
        setVerifyResult(undefined);
        props.setStage('ADDRESS');
        message.success('شما با موفقیت احراز شدید');
      } else {
        if (response.status === 403 || response.status === 401)
          window.open('/login', '_self');
      }
    });
  }

  return (
    <>
      <Modal
        title={'لطفا بعد از بررسی اطلاعات هویتی خود، صحت آنها را تایید کنید'}
        closeAble
        className={'verify-info-modal'}
        isOpen={verifyInfoModalIsOpen}
        setIsOpen={setVerifyInfoModalIsOpen}
      >
        <div className="confirm-box">
          <ul className="head">
            <li>
              <span>نام و نام خانوادگی</span>
              <p>{verifyResult?.firstName + ' ' + verifyResult?.lastName}</p>
            </li>
            <Divider type={'horizontal'} />
            <li>
              <span>نام پدر</span>
              <p>{verifyResult?.fatherName}</p>
            </li>
            <Divider type={'horizontal'} />
            <li>
              <span>کد ملی</span>
              <p>{verifyResult?.nationalCode}</p>
            </li>
            <Divider type={'horizontal'} />
            <li>
              <span>تاریخ تولد</span>
              <p>{`${verifyResult?.birthDate.slice(
                0,
                4,
              )}/${verifyResult?.birthDate.slice(
                4,
                6,
              )}/${verifyResult?.birthDate.slice(6)}`}</p>
            </li>
          </ul>
          <div className="buttons">
            <Button
              type="info"
              text="تایید اطلاعات"
              htmlType="button"
              loading={loading}
              onClick={() => handleVerifySubmit()}
            />
            <Button
              type="outlined"
              text="رد اطلاعات"
              onClick={() => setVerifyInfoModalIsOpen(false)}
            />
          </div>
        </div>
      </Modal>
      <form className="form-basic">
        <div>
          <Input
            label="کد ملی"
            type="text"
            dir="ltr"
            name="national-code"
            onChange={(event) =>
              handleChangeForm(event.target.value.toString(), 'nationalCode')
            }
            value={basicInfo.nationalCode}
            maxLength={10}
            errors={fieldError.nationalCode}
            onPressEnter={handleSubmit}
          />
          <Input
            label="ایمیل"
            type="text"
            dir="ltr"
            name="email"
            onChange={(event) =>
              handleChangeForm(event.target.value.toString(), 'email')
            }
            value={basicInfo.email}
            maxLength={100}
            errors={fieldError.email}
            onPressEnter={handleSubmit}
          />
        </div>
        <div>
          <div className="birth-date">
            <label>تاریخ تولد</label>
            {/*   <Calendar
            name="birth-date"
            label="تاریخ تولد"
            placeholder="روی کادر کلیک کرده تا وارد فرم انتخاب تاریخ شوید"
            error={fieldError.birthDate}
            onChange={(value) =>
              setBasicInfo({
                ...basicInfo,
                birthDate: value,
              })
            }
          />*/}
            <DatePicker
              onChange={(value) =>
                setBasicInfo({
                  ...basicInfo,
                  birthDate: value,
                })
              }
            />
          </div>
        </div>
        <Button
          type="info"
          text="ادامه"
          htmlType="button"
          loading={loading}
          disabled={
            !basicInfo ||
            !basicInfo.nationalCode ||
            !basicInfo.birthDate ||
            !basicInfo.email
          }
          onClick={() => handleSubmit()}
        />
      </form>
    </>
  );
};

import { Col, Row, Spin } from 'antd';
import { Alert } from 'components';
import { HelperBox } from 'components/helper-box/helper-box';
import { HelperCard } from 'components/helper-card/helper-card';
import { HelperRow } from 'components/helper-row/helper-row';
import { Fiat, Market, MarketAllPrices } from 'core/domains';
import { AssetItem } from 'core/domains/markets/asset';
import {
  getAllFiats,
  getAllMarketsPrices,
  getAllOrderMarketNameIds,
  getMineBalanceByPair,
} from 'core/repositories';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { OrderBox } from '../order-box/order-box';
import './create-order.scss';
import { BuyPerfectMoneyThankYouPage } from '../../wallet/perfect-money/buy/thankyou/buy-pm-thankyou';
import { BuyPmHelperText } from './helper-text/buy-pm';
import { SellPmHelperText } from './helper-text/sell-pm';
import { CryptoHelperText } from './helper-text/crypto';

const CreateOrder: React.FC = (props) => {
  const [markets, setMarkets] = React.useState<Market[]>([]);
  const [fiats, setFiats] = React.useState<Fiat[]>([]);

  const [sourceMarket, setSourceMarket] = React.useState<Market>();
  const [destinationMarket, setDestinationMarket] = React.useState<Market>();

  const [depositData, setDepositData] = useState<any>();
  const [depositStage, setDepositStage] = useState<any>();

  const [side, setSide] = React.useState<string>('buy');

  const [cardLoading, setCardLoading] = React.useState<boolean>(false);

  const [balance, setBalance] = React.useState<AssetItem>();
  const [prices, setPrices] = React.useState<MarketAllPrices[]>([]);

  React.useEffect(() => {
    if (prices && prices.length !== 0) {
      const alldata: Market[] = [];
      alldata.push({
        symbol: 'IRT',
        name: 'تومان',
        sellPrice: 1,
        buyPrice: 1,
      });
      prices.map((price) => {
        var item = markets.filter((s) => s.symbol === price.symbol);
        item.length !== 0 &&
          alldata.push({
            ...item[0],
            sellPrice: price.sellPrice,
            buyPrice: price.buyPrice,
          });
      });
      setMarkets(alldata);
    }
  }, [prices]);

  const fetchAllData = async () => {
    markets.length === 0 &&
      (await getAllOrderMarketNameIds(setCardLoading, setMarkets, 1, 1000));
    prices.length === 0 &&
      getAllMarketsPrices(setCardLoading, setPrices, 1, 1000);
    fiats.length === 0 && (await getAllFiats(setCardLoading, setFiats));
    !balance && (await getMineBalanceByPair('IRT', setCardLoading, setBalance));
  };

  React.useEffect(() => {
    fetchAllData();
  }, []);

  return depositStage === 'thankyou' ? (
    <BuyPerfectMoneyThankYouPage
      voucher={{
        voucher_code: depositData.voucher_code,
        activation_code: depositData.activation_code,
        trackingCode: depositData.trackingCode,
        amount: depositData.amount,
      }}
      amount={depositData.amount}
      trackingCode={depositData.trackingCode}
      depositFromWallet={true}
    />
  ) : (
    <>
      <div className="xpay-create-order">
        <Row>
          <Col className="right-side" md={16} sm={24} xs={24} lg={16}>
            {balance &&
            markets &&
            markets.length > 0 &&
            fiats &&
            fiats.length > 0 &&
            prices &&
            prices.length > 0 ? (
              <OrderBox
                cardLoading={cardLoading}
                allFiats={fiats}
                allPrices={prices}
                allMarkets={markets}
                allBalanceSelectAble
                hasInputrange
                side={side}
                setSide={setSide}
                setDepositStage={setDepositStage}
                setDepositData={setDepositData}
                alertBox={
                  sourceMarket?.symbol === 'IRT' ? (
                    <Alert
                      type="info"
                      message={
                        <>
                          مقدار ارز خریداری شده به کیف پول رمز ارزی شما در سایت
                          اضافه میشود.{' '}
                          <Link to="/dashboard/wallet">صفحه کیف پول</Link>
                        </>
                      }
                    />
                  ) : (
                    <Alert
                      type="info"
                      message={
                        <>
                          مقدار ارز فروخته شده به کیف پول تومانی شما در سایت
                          اضافه میشود.{' '}
                          <Link to="/dashboard/wallet">صفحه کیف پول</Link>
                        </>
                      }
                    />
                  )
                }
                allBalances={balance}
                sourceMarket={sourceMarket}
                destinationMarket={destinationMarket}
                setSourceMarket={setSourceMarket}
                setDestinationMarket={setDestinationMarket}
                onSideChangeFetch={() =>
                  destinationMarket &&
                  sourceMarket &&
                  getMineBalanceByPair(
                    destinationMarket?.symbol.toUpperCase(),
                    setCardLoading,
                    setBalance,
                  )
                }
              />
            ) : (
              <Spin spinning={true}></Spin>
            )}
          </Col>
          <Alert
            type="warning"
            message={
              <>
                لطفاً در صورت استفاده از فیلترشکن، آن را خاموش کنید. به دستور
                پلیس فتا، برای واریز و برداشت تومانی IP شما باید کشور ایران
                باشد.
              </>
            }
            iconOnTop
          />
          <Col className="left-side desktop-show" md={8} sm={24} xs={24} lg={8}>
            <HelperCard
              title={
                destinationMarket && sourceMarket
                  ? `راهنمای 
                  ${side === 'buy' ? 'خرید' : 'فروش'} 
                  ${
                    side === 'buy'
                      ? destinationMarket?.name
                      : sourceMarket?.name
                  }`
                  : ''
              }
            >
              <ul>
                {destinationMarket?.symbol === 'PM' ? (
                  <BuyPmHelperText />
                ) : sourceMarket?.symbol === 'PM' ? (
                  <SellPmHelperText />
                ) : (
                  <CryptoHelperText />
                )}
              </ul>
            </HelperCard>
          </Col>
        </Row>
        <HelperBox
          title={
            destinationMarket && sourceMarket
              ? `راهنمای 
                  ${side === 'buy' ? 'خرید' : 'فروش'} 
                  ${
                    side === 'buy'
                      ? destinationMarket?.name
                      : sourceMarket?.name
                  }`
              : ''
          }
        >
          {' '}
          <ul>
            {destinationMarket?.symbol === 'PM' ? (
              <BuyPmHelperText />
            ) : sourceMarket?.symbol === 'PM' ? (
              <SellPmHelperText />
            ) : (
              <CryptoHelperText />
            )}
          </ul>
        </HelperBox>
      </div>

      <Row className="help-bar-row desktop-show">
        <HelperRow />
      </Row>
    </>
  );
};

export default CreateOrder;

import * as React from 'react';

import './widthrawl-crypto-box.scss';
import { Alert, Button, Card, Input, Modal, SwitchBar } from 'components';
import { CloseOutlined, FileSearchOutlined } from '@ant-design/icons';
import { faNumToEnNum } from 'utils/scripts/decimal';
import { Enum, Network, NetwrokList, Transaction } from 'core/domains';
import { Market } from 'core/domains/index';
import { Asset } from 'core/domains/markets/asset';
import { getAllNetworks, getMineBalance } from 'core/repositories';
import { useLocation } from 'react-router-dom';
import { getIconPath } from 'utils/scripts/getIconPath';
import { message } from 'components/toast/toast';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import useOnBlur from 'utils/scripts/useOnBlur';
import { WithdrawalConfirm } from '../confirmModal/withdrawal-confirm-modal';
import toast from 'react-hot-toast';
import { WithdrawlCryptoNetworksModal } from './networks-modal/networks-modal';
import { ReactComponent as CaretDown } from 'assets/icons/carrot-down-blue.svg';

const { useState } = React;

type Props = {
  markets?: Market[];
  setMarketName?: React.Dispatch<any>;
};

export const WidthrawlCryptoBox: React.FC<Props> = (props) => {
  const [cardIsLoading, setCardIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  const location = useLocation();

  const [results, setResults] = useState<Market[]>([]);
  const [marketsIsOpen, setMarketsIsOpen] = useState<boolean>(false);
  const [market, setMarket] = useState<Market>();

  const [networksModalIsOpen, setNetworksModalIsOpen] =
    useState<boolean>(false);
  const [networks, setNetworks] = useState<NetwrokList>();
  const [network, setNetwork] = useState<Network>();
  // states
  const [widthrawlValue, setWidthrawlValue] = useState<string>('');
  const [search, setSearch] = useState<string>();

  const [addressWallet, setAddressWallet] = useState<string>('');
  const [memoAddress, setMemoAddress] = useState<string>('');

  const [modalStage, setModalStage] = useState<'code' | 'thankyou'>('code');
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const [code, setCode] = useState<string>('');
  const [transaction, setTransaction] = useState<Transaction>();

  const [twoFactorMethod, setTwoFactorMethod] = useState<Enum>();
  const [isTwoStepActive, setIsTwoStepActive] = useState<number>();
  const [balance, setBalance] = useState<Asset>();
  const [isMoreThanBalance, setIsMoreThanBalance] = useState<boolean>(false);

  const [withdrawPrecision, setWithdrawPrecision] = useState<number>(6);

  const onMarketChangeHandler = (market: any) => {
    setMarket(market);
    setMarketsIsOpen(false);
    setIsMoreThanBalance(false);
    props.setMarketName && props.setMarketName(market.name);
    getAllNetworks(setCardIsLoading, setNetworks, market.symbol, false);

    window.history.replaceState(null, '', `${market?.symbol}`);
  };

  const onNetworkChangeHandler = (network: any) => {
    setNetwork(network);
    setNetworksModalIsOpen(false);
  };

  const marketDropdownRef = React.useRef<any>();
  const networkDropdownRef = React.useRef<any>();
  useOnBlur(marketDropdownRef, () => marketsIsOpen && setMarketsIsOpen(false));
  useOnBlur(
    networkDropdownRef,
    () => networksModalIsOpen && setNetworksModalIsOpen(false),
  );

  const onSearchChangeHandler = (value: string) => {
    if (value !== '') {
      props.markets &&
        setResults(
          props.markets.filter(
            (s) =>
              s.symbol.toLowerCase().includes(value.trim()) ||
              s.name.includes(value),
          ),
        );
    } else {
      props.markets && setResults(props.markets);
    }
    setSearch(value);
  };

  const stages: Enum[] = [
    {
      id: 1,
      title: `موجودی قابل برداشت  ${market?.name ?? ' '} :
       ${
         balance?.assets.filter((s) => s.symbol == market?.symbol)[0]
           ?.balance ?? 0
       } واحد`,
    },
  ];

  const fetchAllData = () => {
    !balance && getMineBalance(setCardIsLoading, setBalance);
  };

  React.useEffect(() => {
    if (networks) {
      setWithdrawPrecision(6);

      setNetwork(
        networks.networks.filter((q) => q.network.toUpperCase() === 'TRX')
          .length > 0
          ? networks.networks.filter(
              (q) => q.network.toUpperCase() === 'TRX',
            )[0]
          : networks.networks[0],
      );
    }
  }, [networks]);

  React.useEffect(() => {
    resetForm();
    props.markets && props.markets.length != 0 && setResults(props.markets);
    fetchAllData();
  }, []);

  React.useEffect(() => {
    var targetMarket =
      props.markets &&
      props.markets.filter(
        (s) =>
          s.symbol ===
          location.pathname
            .split('/')
            [location.pathname.split('/').length - 1].toUpperCase(),
      )[0];
    targetMarket && setMarket(targetMarket);
    targetMarket &&
      getAllNetworks(setCardIsLoading, setNetworks, targetMarket.symbol, false);
  }, [props.markets]);

  React.useEffect(() => {
    if (marketsIsOpen)
      props.markets &&
        setResults(
          props.markets.filter(
            (s) => s.symbol.includes('') || s.name.includes(''),
          ),
        );
  }, [marketsIsOpen]);

  const onWidthrawlValueChangeHandler = (value: string) => {
    value = faNumToEnNum(value);
    const regex = new RegExp(`^(\\d*\\.{0,100}\\d{0,${withdrawPrecision}})$`);
    if (regex.test(value)) {
      var condition =
        parseFloat(widthrawlValue.replaceAll(',', '') + value) >
        (balance?.assets.filter((s) => s.symbol == market?.symbol)[0].balance ||
          0);
      setWidthrawlValue(faNumToEnNum(value));
      setIsMoreThanBalance(condition);
    }
  };

  const onConfirmHandler = () => {
    if (network) {
      if (!network.isWithdrawEnabled) {
        toast.error(
          `شبکه ${network.name} به دلیل اختلال در دسترس نیست ، لطفاً بعدا تلاش کنید`,
        );
        return;
      }
      if (
        network.addressRegex &&
        !new RegExp(network.addressRegex).test(addressWallet)
      ) {
        toast.error('آدرس ولت وارد شده معتبر نیست.');
        return;
      }

      setLoading(true);
      postViaAuth(API.widthrawl.crypto, {
        amount: parseFloat(widthrawlValue),
        market: market?.symbol,
        network: network.network,
        addressWallet: addressWallet,
        memoAddress: memoAddress,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response?.status)) {
          setModalStage('code');
          setModalIsOpen(true);
          setTwoFactorMethod(response?.value.twoFactorMethod);
          setTransaction(response?.value?.transaction);
          setIsWaiting(response?.value ? false : true);
          setIsTwoStepActive(response?.value?.twoFactorMethod.id);
        }
      });
    }
  };

  function resetForm() {
    setModalStage('code');
    setAddressWallet('');
    setMemoAddress('');
    setCode('');
  }

  function onClose() {
    setModalIsOpen(false);
    resetForm();
  }

  const onVerifyHandler = (code: string) => {
    if (code.length == 6) {
      setModalLoading(true);
      postViaAuth(API.widthrawl.verifyCrypto, {
        code: code,
        trackingCode: transaction?.trackingCode,
      }).then((response: any) => {
        setModalLoading(false);
        if (responseValidator(response.status)) {
          message.success('تراکنش برداشت شما با موفقیت ثبت شد');
          resetForm();
          setModalStage('thankyou');
        }
      });
    }
  };

  return (
    <>
      <div className={`xpay-widthrawl-crypto-box `}>
        {networks && (
          <WithdrawlCryptoNetworksModal
            addressWallet={addressWallet}
            onNetworkChange={onNetworkChangeHandler}
            isOpen={networksModalIsOpen}
            setIsOpen={setNetworksModalIsOpen}
            networks={networks}
          />
        )}
        <Modal
          isOpen={modalIsOpen}
          className="withdrawl-crypto-modal"
          setIsOpen={setModalIsOpen}
        >
          {transaction && market && (
            <WithdrawalConfirm
              onBack={onClose}
              isTwoStepActive={isTwoStepActive ?? 0}
              setIsOpen={setModalIsOpen}
              setLoading={setLoading}
              isWaiting={isWaiting}
              marketName={market.name}
              trackingCode={transaction?.trackingCode}
              stage={modalStage}
              amount={parseFloat(widthrawlValue)}
              loading={modalLoading}
              onConfirm={onVerifyHandler}
              onResend={onConfirmHandler}
              networkFee={transaction.network.fee}
              symbol={transaction.market.symbol}
            />
          )}
        </Modal>

        <Card blur={cardIsLoading || !balance} className="content">
          <SwitchBar disabled items={stages} active={stages[0]} />

          <div className="widthrawl-options">
            <div className="widthrawl-value-row market-options-row">
              <div className="market-selector" ref={marketDropdownRef}>
                <span className="input-info-label"></span>
                <Input
                  type="text"
                  // className={`${isMoreThanBalance ? 'danger' : ''}`}
                  value={widthrawlValue}
                  label="مقدار کوین"
                  onPressEnter={() =>
                    !loading &&
                    widthrawlValue !== '' &&
                    addressWallet !== '' &&
                    onConfirmHandler()
                  }
                  onChange={(e) => {
                    onWidthrawlValueChangeHandler(e.target.value.toString());
                  }}
                  name="widthrawlValue"
                  dir={widthrawlValue === '' ? 'rtl' : 'ltr'}
                  placeholder={`مقدار ${market?.name} را وارد کنید`}
                  maxLength={100}
                  adorments={{
                    startAdornment: {
                      adornmentIcon: (
                        <div
                          onClick={() => setMarketsIsOpen(!marketsIsOpen)}
                          className="mark"
                        >
                          <div className="selected-item">
                            <img src={getIconPath(market?.symbol || '')} />
                            <span>{market?.name}</span>
                            <span>{market?.symbol}</span>
                          </div>
                          <div className="icon">
                            <CaretDown />
                          </div>
                        </div>
                      ),
                    },
                    endAdornment: {
                      adornmentIcon: (
                        <div className="options">
                          <span>{widthrawlValue === '' && 0}</span>
                        </div>
                      ),
                    },
                  }}
                />
                {marketsIsOpen && (
                  <div className={`markets-dropdown`}>
                    <div className="searchbox">
                      <Input
                        type="text"
                        dir={'rtl'}
                        placeholder="نام یا نماد ارز مورد نظر خود را جست و جو کنید"
                        adorments={{
                          startAdornment: {
                            adornmentIcon: <FileSearchOutlined />,
                          },
                          endAdornment: {
                            adornmentIcon: (
                              <CloseOutlined
                                onClick={() => setMarketsIsOpen(false)}
                              />
                            ),
                          },
                        }}
                        name="searchvalue"
                        value={search}
                        onChange={(e) => onSearchChangeHandler(e.target.value)}
                      />
                    </div>
                    <ul>
                      {results &&
                        results
                          .filter(
                            (market) => market.symbol.toUpperCase() !== 'UUSD',
                          )
                          .map((market) => {
                            return (
                              <li
                                onClick={() => onMarketChangeHandler(market)}
                                key={`market-${market.symbol}`}
                              >
                                <img src={getIconPath(market.symbol)} />
                                <span>{market.name}</span>
                                <span>({market.symbol})</span>
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {network && (
              <Alert
                className="minimum-withdrawal"
                type={'warning'}
                message={`حداقل برداشت در شبکه انتخابی با احتساب کارمزد ${parseFloat(
                  (network.withdrawMin + network.withdrawFee)
                    .toFixed(6)
                    .toString(),
                )} ${market?.name} میباشد.`}
              />
            )}
            <div className="network-options-row">
              <div className="network-selector" ref={networkDropdownRef}>
                <span className="input-info-label">
                  {network &&
                    `  حداقل کارمزد شبکه : ` +
                      network.withdrawFee +
                      ' واحد ' +
                      market?.name}
                </span>
                <Input
                  value={addressWallet}
                  type="text"
                  onChange={(e) => {
                    setAddressWallet(e.target.value);
                  }}
                  onPressEnter={() =>
                    !loading &&
                    widthrawlValue !== '' &&
                    addressWallet !== '' &&
                    onConfirmHandler()
                  }
                  dir={'ltr'}
                  className="address-wallet-input"
                  name="addressWallet"
                  label="آدرس کیف پول"
                />
                <Input
                  value={memoAddress}
                  type="text"
                  maxLength={50}
                  onChange={(e) => {
                    setMemoAddress(e.target.value);
                  }}
                  onPressEnter={() =>
                    !loading &&
                    widthrawlValue !== '' &&
                    addressWallet !== '' &&
                    onConfirmHandler()
                  }
                  dir={'ltr'}
                  className="memo-address-input"
                  name="memoAddress"
                  label="آدرس ممو (اختیاری)"
                />
                {networks && (
                  <Input
                    value={network?.name}
                    type="text"
                    onChange={(e) => {}}
                    name="network"
                    onClick={() => setNetworksModalIsOpen(true)}
                    readonly
                    label="شبکه انتقال"
                    adorments={{
                      endAdornment: {
                        adornmentIcon: (
                          <div className="icon">
                            <CaretDown
                              onClick={() => setNetworksModalIsOpen(true)}
                            />
                          </div>
                        ),
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="submit">
            <Button
              disabled={
                loading || widthrawlValue === '' || addressWallet === ''
              }
              type="info"
              loading={loading}
              onClick={() => onConfirmHandler()}
              text="ثبت برداشت"
            />
          </div>
        </Card>
      </div>
    </>
  );
};

import * as React from "react";
import "./button.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Path } from "react-router-dom";
import { XpayComponent } from "core/domains";
import { props } from "ant-responsive-table";


interface Props extends XpayComponent {
    disabled?: boolean
    type: "success" | "error" | "primary" | "info" | "secondary" | "outlined" | "warning" | "trade"
    loading?: boolean
    onClick?: React.MouseEventHandler<HTMLElement>
    text?: string
    icon?: React.ReactNode
    htmlType?: any
    link?: string | Partial<Path>
    url?: string
}

export const Button: React.FC<Props> = (props) => {
    return (
        props.url && props.url !== '' ? <div
            onClick={() => window.open(props.url, '_blank')}
            className={`xpay-button ${props.className && props.className !== "" ? props.className : ""
                }`}
        >

            <button
                type={props.htmlType}
                className={`${props.type} ${props.disabled && 'disable'}`}
                disabled={props.disabled || props.loading}
            >
                <span>{props.text}</span>
                {props.icon}
                {props.loading ? <LoadingOutlined /> : <></>}
            </button>
        </div> :
            <div
                onClick={props.disabled ? () => console.log() : props.onClick}
                className={`xpay-button ${props.className && props.className !== "" ? props.className : ""}`}
            >

                <button
                    type={props.htmlType}
                    className={`${props.type} ${props.disabled && 'disable'}`}
                    disabled={props.disabled || props.loading}
                >
                    <span>{props.text}</span>
                    {props.icon}
                    {props.loading ? <LoadingOutlined /> : <></>}
                </button>
            </div>


    );
};

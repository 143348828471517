import { API } from 'apis';
import axios from 'axios';
import moment from 'moment';
import { Response } from '../../core/interfaces';
import { authToken } from './storage';
import { SERVER_URL } from '../../apis/constants';
import { message as messageToast } from 'components/toast/toast';

export const SERVER = SERVER_URL;
const expireRef = 60 * 9999999999;

export function isLogin() {
  if (window.location.href.includes('verify')) return true;
  try {
    if (localStorage.getItem('date')) {
      let expireIn = parseInt(localStorage.getItem('expires_in') ?? '0');

      return (
        Math.abs(
          moment(localStorage.getItem('date')).diff(moment.utc(), 'seconds'),
        ) < expireIn
      );
    }
    return (
      localStorage.getItem('date') &&
      Math.abs(
        moment(localStorage.getItem('date')).diff(moment.utc(), 'seconds'),
      ) < expireRef
    );
    return false;
  } catch {
    return false;
  }
}

const generateHeader = async (object: any = {}) => {
  const header: any = object;
  if (authToken.get()) {
    let lastRefresh = localStorage.getItem('date');
    let expireIn = parseInt(localStorage.getItem('expires_in') ?? '0');
    if (
      Math.abs(
        moment(localStorage.getItem('date')).diff(moment.utc(), 'seconds'),
      ) > expireIn
    )
      window.location.href = '/login';

    if (
      lastRefresh &&
      Math.abs(moment(lastRefresh).diff(moment.utc(), 'seconds')) > expireRef
    ) {
      await axios
        .get(SERVER + `${API.token}/${localStorage.getItem('ref')}`)
        .then((res) => {
          localStorage.setItem('ref', res.data.data.refresh_token);
          localStorage.setItem('token', res.data.data.access_token);
          res.data.value.fullName &&
            localStorage.setItem('name', res.data.value.fullName);

          localStorage.setItem('date', moment.utc().toString());
        })
        .catch((err) => {
          window.location.href = '/login';
        });
    }
  }
  header.Authorization = authToken.get();
  for (const key of Object.keys(object)) {
    header[key] = object[key];
  }
  return header;
};

export function del(url: string, body: any) {
  let status: number;
  var header: any;
  async () => {
    header = await generateHeader({ 'Content-Type': 'application/json' });
  };

  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: header,
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export const post = async (url: string, body: any) => {
  let status: number;
  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(body),
    })
      .then(function (response) {
        status = response.status;
        if (response.status === 401) window.open('/login', '_self');
        else if (response.status === 403) resolve({ value: null, status });
        return response.json();
      })
      .then(function (data) {
        handleErrors(data);
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
};

export const form = async (url: string, body: any) => {
  let status: number;
  var header = await generateHeader();

  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: 'POST',
      body: body,
      headers: header,
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
};

export function put(url: string, body: any) {
  let status: number;
  var header: any;
  async () => {
    header = await generateHeader({ 'Content-Type': 'application/json' });
  };
  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: header,
    })
      .then(function (response) {
        status = response.status;
        if (response.status === 403 || response.status === 401)
          window.open('/login', '_self');

        return response.json();
      })
      .then(function (data) {
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export function patch(url: string, body: any) {
  let status: number;

  var header: any;
  async () => {
    header = await generateHeader({ 'Content-Type': 'application/json' });
  };

  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: header,
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export function get(url: string, params: any = {}) {
  var header: any;
  async () => {
    header = await generateHeader({ 'Content-Type': 'application/json' });
  };

  const generatedUrl = new URL(SERVER + url);
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      generatedUrl.searchParams.append(key, params[key]);
    }
  });
  let status: number;
  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: 'GET',
      headers: header,
    })
      .then(function (response) {
        status = response.status;
        if (response.status === 401) window.open('/login', '_self');
        else if (response.status === 403) resolve({ value: null, status });

        return response.json();
      })
      .then(function (data) {
        handleErrors(data);

        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export async function getViaAuth<R>(
  url: string,
  params: { [k: string]: any } = {},
): Promise<Response<R | null>> {
  const generatedUrl = new URL(SERVER + url);
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      generatedUrl.searchParams.append(key, params[key]);
    }
  });

  let status: number;
  let message: string;
  const header = await generateHeader({ 'Content-Type': 'application/json' });
  return new Promise(async (resolve) => {
    fetch(generatedUrl.href, {
      method: 'GET',
      headers: header,
    })
      .then((response: any) => {
        status = response.status;
        if (status === 401) window.open('/login', '_self');
        else if (status === 403) {
          message = ' دسترسی غیر مجاز .';
          resolve({ value: null, message, status });
        }
        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.status = status;

        handleErrors(data);

        resolve(data);
      })
      .catch((err) => {
        message = ' خطا در برقراری ارتباط با سرور';
        resolve({ value: null, message, status });
      });
  });
}

export function getGeneric<R>(
  url: string,
  params: { [k: string]: any } = {},
): Promise<Response<R | null>> {
  const generatedUrl = new URL(SERVER + url);
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      generatedUrl.searchParams.append(key, params[key]);
    }
  });
  let status: number;
  let message: string;

  const header: { [k: string]: any } = {};
  header['Content-Type'] = 'application/json';
  return new Promise((resolve) => {
    fetch(generatedUrl.href, {
      method: 'GET',
      headers: header,
    })
      .then((response: any) => {
        status = response.status;
        if (response.status === 401) window.open('/login', '_self');
        else if (response.status === 403) {
          message = 'دسترسی غیر مجاز .';
          resolve({ value: null, message, status });
        }
        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.status = status;
        resolve(data);
      })
      .catch((err) => {
        message = ' خطا در برقراری ارتباط با سرور';
        resolve({ value: null, message, status });
      });
  });
}

export async function postViaAuth<R>(
  url: string,
  body: any,
  fromForm: boolean = false,
  config?: any,
): Promise<Response<R | null>> {
  let status: number;
  let message: string;
  const header =
    fromForm === true
      ? await generateHeader({})
      : await generateHeader({ 'Content-Type': 'application/json' });

  return new Promise(async (resolve, reject) => {
    fetch(SERVER + url, {
      method: 'POST',
      headers: header,
      body: fromForm === true ? body : JSON.stringify(body),
      ...config,
    })
      .then((response: any) => {
        status = response.status;
        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.status = status;

        if (status === 401) window.location.replace('/login/');
        else if (status === 403) {
          message = 'دسترسی غیر مجاز .';
          resolve({ value: null, message, status });
        }

        handleErrors(data);

        resolve(data);
      })
      .catch((err) => {
        message = ' خطا در برقراری ارتباط با سرور';
        resolve({ value: null, message, status });
      });
  });
}

export const uploadViaAuth = async (
  url: string,
  body: FormData,
  setPercent: React.Dispatch<React.SetStateAction<number>>,
  setUploadCancelSource: React.Dispatch<
    React.SetStateAction<AbortController | null>
  >,
): Promise<any> => {
  const uploadController = new AbortController();
  setUploadCancelSource(uploadController);

  const xhr = new XMLHttpRequest();
  const header = await generateHeader({});
  const signal = uploadController.signal;

  return new Promise((resolve, reject) => {
    xhr.open('POST', SERVER + url, true);
    xhr.setRequestHeader('Authorization', header.Authorization);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setPercent(percentCompleted);
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const status = xhr.status;
        const data = JSON.parse(xhr.responseText);

        if (status === 401) window.location.replace('/login/');
        else if (status === 403) {
          const message = 'دسترسی غیر مجاز.';
          reject({ value: null, message, status });
        }

        handleErrors(data);

        resolve(data);
      }
    };

    xhr.onerror = () => {
      const message = ' خطا در برقراری ارتباط با سرور';
      reject({ value: null, message, status: 500 });
    };

    const formData = new FormData();
    body.forEach((value, key) => {
      formData.append(key, value);
    });

    xhr.send(formData);

    signal.addEventListener('abort', () => {
      xhr.abort();
      reject({ value: null, message: 'Request aborted', status: 0 });
    });

    xhr.onerror = (error) => {
      if (xhr.status !== 0) {
        const message = ' خطا در برقراری ارتباط با سرور';
        reject({ value: null, message, status: 500 });
      }
    };
  });
};

export const uploadMultipleViaAuth = async (
  url: string,
  files: any,
  setPercent: React.Dispatch<React.SetStateAction<number>>,
  setUploadCancelSource: React.Dispatch<
    React.SetStateAction<AbortController | null>
  >,
): Promise<any> => {
  const uploadController = new AbortController();
  setUploadCancelSource(uploadController);

  const xhr = new XMLHttpRequest();
  const header = await generateHeader({});
  const signal = uploadController.signal;

  return new Promise((resolve, reject) => {
    xhr.open('POST', SERVER + url, true);
    xhr.setRequestHeader('Authorization', header.Authorization);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setPercent(percentCompleted);
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const status = xhr.status;
        const data = JSON.parse(xhr.responseText);

        if (status === 401) window.location.replace('/login/');
        else if (status === 403) {
          const message = 'دسترسی غیر مجاز.';
          reject({ value: null, message, status });
        }

        handleErrors(data);

        resolve(data);
      }
    };

    xhr.onerror = () => {
      const message = ' خطا در برقراری ارتباط با سرور';
      reject({ value: null, message, status: 500 });
    };

    xhr.send(files);

    signal.addEventListener('abort', () => {
      xhr.abort();
      reject({ value: null, message: 'Request aborted', status: 0 });
    });

    xhr.onerror = (error) => {
      if (xhr.status !== 0) {
        const message = ' خطا در برقراری ارتباط با سرور';
        reject({ value: null, message, status: 500 });
      }
    };
  });
};

export async function postGeneric<R>(
  url: string,
  body: any,
  fromForm: boolean = false,
): Promise<Response<R | null>> {
  let status: number;
  let message: string;

  return new Promise(async (resolve, reject) => {
    fetch(SERVER + url, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: fromForm === true ? body : JSON.stringify(body),
    })
      .then((response: any) => {
        status = response.status;
        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.status = status;

        if (status === 401) window.location.replace('/login/');
        else if (status === 403) {
          message = 'دسترسی غیر مجاز .';
          resolve({ value: null, message, status });
        }

        handleErrors(data);

        resolve(data);
      })
      .catch((err) => {
        message = ' خطا در برقراری ارتباط با سرور';
        resolve({ value: null, message, status });
      });
  });
}

function handleErrors(data: any) {
  if (!responseValidator(data.status) && !data.isSuccess) {
    let message = data.message;
    if (hasValidationError(data.status) && data.errors != null) {
      var validationError = false;
      getValidationErrors(data.errors).map((error) => {
        messageToast.error(error);
        validationError = true;
      });
      if (!validationError) messageToast.error(message);
    } else messageToast.error(message);
  }
}

export function responseValidator(status: number) {
  return status >= 200 && status < 300;
}

export function hasValidationError(status: number) {
  return status == 400;
}

export function getValidationErrors(errors: any): any[] {
  let list = [];

  for (let key in errors) {
    let value = errors[key];
    list.push(value);
  }

  return list;
}

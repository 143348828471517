import { Spin } from 'antd';
import { Button } from 'components';
import { Asset } from 'core/domains/markets/asset';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowTop } from 'assets/icons/main/arrow-top.svg';
import { ReactComponent as ArrowBottom } from 'assets/icons/main/arrow-bottom.svg';
import { ToIranCurrency } from 'utils/scripts/decimal';
import './balance-card.scss';

type Props = {
  loading: boolean;
  balance?: Asset;
};

export const BalanceCard: React.FC<Props> = ({ loading, balance }) => {
  const navigate = useNavigate();
  return (
    <Spin style={{ width: '100%' }} spinning={loading}>
      <div className="xpay-balance">
        <div className="balance-card">
          <div className="balance-card-overlay"></div>
          <div className="balance-card-header">
            <h4>موجودی</h4>
            <div className="more">
              <span>سایر ارزها </span>
              <img src="assets/icons/ArrowLeft.svg" />
            </div>
          </div>
          <div className="balance-card-main">
            <h3>{ToIranCurrency(balance?.irtBalance.toFixed(0))} تومان</h3>
            <span>( ${balance?.usdtBalance.toFixed(2)} )</span>
          </div>
          <div className="balance-card-footer">
            <div className="export">
              <img src="/assets/images/balance-card/export.svg"></img>
            </div>
            {/* <span>15.27% </span> */}
            <span>30 روز گذشته</span>
          </div>
        </div>
        <div className="balance-buttons">
          <Button
            className="xpay-deposit-button"
            type="info"
            text="واریز تومانی"
            onClick={() => navigate('/dashboard/wallet/deposit/irt')}
            icon={<ArrowTop />}
          />
          <Button
            className="xpay-withdrawal-button"
            type="info"
            text="برداشت تومانی"
            icon={<ArrowBottom />}
            onClick={() => navigate('/dashboard/wallet/widthrawl/irt')}
          />
        </div>
      </div>
    </Spin>
  );
};

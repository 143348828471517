import * as React from "react";
import "./price-box.scss";
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { ToIranCurrency } from "utils/scripts/decimal";
import { Divider } from "antd";
import { getIconPath } from "utils/scripts/getIconPath";
type Props = {
    name: string
    symbol: string
    buyPrice: number
    sellPrice: number
};

export const PriceBox: React.FC<Props> = (props) => {
    return (
        <div className={`xpay-price-box`}>
            <div className="box-body">
                <div className="right-side">
                    <img src={getIconPath(props.symbol)} />
                    <div className="name">
                        <span>{props.name}</span>
                        <span>{props.symbol}</span>
                    </div>
                </div>

            </div>
            <Divider />

            <div className="box-footer">
                <div className="right-side">
                    <span>قیمت خرید</span>
                    <span>{ToIranCurrency(props.buyPrice)} تومان</span>
                </div>
                <div className="left-side">
                    <span>قیمت فروش</span>
                    <span>{ToIranCurrency(props.sellPrice)} تومان</span>
                </div>
            </div>
        </div>
    );
};

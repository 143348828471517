import { EditOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Divider, Spin, Switch, Tooltip } from 'antd';
import Progress from 'antd/es/progress';
import { API } from 'apis';
import { Button, Card } from 'components';
import { Modal } from 'components/modal/modal';
import { message } from 'components/toast/toast';
import { ProfileType } from 'core/domains/profile/profile';
import { getMineProfileOtp } from 'core/repositories/profile/profile';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { ChangeEmailAddress } from './change-email';
import { ChangePassword } from './change-password/change-password';

import './profile.scss';
import { TwoStepVerify } from './two-step-verify/two-step-verify';
import { ChangePhone } from './change-phone/change-phone';

const slides = [
  {
    id: 1,
    title:
      'برای مشاهده دسترسی ها و محدودیت های هر سطح وارد پیج احراز هویت شوید',
    description: '',
    image: '/assets/images/slides/income-glass.svg',
    children: (
      <Link to={'/dashboard/authentication'}>
        <Button type="secondary" text="ورود به صفحه" />
      </Link>
    ),
  },
];

const { useState, useEffect } = React;

export const Profile: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalStage, setModalStage] = useState<
    'otp' | 'password' | 'email' | 'change-phone'
  >('otp');
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [twoFaStage, setTwoFaStage] = useState<
    'PHONE' | 'DOWNLOAD' | 'QRCODE' | 'CODE' | 'RECOVERY'
  >('PHONE');
  const [changePhoneStage, setChangePhoneStage] = useState<'PHONE' | 'CODE'>(
    'PHONE',
  );

  const [secutiryCardLoading, setSecutiryCardLoading] =
    useState<boolean>(false);
  const [profileData, setProfileData] = useState<ProfileType>();

  const [otpType, setOtpType] = useState<'sms' | 'google' | 'none'>('none');

  const [loading, setLoading] = useState<boolean>(false);
  const [switchLoading, setSwitchLoading] = useState<boolean>(false);

  const [selectedOtp, setSelectedOtp] = useState<'sms' | 'google'>();

  const handleOtpTypeChange = (type: 'sms' | 'google', checked: boolean) => {
    setSwitchLoading(true);
    setSelectedOtp(type);
    if (type === 'sms') {
      postViaAuth(API.profile.sendOtp, {}).then((response: any) => {
        setSecutiryCardLoading(false);
        setSwitchLoading(false);
        if (responseValidator(response.status)) {
          setModalStage('otp');
          setModalIsOpen(true);
          message.success('کد تایید ارسال شد');
        }
      });
    } else {
      if (checked) {
        setTwoFaStage('DOWNLOAD');
        setModalIsOpen(true);
        setSecutiryCardLoading(false);
        setSwitchLoading(false);
      } else {
        postViaAuth(API.profile.sendOtp, {}).then((response: any) => {
          setSecutiryCardLoading(false);
          setSwitchLoading(false);

          if (responseValidator(response.status)) {
            setTwoFaStage('CODE');
            setModalIsOpen(true);
          }
        });
      }
    }
  };
  useEffect(() => {
    if (otpType === 'sms') {
    }
  }, [otpType]);

  const changeEmailModalHandle = () => {
    setModalStage('email');
    setModalIsOpen(true);
  };

  const changePasswordModalHandle = () => {
    setModalIsOpen(true);
    setModalStage('password');
  };
  const changePhoneNumberModalHandle = () => {
    setModalIsOpen(true);
    setModalStage('change-phone');
  };

  useEffect(() => {
    getMineProfileOtp(setLoading, setProfileData, setOtpType);
  }, []);

  return (
    <>
      <Modal
        title={`${
          modalStage === 'otp'
            ? 'تایید صحت دو مرحله‌ای'
            : modalStage === 'password'
            ? 'رمز عبور جدید'
            : modalStage === 'email'
            ? 'ویرایش آدرس ایمیل'
            : 'ویرایش شماره موبایل'
        }`}
        closeAble
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
          setChangePhoneStage('PHONE');
          setTwoFaStage('PHONE');
        }}
      >
        {profileData && modalStage === 'otp' ? (
          <TwoStepVerify
            setTwoFaStage={setTwoFaStage}
            twoFaStage={twoFaStage}
            selectedOtp={selectedOtp}
            state={profileData.twoFactor}
            phone={profileData.phoneNumber}
            setIsOpen={setModalIsOpen}
            getdata={() =>
              getMineProfileOtp(setLoading, setProfileData, setOtpType)
            }
          />
        ) : modalStage === 'password' ? (
          <ChangePassword
            phone={profileData?.phoneNumber}
            setIsOpen={setModalIsOpen}
          />
        ) : modalStage === 'email' ? (
          <ChangeEmailAddress
            phone={profileData?.phoneNumber}
            setIsOpen={setModalIsOpen}
            setProfileData={setProfileData}
            profileData={profileData}
          />
        ) : (
          <ChangePhone
            setModalStage={setChangePhoneStage}
            modalStage={changePhoneStage}
            phone={profileData?.phoneNumber}
            setIsOpen={setModalIsOpen}
            setProfileData={setProfileData}
            profileData={profileData}
          />
        )}
      </Modal>

      <Spin spinning={loading}>
        <div className="xpay-dashboard-profile">
          <Card blur={secutiryCardLoading} className="security-row">
            <div className="security">
              <h4>امنیت</h4>
              <div className="content">
                <div className={'row'}>
                  <p>رمز عبور</p>

                  <div className="password">
                    <Button
                      type="outlined"
                      text="ویرایش"
                      onClick={() => changePasswordModalHandle()}
                      disabled={modalLoading}
                      loading={modalLoading}
                    />
                  </div>
                </div>
                <div className={'row'}>
                  <p>شماره موبایل</p>

                  <div className="change-phone">
                    <Button
                      type="outlined"
                      text="تغییر شماره"
                      onClick={() => changePhoneNumberModalHandle()}
                      disabled={modalLoading}
                      loading={modalLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="two-step">
              <h4>تایید دو مرحله‌ای</h4>
              <div className="content">
                <div className="static">
                  <div>
                    <p>پیامک</p>
                    <span>دریافت تأیید صحت دو مرحله‌ای از طریق پیامک</span>
                  </div>
                  <div>
                    <p>اپلیکیشن</p>
                    <span>
                      دریافت کد از طریق اپلیکیشن‌های مانند Google Authenticator
                    </span>
                  </div>
                </div>
                <div className="dynamic">
                  <Switch
                    loading={switchLoading}
                    disabled={switchLoading}
                    checked={otpType === 'sms' ? true : false}
                    onChange={(e) => handleOtpTypeChange('sms', e)}
                  />
                  <Switch
                    loading={switchLoading}
                    disabled={switchLoading}
                    checked={otpType === 'google' ? true : false}
                    onChange={(e) => handleOtpTypeChange('google', e)}
                  />
                </div>
              </div>
            </div>
          </Card>
          <div className="info-row">
            <Card className="user-info">
              {profileData && (
                <>
                  <div className="personal">
                    <h4>اطلاعات فردی</h4>
                    <div className="content">
                      <div className="static">
                        <p>نام و نام خانوادگی</p>
                        <p>کد ملی</p>
                        <p>تاریخ تولد</p>
                      </div>
                      <div className="dynamic">
                        <p>{profileData?.fullName}</p>
                        <p>{profileData?.nationalCode}</p>
                        <p>{profileData?.dateOfBirth.split(' ')[0]}</p>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className="contact">
                    <h4>اطلاعات تماس</h4>
                    <div className="content">
                      <div className="static">
                        <p>شماره تماس</p>
                        <p>ایمیل(اختیاری)</p>
                      </div>
                      <div className="dynamic">
                        <p>{profileData?.phoneNumber ?? '-'}</p>

                        <div className="content">
                          <p className="email">
                            {profileData?.emailAddress ?? '-'}
                          </p>
                          <Tooltip title="ویرایش آدرس ایمیل">
                            <EditOutlined
                              onClick={() => changeEmailModalHandle()}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Card>
            <Card className="user-level">
              {profileData && (
                <>
                  <div className="level">
                    <div>
                      <h4>سطح کاربری</h4>
                      <div className="status">
                        <span></span>
                        <p>احراز هویت شده</p>
                      </div>
                      <Link to={'/dashboard/authentication'}>
                        <Button text="ارتقاء سطح" type="info" />
                      </Link>
                    </div>
                    <div>
                      <Progress
                        success={{
                          strokeColor: '#41daa3',
                        }}
                        type="circle"
                        percent={
                          profileData &&
                          (profileData.level?.uniqueName?.id - 1) * (100 / 4)
                        }
                        strokeWidth={10}
                        width={170}
                        strokeColor={{
                          '-25.7%': `${
                            profileData.level.uniqueName.id == 5
                              ? '#41daa3'
                              : '#4f46e0'
                          }`,
                          '112.1%': `${
                            profileData.level.uniqueName.id == 5
                              ? '#2ac769'
                              : '#678AF5'
                          }`,
                        }}
                        format={(precent) => (
                          <div className="percent-info">
                            <span>{precent}%</span>
                            <span
                              className={`${
                                profileData.level.uniqueName.id == 5
                                  ? 'success'
                                  : ''
                              }`}
                            >
                              {profileData?.level.title.split(' ')[0] +
                                ' ' +
                                `${
                                  profileData.level?.uniqueName?.id - 1 == 4
                                    ? 'چهار'
                                    : profileData.level?.uniqueName?.id - 1 == 3
                                    ? 'سه'
                                    : profileData.level?.uniqueName?.id - 1 == 2
                                    ? 'دو'
                                    : profileData.level?.uniqueName?.id - 1 == 1
                                    ? 'یک'
                                    : profileData.level?.uniqueName?.id - 1 == 0
                                    ? 'پایه'
                                    : ''
                                }`}
                            </span>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="slider">
                    <div className="xpay-slider">
                      <Swiper
                        modules={[Navigation, Autoplay]}
                        navigation
                        loop
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay
                      >
                        {slides.map((slide, index) => {
                          return (
                            <SwiperSlide>
                              <div className="slide-overlay"></div>
                              <div className="xpay-slide">
                                <div className="right-side">
                                  <h2>{slide.title}</h2>
                                  <p>
                                    {slide.description && slide.description}
                                  </p>
                                  {slide.children}
                                </div>
                                <img
                                  className={`shape ${
                                    index === 1 ? 'machine' : ''
                                  }`}
                                  src={slide.image}
                                  alt=""
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </>
              )}
            </Card>
          </div>

          {/* <Card className="sessions-row">
          <h4>دستگاه های متصل</h4>

          <div className="desktop-show">
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              style={{ borderRadius: '10px' }}
              pagination={false}
            />
          </div>
          <ul className="mobile-show">
            {dataSource.map((device) => (
              <li>
                <div className="row">
                  <div className="right">
                    <span>{device.model}</span>
                  </div>
                  <div className="left">
                    <p>خروج</p>
                    <DeleteOutlined />
                  </div>
                </div>
                <div className="row">
                  <div className="right">
                    <p>آخرین زمان اتصال: </p>
                    <span>{device.lastTouch}</span>
                  </div>
                  <div className="left">
                    <span>{device.ip}</span>
                    <p>:IP</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Card> */}
        </div>
      </Spin>
    </>
  );
};

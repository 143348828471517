import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Input } from 'components';
import { Badge } from 'components/badge/badge';
import { Chart } from 'components/chart/chart';
import { Table } from 'components/table/table';
import { PriceTableMarket } from 'core/domains';
import * as React from 'react';
import { useState } from 'react';
import { toFixed, ToIranCurrency } from 'utils/scripts/decimal';
import './markets.scss';
import { getViaAuth, responseValidator } from '../../../utils/scripts/api';
import { API } from '../../../apis';
import { getIconPath } from 'utils/scripts/getIconPath';
import { useNavigate } from 'react-router-dom';

export const Markets: React.FC = () => {
  const [filterText, setFilterText] = React.useState('');
  const [markets, setMarkets] = React.useState<PriceTableMarket[]>([]);

  const [search, setSearch] = useState<string>('');
  const [results, setResults] = useState<PriceTableMarket[]>(markets);
  const [cardIsLoading, setCardIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const GetPrices = () => {
    setCardIsLoading(true);
    getViaAuth<PriceTableMarket[]>(API.markets.priceList + '/1/999').then(
      (response: any) => {
        setCardIsLoading(false);
        if (responseValidator(response.status)) {
          setMarkets(response.value);
        }
      },
    );
  };
  React.useEffect(() => {
    GetPrices();
  }, []);

  const onSearchChangeHandler = (value: string) => {
    if (value !== '') {
      setResults(
        results.filter(
          (s) =>
            s.symbol.toLowerCase().includes(value.trim()) ||
            s.name.includes(value),
        ),
      );
    } else {
      setResults(markets);
    }
    setSearch(value);
  };

  const handleClear = () => {
    if (filterText) {
      setFilterText('');
    }
  };

  const DropdownFilter = ({ filterText, onFilter, onClear }: any) => (
    <Dropdown
      dropDownButtonIcon={<DownOutlined />}
      items={[
        {
          id: 1,
          title: 'داغ ترین ها',
          icon: (
            <svg
              width="15"
              height="12"
              viewBox="0 0 15 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0477 7.43821C13.3302 7.43821 14.3689 8.45947 14.3689 9.71947C14.3689 10.9787 13.3302 12 12.0477 12C10.7659 12 9.72642 10.9787 9.72642 9.71947C9.72642 8.45947 10.7659 7.43821 12.0477 7.43821ZM6.32512 8.74662C6.88686 8.74662 7.34286 9.19462 7.34286 9.74651C7.34286 10.2977 6.88686 10.7464 6.32512 10.7464H1.88664C1.32489 10.7464 0.868896 10.2977 0.868896 9.74651C0.868896 9.19462 1.32489 8.74662 1.88664 8.74662H6.32512ZM3.19013 0C4.47263 0 5.51137 1.02126 5.51137 2.28053C5.51137 3.54053 4.47263 4.56179 3.19013 4.56179C1.90839 4.56179 0.868896 3.54053 0.868896 2.28053C0.868896 1.02126 1.90839 0 3.19013 0ZM13.3519 1.28137C13.9129 1.28137 14.3689 1.72937 14.3689 2.28053C14.3689 2.83242 13.9129 3.28042 13.3519 3.28042H8.91343C8.35168 3.28042 7.89568 2.83242 7.89568 2.28053C7.89568 1.72937 8.35168 1.28137 8.91343 1.28137H13.3519Z"
                fill="url(#paint0_linear_1663_7385)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1663_7385"
                  x1="-2.93093"
                  y1="6.72944e-07"
                  x2="17.21"
                  y2="2.15319"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#3F62FE" />
                  <stop offset="1" stop-color="#678AF5" />
                </linearGradient>
              </defs>
            </svg>
          ),
        },
        {
          id: 2,
          title: 'جدید ترین ها',
          icon: (
            <svg
              width="15"
              height="12"
              viewBox="0 0 15 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0477 7.43821C13.3302 7.43821 14.3689 8.45947 14.3689 9.71947C14.3689 10.9787 13.3302 12 12.0477 12C10.7659 12 9.72642 10.9787 9.72642 9.71947C9.72642 8.45947 10.7659 7.43821 12.0477 7.43821ZM6.32512 8.74662C6.88686 8.74662 7.34286 9.19462 7.34286 9.74651C7.34286 10.2977 6.88686 10.7464 6.32512 10.7464H1.88664C1.32489 10.7464 0.868896 10.2977 0.868896 9.74651C0.868896 9.19462 1.32489 8.74662 1.88664 8.74662H6.32512ZM3.19013 0C4.47263 0 5.51137 1.02126 5.51137 2.28053C5.51137 3.54053 4.47263 4.56179 3.19013 4.56179C1.90839 4.56179 0.868896 3.54053 0.868896 2.28053C0.868896 1.02126 1.90839 0 3.19013 0ZM13.3519 1.28137C13.9129 1.28137 14.3689 1.72937 14.3689 2.28053C14.3689 2.83242 13.9129 3.28042 13.3519 3.28042H8.91343C8.35168 3.28042 7.89568 2.83242 7.89568 2.28053C7.89568 1.72937 8.35168 1.28137 8.91343 1.28137H13.3519Z"
                fill="url(#paint0_linear_1663_7385)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1663_7385"
                  x1="-2.93093"
                  y1="6.72944e-07"
                  x2="17.21"
                  y2="2.15319"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#3F62FE" />
                  <stop offset="1" stop-color="#678AF5" />
                </linearGradient>
              </defs>
            </svg>
          ),
        },
      ]}
    />
  );

  const columns: any[] = [
    {
      name: 'نوع رمزارز',
      id: 'name',
      sortable: true,
      cell: (row: PriceTableMarket) => (
        <span className="d-flex">
          <img src={getIconPath(row.symbol)} alt="" />
          <p className="market">
            {row.name}
            <span className="symbol">{row.symbol}</span>
          </p>
        </span>
      ),
    },
    {
      name: 'قیمت دلاری',
      id: 'last_usdt_price',
      sortable: true,
      selector: (row: PriceTableMarket) =>
        row.weeklyPriceLog
          ? toFixed(row.weeklyPriceLog[row.weeklyPriceLog.length - 1])
          : 1,
    },
    {
      name: 'قیمت فروش به ما',
      id: 'buyPrice',
      hide: 720,

      selector: (row: PriceTableMarket) => row.buyPrice,
      cell: (row: PriceTableMarket) => ToIranCurrency(row.buyPrice),
    },
    {
      name: 'قیمت خرید از ما',
      id: 'sellPrice',
      hide: 'md',

      selector: (row: PriceTableMarket) => row.sellPrice,
      cell: (row: PriceTableMarket) => ToIranCurrency(row.sellPrice),
    },
    {
      name: 'تغییرات امروز',
      id: 'change24h',
      hide: 'md',
      selector: (row: PriceTableMarket) => row.changeOf24HourPercent,
      cell: (row: PriceTableMarket) =>
        row.weeklyPriceLog ? (
          <Badge
            text={`${row.changeOf24HourPercent.toFixed(2)}٪`}
            type={row.changeOf24HourPercent > 0.0 ? 'success' : 'error'}
          />
        ) : (
          '-'
        ),
    },
    {
      name: 'نمودار امروز',
      id: 'chart',
      hide: 'md',
      cell: (row: PriceTableMarket) =>
        row.weeklyPriceLog ? (
          <Chart data={row.weeklyPriceLog} />
        ) : (
          <span>-</span>
        ),
    },
    {
      name: 'اقدامات',
      id: 'segmented',
      cell: (row: PriceTableMarket) => (
        <Button
          text="خرید و فروش"
          type="outlined"
          onClick={() =>
            navigate(
              `/dashboard/orders/create-order?side=buy&symbol=${row.symbol.toLowerCase()}`,
            )
          }
        />
      ),
    },
  ];

  return (
    <Card blur={cardIsLoading} className="xpay-markets-dashboard">
      <div className="desktop-show">
        {markets && markets.length > 0 && (
          <Table
            data={
              search === ''
                ? markets.filter((q) => q.symbol !== 'UUSD')
                : results.filter((q) => q.symbol !== 'UUSD')
            }
            className="price-chart-table"
            column={columns}
            hasSearchbar
            searchFilterItems={['symbol', 'name']}
          />
        )}
      </div>
      <div className="mobile-show">
        {/* <DropdownFilter /> */}
        <Input
          type="text"
          placeholder="جستجو..."
          value={search}
          name="search"
          onChange={(e) => setSearch(e.target.value)}
          adorments={{
            startAdornment: {
              adornmentIcon: <SearchOutlined />,
            },
            endAdornment: {
              adornmentIcon: <DownOutlined />,
            },
          }}
        />
        {markets.length > 0 &&
          (search === '' ? markets : results).map((item) => (
            <Card blur={cardIsLoading} className="coin-card">
              <div>
                <div className="right">
                  <img src={getIconPath(item.symbol)} alt="" />
                  <p>{item.name}</p>
                  <span>{item.symbol}</span>
                </div>
                <div className="left">
                  <p>
                    {item.weeklyPriceLog
                      ? item.weeklyPriceLog[item.weeklyPriceLog.length - 1]
                      : '-'}
                    $
                  </p>
                </div>
              </div>
              <div>
                <p className="right">قیمت فروش:</p>
                <p className="left">{ToIranCurrency(item.buyPrice)} تومان</p>
              </div>
              <div>
                <p className="right">قیمت خرید:</p>
                <p className="left">{ToIranCurrency(item.sellPrice)} تومان</p>
              </div>
              <div>
                <div className="right">
                  <p>
                    تغییرات<span>(24h)</span>:
                  </p>
                  <span
                    className={`${
                      item.changeOf24HourPercent > 0 ? 'success' : 'danger'
                    }`}
                  >
                    {item.changeOf24HourPercent.toFixed(1)}٪
                  </span>
                </div>
                <div className="left">
                  {item.weeklyPriceLog ? (
                    <Chart data={item.weeklyPriceLog} />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <Button
                onClick={() =>
                  navigate(
                    `/dashboard/orders/create-order?side=buy&symbol=${item.symbol.toLowerCase()}`,
                  )
                }
                text="خرید و فروش"
                type="info"
              />
            </Card>
          ))}
      </div>
    </Card>
  );
};

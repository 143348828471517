import { APPNAME } from '../../../../../apis/constants';

export const CryptoHelperText = () => {
  return (
    <>
      <li>
        <p>
          <span className="number">1</span>
          کارمزد پرداختی از سوی شما برای تراکنش های رمز ارز ها کارمزد شبکه ارز
          مدنظر میباشد و {APPNAME} از این کارمزد نفعی ندارد
        </p>
      </li>
      <li>
        <p>
          <span className="number">2</span>
          از آنجایی که تراکنش های رمز ارز ها غیر قابل بازگشت است، چنانچه آدرس
          عمومی شخص دریافت کننده رمز ارز را به اشتباه اعلام کنید، تراکنش ارسال
          شده قابل بازگشت نخواهد بود.
        </p>
      </li>
      <li>
        <p>
          <span className="number">3</span>
          اخیرا ویروس و تروجان تغییر دهنده آدرس در زمانی کپی کردن آن گسترش یافته
          است.حتما در پس از کپی ادرس خود و اعمال آن در کادر کیف ارز مد نظر دقت
          فرمائید و آن را با ادرس خود مقایسه نمائید.
        </p>
      </li>
      <li>
        <p>
          <span className="number">4</span>
          با توجه به غیر قابل برگشت بودن تراکنش رمز ارز ها امکان لغو تراکنش یا
          بازگشت دادن وجه پس از انجام امکان پذیر نیست.
        </p>
      </li>
      <li>
        <p>
          <span className="number">5</span>
          به این دلیل که ارز های رمزنگاری شده تحت تاثیر برخی از عوامل ممکن است
          صعود یا سقوط ارزش را به صورت ناگهانی تجربه کنند، {APPNAME}
          همانگونه که منفعتی در قبال افزایش ارزش آن نمی برد، مسئولیتی هم در قبال
          سقوط قیمت آن نخواهد داشت.
        </p>
      </li>
      <li>
        <p>
          <span className="number">6</span>
          خرید رمز ارز ها فقط از طریق کیف پول انجام میشود و خرید از درگاه برای
          رمز ارز ها امکان پذیر نیست و بایستی ابتدا از طریق درگاه کیف پول خود را
          شارژ کنید.
        </p>
      </li>
      <li>
        <p>
          <span className="number">7</span>
          پیش از ثبت درخواست و پرداخت وجه، از میزان ارز وارد شده اطمینان حاصل
          نمایید.
        </p>
      </li>
    </>
  );
};

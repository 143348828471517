import * as React from 'react';
import './../credit-card/credit-card.scss';
import {CheckCircleOutlined, CloseCircleOutlined, WarningOutlined,} from '@ant-design/icons';
import {Cart} from 'core/domains';

type Props = {
    cart: Cart;
};

const {useState} = React;

export const CreditCard: React.FC<Props> = ({cart}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            {/* <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="confirm-box">
          <div className="head">
            <h4>آیا در حذف کردن کارت مطمئنید؟</h4>
          </div>
          <div className="text">
            <p>
              در صورت حذف شدن کارت، برای اضافه کردن مجدد کارت باید دوباره روند
              تایید شدن کارت طی شود.
            </p>
          </div>
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              onClick={() => handleModalSubmit()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
      </Modal> */}
            <div className={`xpay-credit-card`} style={{background: '#22bdff'}}>
                <div
                    className={`card-state-badge ${cart.state.id === 1
                        ? 'success'
                        : cart.state.id === 2
                            ? 'warning'
                            : 'danger'
                    }`}
                >
                    {cart.state.id === 1 ? (
                        <CheckCircleOutlined/>
                    ) : cart.state.id === 2 ? (
                        <WarningOutlined/>
                    ) : (
                        <CloseCircleOutlined/>
                    )}

                    <span>{cart.state.title}</span>
                </div>
                <div className="bg-overlay"></div>
                <div className="card-bank">
                    <h4>{cart.bank?.name}</h4>
                </div>
                <div className="card-number">
                    <span>{cart.cardNumber}</span>
                </div>
                <span className="card-shaba">{cart.iBan.startsWith('IR') ? cart.iBan : 'IR' + cart.iBan}</span>
            </div>
        </>
    );
};

import * as React from 'react';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Divider, Dropdown, MenuProps, Spin } from 'antd';
import { Button, Calendar, Input } from 'components';
import { Badge } from 'components/badge/badge';
import { Table } from 'components/table/table';
import moment from 'jalali-moment';
import './my-trades.scss';
import { Enum, Order, PaginationList } from 'core/domains';
import { digitSperator } from '../../../../../utils/scripts/exchange';
import { OrderDetailModal } from '../detail-modal/orders/modal';
import { getMineOrders } from 'core/repositories/orders/order';
import { OrderFilterValues } from 'core/domains/orders/order';
import { getIconPath } from 'utils/scripts/getIconPath';
import { ToItemTypes } from 'utils/scripts/table';
import { Pagination } from 'components/pagination/pagination';
import { getPageNum } from '../../../../../utils/scripts/paggingHelper';
import { TransactionOrderDetailModal } from '../../../../../components/transaction-order-modal/transaction-order-modal';

const { useState } = React;

const empty_filters = {
  sort: {
    id: 2,
    title: 'جدیدترین',
  },
  side: {
    id: 0,
    title: 'همه',
  },
};

export const MyTrades: React.FC = () => {
  const [orders, setOrders] = useState<PaginationList<Order>>();

  const [cardLoading, setCardloading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [filters, setFilters] = useState<OrderFilterValues>(empty_filters);
  const [query, setQuery] = useState<string>('');

  const [selectedItem, setSelectedItem] = useState<Order | null>();

  const [loading, setLoading] = useState<boolean>(false);

  const filterHandle = (filters: any) => {
    var q = '';

    if (filters) {
      if (filters.side?.id !== 0)
        q += `${q.includes('?') ? '&' : '?'}side=${filters.side?.id}`;
      if (filters.startDate)
        q += `${q.includes('?') ? '&' : '?'}startDate=${filters.startDate}`;
      if (filters.endDate)
        q += `${q.includes('?') ? '&' : '?'}endDate=${filters.endDate}`;
      if (filters.sort?.id)
        q += `${q.includes('?') ? '&' : '?'}sort=${filters.sort?.id}`;
      if (filters.keywords)
        q += `${q.includes('?') ? '&' : '?'}keywords=${filters.keywords}`;
    }
    setQuery(q);
    getMineOrders(
      setCardloading,
      setOrders,
      {
        pageNumber: getPageNum(),
        pageSize: 10,
      },
      q,
    );
  };

  const clickOnSide: MenuProps['onClick'] = ({ key }) => {
    setFilters({
      ...filters,
      side: sides.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const clickOnSort: MenuProps['onClick'] = ({ key }) => {
    setFilters({
      ...filters,
      sort: sorts.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const sides: Enum[] = [
    {
      id: 0,
      title: 'همه',
    },
    {
      id: 1,
      title: 'خرید',
    },
    {
      id: 2,
      title: 'فروش',
    },
    {
      id: 3,
      title: 'مبادله',
    },
  ];

  const sorts: Enum[] = [
    {
      id: 1,
      title: 'قدیمی ترین',
    },
    {
      id: 2,
      title: 'جدیدترین',
    },
  ];

  const columns: any[] = [
    {
      name: '#',
      id: 'index',
      selector: (row: Order, rowIndex: number) => rowIndex + 1,
      width: '50px',
    },
    {
      name: 'کد پیگیری',
      id: 'trackingCode',
      selector: (row: Order) => row?.trackingCode,
      cell: (row: Order) => <span className="en">{row.trackingCode}</span>,
    },
    {
      name: 'جفت ارز',
      id: 'pair',
      selector: (row: Order) => row.pair.quote + '/' + row.pair.base,
      cell: (row: Order) => (
        <>
          <img
            style={{ marginLeft: '4px', width: '26px', height: '26px' }}
            src={getIconPath(row.pair.base)}
          />
          <span className="en">{row.pair.base.toUpperCase()}</span>
        </>
      ),
    },
    {
      name: 'سمت',
      id: 'side',
      selector: (row: Order) => row.side.title,
      cell: (row: Order) => (
        <>
          <Badge
            type={row.side.id === 1 ? 'success' : 'error'}
            text={row.side.title}
          />
        </>
      ),
    },
    {
      name: 'مقدار مبدا',
      id: 'sourceQuantity',
      selector: (row: Order) =>
        row.side.id === 1
          ? digitSperator(row.sourceQuantity) + ' تومان'
          : row.sourceQuantity + ' ' + ' واحد',
    },
    {
      name: 'مقدار مقصد',
      id: 'destinationQuantity',
      selector: (row: Order) => digitSperator(row.destinationQuantity),
    },
    {
      name: 'زمان',
      id: 'createdOn',
      selector: (row: Order) => row.createdOn,
      cell: (row: Order) => (
        <div className="column-date">
          <span className="day">
            {moment(row.createdOn).locale('fa').local().format('jYYYY/MM/DD')}
          </span>
          <span className="time">
            {moment(row.createdOn).local().format('HH:mm')}
          </span>
        </div>
      ),
    },
    {
      name: 'وضعیت',
      id: 'state',
      selector: (row: Order) => row.state.id,
      cell: (row: Order) => (
        <Badge
          text={
            row.state.id == 3 || row.state.id == 2 ? 'موفق' : row.state.title
          }
          type={
            row.state.id == 3 || row.state.id == 2
              ? 'success'
              : row.state.id == 1
              ? 'waiting'
              : 'error'
          }
          icon={`/assets/icons/table/${
            row.state.id == 3 || row.state.id == 2
              ? 'success'
              : row.state.id == 1
              ? 'waiting'
              : 'danger'
          }.svg`}
        />
      ),
    },
    {
      name: 'جزئیات',
      id: 'detail',
      cell: (row: Order) => (
        <InfoCircleOutlined onClick={() => handleModal(row)} />
      ),
    },
  ];

  const handleModal = (item: Order) => {
    setSelectedItem(item);
  };

  React.useEffect(() => {
    selectedItem && setIsOpen(!isOpen);
  }, [selectedItem]);

  React.useEffect(() => {
    !isOpen && setSelectedItem(null);
  }, [isOpen]);

  React.useEffect(() => {
    filterHandle(filters);
  }, [filters]);

  return (
    <div className="orders-trade">
      {selectedItem && (
        <TransactionOrderDetailModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          orderItem={selectedItem}
        />
      )}

      <Spin spinning={cardLoading}>
        <div className="desktop-show">
          <div className="filter-row">
            <div className="fields">
              <Input
                type="text"
                className="search"
                value={filters?.keywords}
                placeholder="جستجو کنید ..."
                name="search"
                onChange={(e) =>
                  setFilters({ ...filters, keywords: e.target.value })
                }
              />

              <Dropdown
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(sorts), onClick: clickOnSort }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.sort?.title}
                />
              </Dropdown>

              <Dropdown
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(sides), onClick: clickOnSide }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.side?.title}
                />
              </Dropdown>

              <Calendar
                label="از تاریخ"
                placeholder="از تاریخ"
                name="startDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, startDate: value }))
                }
              />
              <Calendar
                label="تا تاریخ"
                placeholder="تا تاریخ"
                name="endDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, endDate: value }))
                }
              />
            </div>
            <Divider type="vertical" />
            <div className="buttons">
              <span onClick={() => setFilters(empty_filters)}>حذف فیلتر</span>
            </div>
          </div>
          {orders && (
            <Table
              setData={setOrders}
              pagination={orders.pagination}
              hasFiltering={true}
              blur={cardLoading}
              data={orders.items}
              column={columns}
              getData={getMineOrders}
              setTableLoading={setCardloading}
              filters={filters}
              query={query}
            />
          )}
        </div>

        <ul className="mobile-show">
          <div className="filter-row">
            <h4>خرید و فروش رمز ارز</h4>

            <div className="fields">
              <Dropdown
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(sorts), onClick: clickOnSort }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.sort?.title}
                />
              </Dropdown>

              <Dropdown
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(sides), onClick: clickOnSide }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.side?.title}
                />
              </Dropdown>

              <Calendar
                label="از تاریخ"
                placeholder="از تاریخ"
                name="fromDate"
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, fromDate: value }))
                }
              />
              <Calendar
                label="تا تاریخ"
                placeholder="تا تاریخ"
                name="toDate"
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, toDate: value }))
                }
              />
            </div>

            <div className="buttons">
              <Button
                type="info"
                text="حذف فیلتر"
                onClick={() => {
                  setFilters(empty_filters);
                }}
              />
            </div>
          </div>
          {orders &&
            orders.items &&
            orders.items?.map((item) => {
              return (
                <li>
                  <div>
                    <div className="right">
                      <img src={getIconPath(item.pair.base)} alt="" />
                      <p className="en">
                        {item.pair.base} / {item.pair.quote}
                      </p>
                    </div>
                    <div>
                      <p> نوع سفارش : {item?.side.title}</p>
                    </div>
                  </div>
                  <div>
                    <div className="right">
                      <p>مقدار مبدا: </p>
                      {item.side.id == 1 ? (
                        <>
                          {' '}
                          <span>{digitSperator(item.sourceQuantity)}</span>
                          <p>تومان</p>
                        </>
                      ) : (
                        <>
                          <span>{item.sourceQuantity}</span>
                          <p>واحد</p>
                        </>
                      )}
                    </div>
                    <div className="left">
                      <p>مقدار مقصد: </p>
                      {item.side.id == 2 ? (
                        <>
                          {' '}
                          <span>{digitSperator(item.destinationQuantity)}</span>
                          <p>تومان</p>
                        </>
                      ) : (
                        <>
                          <span>{item.destinationQuantity}</span>
                          <p>واحد</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="right">
                      <p>
                        {moment(item.createdOn)
                          .locale('fa')
                          .local()
                          .format('YYYY/MM/DD')}{' '}
                        | {moment(item.createdOn).local().format('HH:mm')}
                      </p>
                    </div>
                    <div className="left">
                      <Badge
                        text={item.state.title}
                        type={
                          item.state.id == 2 || item.state.id == 3
                            ? 'success'
                            : item.state.id == 1
                            ? 'error'
                            : 'waiting'
                        }
                      />
                    </div>
                  </div>
                  <Button
                    type="outlined"
                    text="مشاهده جزئیات"
                    onClick={() => handleModal(item)}
                  />
                </li>
              );
            })}
        </ul>
        <div className="mobile-show">
          {orders && orders.items.length > 0 && (
            <Pagination
              page={orders.pagination.pageIndex || 0}
              data={orders.items || []}
              setdata={setOrders}
              setloader={setCardloading}
              getdata={getMineOrders}
              size={orders.pagination.pageSize || 10}
              totalRows={orders.pagination.totalCount}
              query=""
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

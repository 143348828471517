import { Col, Row } from 'antd';
import { HelperCard } from 'components/helper-card/helper-card';
import { HelperRow } from 'components/helper-row/helper-row';
import * as React from 'react';
import { useState } from 'react';
import { DepositCurrencyBox } from './currency/deposit-currency-box';
import { DepositCryptoBox } from './crypto/deposit-crypto-box';
import './deposit.scss';
import { HelperBox } from 'components/helper-box/helper-box';
import { BuyPerfectMoneyThankYouPage } from '../perfect-money/buy/thankyou/buy-pm-thankyou';
import { getAllDepositCryptoMarketNameIds } from 'core/repositories';
import { Market } from 'core/domains';
import { DepositIrtHelperText } from '../helper-text/deposit-irt';
import { DepositCryptoHelperText } from '../helper-text/deposit-crypto';
import { useLocation } from 'react-router-dom';
import { CashInHelperText } from '../helper-text/cash-in-help-text';

const Deposit: React.FC = (props) => {
  const [stagePM, setStagePM] = useState<any>({ stage: 'buy' });
  const [cardIsLoading, setCardIsLoading] = useState<boolean>(false);

  const [markets, setMarkets] = useState<Market[]>([]);
  const [marketName, setMarketName] = useState<string>();
  const [depositCurrencyType, setDepositCurrencyType] = useState<number>(1);
  const location = useLocation();

  React.useEffect(() => {
    markets.length === 0 &&
      getAllDepositCryptoMarketNameIds(setCardIsLoading, setMarkets);
  }, []);

  React.useEffect(() => {
    location.pathname.toUpperCase().endsWith('IRT')
      ? setMarketName('تومان')
      : markets.length !== 0 &&
        setMarketName(
          markets?.find(
            (e: Market) =>
              e.symbol.toUpperCase() ===
              location.pathname
                .split('/')
                [location.pathname.split('/').length - 1].toUpperCase(),
          )?.name,
        );
  }, [markets]);

  const getDepositBox = (symbol: string) => {
    switch (symbol) {
      case 'IRT':
        return (
          <DepositCurrencyBox
            depositType={depositCurrencyType}
            setDepositType={setDepositCurrencyType}
            setMarketName={setMarketName}
          />
        );
      default:
        return (
          <DepositCryptoBox markets={markets} setMarketName={setMarketName} />
        );
    }
  };
  return stagePM.stage === 'buy' ? (
    <>
      <div className="xpay-deposit-page">
        <Row>
          <Col className="right-side" md={16} sm={24} xs={24} lg={16}>
            {getDepositBox(window.location.href.split('/')[6].toUpperCase())}
          </Col>
          <Col className="left-side" md={8} sm={24} xs={24} lg={8}>
            <HelperCard
              className="desktop-show"
              title={`راهنمای واریز ${marketName || ''}`}
            >
              <ul>
                {marketName === 'تومان' ? (
                  depositCurrencyType === 2 ? (
                    <CashInHelperText />
                  ) : (
                    <DepositIrtHelperText />
                  )
                ) : (
                  <DepositCryptoHelperText />
                )}
              </ul>
            </HelperCard>
            <HelperBox title={`راهنمای واریز ${marketName || ''}`}>
              {marketName === 'تومان' ? (
                depositCurrencyType === 2 ? (
                  <CashInHelperText />
                ) : (
                  <DepositIrtHelperText />
                )
              ) : (
                <DepositCryptoHelperText />
              )}
            </HelperBox>
          </Col>
        </Row>
      </div>
      <Row className="help-bar-row in-deposit-page">
        <HelperRow />
      </Row>
    </>
  ) : (
    <BuyPerfectMoneyThankYouPage
      voucher={stagePM.voucher}
      amount={stagePM.voucher.amount}
      trackingCode={stagePM.voucher.trackingCode}
      depositFromWallet={true}
    />
  );
};

export default Deposit;

import * as React from 'react';

import './deposit-currency-box.scss';
import { Alert, Button, Card, Input, Modal, SwitchBar } from 'components';
import {
  CopyOutlined,
  CreditCardOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { faNumToEnNum, ToIranCurrency } from 'utils/scripts/decimal';
import { Bank, Cart } from 'core/domains';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { deleteCommaSperator, digitSperator } from 'utils/scripts/exchange';
import { API } from 'apis';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';
import { detect_bank, formatCardNumber } from 'utils/scripts/bankDetecter';
import { AssetItem } from '../../../../../core/domains/markets/asset';
import { useNavigate } from 'react-router-dom';
import { message } from 'components/toast/toast';
import { getMineCarts } from 'core/repositories';
import { AmountPolicy } from 'core/domains/sitesettings/sitesetting';
import { RootState } from '../../../../../core/redux/store';
import { isMobile } from 'react-device-detect';
import { SelectBankAccountModal } from '../../../../../components/select-bankaccount-modal/select-bankAccount-modal';
import { copyClipboard } from '../../../../../utils/scripts/copyToClipboard';
import deposit from '../deposit';

const stageItems = [
  {
    id: 1,
    title: 'واریز از درگاه',
    disabled: false,
  },
  {
    id: 2,
    title: 'واریز شناسه دار (+۲۵ میلیون تومان در روز)',
    disabled: false,
  },
];
(window as any).dataLayer = (window as any).dataLayer || [];

const mapState = (state: RootState) => ({
  state: state,
});

const { useState } = React;

interface Props {
  setMarketName?: React.Dispatch<any>;
  setDepositType: React.Dispatch<number>;
  depositType: number;
}

interface AccountData {
  depositId: string;
  iBan: string;
  accountNumber: string;
  bankName: string;
  bankAccountOwner: string;
}

export const DepositCurrencyBox: React.FC<Props> = (props) => {
  // states
  const [stages, setStages] = useState(stageItems);
  const [cashInStage, setCashInStage] = useState<
    'receive-deposit-id' | 'show-deposit-info'
  >('receive-deposit-id');
  const [depositValue, setDepositValue] = useState<string>('');
  const [accountData, setAccountData] = useState<AccountData>();
  const [isCashIsActive, setIsCashIsActive] = useState<boolean>(false);
  const [carts, setCarts] = useState<Cart[]>([]);
  const [selectedCart, setSelectedCart] = useState<Cart>();
  const [bank, setBank] = useState<Bank>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [balance, setBalance] = useState<AssetItem>();

  const [uploading, setUploading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [cardIsLoading, setCardIsLoading] = useState<boolean>(false);

  const [cartModalIsOpen, setCardModalIsOpen] = useState<boolean>(false);
  const [cardNumber, setCardNumber] = useState<string>('');
  const [trackingCode, setTrackingCode] = useState<string>('');

  const [policies, setPolicies] = useState<AmountPolicy>();
  var navigate = useNavigate();
  const getBankAccounts = () => {
    getViaAuth<Cart[]>(API.bankAccount.mine).then((response: any) => {
      if (responseValidator(response.status)) {
        setCarts(response.value);
        setSelectedCart(response.value[0]);
      }
    });
  };

  const getCashInActivationStatus = async () => {
    var cashInStage = stages.filter((e) => e.id === 2)[0];
    var copyArray = stages;
    await getViaAuth(API.deposit.cashIn.cashInActivationSetting).then(
      (response: any) => {
        if (responseValidator(response.status)) {
          var newEntry = [
            copyArray.filter((e) => e.id === 1)[0],
            {
              ...cashInStage,
              disabled: response.value.isCashInActive !== true,
            },
          ];
          setStages(newEntry);
        }
      },
    );
  };

  const getAmountPolicies = () => {
    getViaAuth<Cart[]>(API.policies.amount).then((response: any) => {
      if (responseValidator(response.status)) {
        setPolicies(response.value);
      }
    });
  };
  const onSubmitGatewayPayment = () => {
    if (policies) {
      if (
        policies &&
        policies.minDepositlAmount &&
        Number.parseInt(policies.minDepositlAmount) <=
          Number.parseInt(deleteCommaSperator(depositValue))
      ) {
        setLoading(true);
        postViaAuth(API.deposit.currency.pay, {
          amount: Number.parseInt(deleteCommaSperator(depositValue)),
          BankAccountId: selectedCart?.id,
        }).then((response: any) => {
          setLoading(false);
          if (responseValidator(response.status)) {
            (window as any).dataLayer.push({
              event: 'begin_checkout',
              ecommerce: {
                value: depositValue,
                tax: 0,
                currency_type: 'IRT',
                currency: 'TRY',
                items: [
                  {
                    item_name: 'create-order',
                    index: 0,
                    price: deposit,
                    quantity: 1,
                  },
                ],
              },
            });

            message.info('درحال انتقال به درگاه پرداخت ');
            //navigate(response.value.paymentUrl);
            window.open(response.value.paymentUrl, '_self');
            // setTrackingCode(response.data.value);
          }
        });
      } else
        message.error(
          `حداقل مقدار واریز ${digitSperator(
            policies?.minDepositlAmount,
          )} تومان است.`,
        );
    }
  };

  const onSubmitRequestDepositId = () => {
    setLoading(true);
    postViaAuth(API.deposit.cashIn.request, {
      amount: null,
      bankAccountId: selectedCart?.id,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setCashInStage('show-deposit-info');

        setAccountData(response.value);
      }
    });
  };
  const changeStageHandler = (e: any) => {
    props.setDepositType(e.id);
  };
  const getMineBalance = () => {
    getViaAuth<AssetItem>(API.wallet.pairBalance + 'IRT').then(
      (response: any) => {
        setCardIsLoading(false);

        if (responseValidator(response.status)) {
          setBalance(response.value);
        }
      },
    );
  };

  const onCartChangeHandler = (cart: any) => {
    setSelectedCart(cart);
    setIsOpen(false);
  };

  const handleCreditCardChange = (value: string) => {
    const cardNumber = formatCardNumber(faNumToEnNum(value));
    if (cardNumber) {
      const card = detect_bank(cardNumber);
      card &&
        setBank({
          code: card.bank_logo,
          name: card.bank_title,
          bank_logo: card.bank_logo,
        });
      setCardNumber(cardNumber);
    }
  };

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>,
  ) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    }
    if (info.file.status === 'done') {
    }
  };
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'video/mp4';

    if (!isJpgOrPng) {
      message.error('فقط فرمت های mp4 قابل قبول میباشد');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('حجم عکس باید کمتر از ۲ مگابایت باشد.');
    }

    return isJpgOrPng && isLt2M;
  };

  const onCreateBankAccount = () => {
    setModalLoading(true);
    postViaAuth(API.bankAccount.addCard, {
      cardNumber: cardNumber.replaceAll('-', ''),
    }).then((response: any) => {
      setModalLoading(false);

      if (responseValidator(response.status)) {
        setCardModalIsOpen(false);
        getMineCarts(setCardIsLoading, setCarts);
        message.success('کارت با موفقیت اضافه شد');
      }
    });
    setIsOpen(false);
  };

  const fetches = async () => {
    setCardIsLoading(true);
    await getCashInActivationStatus();
    getBankAccounts();
    getMineBalance();
  };

  React.useEffect(() => {
    fetches();
    props.setDepositType(stages[0].id);
  }, []);

  React.useEffect(() => {
    setSelectedCart(carts[0]);
  }, [carts]);

  React.useEffect(() => {
    props.setMarketName && props.setMarketName('تومان');
    getAmountPolicies();
  }, []);

  return (
    <>
      <Card blur={cardIsLoading} className={`xpay-deposit-currency-box`}>
        <Modal
          title="افزودن حساب بانکی"
          closeAble
          isOpen={cartModalIsOpen}
          setIsOpen={setCardModalIsOpen}
        >
          <div className="create-cart-modal">
            <Input
              placeholder="شماره کارت خود را وارد کنید"
              type="text"
              dir={cardNumber === '' ? 'rtl' : 'ltr'}
              name="card"
              onChange={(el) => handleCreditCardChange(el.target.value)}
              adorments={{
                startAdornment: {
                  adornmentIcon: bank ? (
                    <img src={bank?.code} alt="" />
                  ) : (
                    <CreditCardOutlined />
                  ),
                },
              }}
              value={cardNumber}
              maxLength={19}
              onPressEnter={onCreateBankAccount}
            />
            <div className="buttons">
              <Button
                type="info"
                text="افزودن حساب بانکی"
                htmlType="button"
                loading={modalLoading}
                onClick={() => onCreateBankAccount()}
              />
              <Button
                type="outlined"
                text="بازگشت"
                onClick={() => setCardModalIsOpen(false)}
              />
            </div>
          </div>
        </Modal>

        <div className="content">
          <SwitchBar
            items={stages}
            active={stages.filter((q) => q.id == props.depositType)[0]}
            onChange={changeStageHandler}
          />
          <div className="deposit-options">
            {props.depositType === 2 && (
              <>
                {isMobile ? (
                  <>
                    {cashInStage === 'show-deposit-info' ? (
                      <>
                        <Alert
                          className="mobile-show"
                          title="هشدار!"
                          type="danger"
                          closeAble
                          noIcon
                          message={
                            <p>
                              ۱- واریز از حساب دیگران امکان پذیر نیست و در صورت
                              واریز طی ۷۲ ساعت وجه پس از کسر کارمزد پایا استرداد
                              خواهد شد.
                              <br />
                              ۲- وجه به صورت خودکار پس از نشستن به حساب مقصد ،
                              به اکانت شما افزوده خواهد شد.
                              <br />
                              ۳- واریز فقط از طریق شیوه پایا و ساتنا باشد ،
                              حواله لحظه ای و پل و سایر روش ها قابل قبول نیست
                            </p>
                          }
                        />
                        <Alert
                          className="mobile-show"
                          title="اطلاعات پرداخت"
                          type="info"
                          closeAble
                          noIcon
                          message={
                            <div className="purchase-info">
                              <span>
                                برای پرداخت و شارژ کیف پول، واریز خود را به حساب
                                زیر انجام دهید.
                              </span>
                              <ul>
                                <li>
                                  <span>شماره شبا</span>
                                  <div className="dotted-divider"></div>
                                  <span>
                                    {accountData?.iBan}{' '}
                                    <CopyOutlined
                                      onClick={() =>
                                        copyClipboard(
                                          accountData?.iBan || '',
                                          'شماره شبا',
                                        )
                                      }
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span>شماره حساب</span>
                                  <div className="dotted-divider"></div>
                                  <span>
                                    {accountData?.accountNumber}{' '}
                                    <CopyOutlined
                                      onClick={() =>
                                        copyClipboard(
                                          accountData?.accountNumber || '',
                                          'شماره حساب',
                                        )
                                      }
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span>شناسه واریز</span>
                                  <div className="dotted-divider"></div>
                                  <span>
                                    {accountData?.depositId}{' '}
                                    <CopyOutlined
                                      onClick={() =>
                                        copyClipboard(
                                          accountData?.depositId || '',
                                          'شناسه واریز',
                                        )
                                      }
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span>نام صاحب حساب</span>
                                  <div className="dotted-divider"></div>
                                  <span>{accountData?.bankAccountOwner}</span>
                                </li>
                                <li>
                                  <span>نام بانک</span>
                                  <div className="dotted-divider"></div>
                                  <span>{accountData?.bankName}</span>
                                </li>
                              </ul>
                            </div>
                          }
                        />
                      </>
                    ) : (
                      <Alert
                        title="هشدار!"
                        type="danger"
                        className="mobile-show"
                        closeAble
                        noIcon
                        message={
                          <p>
                            به نکات زیر توجه کنید!
                            <br />
                            ۱- واریز از حساب دیگران امکان پذیر نیست و در صورت
                            واریز طی ۷۲ ساعت وجه پس از کسر کارمزد پایا استرداد
                            خواهد شد.
                            <br />
                            ۲- وجه به صورت خودکار پس از نشستن به حساب مقصد ، به
                            اکانت شما افزوده خواهد شد.
                            <br />
                            ۳- واریز فقط از طریق شیوه پایا و ساتنا باشد ، حواله
                            لحظه ای و پل و سایر روش ها قابل قبول نیست
                          </p>
                        }
                      />
                    )}
                  </>
                ) : (
                  <>
                    {cashInStage === 'show-deposit-info' ? (
                      <>
                        <Alert
                          title="هشدار!"
                          type="danger"
                          closeAble
                          outlinedIcon
                          message={
                            <p>
                              ۱- واریز از حساب دیگران امکان پذیر نیست و در صورت
                              واریز طی ۷۲ ساعت وجه پس از کسر کارمزد پایا استرداد
                              خواهد شد.
                              <br />
                              ۲- وجه به صورت خودکار پس از نشستن به حساب مقصد ،
                              به اکانت شما افزوده خواهد شد.
                              <br />
                              ۳- واریز فقط از طریق شیوه پایا و ساتنا باشد ،
                              حواله لحظه ای و پل و سایر روش ها قابل قبول نیست
                            </p>
                          }
                        />
                        <Alert
                          title="نکته!"
                          type="warning"
                          closeAble
                          outlinedIcon
                          message={
                            <p>
                              ۱- کارمزد: ٪0.02 مبلغ واریزی.
                              <br />
                              برای مثال اگر مبلغ واریزی، صد میلیون تومان باشد،
                              کارمزد 0.02 درصد آن معادل 20 هزار تومان خواهد بود.{' '}
                            </p>
                          }
                        />
                        <Alert
                          title="اطلاعات پرداخت"
                          type="info"
                          closeAble
                          outlinedIcon
                          message={
                            <div className="purchase-info">
                              <span>
                                برای پرداخت و شارژ کیف پول، واریز خود را به حساب
                                زیر انجام دهید.
                              </span>
                              <ul>
                                <li>
                                  <span>شماره شبا</span>
                                  <div className="dotted-divider"></div>
                                  <span>
                                    IR{accountData?.iBan}
                                    <CopyOutlined
                                      onClick={() =>
                                        copyClipboard(
                                          accountData?.iBan || '',
                                          'شماره شبا',
                                        )
                                      }
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span>شماره حساب</span>
                                  <div className="dotted-divider"></div>
                                  <span>
                                    {accountData?.accountNumber}
                                    <CopyOutlined
                                      onClick={() =>
                                        copyClipboard(
                                          accountData?.accountNumber || '',
                                          'شماره حساب',
                                        )
                                      }
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span>شناسه واریز</span>
                                  <div className="dotted-divider"></div>
                                  <span>
                                    {accountData?.depositId}{' '}
                                    <CopyOutlined
                                      onClick={() =>
                                        copyClipboard(
                                          accountData?.depositId || '',
                                          'شناسه واریز',
                                        )
                                      }
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span>نام صاحب حساب</span>
                                  <div className="dotted-divider"></div>
                                  <span>{accountData?.bankAccountOwner}</span>
                                </li>
                                <li>
                                  <span>نام بانک</span>
                                  <div className="dotted-divider"></div>
                                  <span>{accountData?.bankName}</span>
                                </li>
                              </ul>
                            </div>
                          }
                        />
                      </>
                    ) : (
                      <>
                        <Alert
                          title="هشدار!"
                          type="danger"
                          closeAble
                          noIcon
                          message={
                            <p>
                              ۱- واریز از حساب دیگران امکان پذیر نیست و در صورت
                              واریز طی ۷۲ ساعت وجه پس از کسر کارمزد پایا استرداد
                              خواهد شد.
                              <br />
                              ۲- وجه به صورت خودکار پس از نشستن به حساب مقصد ،
                              به اکانت شما افزوده خواهد شد.
                              <br />
                              واریز با همه روش‌های پرداخت، به غیر از پل و حواله
                              لحظه ای امکان‌پذیر است.
                            </p>
                          }
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {props.depositType === 1 ? (
              <div className="deposit-value-row">
                <div className="input-info-labels">
                  <span className="input-info-label">مقدار واریز</span>
                  <span className="input-info-label">
                    موجودی فعلی شما
                    <span>{ToIranCurrency(balance?.balance)} تومان </span>
                  </span>
                </div>
                <Input
                  type="text"
                  value={depositValue}
                  onChange={(e) => {
                    setDepositValue(
                      digitSperator(
                        deleteCommaSperator(faNumToEnNum(e.target.value)),
                      ),
                    );
                  }}
                  onPressEnter={() =>
                    !loading &&
                    depositValue !== '' &&
                    selectedCart &&
                    onSubmitGatewayPayment()
                  }
                  name="depositValue"
                  dir={depositValue === '' ? 'rtl' : 'ltr'}
                  placeholder="0"
                  maxLength={12}
                  adorments={{
                    startAdornment: {
                      adornmentIcon: (
                        <div className="mark">
                          <div className="selected-item">
                            <img src={`/coins/irt.svg`} />
                            <span>تومان</span>
                            <span>IRT</span>
                          </div>
                        </div>
                      ),
                    },
                    endAdornment: {
                      adornmentIcon: <span>تومان</span>,
                    },
                  }}
                />
              </div>
            ) : props.depositType === 2 ? (
              cashInStage === 'receive-deposit-id' ? (
                <></>
              ) : (
                <div className="payment-options-row"></div>
              )
            ) : (
              <></>
            )}
            {(cashInStage === 'receive-deposit-id' ||
              props.depositType === 1) && (
              <div className="payment-options-row">
                <div className="payment-cart-selector">
                  {carts.length === 0 ? (
                    <>
                      <div
                        className="add-new-cart"
                        onClick={() => setCardModalIsOpen(true)}
                      >
                        <PlusOutlined />
                        <span>جهت افزودن حساب بانکی کلیک کنید</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-info-labels">
                        <span className="input-info-label">
                          {props.depositType === 1
                            ? ' انتخاب کارت بانکی'
                            : 'انتخاب حساب مبدأ'}
                        </span>
                        <span className="input-info-label"></span>
                      </div>
                      <Input
                        value={
                          props.depositType === 1
                            ? selectedCart?.cardNumber
                            : selectedCart?.iBan
                        }
                        type="text"
                        onPressEnter={() =>
                          !loading &&
                          depositValue !== '' &&
                          selectedCart &&
                          onSubmitGatewayPayment()
                        }
                        onClick={() => setIsOpen(true)}
                        onChange={(e) => {}}
                        name="cart"
                        placeholder="(کلیک کنید) کارت بانکی خود را از لیست کارت های ثبت شده انتخاب کنید."
                        adorments={{
                          startAdornment: {
                            adornmentIcon: (
                              <div
                                onClick={() => setIsOpen(!isOpen)}
                                className="mark"
                              >
                                <div className="selected-cart">
                                  <img
                                    src={`/banks/${selectedCart?.bank?.code.toLowerCase()}.svg`}
                                  />
                                  <span>
                                    {selectedCart?.bank?.name.replace(
                                      'بانک',
                                      '',
                                    )}
                                  </span>
                                </div>
                              </div>
                            ),
                          },
                          endAdornment: {
                            adornmentIcon: (
                              <DownOutlined onClick={() => setIsOpen(true)} />
                            ),
                          },
                        }}
                      />
                      {carts && (
                        <SelectBankAccountModal
                          title={
                            props.depositType === 1
                              ? 'کارت بانکی خود برای واریز از درگاه را انتخاب کنید'
                              : 'حساب مبدا جهت واریز را انتخاب کنید'
                          }
                          isIban={props.depositType === 2}
                          carts={carts}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          onChange={onCartChangeHandler}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="submit">
            {props.depositType == 1 ? (
              <Button
                loading={loading}
                disabled={loading || depositValue === '' || !selectedCart}
                onClick={() => onSubmitGatewayPayment()}
                type="info"
                text={'انتقال به درگاه پرداخت'}
              />
            ) : props.depositType === 2 ? (
              cashInStage === 'receive-deposit-id' ? (
                <Button
                  loading={loading}
                  disabled={loading}
                  onClick={() => onSubmitRequestDepositId()}
                  type="info"
                  text={'دریافت شناسه واریز'}
                />
              ) : (
                <Button
                  onClick={() => setCashInStage('receive-deposit-id')}
                  type="info"
                  text="تغییر حساب مبدأ"
                />
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

import * as React from 'react';
import './cart.scss';
import 'components/transaction-box/transaction-box.scss';
import {BankOutlined, CreditCardOutlined} from '@ant-design/icons';
import {Button, Input} from 'components';
import {Bank} from 'core/domains';
import {faNumToEnNum, IsNumeric} from 'utils/scripts/decimal';
import {detect_bank, formatCardNumber} from 'utils/scripts/bankDetecter';
import {createCart} from 'core/repositories';

const {useState} = React;

type Props = {
  setBank: React.Dispatch<any>;
  setCardNumber: React.Dispatch<any>;
  setIBan: React.Dispatch<any>;
  setLoading: React.Dispatch<any>;
  setModalStage: React.Dispatch<any>;
  cardNumber?: string;
  isIBanNeeded: boolean;
  bank?: Bank;
  iBan?: string;
};

export const OrderModalCart: React.FC<Props> = ({
                                                  cardNumber,
                                                  setBank,
                                                  setCardNumber,
                                                  isIBanNeeded,
                                                  bank,
                                                  setIBan,
                                                  iBan,
                                                  setLoading,
                                                  setModalStage,
                                                }) => {
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const handleCreditCardChange = (value: string) => {
    const cardNumber = formatCardNumber(faNumToEnNum(value));
    if (cardNumber) {
      const card = detect_bank(cardNumber);
      card && setBank({code: card.bank_logo, name: card.bank_title});
    }
    setCardNumber(cardNumber);
  };

  const onCreateBankAccount = () => {

    cardNumber && createCart(cardNumber, setModalLoading, iBan);

  };

  function handleIBanChange(value: string) {
    // all number Fa to En
    if (value !== '') value = faNumToEnNum(value);
    // validation
    if (value !== '' && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    // set on state
    setIBan(value);
  }

  return (
      <>
        <div className="create-cart-modal">
          <Input
              placeholder="شماره کارت خود را وارد کنید"
              type="text"
              dir={cardNumber === '' ? 'rtl' : 'ltr'}
              name="card"
              onChange={(el) => handleCreditCardChange(el.target.value)}
              adorments={{
                startAdornment: {
                  adornmentIcon: bank ? (
                      <img src={bank?.code} alt=""/>
                  ) : (
                      <CreditCardOutlined/>
                  ),
                },
              }}
              value={cardNumber}
              maxLength={19}
              onPressEnter={onCreateBankAccount}
          />
          {isIBanNeeded && (
              <Input
                  label="شماره شبا*"
                  type="text"
                  name="iban"
                  onChange={(el) => handleIBanChange(el.target.value)}
                  adorments={{
                    startAdornment: {
                      adornmentIcon: <BankOutlined/>,
                    },
                    endAdornment: {
                      adornmentIcon: 'IR',
                    },
                  }}
                  value={iBan}
                  maxLength={19}
                  onPressEnter={onCreateBankAccount}
              />
          )}
          <div className="buttons">
            <Button
                type="info"
                text="افزودن حساب بانکی"
                disabled={modalLoading}
                htmlType="button"
                loading={modalLoading}
                onClick={() => onCreateBankAccount()}
            />
            <Button
                type="outlined"
                text="بازگشت"
                onClick={() => setModalStage('payment')}
            />
          </div>
        </div>
      </>
  );
};

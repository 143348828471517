import * as React from 'react';

import 'components/bottom-menu/bottom-menu.scss';
import BottomMenuItem from 'components/bottom-menu/bottom-menu-item/bottom-menu-item';

type Props = {
  items: any[];
};

const apps = {
  id: -1,
  title: 'منو',
  link: '',
  icon: (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.10411 14.423C8.79613 14.423 10.1522 15.7922 10.1522 17.4963V21.5871C10.1522 23.2792 8.79613 24.6592 7.10411 24.6592H3.04805C1.36802 24.6592 0 23.2792 0 21.5871V17.4963C0 15.7922 1.36802 14.423 3.04805 14.423H7.10411ZM20.9524 14.423C22.6325 14.423 24.0005 15.7922 24.0005 17.4963V21.5871C24.0005 23.2792 22.6325 24.6592 20.9524 24.6592H16.8964C15.2043 24.6592 13.8483 23.2792 13.8483 21.5871V17.4963C13.8483 15.7922 15.2043 14.423 16.8964 14.423H20.9524ZM7.10411 0.65918C8.79613 0.65918 10.1522 2.0392 10.1522 3.73243V7.82329C10.1522 9.52731 8.79613 10.8953 7.10411 10.8953H3.04805C1.36802 10.8953 0 9.52731 0 7.82329V3.73243C0 2.0392 1.36802 0.65918 3.04805 0.65918H7.10411ZM20.9524 0.65918C22.6325 0.65918 24.0005 2.0392 24.0005 3.73243V7.82329C24.0005 9.52731 22.6325 10.8953 20.9524 10.8953H16.8964C15.2043 10.8953 13.8483 9.52731 13.8483 7.82329V3.73243C13.8483 2.0392 15.2043 0.65918 16.8964 0.65918H20.9524Z"
        fill="#859ABC"
      />
    </svg>
  ),
};

export const BottomMenu: React.FC<Props> = (props) => {
  return (
    <div className="xpay-bottom-menu">
      <div className="bottom-menu-items">
        <BottomMenuItem
          id={apps.id}
          icon={apps.icon}
          title={apps.title}
          link={apps.link}
          sidebar={true}
        />
        {props.items.map((item, index) => (
          <BottomMenuItem
            key={`bottom-menu-item-${index}`}
            id={item.id}
            icon={item.icon}
            title={item.title}
            link={item.link}
            childrens={item?.childrens}
          />
        ))}
      </div>
    </div>
  );
};

import * as React from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Card } from 'components';

import './referral-invited-users.scss';
import { Table } from 'components/table/table';
import { TableColumn } from 'react-data-table-component';
import { Badge } from 'components/badge/badge';
import { PaginationList } from 'core/domains';
import { ReferralInvitedUser } from 'core/domains/referral/referral';
import { getMineInvitedUsers } from 'core/repositories/referral/referral';
import { getPageNum } from 'utils/scripts/paggingHelper';
import moment from 'jalali-moment';

const { useState, useRef } = React;

export const ReferralInvitedUsers: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [referralsInvitedUsers, setReferralsInvitedUsers] =
    useState<PaginationList<ReferralInvitedUser>>();

  const columns: TableColumn<any>[] = [
    {
      name: '#',
      id: 'index',
      selector: (row: any, rowIndex: any) => rowIndex + 1,
      width: '50px',
    },
    {
      name: 'شماره تماس',
      id: 'phoneNumber',
      selector: (row: any) => row.phoneNumber,
    },
    {
      name: 'سطح',
      id: 'levelTitle',
      selector: (row: any) => row.levelTitle,
    },
    {
      name: 'تاریخ',
      id: 'createdOn',
      selector: (row: any) =>
        moment
          .utc(row?.createdOn)
          .locale('fa')
          .format('  HH:mm  -  jYYYY/MM/DD  '),
    },
  ];

  React.useEffect(() => {
    getMineInvitedUsers(
      setLoading,
      setReferralsInvitedUsers,
      {
        pageNumber: getPageNum(),
        pageSize: 10,
      },
      '',
    );
  }, []);

  return (
    <>
      <Card className={`referral-table-card referral-invited }`} blur={loading}>
        <div className="head">
          <div className="right">
            <h4>دوستان دعوت شده</h4>
          </div>

          <Button
            text={`${isExpanded ? 'بستن' : 'نمایش'} لیست`}
            type="outlined"
            icon={isExpanded ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
        {isExpanded && referralsInvitedUsers && (
          <div className={`desktop-show }`}>
            <Table
              className="invited-table"
              setData={setReferralsInvitedUsers}
              pagination={referralsInvitedUsers.pagination}
              hasFiltering={true}
              blur={loading}
              data={referralsInvitedUsers.items}
              column={columns}
              getData={getMineInvitedUsers}
              setTableLoading={loading}
            />
          </div>
        )}
        {isExpanded && (
          <ul className={`mobile-show ${isExpanded ? '' : 'd-none'}`}>
            {referralsInvitedUsers?.items?.map((item) => (
              <li>
                <div className="row">
                  <div className="right">
                    <p>شماره تماس:</p>
                    <span>{item.phoneNumber}</span>
                  </div>
                  <div className="left">
                    <p>تاریخ پیوستن: </p>
                    <span>
                      {moment
                        .utc(item?.createdOn)
                        .locale('fa')
                        .format('  HH:mm  -  jYYYY/MM/DD  ')}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="right">
                    <p>وضعیت احراز هویت:</p>
                  </div>
                  <div className="left">
                    <span>
                      <Badge text={item.levelTitle} type="info" />
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Card>
    </>
  );
};

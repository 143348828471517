import { APPNAME } from '../../../../apis/constants';

export const DepositIrtHelperText = () => {
  return (
    <>
      <li>
        <p>
          <span className="number">1</span>
          شارژ کیف پول فقط با کارت های ثبت شده و به نام خود کاربر مجاز است و در
          صورت پرداخت با کارت های دیگر تراکنش ناموفق شده و طی 72 ساعت کاری به
          حساب بازگشت داده میشود
        </p>
      </li>
      <li>
        <p>
          <span className="number">2</span>
          موجودی کیف پول به شما این امکان را می دهد تا در مواردی که درگاه بانکی
          مشکل دارد سریعتر خرید کنید و هر بار نیاز به وارد کردن اطلاعات حساب
          بانکی نباشد.
        </p>
      </li>
      <li>
        <p>
          <span className="number">3</span>
          جهت استفاده از موجودی کیف پول در هنگام پرداخت فاکتور، می بایست گزینه
          پرداخت با « کیف پول » را انتخاب نمایید.
        </p>
      </li>
      <li>
        <p>
          <span className="number">4</span>
          تنها زمانی می توانید از اعتبار کیف پول برای پرداخت فاکتور استفاده کنید
          که کیف پول شما موجودی کافی داشته باشد.
        </p>
      </li>
      <li>
        <p>
          <span className="number">5</span>
          برای خرید بالای 100 میلیون تومان میتوانید از پشتیبانی {APPNAME}
          کمک بگیرید
        </p>
      </li>
    </>
  );
};

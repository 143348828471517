import { CreditCardSlider } from './credit-card/slider/credit-card-slider';
import * as React from 'react';
import { CreateBankAccount } from './create-bank-account/create-bank-account';
import './bank-account.scss';
import { Spin } from 'antd';
import { HelperRow } from 'components/helper-row/helper-row';
import { Cart } from '../../../core/domains';
import { HelperBox } from 'components/helper-box/helper-box';
import { getMineCarts } from 'core/repositories';
import { Alert } from 'components';
import { APPNAME } from '../../../apis/constants';

const { useState, useEffect } = React;

export const BankAccount: React.FC = () => {
  const [carts, setCarts] = useState<Cart[]>();
  const [isCardsLoading, setCardsIsLoading] = useState<boolean>(false);

  const fetchAllData = () => {
    getMineCarts(setCardsIsLoading, setCarts);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <div className="xpay-dashboard-bank-account">
      <div className="bank-accounts">
        <Spin spinning={isCardsLoading}>
          {carts && carts.length !== 0 ? (
            <CreditCardSlider carts={carts} />
          ) : (
            <div>
              <Alert
                className="desktop-show"
                type={'warning'}
                title={'هنوز هیچ حسابی اضافه نکرده اید'}
                message={
                  `با افزودن حساب از طریق اضافه کردن شماره کارت متعلق به خود امکان ثبت معامله در ` +
                  APPNAME +
                  ` را پیدا خواهید کرد.`
                }
              />

              <Alert
                className="mobile-show"
                type={'warning'}
                title={'هنوز هیچ حسابی اضافه نکرده اید'}
                message={
                  `با افزودن حساب از طریق اضافه کردن شماره کارت متعلق به خود امکان ثبت معامله در ` +
                  APPNAME +
                  ` را پیدا خواهید کرد.`
                }
                noIcon
              />
            </div>
          )}
        </Spin>
      </div>
      <div className="create-bank-account">
        <CreateBankAccount
          setCreditCardList={setCarts}
          getMineBankAccounts={fetchAllData}
        />
      </div>
      <HelperRow className="desktop-show" />
      <HelperBox title={''} />
    </div>
  );
};

import * as React from "react";
import "./mobile-navigate.scss";

type Props = {
  title: string;
  onBackClick: () => void;
  backBtnDisabled?: boolean;
};

export const MobileNavigate: React.FC<Props> = (props) => {
  return (
    <div className={`xpay-mobile-navigate `}>
      <h3>{props.title}</h3>
      <svg
        className={`mob-mobile-back-btn ${
          props.backBtnDisabled ? "disabled" : ""
        }`}
        onClick={() => props.onBackClick()}
        width='38'
        height='38'
        viewBox='0 0 38 38'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='38' height='38' rx='10' fill='#F1F8FF' />
        <path
          d='M27 19.4408C27 20.0689 26.5333 20.588 25.9277 20.6702L25.7594 20.6815L12.2413 20.6815C11.5561 20.6815 11.0006 20.126 11.0006 19.4408C11.0006 18.8128 11.4673 18.2937 12.0729 18.2115L12.2413 18.2002L25.7594 18.2002C26.4445 18.2002 27 18.7557 27 19.4408Z'
          fill='#859ABC'
        />
        <path
          d='M19.346 12.3652C19.7855 12.8066 19.8241 13.496 19.4627 13.9809L19.3423 14.1198L13.9994 19.4391L19.3423 24.7614C19.7837 25.2009 19.8252 25.89 19.4658 26.3765L19.346 26.5159C18.9065 26.9573 18.2173 26.9988 17.7309 26.6395L17.5915 26.5196L11.3651 20.3197C10.9223 19.8789 10.8821 19.1872 11.2443 18.7008L11.3651 18.5614L17.5915 12.3615C18.077 11.878 18.8625 11.8797 19.346 12.3652Z'
          fill='#859ABC'
        />
      </svg>
    </div>
  );
};

import { getIconPath } from '../../../utils/scripts/getIconPath';
import { digitSperator } from '../../../utils/scripts/exchange';
import { copyClipboard } from '../../../utils/scripts/copyToClipboard';
import { getMarketType, MarketType } from '../../../utils/scripts/market';
import {
  detect_bank,
  formatCardNumber,
} from '../../../utils/scripts/bankDetecter';
import moment from 'jalali-moment';
import * as React from 'react';
import {
  Transaction,
  TransactionState,
  TransactionType,
} from '../../../core/domains';
import { ReactComponent as CopyOutlined } from 'assets/icons/copy-outlined.svg';

interface Props {
  transactionItem: Transaction;
}

export const TransactionDetailModalData: React.FC<Props> = ({
  transactionItem,
}) => {
  return (
    <>
      {transactionItem?.market && (
        <div className="row double-column">
          <div className="col">
            <span>نام ارز</span>
            <div className="coin-info">
              <img src={getIconPath(transactionItem?.market?.symbol)} />
              <span>{transactionItem?.market.name}</span>
            </div>
          </div>
          {transactionItem.network && (
            <div className="col">
              <span>نوع شبکه</span>
              <div>
                <span className="en">
                  {transactionItem?.network.name.toUpperCase()} |{' '}
                  {transactionItem?.network.network.toUpperCase()}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {transactionItem?.trackingCode && (
        <div className="row has-divider">
          <span>شناسه تراکنش</span>
          <div>
            <span className="en">{transactionItem?.trackingCode}</span>
            <CopyOutlined
              onClick={() =>
                transactionItem?.trackingCode &&
                copyClipboard(transactionItem?.trackingCode, 'شناسه تراکنش')
              }
            />
          </div>
        </div>
      )}
      {transactionItem?.type && (
        <div className="row has-divider">
          <span>نوع تراکنش</span>
          <div>
            <span>{transactionItem?.type.title}</span>
          </div>
        </div>
      )}
      {transactionItem?.amount && (
        <div className="row double-column has-divider">
          <div className="col">
            <span>مقدار تراکنش</span>
            <div>
              <span>
                {digitSperator(transactionItem?.amount)}{' '}
                {transactionItem?.market.symbol === 'IRT'
                  ? 'تومان'
                  : transactionItem.market.name}
              </span>
            </div>
          </div>
          {transactionItem.type.id === TransactionType.Withdrawal &&
            transactionItem.network &&
            transactionItem.network.fee && (
              <div className="col">
                <span> کارمزد شبکه</span>
                <div>
                  <span>
                    {digitSperator(transactionItem?.network.fee)}{' '}
                    {transactionItem.market.name}
                  </span>
                </div>
              </div>
            )}
        </div>
      )}

      {transactionItem?.voucher && (
        <>
          {transactionItem?.voucher.voucherCode && (
            <div className="row has-divider">
              <span>کد ووچر</span>
              <div>
                <span className="en">
                  {transactionItem?.voucher.voucherCode}
                </span>
                <CopyOutlined
                  onClick={() =>
                    copyClipboard(
                      transactionItem?.voucher.voucherCode,
                      'کد ووچر',
                    )
                  }
                />
              </div>
            </div>
          )}
          {transactionItem?.voucher.voucerhActivationCode && (
            <div className="row has-divider">
              <span>کد فعال ساز ووچر</span>
              <div>
                <span className="en">
                  {transactionItem?.voucher.voucerhActivationCode}
                </span>
                <CopyOutlined
                  onClick={() =>
                    copyClipboard(
                      transactionItem?.voucher.voucerhActivationCode,
                      'کد فعال ساز ووچر',
                    )
                  }
                />
              </div>
            </div>
          )}
          {transactionItem?.voucher.batchNumber && (
            <div className="row has-divider">
              <span>بچ نامبر</span>
              <div>
                <span className="en">
                  {transactionItem?.voucher.batchNumber}
                </span>
                <CopyOutlined
                  onClick={() =>
                    copyClipboard(
                      transactionItem?.voucher.batchNumber,
                      'بچ نامبر',
                    )
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
      {transactionItem?.digitalReceipt && (
        <>
          <div className="row has-divider">
            <span>رسید دیجیتال</span>
            <div>
              <a target="_blank" href={transactionItem.digitalReceipt}>
                <span className="en long-text">
                  {transactionItem.digitalReceipt}
                </span>
              </a>
            </div>
          </div>
        </>
      )}
      {transactionItem?.network &&
        transactionItem.type.id === TransactionType.Withdrawal && (
          <>
            <div className="row has-divider">
              <span>مقدار نهایی پس از کسر کارمزد</span>
              <div>
                <span>
                  {transactionItem?.amount - transactionItem?.network.fee}{' '}
                  {transactionItem.market.name}
                </span>
              </div>
            </div>
          </>
        )}
      {transactionItem?.bankAccount &&
        getMarketType(transactionItem?.market.symbol) === MarketType.IRT && (
          <div className="row has-divider ">
            <span>حساب بانکی</span>
            <div className="bankaccount">
              <img
                src={detect_bank(
                  transactionItem?.bankAccount.cardNumber,
                )?.bank_logo.toLowerCase()}
              />
              <span>
                {transactionItem?.type.title === 'برداشت'
                  ? transactionItem?.bankAccount.iBan
                  : formatCardNumber(transactionItem?.bankAccount.cardNumber)}
              </span>
            </div>
          </div>
        )}
      {transactionItem?.commission != 0 &&
        transactionItem?.market?.symbol !== 'PM' &&
        transactionItem?.market?.symbol !== 'UUSD' && (
          <div className="row has-divider">
            <span>کارمزد تراکنش</span>
            <div>
              <span>
                {digitSperator(transactionItem?.commission)}{' '}
                {transactionItem?.market.name}
              </span>
            </div>
          </div>
        )}
      {transactionItem?.txId && (
        <>
          <div className="row has-divider">
            <span>هش تراکنش (تی ایکس آیدی)</span>
            <div>
              {transactionItem?.network?.network.toUpperCase() === 'TRX' ? (
                <a
                  className="long-text"
                  href={`https://tronscan.org/#/transaction/${transactionItem.txId}`}
                >{`https://tronscan.org/#/transaction/${transactionItem.txId}`}</a>
              ) : (
                <>
                  <span className="long-text">
                    {transactionItem?.txId ?? '-'}
                  </span>
                </>
              )}
              <CopyOutlined
                onClick={() =>
                  copyClipboard(transactionItem?.txId ?? '', 'هش تراکنش')
                }
              />
            </div>
          </div>
        </>
      )}
      {transactionItem?.wallet && (
        <>
          <div className="row has-divider">
            <span>آدرس کیف پول</span>
            <div>
              <span className="en">
                {transactionItem?.wallet.walletAddress || ''}
              </span>
              <CopyOutlined
                onClick={() =>
                  copyClipboard(
                    transactionItem?.wallet?.walletAddress || '',
                    'آدرس کیف پول',
                  )
                }
              />
            </div>
          </div>
        </>
      )}
      {transactionItem?.createdAt && (
        <div className="row has-divider">
          <span>تاریخ و ساعت</span>
          <span>
            {moment
              .utc(transactionItem.createdAt)
              .locale('fa')
              .local()
              .format('  HH:mm  -  jYYYY/MM/DD  ')}
          </span>
        </div>
      )}

      {transactionItem?.state && (
        <div className="row has-divider">
          <span>وضعیت</span>
          <span
            className={`${
              transactionItem?.state.id === TransactionState.Succeed
                ? 'success'
                : transactionItem?.state.id == TransactionState.Rejected ||
                  transactionItem?.state.id == TransactionState.Cancel
                ? 'error'
                : 'waiting'
            }`}
          >
            {transactionItem?.state.title}
          </span>
        </div>
      )}
    </>
  );
};

import * as React from 'react';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Card, Input } from 'components';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import ReactCodeInput from 'react-verification-code-input';
import './change-password.scss';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { message } from 'components/toast/toast';
import {
  ChangePasswordNewPassword,
  ChangePasswordNewPasswordError,
  ChangePasswordNewPasswordGreenText,
  ChangePasswordSmsVerify,
  ChangePasswordSmsVerifyError,
} from 'core/domains/profile/change-password/change-password';

const { useState, useRef, useEffect } = React;

type Props = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  phone?: string;
};

export const ChangePassword: React.FC<Props> = (props) => {
  const [codeStageData, setCodeStageData] = useState<ChangePasswordSmsVerify>();
  const [passwordStageData, setPasswordStageData] =
    useState<ChangePasswordNewPassword>();
  const [stage, setStage] = useState<'PHONE' | 'NEW_PASS'>('NEW_PASS');
  const [countDownTime, setCountDownTime] = useState<number>(120);
  const [loading, setLoading] = useState<boolean>(false);
  const [codeStageError, setCodeStageError] =
    useState<ChangePasswordSmsVerifyError>();
  const [passwordStageError, setPasswordStageError] =
    useState<ChangePasswordNewPasswordError>({
      newPassword: ['حداقل یک عدد داشته باشد.', 'حداقل ۶ کاراکتر داشته باشد.'],
      newPasswordRepeat: [''],
    });
  const [passwordStageGreen, setPasswordStageGreen] =
    useState<ChangePasswordNewPasswordGreenText>({
      newPassword: [''],
      newPasswordRepeat: [''],
    });
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(false);
  const [isRepeatPasswordHidden, setIsRepeatPasswordHidden] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function changePassword() {
    await postViaAuth(API.changePassword, {
      password: passwordStageData?.newPassword,
      repeatPassword: passwordStageData?.newPasswordRepeat,
    }).then(function (response: any) {
      setIsLoading(false);

      if (responseValidator(response.status)) {
        message.success('پسورد شما با موفقیت بازیابی شد.');
        props.setIsOpen(false);
        window.open('/login', '_self');
      }
    });
  }

  function handlePhoneCodeVerify() {
    setLoading(true);
    setCodeStageError(undefined);
    if (codeStageData?.phoneCode && codeStageData.phoneCode.length === 6) {
      postViaAuth(API.verify_2fa, {
        code: codeStageData.phoneCode,
        phoneNumber: localStorage.getItem('phoneNumber'),
      }).then(function (response: any) {
        setLoading(false);
        if (responseValidator(response.status)) {
          setStage('NEW_PASS');
        } else {
          if (response.status === 400)
            setCodeStageError({
              ...codeStageError,
              phoneCode: response.message,
            });
        }
      });
    } else {
      setLoading(false);
      setCodeStageError({
        ...codeStageError,
        phoneCode: 'کد تایید باید دارای ۶ رقم باشد',
      });
    }
  }

  function handlePasswordChange(value: string): void {
    setPasswordStageError((prev) => ({
      newPassword: [''],
      newPasswordRepeat: [''],
    }));
    setPasswordStageGreen((prev) => ({
      newPassword: [''],
      newPasswordRepeat: [''],
    }));
    setPasswordStageData({ ...passwordStageData, newPassword: '' });

    if (value.length < 6) {
      setPasswordStageError((prev) => ({
        ...prev,
        newPassword: [...prev.newPassword, 'حداقل ۶ کاراکتر داشته باشد.'],
      }));
    } else {
      setPasswordStageGreen((prev) => ({
        newPasswordRepeat: [''],
        newPassword: [...prev.newPassword, 'حداقل ۶ کاراکتر داشته باشد.'],
      }));
      setPasswordStageError((prev) => ({ ...prev, newPassword: [''] }));
    }

    if (
      value.includes('1') ||
      value.includes('2') ||
      value.includes('3') ||
      value.includes('4') ||
      value.includes('5') ||
      value.includes('6') ||
      value.includes('7') ||
      value.includes('8') ||
      value.includes('9') ||
      value.includes('0')
    ) {
      setPasswordStageGreen((prev) => ({
        newPasswordRepeat: [''],
        newPassword: [...prev.newPassword, 'حداقل یک عدد داشته باشد.'],
      }));
      setPasswordStageError((prev) => ({ ...prev, newPassword: [''] }));
    } else {
      setPasswordStageError((prev) => ({
        ...prev,
        newPassword: [...prev.newPassword, 'حداقل یک عدد داشته باشد.'],
      }));
    }

    setPasswordStageData({ ...passwordStageData, newPassword: value });
  }

  function renderStage() {
    switch (stage) {
      case 'PHONE':
        return (
          <>
            <div className="main code">
              <ReactCodeInput
                className={`input ${
                  codeStageError?.phoneCode ? 'has-error' : ''
                }`}
                fieldWidth={38}
                values={
                  codeStageData?.phoneCode
                    ? codeStageData?.phoneCode.split('')
                    : []
                }
                autoFocus={true}
                onChange={(value) => {
                  setCodeStageData((prev) => ({
                    ...prev,
                    phoneCode: value,
                  }));
                }}
                onComplete={() => handlePhoneCodeVerify()}
              />
              <div className="stats">
                <div className="timer">
                  {countDownTime < 1 ? (
                    <span
                      className="resend"
                      onClick={() => setCountDownTime(120)}
                    >
                      ارسال مجدد
                    </span>
                  ) : (
                    <>
                      <CountdownCircleTimer
                        size={30}
                        isPlaying
                        duration={countDownTime || 0}
                        colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                        colorsTime={[7, 5, 2, 0]}
                        onComplete={() => setCountDownTime(0)}
                      >
                        {({ remainingTime }) => <span>{remainingTime}</span>}
                      </CountdownCircleTimer>{' '}
                      ثانیه تا ارسال مجدد کد
                    </>
                  )}
                </div>
                <div className="errors">
                  {codeStageError?.phoneCode && (
                    <>
                      <QuestionCircleOutlined />
                      <span>{codeStageError?.phoneCode}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Button
              type="info"
              text="تایید"
              onClick={() => handlePhoneCodeVerify()}
              disabled={loading ? true : false}
              loading={loading}
            />
          </>
        );

      case 'NEW_PASS':
        return (
          <>
            <div className="main new-password">
              <p>لطفا پسورد جدید خود را وارد کنید</p>
              <div>
                <form className="new-password-form">
                  <Input
                    name="password"
                    type={isPasswordHidden ? 'password' : 'text'}
                    className="new-password-input"
                    value={passwordStageData?.newPassword}
                    onChange={(el) => handlePasswordChange(el.target.value)}
                    placeholder="رمز عبور"
                    adorments={{
                      startAdornment: {
                        adornmentIcon: (
                          <img
                            className="start-adornment"
                            src="/assets/icons/unlock.svg"
                          />
                        ),
                      },
                      endAdornment: {
                        adornmentIcon: isPasswordHidden ? (
                          <EyeOutlined
                            style={{ marginLeft: 10 }}
                            onClick={() => setIsPasswordHidden(false)}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            style={{ marginLeft: 10 }}
                            onClick={() => setIsPasswordHidden(true)}
                          />
                        ),
                      },
                    }}
                    errors={passwordStageError?.newPassword}
                    greenHelperText={passwordStageGreen?.newPassword}
                    dir={
                      passwordStageData?.newPassword?.length !== 0
                        ? 'ltr'
                        : 'rtl'
                    }
                  />
                  <Input
                    name="repeat-password"
                    type={isRepeatPasswordHidden ? 'password' : 'text'}
                    className="new-password-input"
                    value={passwordStageData?.newPasswordRepeat}
                    onChange={(el) =>
                      setPasswordStageData({
                        ...passwordStageData,
                        newPasswordRepeat: el.target.value,
                      })
                    }
                    placeholder="تکرار رمز عبور"
                    adorments={{
                      startAdornment: {
                        adornmentIcon: (
                          <img
                            className="start-adornment"
                            src="/assets/icons/unlock.svg"
                          />
                        ),
                      },
                      endAdornment: {
                        adornmentIcon: isRepeatPasswordHidden ? (
                          <EyeOutlined
                            style={{ marginLeft: 10 }}
                            onClick={() => setIsRepeatPasswordHidden(false)}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            style={{ marginLeft: 10 }}
                            onClick={() => setIsRepeatPasswordHidden(true)}
                          />
                        ),
                      },
                    }}
                    errors={passwordStageError?.newPasswordRepeat}
                    dir={
                      passwordStageData?.newPasswordRepeat?.length !== 0
                        ? 'ltr'
                        : 'rtl'
                    }
                  />
                  <Button
                    type="info"
                    onClick={(e) => changePassword()}
                    text="تایید"
                    className={`new-password-button `}
                    htmlType="button"
                    disabled={
                      passwordStageData?.newPassword?.length === 0 || isLoading
                        ? true
                        : false
                    }
                    loading={isLoading ? true : false}
                  />
                </form>
              </div>
            </div>
          </>
        );

        break;

      default:
        return <>invalid stage</>;
    }
  }

  return <Card className="xpay-profile-change-password">{renderStage()}</Card>;
};

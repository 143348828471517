import * as React from 'react';
import './transaction-order-modal.scss';
import { Order, Transaction } from 'core/domains';
import { isMobile } from 'react-device-detect';
import { TransactionOrderDetailModalMobileContent } from './mobile/transaction-order-modal-content-mobile';
import { TransactionOrderDetailModalDesktopContent } from './desktop/transaction-order-modal-content-desktop';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transactionItem?: Transaction;
  orderItem?: Order;
  modalLoading?: boolean;
}

export const TransactionOrderDetailModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  transactionItem,
  orderItem,
  modalLoading,
}) => {
  const closeDrawer = () => {
    setIsOpen(false);
  };

  return orderItem !== null || transactionItem !== null ? (
    <>
      {isMobile ? (
        <TransactionOrderDetailModalMobileContent
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          transactionItem={transactionItem}
          orderItem={orderItem}
        />
      ) : (
        <TransactionOrderDetailModalDesktopContent
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          transactionItem={transactionItem}
          orderItem={orderItem}
        />
      )}
    </>
  ) : (
    <></>
  );
};

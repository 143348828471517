import * as React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { LevelInfoCard } from '../level-card';
import './level-card-list.scss';
import { Divider, Spin } from 'antd';
import { UserLevel } from 'core/domains';
import { getViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { digitSperator } from 'utils/scripts/exchange';

type Props = {
  level: UserLevel;
  className?: string;
};
export const LevelCardList: React.FC<Props> = ({ level, className }) => {
  const [activeItem, setActiveItem] = React.useState<number>(
    level?.uniqueName?.id,
  );

  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [levels, setLevels] = React.useState<UserLevel[]>([]);

  const [levelOne, setLevelOne] = React.useState<UserLevel>();
  const [levelTwo, setLevelTwo] = React.useState<UserLevel>();
  const [levelThree, setLevelThree] = React.useState<UserLevel>();

  const getAllLevels = () => {
    setCardLoading(true);
    getViaAuth(API.level.getAll).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) setLevels(response.value);
    });
  };

  React.useEffect(() => {
    levels.length === 0 && getAllLevels();
  }, []);

  React.useEffect(() => {
    setLevelOne(levels.filter((s) => s.uniqueName.id === 1)[0]);
    setLevelTwo(levels.filter((s) => s.uniqueName.id === 2)[0]);
    setLevelThree(levels.filter((s) => s.uniqueName.id === 3)[0]);
  }, [levels]);

  return (
    <Spin spinning={cardLoading} className={`level-card-list ${className}`}>
      {levelOne && levelTwo && levelThree && (
        <>
          <LevelInfoCard
            star={0}
            name={levelOne.name}
            active={activeItem}
            setOpen={setActiveItem}
            badge={
              level?.uniqueName.id === levelOne.uniqueName.id
                ? 'سطح فعلی'
                : undefined
            }
            checked={
              level?.uniqueName.id > 1 ? (
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 6.26691L5.73333 10.0002L13.2 2.00024"
                    stroke="white"
                    stroke-width="2.1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : undefined
            }
          >
            <Divider />
            <div className="accesses-list">
              <span>دسترسی ها و محدودیت ها</span>
              <ul>
                <li>
                  <CheckCircleFilled />
                  <span>واریزی ارزی نامحدود</span>
                </li>
                <li>
                  <CheckCircleFilled />
                  <span>پرداشت ارزی {levelOne.maxCryptoWithdrawal} تومان</span>
                </li>
                <li>
                  <CheckCircleFilled />
                  <span>{levelOne.sumDaysStayInLevel} روز تا سطح بعدی</span>
                </li>
                <li>
                  <CheckCircleFilled />
                  <span>واریز تومانی نامحدود</span>
                </li>
                <li>
                  <CheckCircleFilled />
                  <span>برداشت تومانی {levelOne.maxIrtWithdrawal} تومان</span>
                </li>
              </ul>
            </div>
            <Divider />
            <div className="level-note">
              <span>
                <img src="/assets/icons/level-card/info-filled.svg" /> نکته
              </span>
              <p>{levelOne.description}</p>
            </div>
          </LevelInfoCard>
          <LevelInfoCard
            star={1}
            setOpen={setActiveItem}
            name={levelTwo.name}
            active={activeItem}
            badge={
              level.uniqueName.id === levelTwo.uniqueName.id
                ? 'سطح فعلی'
                : undefined
            }
            checked={
              level?.uniqueName?.id > 2 ? (
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 6.26691L5.73333 10.0002L13.2 2.00024"
                    stroke="white"
                    stroke-width="2.1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : undefined
            }
          >
            <Divider />
            <div className="accesses-list">
              <span>دسترسی ها و محدودیت ها</span>

              <ul>
                <li>
                  <CheckCircleFilled />
                  <span>واریزی ارزی نامحدود</span>
                </li>

                <li>
                  <CheckCircleFilled />
                  <span>واریز تومانی نامحدود</span>
                </li>
                <li>
                  <CheckCircleFilled />
                  <span>برداشت تومانی {levelTwo.maxIrtWithdrawal} تومان</span>
                </li>
              </ul>
            </div>
            <Divider />
            <div className="required-info">
              <span>اطلاعات مورد نیاز</span>

              <div className="requirement-item">
                <div className="icon">
                  <img src="/assets/icons/level-card/idcard.svg" />
                </div>
                <div className="info">
                  <span>شماره موبایل</span>
                  <p>شماره موبایل به نام خود</p>
                </div>
              </div>
              <div className="requirement-item">
                <div className="icon">
                  <img src="/assets/icons/level-card/calendar.svg" />
                </div>
                <div className="info">
                  <span>مشخصات هویتی</span>
                  <p>مشخصات هویتی شخص ثبت نام کننده</p>
                </div>
              </div>
              <div className="requirement-item">
                <div className="icon">
                  <img src="/assets/icons/level-card/home.svg" />
                </div>
                <div className="info">
                  <span>حساب بانکی</span>
                  <p>افزودن حداقل یک حساب بانکی به نام خود</p>
                </div>
              </div>
            </div>
            <Divider />
            <div className="level-note">
              <span>
                <img src="/assets/icons/level-card/info-filled.svg" /> نکته
              </span>
              <p>{levelTwo.description}</p>
            </div>
          </LevelInfoCard>
          <LevelInfoCard
            star={2}
            name={levelThree.name}
            active={activeItem}
            setOpen={setActiveItem}
            badge={
              level.uniqueName.id === levelThree.uniqueName.id
                ? 'سطح فعلی'
                : undefined
            }
            checked={
              level?.uniqueName?.id > 3 ? (
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 6.26691L5.73333 10.0002L13.2 2.00024"
                    stroke="white"
                    stroke-width="2.1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : undefined
            }
          >
            <Divider />
            <div className="accesses-list">
              <span>دسترسی ها و محدودیت ها</span>

              <ul>
                <li>
                  <CheckCircleFilled />
                  <span>واریز ارزی نامحدود</span>
                </li>
                <li>
                  <CheckCircleFilled />
                  <span>برداشت ارزی نامحدود</span>
                </li>
                <li>
                  <CheckCircleFilled />
                  <span>واریزی تومانی نامحدود</span>
                </li>
                <li>
                  <CheckCircleFilled />
                  <span>
                    برداشت تومانی{' '}
                    {digitSperator(levelThree.maxIrtWithdrawal?.toString())}{' '}
                    تومان
                  </span>
                </li>
              </ul>
            </div>
            <Divider />
            <div className="required-info">
              <span>اطلاعات مورد نیاز</span>
              <div className="requirement-item">
                <div className="icon">
                  <img src="/assets/icons/level-card/full-name.svg" />
                </div>
                <div className="info">
                  <span>
                    عکس مدارک <span>(مطابق با نمونه عکس)</span>
                  </span>
                  <p>تصویر از متن دست نوشته در کنار کارت ملی و کارت بانکی</p>
                </div>
              </div>
            </div>
            <Divider />
            <div className="level-note">
              <span>
                <img src="/assets/icons/level-card/info-filled.svg" /> نکته
              </span>
              <p>{levelThree.description}</p>
            </div>
          </LevelInfoCard>
        </>
      )}
    </Spin>
  );
};

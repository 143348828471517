import * as React from 'react';
import { useRef } from 'react';
import './select-bankAccount-modal.scss';
import { Modal } from '../modal/modal';
import { Cart } from '../../core/domains';
import useOnBlur from '../../utils/scripts/useOnBlur';

type Props = {
  carts: Cart[];
  isOpen: boolean;
  setIsOpen: any;
  onChange: any;
  title: string;
  isIban?: boolean;
};

export const SelectBankAccountModal: React.FC<Props> = (props) => {
  const modalRef = useRef<any>();

  useOnBlur(modalRef, () => {
    if (props.isOpen) props.setIsOpen(false);
  });
  return (
    props.carts && (
      <Modal
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        title={props.title}
        closeAble
        className="carts-dropdown-modal"
      >
        <div className={`carts-dropdown`}>
          <ul>
            {props.carts.map((cart) => {
              return (
                <li
                  onClick={() => props.onChange(cart)}
                  key={`cart-${cart.id}`}
                >
                  <div className="bank-info">
                    <img
                      src={`/banks/${cart.bank.code.toLowerCase()}.svg`}
                      alt=""
                    />
                    <span>{cart.bank.name}</span>
                  </div>
                  <span>{props.isIban ? cart.iBan : cart.cardNumber}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
    )
  );
};

import { Alert, Button, Card } from 'components';
import * as React from 'react';
import { useState } from 'react';
import './thankyou-irt.scss';
import {
  postViaAuth,
  responseValidator,
} from '../../../../../../utils/scripts/api';
import { API } from '../../../../../../apis';
import { useLocation, useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { copyToClipboard } from 'utils/scripts/copyToClipboard';
import { TransactionVerify } from '../../../../../../core/domains/transactions/transactionVerify';
import { ToIranCurrency } from '../../../../../../utils/scripts/decimal';
import moment from 'jalali-moment';
import { TGBOT_ID } from '../../../../../../apis/constants';

export const ThankYouIrt: React.FC = () => {
  const URL = window.location;
  var urlSections = URL.pathname.split('/');
  const trackingCode = urlSections[urlSections.length - 1];

  const [cardIsLoading, setCardIsLoading] = useState<boolean>(true);

  const orderIdRef = React.useRef<HTMLElement>(null);

  const navigate = useNavigate();

  const [verifyResult, setVerifyResult] = useState<boolean | null>(null);

  const [verifyData, setVerifyData] = useState<TransactionVerify | null>(null);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  // Vandar Query parametr payment_status=OK
  //Zibal Query paramer status=1
  const payment =
    query.get('payment_status') === 'OK' || query.get('status') === '1';
  var identifier = query.get('token');
  // PayStar Card
  const cardNumber = query.get('card_number');
  //PayStar trackingCode
  const additionalData = query.get('tracking_code');
  const verifyTransaction = () => {
    setCardIsLoading(true);

    (async () =>
      await postViaAuth<TransactionVerify>(API.deposit.currency.verify, {
        cardNumber: cardNumber,
        trackingCode: trackingCode,
        additionalData: additionalData,
      }).then((response: any) => {
        setCardIsLoading(false);
        if (responseValidator(response.status)) {
          setVerifyResult(true);
          setVerifyData(response.value);
        } else {
          setVerifyResult(false);
        }
      }))();
  };
  React.useEffect(() => {
    verifyTransaction();
  }, []);

  return (
    <div className="xpay-thankyou-dashboard">
      {verifyResult === null ? (
        <Card blur={cardIsLoading} className="transaction-info">
          <div className="header">
            <div className="right-side">
              <h3>درحال بارگذاری نتیجه پرداخت</h3>
              <p>
                شارژ کیف پول شما به مبلغ تومان در تاریخ با موفقیت انجام شد. از
                طریق صفحه سوابق میتوانید به تاریخچه ی شارژ کیف پول خود دسترسی
                داشته باشید و از وضعیت سفارشات خود مطلع شوید.
              </p>
            </div>
            <div className="left-side">
              <img src="/assets/images/thankyou-success.svg" alt="" />
            </div>
          </div>
          <div className="body">
            <ul>
              <li className="mobile-show">
                <div className="col">
                  <span>شناسه سفارش</span>
                  <span>
                    <span className="en" ref={orderIdRef}>
                      {trackingCode}
                    </span>
                    <CopyOutlined
                      onClick={() =>
                        copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                      }
                    />
                  </span>
                </div>

                <div className="col">
                  <span>زمان پرداخت</span>
                </div>
              </li>
              <li className="mobile-show">
                <div className="col">
                  <span>مقدار</span>
                </div>
                <div className="col">
                  <span>وضعیت</span>
                </div>
              </li>
              <li className="desktop-show">
                <span>شناسه سفارش</span>
                <span>
                  <span className="en" ref={orderIdRef}>
                    {trackingCode}
                  </span>
                  <CopyOutlined
                    onClick={() =>
                      copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                    }
                  />
                </span>
              </li>
              <li className="desktop-show">
                <span>زمان پرداخت</span>
              </li>
              <li className="desktop-show">
                <span>مقدار</span>
              </li>

              <li className="desktop-show">
                <span>وضعیت</span>
              </li>
            </ul>
          </div>
          <div className="footer">
            <Button
              type={'info'}
              text={'مشاهده سوابق'}
              onClick={() => navigate('/dashboard/orders')}
            />
            <Button
              type={'outlined'}
              text={`بازگشت به صفحه اصلی`}
              onClick={() =>
                verifyData && verifyData.sourcePlatform?.id === 2
                  ? window.open('https://t.me/Xpay_Robot')
                  : navigate('/dashboard/wallet')
              }
            />
          </div>
        </Card>
      ) : verifyResult && verifyData ? (
        <>
          <Alert
            title="تبریک"
            type="success"
            message="پرداخت تومانی شما با موفقیت انجام شد."
            closeAble
          />
          <Card blur={cardIsLoading} className="transaction-info">
            <div className="header">
              <div className="right-side">
                <h3>کیف پول شما با موفقیت شارژ شد</h3>
                <p>
                  شارژ کیف پول شما به مبلغ
                  {ToIranCurrency(verifyData.amount)}
                  تومان در تاریخ{' '}
                  {moment().locale('fa').local().format('jYYYY/MM/DD HH:mm')}
                  با موفقیت انجام شد. از طریق صفحه سوابق میتوانید به تاریخچه ی
                  شارژ کیف پول خود دسترسی داشته باشید و از وضعیت سفارشات خود
                  مطلع شوید.
                </p>
              </div>
              <div className="left-side">
                <img src="/assets/images/thankyou-success.svg" alt="" />
              </div>
            </div>
            <div className="body">
              <ul>
                <li className="mobile-show">
                  <div className="col">
                    <span>شناسه سفارش</span>
                    <span>
                      <span className="en" ref={orderIdRef}>
                        {trackingCode}
                      </span>
                      <CopyOutlined
                        onClick={() =>
                          copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                        }
                      />
                    </span>
                  </div>

                  <div className="col">
                    <span>زمان پرداخت</span>
                    <span>
                      {' '}
                      {moment()
                        .locale('fa')
                        .local()
                        .format('jYYYY/MM/DD HH:mm')}
                    </span>
                  </div>
                </li>
                <li className="mobile-show">
                  <div className="col">
                    <span>مقدار</span>
                    <span>{ToIranCurrency(verifyData.amount)} تومان</span>
                  </div>
                  <div className="col">
                    <span>وضعیت</span>
                    <span className="succssed">
                      {verifyData.transactionState.title}
                    </span>
                  </div>
                </li>
                <li className="desktop-show">
                  <span>شناسه سفارش</span>
                  <span>
                    <span className="en" ref={orderIdRef}>
                      {trackingCode}
                    </span>
                    <CopyOutlined
                      onClick={() =>
                        copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                      }
                    />
                  </span>
                </li>
                <li className="desktop-show">
                  <span>زمان پرداخت</span>
                  <span>
                    {' '}
                    {moment().locale('fa').local().format('jYYYY/MM/DD HH:mm')}
                  </span>
                </li>
                <li className="desktop-show">
                  <span>مقدار</span>
                  <span>{ToIranCurrency(verifyData.amount)} تومان</span>
                </li>

                <li className="desktop-show">
                  <span>وضعیت</span>
                  <span className="succssed">
                    {verifyData.transactionState.title}
                  </span>
                </li>
              </ul>
            </div>
            <div className="footer">
              <Button
                type={'info'}
                text={'مشاهده سوابق'}
                onClick={() => navigate('/dashboard/orders')}
              />
              <Button
                type={'outlined'}
                text={'بازگشت به صفحه اصلی'}
                onClick={() => navigate('/dashboard/wallet')}
              />
            </div>
          </Card>
        </>
      ) : (
        <>
          {' '}
          <Alert
            title="تراکنش ناموفق"
            type="danger"
            message="متاسفانه پرداخت شما انجام نشد، در صورت کسر از حساب مبلغ واریزی تا ۷۲ ساعت به حساب شما برگشت داده میشود"
            closeAble
          />
          <Card blur={cardIsLoading} className="transaction-info">
            <div className="header">
              <div className="right-side">
                <h3>کیف پول شما با موفقیت شارژ نشد</h3>
                <p>
                  شارژ کیف پول شما با موفقیت انجام نشد. از طریق صفحه سوابق
                  میتوانید به تاریخچه ی شارژ کیف پول خود دسترسی داشته باشید و از
                  وضعیت سفارشات خود مطلع شوید.
                </p>
              </div>
              <div className="left-side">
                <img src="/assets/images/thankyou/danger.svg" alt="" />
              </div>
            </div>
            <div className="body">
              <ul>
                <li className="mobile-show">
                  <div className="col">
                    <span>شناسه سفارش</span>
                    <span>
                      <span className="en" ref={orderIdRef}>
                        {trackingCode}
                      </span>
                      <CopyOutlined
                        onClick={() =>
                          copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                        }
                      />
                    </span>
                  </div>
                  <div className="col">
                    <span>زمان پرداخت</span>
                    <span>
                      {' '}
                      {moment()
                        .locale('fa')
                        .local()
                        .format('jYYYY/MM/DD HH:mm')}
                    </span>
                  </div>
                </li>
                <li className="mobile-show">
                  <div className="col">
                    <span>وضعیت</span>
                    <span className="danger">ناموفق</span>
                  </div>
                </li>
                <li className="desktop-show">
                  <span>شناسه سفارش</span>
                  <span>
                    <span className="en" ref={orderIdRef}>
                      {trackingCode}
                    </span>
                    <CopyOutlined
                      onClick={() =>
                        copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                      }
                    />
                  </span>
                </li>
                <li className="desktop-show">
                  <span>زمان پرداخت</span>
                  <span>
                    {' '}
                    {moment().locale('fa').local().format('jYYYY/MM/DD HH:mm')}
                  </span>
                </li>

                <li className="desktop-show">
                  <span>وضعیت</span>
                  <span className="danger">ناموفق</span>
                </li>
              </ul>
            </div>
            <div className="footer">
              <Button
                type={'info'}
                text={'مشاهده سوابق'}
                onClick={() => navigate('/dashboard/orders')}
              />
              <Button
                type={'outlined'}
                text={`بازگشت به ${
                  verifyData?.sourcePlatform?.id === 1
                    ? 'صفحه اصلی'
                    : 'ربات تلگرام'
                }`}
                onClick={() =>
                  verifyData?.sourcePlatform?.id === 1
                    ? navigate('/dashboard/wallet')
                    : window.open(TGBOT_ID)
                }
              />
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

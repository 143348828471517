import { Button, Input } from 'components';
import * as React from 'react';
import { phoneRegex } from 'utils/constants/constants';
import call from 'assets/icons/call.svg';

import 'routes/pages/signin/stages/password-reset/password-reset.scss';
import {
  getValidationErrors,
  hasValidationError,
  post,
  responseValidator,
} from 'utils/scripts/api';
import { API } from 'apis';
import { message } from '../../../../../components/toast/toast';
import { MobileNavigate } from 'components/mobile-navigate/mobile-navigate';
import { faNumToEnNum, IsNumeric } from 'utils/scripts/decimal';
import { useNavigate } from 'react-router-dom';

type Props = {};

const { useState } = React;

export const PasswordReset: React.FC<Props> = (props) => {
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState<string[]>(['']);

  const navigate = useNavigate();

  function handlePhoneChange(value: string): void {
    setHasError(false);
    // all number Fa to En
    if (value !== '') value = faNumToEnNum(value);
    // validation nationalCode
    if (value !== '' && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    setPhoneValue(value);
  }

  function handleSubmit(): void {
    setIsLoading(true);
    if (phoneValue.search(phoneRegex) !== 0) {
      setIsLoading(false);
      setHasError(true);
      setFieldError(['شماره موبایل وارد شده نادرست است.']);
    } else {
      post(API.forgotPassowrd, { phoneNumber: phoneValue })
        .then(function (response: any) {
          setIsLoading(false);
          if (responseValidator(response.status)) {
            localStorage.setItem('phoneNumber', phoneValue);
            navigate('/login/password-reset-code');
          }
        })
        .then(function (error) {});
    }
  }

  return (
    <div className="password-reset-stage">
      <MobileNavigate
        title="بازیابی رمز عبور"
        onBackClick={() => navigate('/login/')}
      />
      <h3>بازیابی رمز عبور</h3>
      <div className="password-reset-main">
        <Input
          name="phone"
          type="text"
          className="password-reset-input"
          value={phoneValue}
          onChange={(el) => handlePhoneChange(el.target.value)}
          placeholder="موبایل"
          onPressEnter={() => handleSubmit()}
          adorments={{
            startAdornment: {
              adornmentIcon: <img src={call} alt={'call'} />,
            },
          }}
          hasError={hasError}
          errors={fieldError}
          maxLength={11}
          dir={phoneValue.length !== 0 ? 'ltr' : 'rtl'}
        />
      </div>
      <Button
        type="info"
        htmlType="button"
        text="ارسال کد بازیابی"
        disabled={
          phoneValue.length !== 11 || hasError || isLoading ? true : false
        }
        className={`password-reset-button ${
          phoneValue.length !== 11 || hasError
            ? 'button-disabled'
            : '' || isLoading
        }`}
        onClick={() => handleSubmit()}
        loading={isLoading ? true : false}
      />
    </div>
  );
};

import * as React from 'react';
import { useEffect } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'routes/pages/signin/signin.scss';
import { APP_VERSION, APPNAME, SITE_ADDRESS } from '../../../apis/constants';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const { useState } = React;

SwiperCore.use([Autoplay]);
SwiperCore.use([Pagination]);

enum Stages {
  LOGIN = 'LOGIN',
  CODE = 'CODE',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PASSWORD_RESET_CODE = 'PASSWORD_RESET_CODE',
  NEW_PASSWORD = 'NEW_PASSWORD',
  TWOFACTOR = 'TWOFACTOR',
}

interface Props {
  component: React.ReactNode;
}

export const SignIn: React.FC<Props> = ({ component }) => {
  // States
  useEffect(() => {}, []);
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>ورود | {APPNAME}</title>
        <meta
          name="description"
          content="خرید و فروش اتوماتیک و آسان پرفکت مانی ، تتر و رمز ارز ها احراز هویت هوشمند و سریع زیر ۱ دقیقه بدون معطلی نرخ های رقابتی و بدون کارمزد"
        />
      </Helmet>
      <div className="xpay-signin">
        <div className="signin-right">
          {
            <div className="xpay-signin-slider">
              <Swiper
                modules={[Autoplay, Pagination]}
                pagination={{ clickable: true }}
                autoplay
                // loop
                spaceBetween={50}
                slidesPerView={1}
              >
                <SwiperSlide
                  style={{
                    background: 'url("/assets/images/slides/signin-xpay.png")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <div className="slide-content">
                    <div className="footer">
                      <h2>پلتفرم خرید و فروش ارزهای دیجیتال</h2>
                      <p>
                        ارزهای دیجیتال خود را با استفاده از کیف پول امن{' '}
                        {APPNAME} مدیریت و خرید و فروش کنید
                      </p>
                      <div className="app-info desktop-show">
                        <div className="footer-logo">
                          <a href="/"></a>
                        </div>
                        <div className="copyright">
                          <span className="year">
                            Copyright © {new Date(Date.now()).getFullYear()} |
                            Made with ❤ | {APP_VERSION}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide
                  style={{
                    background: '$primary',
                  }}
                >
                  <div className="slide-content">
                    <img
                      src="/assets/images/slides/globe.svg"
                      alt="xpay-payments"
                    />
                    <div className="footer">
                      <h2>پلتفرم خرید و فروش ارزهای دیجیتال</h2>
                      <p>
                        ارزهای دیجیتال خود را با استفاده از کیف پول امن ایکس پی
                        مدیریت و خرید و فروش کنید
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    background: '$primary',
                  }}
                >
                  <div className="slide-content">
                    <img
                      src="/assets/images/slides/payment.svg"
                      alt="xpay-payments"
                    />
                    <div className="footer">
                      <h2>پلتفرم خرید و فروش ارزهای دیجیتال</h2>
                      <p>
                        ارزهای دیجیتال خود را با استفاده از کیف پول امن ایکس پی
                        مدیریت و خرید و فروش کنید
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    background: '$primary',
                  }}
                >
                  <div className="slide-content">
                    <img
                      src="/assets/images/slides/phone.svg"
                      alt="xpay-payments"
                    />
                    <div className="footer">
                      <h2>پلتفرم خرید و فروش ارزهای دیجیتال</h2>
                      <p>
                        ارزهای دیجیتال خود را با استفاده از کیف پول امن ایکس پی
                        مدیریت و خرید و فروش کنید
                      </p>
                    </div>
                  </div>
                </SwiperSlide> */}
              </Swiper>
            </div>
          }
        </div>
        <div className="signin-left">
          <div
            className={`back-btn ${
              window.location.pathname.replaceAll('/', '') === 'login'
                ? 'disabled'
                : ''
            }`}
            onClick={() =>
              window.location.pathname.replaceAll('/', '') !== 'login' &&
              navigate(-1)
            }
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="48"
                height="48"
                rx="10"
                fill="url(#paint0_linear_751_1700)"
              />
              <path
                d="M35.6074 24.0002C35.6074 24.9115 34.9303 25.6646 34.0517 25.7838L33.8074 25.8002L14.1946 25.8002C13.2005 25.8002 12.3946 24.9943 12.3946 24.0002C12.3946 23.0889 13.0718 22.3358 13.9504 22.2166L14.1946 22.2002L33.8074 22.2002C34.8015 22.2002 35.6074 23.0061 35.6074 24.0002Z"
                fill="white"
              />
              <path
                d="M24.5028 13.7345C25.1405 14.3749 25.1965 15.375 24.6721 16.0786L24.4974 16.2801L16.7457 23.9976L24.4974 31.7195C25.1378 32.3572 25.198 33.357 24.6767 34.0628L24.5028 34.2651C23.8652 34.9055 22.8653 34.9657 22.1595 34.4443L21.9573 34.2705L12.9237 25.2753C12.2813 24.6357 12.2229 23.6322 12.7485 22.9264L12.9237 22.7243L21.9573 13.7291C22.6617 13.0276 23.8014 13.0301 24.5028 13.7345Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_751_1700"
                  x1="-13.5105"
                  y1="2.69178e-06"
                  x2="58.2718"
                  y2="6.82133"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#3F62FE" />
                  <stop offset="1" stop-color="#678AF5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          {component}
        </div>
      </div>
      <div className="app-info mobile-show">
        <div className="footer-logo">
          <a href="/"></a>
        </div>
        <div className="copyright">
          <span className="year">
            Copyright © {new Date(Date.now()).getFullYear()} | {APP_VERSION}
          </span>
          <a href="/">{SITE_ADDRESS} </a>
        </div>
      </div>
      <div className="mobile-layout-circle">
        <svg
          width="375"
          height="389"
          viewBox="0 0 375 389"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M187.295 560.508C341.849 560.508 467.14 435.258 467.14 280.754C467.14 126.25 341.849 1 187.295 1C32.7403 1 -92.5508 126.25 -92.5508 280.754C-92.5508 435.258 32.7403 560.508 187.295 560.508Z"
            stroke="url(#paint0_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.295 538.244C329.549 538.244 444.869 422.962 444.869 280.754C444.869 138.546 329.549 23.2639 187.295 23.2639C45.0402 23.2639 -70.2798 138.546 -70.2798 280.754C-70.2798 422.962 45.0402 538.244 187.295 538.244Z"
            stroke="url(#paint1_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.295 515.972C317.244 515.972 422.59 410.661 422.59 280.754C422.59 150.847 317.244 45.5361 187.295 45.5361C57.345 45.5361 -48 150.847 -48 280.754C-48 410.661 57.345 515.972 187.295 515.972Z"
            stroke="url(#paint2_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.295 493.708C304.944 493.708 400.318 398.365 400.318 280.754C400.318 163.143 304.944 67.8002 187.295 67.8002C69.6449 67.8002 -25.729 163.143 -25.729 280.754C-25.729 398.365 69.6449 493.708 187.295 493.708Z"
            stroke="url(#paint3_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.295 471.436C292.64 471.436 378.039 386.065 378.039 280.754C378.039 175.444 292.64 90.0724 187.295 90.0724C81.9503 90.0724 -3.44873 175.444 -3.44873 280.754C-3.44873 386.065 81.9503 471.436 187.295 471.436Z"
            stroke="url(#paint4_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.295 449.172C280.34 449.172 355.768 373.769 355.768 280.754C355.768 187.739 280.34 112.336 187.295 112.336C94.2501 112.336 18.8223 187.739 18.8223 280.754C18.8223 373.769 94.2501 449.172 187.295 449.172Z"
            stroke="url(#paint5_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.295 426.9C268.036 426.9 333.489 361.468 333.489 280.754C333.489 200.04 268.036 134.609 187.295 134.609C106.555 134.609 41.1021 200.04 41.1021 280.754C41.1021 361.468 106.555 426.9 187.295 426.9Z"
            stroke="url(#paint6_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.295 404.636C255.735 404.636 311.217 349.172 311.217 280.754C311.217 212.336 255.735 156.872 187.295 156.872C118.855 156.872 63.373 212.336 63.373 280.754C63.373 349.172 118.855 404.636 187.295 404.636Z"
            stroke="url(#paint7_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.294 382.363C243.43 382.363 288.936 336.871 288.936 280.754C288.936 224.637 243.43 179.145 187.294 179.145C131.158 179.145 85.6514 224.637 85.6514 280.754C85.6514 336.871 131.158 382.363 187.294 382.363Z"
            stroke="url(#paint8_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.296 360.1C231.131 360.1 266.667 324.575 266.667 280.754C266.667 236.933 231.131 201.409 187.296 201.409C143.46 201.409 107.924 236.933 107.924 280.754C107.924 324.575 143.46 360.1 187.296 360.1Z"
            stroke="url(#paint9_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.294 337.827C218.825 337.827 244.386 312.275 244.386 280.754C244.386 249.234 218.825 223.681 187.294 223.681C155.764 223.681 130.203 249.234 130.203 280.754C130.203 312.275 155.764 337.827 187.294 337.827Z"
            stroke="url(#paint10_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M187.294 315.563C206.525 315.563 222.115 299.979 222.115 280.754C222.115 261.53 206.525 245.945 187.294 245.945C168.063 245.945 152.474 261.53 152.474 280.754C152.474 299.979 168.063 315.563 187.294 315.563Z"
            stroke="url(#paint11_linear_1663_11477)"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1663_11477"
              x1="184.93"
              y1="0.999976"
              x2="184.93"
              y2="281"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1663_11477"
              x1="185.118"
              y1="23.2639"
              x2="185.118"
              y2="280.98"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1663_11477"
              x1="185.307"
              y1="45.5361"
              x2="185.307"
              y2="280.961"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_1663_11477"
              x1="185.495"
              y1="67.8002"
              x2="185.495"
              y2="280.941"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_1663_11477"
              x1="185.684"
              y1="90.0724"
              x2="185.684"
              y2="280.922"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_1663_11477"
              x1="185.872"
              y1="112.336"
              x2="185.872"
              y2="280.902"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_1663_11477"
              x1="186.06"
              y1="134.609"
              x2="186.06"
              y2="280.883"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_1663_11477"
              x1="186.248"
              y1="156.872"
              x2="186.248"
              y2="280.863"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_1663_11477"
              x1="186.435"
              y1="179.145"
              x2="186.435"
              y2="280.843"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_1663_11477"
              x1="186.625"
              y1="201.409"
              x2="186.625"
              y2="280.824"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_1663_11477"
              x1="186.812"
              y1="223.681"
              x2="186.812"
              y2="280.804"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_1663_11477"
              x1="187"
              y1="245.945"
              x2="187"
              y2="280.785"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEE4FF" />
              <stop offset="0.789412" stop-color="white" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </>
  );
};

import * as React from 'react';

import './widthrawl-pm-box.scss';
import { Button, Card, Input, Modal, SwitchBar } from 'components';
import { faNumToEnNum } from 'utils/scripts/decimal';
import { Enum, Transaction, Voucher } from 'core/domains';
import { Market } from 'core/domains/index';
import { Asset } from 'core/domains/markets/asset';
import { getMineBalance } from 'core/repositories';
import { useLocation } from 'react-router-dom';
import { getIconPath } from 'utils/scripts/getIconPath';
import { message } from 'components/toast/toast';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import useOnBlur from 'utils/scripts/useOnBlur';
import { WithdrawalConfirm } from '../confirmModal/withdrawal-confirm-modal';
import { PmWithdrawalTypeModal } from './withdrawal-type-modal/withdrawal-type-modal';
import { ReactComponent as CaretDown } from 'assets/icons/carrot-down-blue.svg';

const { useState } = React;

type Props = {};

export const WidthrawlPmBox: React.FC<Props> = () => {
  const [cardIsLoading, setCardIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  const location = useLocation();

  const [results, setResults] = useState<Market[]>([]);
  const [marketsIsOpen, setMarketsIsOpen] = useState<boolean>(false);
  const [market, setMarket] = useState<Market>();

  const [withdrawalTypeModalIsOpen, setWithdrawalTypeModalIsOpen] =
    useState<boolean>(false);
  const [withdrawalType, setWithdrawalType] = useState<'account' | 'voucher'>();
  // states
  const [widthrawlValue, setWidthrawlValue] = useState<string>('');
  const [search, setSearch] = useState<string>();

  const [addressWallet, setAddressWallet] = useState<string>('');
  const [verificationCode, setMemoAddress] = useState<string>('');

  const [modalStage, setModalStage] = useState<'code' | 'thankyou'>('code');
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const [code, setCode] = useState<string>('');
  const [transaction, setTransaction] = useState<Transaction>();
  const [isMoreThanBalance, setIsMoreThanBalance] = useState<boolean>(false);

  const [twoFactorMethod, setTwoFactorMethod] = useState<Enum>();
  const [isTwoStepActive, setIsTwoStepActive] = useState<number>();
  const [balance, setBalance] = useState<Asset>();
  const [voucher, setVoucher] = useState<Voucher | null>(null);
  const [withdrawPrecision, setWithdrawPrecision] = useState<number>(6);

  const onWithdrawalTypeChangeHandle = (
    withdrawalType: 'account' | 'voucher',
  ) => {
    setWithdrawalType(withdrawalType);
    setWithdrawalTypeModalIsOpen(false);
  };

  const marketDropdownRef = React.useRef<any>();
  const networkDropdownRef = React.useRef<any>();
  useOnBlur(marketDropdownRef, () => marketsIsOpen && setMarketsIsOpen(false));
  useOnBlur(
    networkDropdownRef,
    () => withdrawalTypeModalIsOpen && setWithdrawalTypeModalIsOpen(false),
  );

  const stages: Enum[] = [
    {
      id: 1,
      title: `موجودی قابل برداشت  پرفکت مانی :
       ${
         balance?.assets.filter((s) => s.symbol == 'PM')[0]?.balance ?? 0
       } واحد`,
    },
  ];

  const fetchAllData = () => {
    !balance && getMineBalance(setCardIsLoading, setBalance);
  };

  React.useEffect(() => {
    resetForm();

    fetchAllData();
  }, []);

  const onWidthrawlValueChangeHandler = (value: string) => {
    value = faNumToEnNum(value);
    const regex = new RegExp(`^(\\d*\\.{0,100}\\d{0,${withdrawPrecision}})$`);
    if (regex.test(value)) {
      var condition =
        parseFloat(widthrawlValue.replaceAll(',', '') + value) >
        (balance?.assets.filter((s) => s.symbol == 'PM')[0].balance || 0);
      setWidthrawlValue(faNumToEnNum(value));
      setIsMoreThanBalance(condition);
    }
  };

  const onConfirmHandler = () => {
    setLoading(true);
    postViaAuth(API.widthrawl.withdrawalPm, {
      amount: parseFloat(widthrawlValue),
      walletAddress: addressWallet,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalStage('code');
        setModalIsOpen(true);
        setTwoFactorMethod(response.value.twoFactorMethod);
        setTransaction(response.value.transaction);
        setIsWaiting(response.data.value ? false : true);
        setIsTwoStepActive(response.value.isTwoStepActive);
      }
    });
  };

  function resetForm() {
    setModalStage('code');
    setAddressWallet('');
    setMemoAddress('');
    setCode('');
  }

  function onClose() {
    setModalIsOpen(false);
    resetForm();
  }

  const onVerifyHandler = (code: string) => {
    if (code.length == 6) {
      setModalLoading(true);
      postViaAuth(API.widthrawl.verifyWithdrawalPm, {
        code: code,
        trackingCode: transaction?.trackingCode,
      }).then((response: any) => {
        setModalLoading(false);
        if (responseValidator(response.status)) {
          response.value.voucher &&
            setVoucher({
              voucher_code: response.value.voucher,
              activation_code: response.value.voucherActivationCode,
            });
          message.success('تراکنش برداشت شما با موفقیت ثبت شد');
          resetForm();
          setModalStage('thankyou');
        }
      });
    }
  };

  return (
    <>
      <div className={`xpay-widthrawl-pm-box `}>
        <PmWithdrawalTypeModal
          onTypeChange={onWithdrawalTypeChangeHandle}
          isOpen={withdrawalTypeModalIsOpen}
          setIsOpen={setWithdrawalTypeModalIsOpen}
        />

        <Modal
          isOpen={modalIsOpen}
          className="withdrawl-pm-modal"
          setIsOpen={setModalIsOpen}
        >
          {transaction && (
            <WithdrawalConfirm
              onBack={onClose}
              isTwoStepActive={isTwoStepActive ?? 0}
              setIsOpen={setModalIsOpen}
              setLoading={setLoading}
              isWaiting={isWaiting}
              marketName={'پرفکت مانی'}
              trackingCode={transaction?.trackingCode}
              stage={modalStage}
              amount={parseFloat(widthrawlValue)}
              loading={modalLoading}
              onConfirm={onVerifyHandler}
              onResend={onConfirmHandler}
              symbol={transaction.market.symbol}
              voucher={voucher}
            />
          )}
        </Modal>

        <Card blur={cardIsLoading || !balance} className="content">
          <SwitchBar disabled items={stages} active={stages[0]} />

          <div className="widthrawl-options">
            <div className="widthrawl-value-row market-options-row">
              <div className="market-selector" ref={marketDropdownRef}>
                <span className="input-info-label"></span>
                <Input
                  type="text"
                  // className={`${isMoreThanBalance ? 'danger' : ''}`}
                  value={widthrawlValue}
                  label="مقدار کوین"
                  onPressEnter={() =>
                    !loading &&
                    widthrawlValue !== '' &&
                    addressWallet !== '' &&
                    onConfirmHandler()
                  }
                  onChange={(e) => {
                    onWidthrawlValueChangeHandler(e.target.value.toString());
                  }}
                  name="widthrawlValue"
                  dir={widthrawlValue === '' ? 'rtl' : 'ltr'}
                  placeholder={`مقدار پرفکت مانی را وارد کنید`}
                  maxLength={100}
                  adorments={{
                    startAdornment: {
                      adornmentIcon: (
                        <div className="mark">
                          <div className="selected-item">
                            <img src={getIconPath('pm')} />
                            <span>پرفکت مانی</span>
                            <span>PM</span>
                          </div>
                        </div>
                      ),
                    },
                    endAdornment: {
                      adornmentIcon: (
                        <div className="options">
                          <span>{widthrawlValue === '' && 0}</span>
                        </div>
                      ),
                    },
                  }}
                />
              </div>
            </div>
            <div className="network-options-row">
              <div className="network-selector" ref={networkDropdownRef}>
                <Input
                  value={
                    withdrawalType === 'account' ? 'حساب پرفکت مانی' : 'ووچر'
                  }
                  type="text"
                  onChange={(e) => {}}
                  name="withdrawal-type"
                  onClick={() => setWithdrawalTypeModalIsOpen(true)}
                  readonly
                  label="نوع برداشت"
                  adorments={{
                    endAdornment: {
                      adornmentIcon: (
                        <div className="icon">
                          <CaretDown
                            onClick={() => setWithdrawalTypeModalIsOpen(true)}
                          />
                        </div>
                      ),
                    },
                  }}
                />

                {withdrawalType === 'account' && (
                  <Input
                    value={addressWallet}
                    type="text"
                    onChange={(e) => {
                      setAddressWallet(e.target.value);
                    }}
                    onPressEnter={() =>
                      !loading &&
                      widthrawlValue !== '' &&
                      addressWallet !== '' &&
                      onConfirmHandler()
                    }
                    dir={'ltr'}
                    className="address-wallet-input"
                    name="addressWallet"
                    label="آدرس کیف پول"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="submit">
            <Button
              disabled={
                loading ||
                widthrawlValue === '' ||
                (withdrawalType == 'account' && addressWallet === '')
              }
              type="info"
              loading={loading}
              onClick={() => onConfirmHandler()}
              text="ثبت برداشت"
            />
          </div>
        </Card>
      </div>
    </>
  );
};

import { Enum } from "core/domains";
import * as React from "react";
import { ToIranCurrency } from "utils/scripts/decimal";
import { digitSperator } from "utils/scripts/exchange";
import "./transaction-card.scss";

type Props = {
  date: string
  amount: number
  code: string
  action: Enum
};
export const TransactionCard: React.FC<Props> = (props: Props) => {
  return (
    <div className='xpay-transaction-card'>
      <span
        style={{
          backgroundColor: props.action.id === 1 ? "#2ac769" : "#E93C3C",
        }}
      ></span>
      <div className='transaction-card-content'>
        <div className='right'>
          <p>
            {props.action.id === 1
              ? "واریز به کیف پول"
              : "برداشت از کیف پول  "}
          </p>
          <span>{props.date}</span>
        </div>
        <div className='left'>
          <p
            style={{
              color: props.action.id === 1 ? "#2ac769" : "#E93C3C",
            }}
          >
            {digitSperator(props.amount)}
          </p>
          <span className="en">{props.code}</span>
        </div>
      </div>
      <svg
        width='7'
        height='10'
        viewBox='0 0 10 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.356857 4.869C0.411469 4.811 0.617674 4.563 0.809755 4.359C1.93588 3.076 4.87359 0.976 6.41119 0.335C6.6447 0.232 7.23507 0.014 7.55049 0C7.85274 0 8.14086 0.072 8.4158 0.218C8.75854 0.422 9.03347 0.743 9.18413 1.122C9.28111 1.385 9.43176 2.172 9.43176 2.186C9.58241 3.047 9.66527 4.446 9.66527 5.992C9.66527 7.465 9.58241 8.807 9.45907 9.681C9.44494 9.695 9.29429 10.673 9.12952 11.008C8.82727 11.62 8.2369 12 7.60511 12H7.55049C7.13903 11.985 6.27372 11.605 6.27372 11.591C4.81898 10.949 1.94906 8.952 0.795631 7.625C0.795631 7.625 0.470788 7.284 0.329551 7.071C0.109223 6.765 0 6.386 0 6.007C0 5.584 0.123346 5.19 0.356857 4.869Z'
          fill='#859ABC'
        />
      </svg>
    </div>
  );
};

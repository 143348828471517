export const SellPmHelperText = () => {
  return (
    <>
      <li>
        <p>
          <span className="number">1</span>
          در کادر اول کد ووچر و کادر دوم کد فعالسازی ووچر را وارد نمائید ، در
          صورتی که کد ها سالم باشند فروش شماانجام میشود
        </p>
      </li>
      <li>
        <p>
          <span className="number">2</span>
          پس از ثبت، چنانچه قصد دريافت معادل توماني را داشته باشيد، در صورت
          تایید انتقال،و اتخاب کارت بانکی مبلغ به حساب شما واریز خواهد شد. ، در
          غیر اینصورت میتوانید از گزینه واریز به کیف پول استفاده نمائید
        </p>
      </li>
      <li>
        <p>
          <span className="number">3</span>
          کیف پول داخلی برای کاربرانی که خریدهای مکرر داشته و یا قصد تبدیل به
          سایر ارزها را دارند مناسب تر است. در صورت صلاح دید میتوانید مبلغ را در
          کیف پول خود در سایت نگه داری کنید.
        </p>
      </li>
    </>
  );
};

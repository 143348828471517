import * as React from 'react';
import { useEffect } from 'react';
import { Button, Input } from 'components';
import {
  CaretLeftFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import 'routes/pages/signup/stages/signup/signup.scss';
import { phoneRegex } from 'utils/constants/constants';
import { post, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { MobileNavigate } from 'components/mobile-navigate/mobile-navigate';
import { faNumToEnNum, IsNumeric } from 'utils/scripts/decimal';
import { message } from 'components/toast/toast';
import { Link, useNavigate } from 'react-router-dom';
import { APPNAME, TERM_LINK } from '../../../../../apis/constants';
import { useQuery } from '../../../../../utils/scripts/route';

type fieldHelperTextType = {
  password: string[];
  phone: string[];
  repeatPassword: string[];
};

type fieldErrorType = {
  password: string[];
  phone: string[];
  repeatPassword: string[];
};

type hasErrorType = {
  password: boolean;
  phone: boolean;
  repeatPassword: boolean;
};

const { useState } = React;

type Props = {};

export const SignupStage: React.FC<Props> = (props) => {
  const [referralValue, setReferralValue] = useState<string>('');
  const [referralIsOpen, setReferralIsOpen] = useState<boolean>(false);
  const [repeatPasswordValue, setRepeatPasswordValue] = useState<string>('');
  const [passwordIsHidden, setPasswordIsHidden] = useState<boolean>(true);
  const [rememberMeIsChecked, setRememberMeIsChecked] =
    useState<boolean>(false);
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [greenHelperText, setGreenHelperText] = useState<string[]>(['']);
  const [fieldHelperText, setFieldHelperText] = useState<fieldHelperTextType>({
    password: [''],
    phone: ['شماره باید به اسم خودتان باشد.'],
    repeatPassword: [''],
  });
  const [hasError, setHasError] = useState<hasErrorType>({
    password: false,
    phone: false,
    repeatPassword: false,
  });
  const [fieldError, setFieldError] = useState<fieldErrorType>({
    password: [''],
    phone: [''],
    repeatPassword: [''],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [repeatPasswordIsHidden, setRepeatPasswordIsHidden] =
    useState<boolean>(true);

  const navigate = useNavigate();

  let query = useQuery();
  useEffect(() => {
    var referralCode = query.get('ref');
    if (referralCode) {
      setReferralValue(referralCode);
    }
  }, []);

  function handleSubmit(): void {
    setHasError({
      password: false,
      repeatPassword: false,
      phone: false,
    });
    setFieldError({
      password: [''],
      repeatPassword: [''],
      phone: [''],
    });
    if (passwordValue === repeatPasswordValue) {
      setIsLoading(true);
      post(API.signup, {
        phoneNumber: phoneValue,
        password: passwordValue,
        repeatPassword: repeatPasswordValue,
        referralCode: referralValue,
      })
        .then(function (response: any) {
          setIsLoading(false);
          if (responseValidator(response.status)) {
            message.success('حساب شما با موفقیت ایجاد شد.');
            localStorage.setItem('phoneNumber', phoneValue);
            localStorage.setItem('referralCode', referralValue);
            localStorage.setItem('passwordValue', passwordValue);
            localStorage.setItem('repeatPasswordValue', repeatPasswordValue);
            navigate('/register/code');
          }
        })
        .catch(function (error: any) {
          setIsLoading(false);
        });
    } else {
      if (passwordValue !== repeatPasswordValue) {
        setHasError((prev) => ({
          ...prev,
          repeatPassword: true,
        }));
        setFieldError((prev) => ({
          ...prev,
          repeatPassword: ['تکرار پسورد با پسورد همخوانی ندارد'],
        }));
      }
      // if (passwordValue.search(passwordRegex) !== 0) {
      //   setHasError((prev) => ({
      //     ...prev,
      //     password: true,
      //   }));
      //   setFieldError((prev) => ({
      //     ...prev,
      //     password: ['پسورد وارد شده قابل قبول نیست.'],
      //   }));
      // }
      if (phoneValue.search(phoneRegex) !== 0) {
        setHasError((prev) => ({
          ...prev,
          phone: true,
        }));
        setFieldError((prev) => ({
          ...prev,
          phone: ['شماره موبایل وارد شده نادرست است.'],
        }));
      }
    }
  }

  function handlePasswordChange(value: string): void {
    setFieldError((prev) => ({
      ...prev,
      password: [''],
    }));
    setHasError((prev) => ({
      ...prev,
      password: false,
    }));
    setGreenHelperText(['']);
    setPasswordValue('');
    if (value.length < 6) {
      setHasError((prev) => ({
        ...prev,
        password: true,
      }));
      setFieldError((prev) => ({
        ...prev,
        password: [...prev.password, 'حداقل ۶ کاراکتر داشته باشد.'],
      }));
    } else {
      setGreenHelperText((prev) => [...prev, 'حداقل ۶ کاراکتر داشته باشد.']);
      setFieldError((prev) => ({
        ...prev,
        password: prev.password,
      }));
    }

    if (
      value.includes('1') ||
      value.includes('2') ||
      value.includes('3') ||
      value.includes('4') ||
      value.includes('5') ||
      value.includes('6') ||
      value.includes('7') ||
      value.includes('8') ||
      value.includes('9') ||
      value.includes('0')
    ) {
      setGreenHelperText((prev) => [...prev, 'حداقل یک عدد داشته باشد.']);
      setFieldError((prev) => ({
        ...prev,
        password: prev.password,
      }));
    } else {
      setHasError((prev) => ({
        ...prev,
        password: true,
      }));
      setFieldError((prev) => ({
        ...prev,
        password: [...prev.password, 'حداقل یک عدد داشته باشد.'],
      }));
    }

    if (value.length === 0) {
      setHasError((prev) => ({
        ...prev,
        password: false,
      }));
      setFieldError((prev) => ({
        ...prev,
        password: [''],
      }));
    }

    setPasswordValue(value);
  }

  function handlePhoneChange(value: string): void {
    setHasError((prev) => ({
      ...prev,
      phone: false,
    }));
    setFieldError((prev) => ({
      ...prev,
      phone: [''],
    }));

    // all number Fa to En
    if (value !== '') value = faNumToEnNum(value);
    // validation nationalCode
    if (value !== '' && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    setPhoneValue(value);
  }

  return (
    <div className="signup-stage">
      <MobileNavigate
        title="ایجاد حساب کاربری"
        onBackClick={() => window.open('/login', '_self')}
        backBtnDisabled={true}
      />
      <h3>ایجاد حساب کاربری</h3>
      <form>
        <Input
          name="phone"
          type="text"
          className="signup-input"
          value={phoneValue}
          onChange={(el) => handlePhoneChange(el.target.value)}
          placeholder="موبایل"
          onPressEnter={() => handleSubmit()}
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <img className="start-adornment" src="assets/icons/Call.svg" />
              ),
            },
          }}
          helperText={fieldHelperText.phone}
          errors={fieldError.phone}
          maxLength={11}
          dir={phoneValue.length !== 0 ? 'ltr' : 'rtl'}
        />
        <Input
          name="password"
          type={passwordIsHidden ? 'password' : 'text'}
          className="signup-input"
          value={passwordValue}
          onChange={(el) => handlePasswordChange(el.target.value)}
          placeholder="رمز عبور"
          onPressEnter={() => handleSubmit()}
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <img
                  className="start-adornment"
                  src="assets/icons/unlock.svg"
                />
              ),
            },
            endAdornment: {
              adornmentIcon: passwordIsHidden ? (
                <EyeOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => setPasswordIsHidden(false)}
                />
              ) : (
                <EyeInvisibleOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => setPasswordIsHidden(true)}
                />
              ),
            },
          }}
          errors={fieldError.password}
          greenHelperText={greenHelperText}
          dir={passwordValue.length !== 0 ? 'ltr' : 'rtl'}
        />
        <Input
          name="repeat-password"
          type={repeatPasswordIsHidden ? 'password' : 'text'}
          className="signup-input"
          value={repeatPasswordValue}
          onChange={(el) => setRepeatPasswordValue(el.target.value)}
          placeholder="تکرار رمز عبور"
          onPressEnter={() => handleSubmit()}
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <img
                  className="start-adornment"
                  src="/assets/icons/unlock.svg"
                />
              ),
            },
            endAdornment: {
              adornmentIcon: repeatPasswordIsHidden ? (
                <EyeOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => setRepeatPasswordIsHidden(false)}
                />
              ) : (
                <EyeInvisibleOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() => setRepeatPasswordIsHidden(true)}
                />
              ),
            },
          }}
          errors={fieldError.repeatPassword}
          dir={repeatPasswordValue.length !== 0 ? 'ltr' : 'rtl'}
        />
        <div className="signup-options">
          <div
            className="signup-referral"
            onClick={() => setReferralIsOpen((prev: boolean) => !prev)}
          >
            <CaretLeftFilled className={`${referralIsOpen ? 'active' : ''}`} />
            <span>کد معرف دارید؟</span>
          </div>
          {referralIsOpen ? (
            <div className="signup-referral-box">
              <Input
                name="referral"
                type="text"
                placeholder="کد معرف را وارد کنید"
                value={referralValue}
                onChange={(el) => setReferralValue(el.target.value)}
                maxLength={10}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="rules">
          با عضویت در {APPNAME}{' '}
          <a target="_blank" href={TERM_LINK}>
            قوانین و شرایط استفاده{' '}
          </a>{' '}
          را میپذیرم.
        </div>
        <Button
          type="info"
          onClick={() => handleSubmit()}
          text="ثبت نام"
          className={`signup-button ${
            hasError.password ||
            hasError.phone ||
            passwordValue.length === 0 ||
            isLoading
              ? 'button-disabled'
              : ''
          }`}
          htmlType="button"
          disabled={
            hasError.password ||
            hasError.phone ||
            passwordValue.length === 0 ||
            isLoading
              ? true
              : false
          }
          loading={isLoading ? true : false}
        />
        <div className="other-options">
          <div className="signin">
            <span>حساب کاربری دارید؟</span>{' '}
            <Link to="/login">بازگشت به صفحه ورود</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

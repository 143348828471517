import * as React from 'react';
import './confirm.scss';
import 'components/transaction-box/transaction-box.scss';
import { Alert, Button, Card } from 'components';
import { Market } from 'core/domains';
import { getIconPath } from 'utils/scripts/getIconPath';
import { createOrders } from 'core/repositories/orders/order';
import { Side } from '../../calculator/script/calculator';
import {
  deleteCommaSperator,
  digitSperator,
} from '../../../../../../utils/scripts/exchange';

const { useState } = React;

type Props = {
  side: string;
  sourceMarket: Market;
  destinationMarket: Market;
  sourceValue: number;
  destinationValue: number;
  commissions: any;
  setModalIsOpen: React.Dispatch<any>;
  setSourceValue: React.Dispatch<any>;
  setDestinationValue: React.Dispatch<any>;
};

export const OrderModalConfirm: React.FC<Props> = ({
  side,
  sourceMarket,
  destinationMarket,
  sourceValue,
  destinationValue,
  commissions,
  setModalIsOpen,
  setSourceValue,
  setDestinationValue,
}) => {
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const clearInputs = () => {
    setSourceValue(0);
    setDestinationValue(0);
  };

  return (
    <Card className="detail-card">
      <div className="confirm-modal confirm-purchase">
        <div className="row gray-titles">
          <div className="col">
            <span>نام ارز</span>
            <div className="coin-info">
              {side === 'sell' ? (
                <>
                  <img src={getIconPath(sourceMarket?.symbol)} />{' '}
                  <span>{sourceMarket?.name}</span>
                </>
              ) : (
                <>
                  <img src={getIconPath(destinationMarket?.symbol)} />
                  <span>{destinationMarket?.name}</span>
                </>
              )}
            </div>
          </div>
          <div className="col">
            <span>نوع سفارش</span>
            <div>
              <span>{side === 'buy' ? 'خرید' : 'فروش'}</span>
            </div>
          </div>
          {commissions && (
            <div className="col desktop-show">
              <span>کارمزد</span>
              <div>
                <span>{`${digitSperator(
                  side === 'buy'
                    ? commissions.filter((i: any) => i.orderSide === 1)[0]
                        .commission
                    : commissions.filter((i: any) => i.orderSide === 2)[0]
                        .commission,
                )} تومان`}</span>
              </div>
            </div>
          )}
        </div>
        <div className={'row gray-titles mobile-show has-divider-before'}>
          {commissions && (
            <div className="col">
              <span>کارمزد</span>
              <div>
                <span>{`${digitSperator(
                  side === 'buy'
                    ? commissions.filter((i: any) => i.orderSide === 1)[0]
                        .commission
                    : commissions.filter((i: any) => i.orderSide === 2)[0]
                        .commission,
                )} تومان`}</span>
              </div>
            </div>
          )}
        </div>
        <div className="row has-divider-before two-column-row  gray-titles">
          <div className="col">
            <>
              <span>مقدار {side === 'buy' ? 'پرداختی' : 'فروشی'}</span>
              <div>
                <span
                  className={`${sourceMarket.symbol === 'IRT' ? '' : 'en'}`}
                >
                  {sourceMarket.symbol === 'IRT' ? (
                    <>
                      {sourceValue} {'تومان'}
                    </>
                  ) : (
                    <>
                      {sourceMarket?.symbol} {sourceValue}
                    </>
                  )}
                </span>
              </div>
            </>
          </div>

          <div className="col">
            <span>مقدار دریافتی تقریبی</span>
            <div>
              <span>{`${
                side === 'sell'
                  ? digitSperator(destinationValue)
                  : deleteCommaSperator(destinationValue)
              } ${side === 'sell' ? 'تومان' : destinationMarket.name}`}</span>
            </div>
          </div>
        </div>
        <div className="row has-divider-before">
          <Alert
            message="مقدار تبدیلی که نمایش داده شده با مقداری که دریافت میکنید یکی نیست و نرخ نهایی در لحظه نهایی شدن تراکنش تعیین میگردد "
            type="danger"
            className="confirm-modal-alert"
          />
        </div>

        <div className="row footer has-divider-before ">
          <div className="buttons">
            <Button
              type={'info'}
              text={'ثبت سفارش'}
              disabled={modalLoading}
              loading={modalLoading}
              onClick={async () =>
                await createOrders(
                  setModalIsOpen,
                  setModalLoading,
                  side === 'buy' ? destinationMarket : sourceMarket,
                  sourceValue,
                  side === 'buy' ? Side.Buy : Side.Sell,
                  clearInputs,
                )
              }
            />
            <Button
              type={'outlined'}
              text={'انصراف'}
              onClick={() => setModalIsOpen(false)}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

import * as React from 'react';
import './order-box-dropdown.scss';
import 'components/transaction-box/transaction-box.scss';
import {
  CloseOutlined,
  PushpinOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Input } from 'components';
import { Fiat, Market, MarketAllPrices } from 'core/domains';
import { ToIranCurrency } from 'utils/scripts/decimal';
import { getIconPath } from 'utils/scripts/getIconPath';
import { Drawer } from 'antd';
import { digitSperator } from '../../../../../utils/scripts/exchange';

const { useState } = React;

type Props = {
  selected?: Market;
  onClick: any;
  side: string;
  isOpen: boolean;
  onClose: any;
  markets: Market[];
  fiats: Fiat[];
  searchRef?: any;
  allPrices: MarketAllPrices[];
};

export const OrderBoxCoinsDropDown: React.FC<Props> = ({
  isOpen,
  markets,
  selected,
  onClick,
  onClose,
  side,
  fiats,
  allPrices,
}) => {
  const [search, setSearch] = useState<string>('');
  const [results, setResults] = useState<Market[]>(markets);
  const searchRef = React.useRef<HTMLInputElement>();

  const onDrawerClose = () => {
    onClose(false);
  };
  const onSearchChangeHandler = (value: string) => {
    if (value !== '') {
      setResults([
        ...markets
          .filter(
            (e) =>
              e.symbol.toLowerCase() !== 'irt' &&
              e.symbol.toLowerCase() !== 'crypto',
          )
          .filter(
            (s) =>
              s.symbol.toLowerCase().includes(value.trim().toLowerCase()) ||
              s.name.includes(value),
          ),
        ...fiats.filter(
          (s) =>
            s.symbol.toLowerCase().includes(value.trim().toLowerCase()) ||
            s.name.includes(value),
        ),
      ]);
    } else {
      setResults([...markets, ...fiats]);
    }
    setSearch(value);
  };

  const getMarket = (symbol: string) => {
    return (
      allPrices.filter(
        (coin) => coin.symbol.toUpperCase() === symbol.toUpperCase(),
      )[0] ?? 0
    );
  };

  const getFiat = (symbol: string) => {
    return fiats.filter((coin) => coin.symbol.toLowerCase() === symbol)[0];
  };
  React.useEffect(() => {
    if (searchRef?.current) searchRef?.current?.focus();
    if (isOpen)
      setResults(
        markets.filter((s) => s.symbol.includes('') || s.name.includes('')),
      );
  }, [isOpen]);

  return (
    <>
      <Drawer
        title="انتخاب ارز جهت خرید"
        className="xpay-orderbox-drawer"
        placement={'bottom'}
        closeIcon={<CloseOutlined onClick={onDrawerClose} />}
        zIndex={10000}
        onClose={onDrawerClose}
        open={isOpen === true ? true : false}
      >
        <div className="searchbox">
          <Input
            type="text"
            dir={'rtl'}
            placeholder="نام یا نماد ارز مورد نظر خود را جست و جو کنید"
            adorments={{
              startAdornment: {
                adornmentIcon: <SearchOutlined />,
              },
              endAdornment: {
                adornmentIcon: <CloseOutlined onClick={() => onClose(false)} />,
              },
            }}
            name="searchvalue"
            value={search}
            onChange={(e) => onSearchChangeHandler(e.target.value)}
            ref={searchRef}
          />
        </div>
        {search == '' && (
          <li
            className="coin-item"
            onClick={() => {
              onClick(getFiat('pm'));
              onDrawerClose();
            }}
          >
            <div className="coin-info">
              <img src={getIconPath('pm')} />
              <div className="names">
                <span>ووچر پرفکت مانی</span>
                <span className="en">PM</span>
              </div>
            </div>

            <div className="price-info">
              {getFiat('pm') && side === 'buy' ? (
                <span>
                  قیمت خرید :{' '}
                  <span>
                    {fiats && ToIranCurrency(getFiat('pm')?.sellPrice)} تومان
                  </span>
                </span>
              ) : (
                <span>
                  قیمت فروش :{' '}
                  <span>
                    {fiats && ToIranCurrency(getFiat('pm')?.buyPrice)} تومان
                  </span>
                </span>
              )}
            </div>
          </li>
        )}

        {search == '' && (
          <li
            className="coin-item"
            onClick={() => {
              onClick(getFiat('uusd'));
              onDrawerClose();
            }}
          >
            <div className="coin-info">
              <img src={getIconPath('uusd')} />
              <div className="names">
                <span>یوتوپیا</span>
                <span className="en">UUSD</span>
              </div>
            </div>

            <div className="price-info">
              {getFiat('pm') && side === 'buy' ? (
                <span>
                  قیمت خرید :{' '}
                  <span>
                    {fiats && ToIranCurrency(getFiat('uusd')?.sellPrice)} تومان
                  </span>
                </span>
              ) : (
                <span>
                  قیمت فروش :{' '}
                  <span>
                    {fiats && ToIranCurrency(getFiat('uusd')?.buyPrice)} تومان
                  </span>
                </span>
              )}
            </div>
          </li>
        )}
        {search == '' && (
          <li
            className="coin-item"
            onClick={() => {
              onClick(getFiat('usdt'));
              onDrawerClose();
            }}
          >
            <div className="coin-info">
              <img src={getIconPath('usdt')} />
              <div className="names">
                <span>تتر</span>
                <span className="en">USDT</span>
              </div>
            </div>

            <div className="price-info">
              {getFiat('usdt') && side === 'buy' ? (
                <span>
                  قیمت خرید :{' '}
                  <span>
                    {fiats && ToIranCurrency(getFiat('usdt')?.sellPrice)} تومان
                  </span>
                </span>
              ) : (
                <span>
                  قیمت فروش :{' '}
                  <span>
                    {fiats && ToIranCurrency(getFiat('usdt')?.buyPrice)} تومان
                  </span>
                </span>
              )}
            </div>
          </li>
        )}

        {search == '' && (
          <li
            className="coin-item"
            onClick={() => {
              onClick(getMarket('trx'));
              onDrawerClose();
            }}
          >
            <div className="coin-info">
              <img src={getIconPath('trx')} />
              <div className="names">
                <span>ترون</span>
                <span className="en">TRX</span>
              </div>
            </div>

            <div className="price-info">
              {getMarket('trx') && side === 'buy' ? (
                <span>
                  قیمت خرید :{' '}
                  <span>
                    {markets && ToIranCurrency(getMarket('trx')?.sellPrice)}{' '}
                    تومان
                  </span>
                </span>
              ) : (
                <span>
                  قیمت فروش :{' '}
                  <span>
                    {markets && ToIranCurrency(getMarket('trx')?.buyPrice)}{' '}
                    تومان
                  </span>
                </span>
              )}
            </div>
          </li>
        )}

        {results
          .filter((coin) => coin !== selected)
          .filter((coin) => coin.symbol.toLowerCase() !== 'irt')
          .filter((coin) => coin.symbol.toLowerCase() !== 'trx')
          .filter((coin) => coin.symbol.toLowerCase() !== 'pm')
          .filter((coin) => coin.symbol.toLowerCase() !== 'usdt')
          .filter((coin) => coin.symbol.toLowerCase() !== 'uusd')
          .map((item, index) => (
            <React.Fragment key={`coin-${index}`}>
              <div
                className="coin-item"
                onClick={() => {
                  onClick(item);
                  onDrawerClose();
                }}
              >
                <div className="coin-info">
                  <img src={getIconPath(item.symbol)} />
                  <div className="names">
                    <span>{item.name}</span>
                    <span className="en">{item.symbol}</span>
                  </div>
                </div>
                <div className="price-info">
                  {side === 'buy' ? (
                    <span>
                      قیمت خرید :{' '}
                      <span>
                        {digitSperator(getMarket(item.symbol).buyPrice)} تومان
                      </span>
                    </span>
                  ) : (
                    <span>
                      قیمت فروش :{' '}
                      <span>
                        {ToIranCurrency(getMarket(item.symbol).sellPrice)} تومان
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
      </Drawer>
      <div className="desktop-show">
        <div className={`xpay-orderbox-dropdown ${isOpen ? 'active' : ''}`}>
          <div className="searchbox">
            <Input
              type="text"
              dir={'rtl'}
              placeholder="نام یا نماد ارز مورد نظر خود را جست و جو کنید"
              adorments={{
                startAdornment: {
                  adornmentIcon: <SearchOutlined />,
                },
                endAdornment: {
                  adornmentIcon: (
                    <CloseOutlined onClick={() => onClose(false)} />
                  ),
                },
              }}
              name="searchvalue"
              value={search}
              onChange={(e) => onSearchChangeHandler(e.target.value)}
              ref={searchRef}
            />
          </div>
          <ul>
            <ul>
              {search == '' && (
                <li
                  className="pinned"
                  onClick={() => {
                    onClick(getFiat('pm'));
                    onClose(false);
                  }}
                >
                  <div className="coin-info">
                    <PushpinOutlined />
                    <img src={`/coins/pm.svg`} />
                    <span>ووچر پرفکت مانی</span>
                    <span>PM</span>
                  </div>
                  <div className="price-info">
                    {getFiat('pm') && side === 'buy' ? (
                      <span>
                        قیمت خرید :{' '}
                        <b>
                          {fiats && ToIranCurrency(getFiat('pm')?.sellPrice)}{' '}
                          تومان
                        </b>
                      </span>
                    ) : (
                      <span>
                        قیمت فروش :{' '}
                        <b>
                          {fiats && ToIranCurrency(getFiat('pm')?.buyPrice)}{' '}
                          تومان
                        </b>
                      </span>
                    )}
                  </div>
                </li>
              )}
              {search == '' && (
                <li
                  className="pinned"
                  onClick={() => {
                    onClick(getFiat('uusd'));
                    onClose(false);
                  }}
                >
                  <div className="coin-info">
                    <PushpinOutlined />
                    <img src={`/coins/UUSD.png`} />
                    <span>یوتوپیا</span>
                    <span>UUSD</span>
                  </div>
                  <div className="price-info">
                    {getFiat('uusd') && side === 'buy' ? (
                      <span>
                        قیمت خرید :{' '}
                        <b>
                          {fiats && ToIranCurrency(getFiat('uusd')?.sellPrice)}{' '}
                          تومان
                        </b>
                      </span>
                    ) : (
                      <span>
                        قیمت فروش :{' '}
                        <b>
                          {fiats && ToIranCurrency(getFiat('uusd')?.buyPrice)}{' '}
                          تومان
                        </b>
                      </span>
                    )}
                  </div>
                </li>
              )}
              {search == '' && (
                <li
                  className="pinned"
                  onClick={() => {
                    onClick(getFiat('usdt'));
                    onClose(false);
                  }}
                >
                  <div className="coin-info">
                    <PushpinOutlined />
                    <img src={`/coins/usdt.svg`} />
                    <span>تتر</span>
                    <span>usdt</span>
                  </div>
                  <div className="price-info">
                    {getFiat('usdt') && side === 'buy' ? (
                      <span>
                        قیمت خرید :{' '}
                        <b>
                          {fiats && ToIranCurrency(getFiat('usdt')?.sellPrice)}{' '}
                          تومان
                        </b>
                      </span>
                    ) : (
                      <span>
                        قیمت فروش :{' '}
                        <b>
                          {fiats && ToIranCurrency(getFiat('usdt')?.buyPrice)}{' '}
                          تومان
                        </b>
                      </span>
                    )}
                  </div>
                </li>
              )}

              {search == '' && (
                <li
                  className="pinned"
                  onClick={() => {
                    onClick(getMarket('trx'));
                    onClose(false);
                  }}
                >
                  <div className="coin-info">
                    <PushpinOutlined />
                    <img src={`/coins/trx.svg`} />
                    <span>ترون</span>
                    <span>trx</span>
                  </div>
                  <div className="price-info">
                    {getMarket('trx') && side === 'buy' ? (
                      <span>
                        قیمت خرید :{' '}
                        <b>
                          {markets &&
                            ToIranCurrency(getMarket('trx')?.sellPrice)}{' '}
                          تومان
                        </b>
                      </span>
                    ) : (
                      <span>
                        قیمت فروش :{' '}
                        <b>
                          {markets &&
                            ToIranCurrency(getMarket('trx')?.buyPrice)}{' '}
                          تومان
                        </b>
                      </span>
                    )}
                  </div>
                </li>
              )}

              {search == '' ? (
                <>
                  {results
                    .filter((coin) => coin.symbol.toLowerCase() !== 'irt')
                    .filter((coin) => coin !== selected)
                    .filter((coin) => coin.symbol.toLowerCase() !== 'trx')
                    .filter((coin) => coin.symbol.toLowerCase() !== 'pm')
                    .filter((coin) => coin.symbol.toLowerCase() !== 'usdt')
                    .filter((coin) => coin.symbol.toLowerCase() !== 'uusd')
                    .map((item, index) => (
                      <React.Fragment key={`coin-${index}`}>
                        <li
                          onClick={() => {
                            onClick(item);
                            onClose(false);
                          }}
                        >
                          <div className="coin-info">
                            <img src={getIconPath(item.symbol)} />
                            <span>{item.name}</span>
                            <span>{item.symbol}</span>
                          </div>
                          <div className="price-info">
                            {side === 'buy' ? (
                              <span>
                                قیمت خرید :{' '}
                                <b>{digitSperator(item.sellPrice)} تومان</b>
                              </span>
                            ) : (
                              <span>
                                قیمت فروش :{' '}
                                <b>{ToIranCurrency(item.buyPrice)} تومان</b>
                              </span>
                            )}
                          </div>
                        </li>
                      </React.Fragment>
                    ))}
                </>
              ) : (
                <>
                  {results
                    .filter((coin) => coin !== selected)
                    .map((item, index) => (
                      <React.Fragment key={`coin-${index}`}>
                        <li
                          onClick={() => {
                            onClick(item);
                            onClose(false);
                          }}
                        >
                          <div className="coin-info">
                            <img src={getIconPath(item.symbol)} />
                            <span>{item.name}</span>
                            <span>{item.symbol}</span>
                          </div>
                          <div className="price-info">
                            {side === 'buy' ? (
                              <span>
                                قیمت خرید :{' '}
                                <b>{ToIranCurrency(item.sellPrice)} تومان</b>
                              </span>
                            ) : (
                              <span>
                                قیمت فروش :{' '}
                                <b>{ToIranCurrency(item.buyPrice)} تومان</b>
                              </span>
                            )}
                          </div>
                        </li>
                      </React.Fragment>
                    ))}
                </>
              )}
            </ul>
          </ul>
        </div>
      </div>
    </>
  );
};
